import { ChangeDetectorRef, Component } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { API } from '../../../../../_store';
import { Router } from '@angular/router';
import { convertDateTime } from '../../_common';

@Component({
  selector: 'tags-categories-block',
  templateUrl: './tags-categories.component.html',
  styleUrls: ['./tags-categories.component.scss']
})

export class TagsAndCategoriesComponent extends GenericBlock {

  public static type = BlockType.TagsAndCategories;
  public static tagNames = undefined;
  public static blockCategory = 'Immutable';
  public static blockName = 'Tags and categories';
  public static structure = {
    id: null,
    type: BlockType.TagsAndCategories,
    data: null
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-right" class="svg-inline--fa fa-arrow-alt-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 304v-96c0-13.3 10.7-24 24-24h200V80.2c0-21.4 25.8-32.1 41-17L441 239c9.4 9.4 9.4 24.6 0 34L265 448.7c-15.1 15.1-41 4.4-41-17V328H24c-13.3 0-24-10.7-24-24z"></path></svg>';

  readonly _language: string;

  private _filters: any;
  private _active: boolean;

  constructor(
    private api: API,
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this._language = translateService.currentLang;
  }

  public init(): void {
    const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]: ( hash[obj[key]] || [] ).concat(obj)}), {});
    if (this.pageData.tags) {
      this._filters = groupByKey(this.pageData.tags, 'category');
    }
    this.changeDetectorRef.detectChanges();
  }

  public activateFilters(): void {
    this._active = !this._active;
  }

  public goToPage(event: any, tag: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/'], { queryParams: this.tagQueryParams(tag) }).catch();
  }

  public tagQueryParams(tag: any): object {
    const params = {};
    if (tag.category) {
      params[tag.category] = tag.name;
    }
    return params;
  }

  public dateFormat(date: string): string {
    return convertDateTime(date, 'F d Y', false, '-');
  }

  public get type(): string {
    return TagsAndCategoriesComponent.type;
  }

  public get tagNames(): Array<string> {
    return TagsAndCategoriesComponent.tagNames;
  }

  public get blockName(): string {
    return TagsAndCategoriesComponent.blockName;
  }

  public get blockCategory(): string {
    return TagsAndCategoriesComponent.blockCategory;
  }

  public get icon(): string {
    return TagsAndCategoriesComponent.icon;
  }

  public get structure(): object {
    return TagsAndCategoriesComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

  public get active(): boolean {
    return this._active;
  }

  public get filters(): any {
    return this._filters;
  }

}
