import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { markAsTouched } from '../../_helpers/common';
import { API } from '../../_store';
import { HttpClient } from '@angular/common/http';

@AutoUnsubscribe()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public forgotForm: FormGroup;

  private _submitting: boolean;
  private _submitted: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: API,
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']).catch();
    }
    this.forgotForm = new FormGroup({
      email: new FormControl(null, Validators.email)
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public onSubmit(): void {

    markAsTouched(this.forgotForm);

    if (this.forgotForm.invalid) {
      return;
    }

    this._submitting = true;

    this.authService.recover(this.f.email.value).pipe(first()).subscribe(() => {
      this._submitting = false;
      this._submitted = true;
    }, error => {
      this._submitting = false;
      this._submitted = true;
      console.log(error);
    });
  }

  public get f(): any {
    return this.forgotForm.controls;
  }

  public get submitting(): boolean {
    return this._submitting;
  }

  public get submitted(): boolean {
    return this._submitted;
  }
}
