import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { GoogleAnalyticsService } from '../../../../../_services/google-analytics.service';

@Component({
  selector: 'search-cta-block',
  templateUrl: './search-cta.component.html',
  styleUrls: ['./search-cta.component.scss']
})

export class SearchCtaComponent extends GenericBlock {

  public static type = BlockType.SearchCta;
  public static tagNames = undefined;
  public static blockCategory = 'Immutable';
  public static blockName = 'Search CTA';
  public static structure = {
    id: null,
    type: BlockType.SearchCta,
    data: {
      title: 'Not what you\'re looking for?',
      buttonText: 'Search again'
    }
  };

  public static icon = '<svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="20.5" viewBox="0 0 31.5 20.5"> <g transform="translate(23518.5 22321.25)"><path class="a" fill="currentColor" d="M3.75,19.5h-3A.751.751,0,0,1,0,18.75v-3A.751.751,0,0,1,.75,15h3a.751.751,0,0,1,.75.75v3A.751.751,0,0,1,3.75,19.5ZM17,18.375H8.625a1.125,1.125,0,0,1,0-2.25H17v2.249ZM3.75,12h-3A.751.751,0,0,1,0,11.25v-3A.751.751,0,0,1,.75,7.5h3a.751.751,0,0,1,.75.751v3A.751.751,0,0,1,3.75,12ZM14,10.875H8.625a1.125,1.125,0,1,1,0-2.25H14v2.249ZM3.75,4.5h-3A.751.751,0,0,1,0,3.75v-3A.751.751,0,0,1,.75,0h3A.751.751,0,0,1,4.5.75v3A.751.751,0,0,1,3.75,4.5ZM22.875,3.375H8.625a1.125,1.125,0,0,1,0-2.25H22.875a1.125,1.125,0,1,1,0,2.25Z" transform="translate(-23518 -22320.75)"/><path class="b" fill="currentColor" d="M6.808.011l-3.4-3.4a5.661,5.661,0,0,0,.967-3.173A5.688,5.688,0,0,0-1.312-12.25,5.688,5.688,0,0,0-7-6.562,5.688,5.688,0,0,0-1.312-.875a5.661,5.661,0,0,0,3.173-.967l3.4,3.4a.656.656,0,0,0,.928,0L6.808.939A.656.656,0,0,0,6.808.011ZM-4.812-6.562a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-4.812-6.562Z" transform="translate(-23494 -22303)"/></g></svg>';

  public data: any;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private ga: GoogleAnalyticsService
  ) {
    super();
  }

  public init(): void {
    // if (this.block.autoFocus) {
    //   this.elementRef.nativeElement.click();
    // }
    this.data = this.block.data || SearchCtaComponent.structure.data;
    this.changeDetectorRef.detectChanges();
  }

  public searchAgain(): void {
    this.ga.event('searchButtons', 'searchAgain');
  }

  public get type(): string {
    return SearchCtaComponent.type;
  }

  public get tagNames(): Array<string> {
    return SearchCtaComponent.tagNames;
  }

  public get blockName(): string {
    return SearchCtaComponent.blockName;
  }

  public get blockCategory(): string {
    return SearchCtaComponent.blockCategory;
  }

  public get icon(): string {
    return SearchCtaComponent.icon;
  }

  public get structure(): object {
    return SearchCtaComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
