import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SelectItemInterface } from '../../../erom-select/_interfaces';
import {
  EromBlockGroupInterface,
  EromBlockInterface,
  EromGroupInterface,
  EromGroupRowInterface
} from '../_interfaces';
import { EromSelectComponent } from '../../../erom-select/component/erom-select.component';

@AutoUnsubscribe()
@Component({
  selector: 'erom-editor-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['../editor/erom-editor.component.scss', './schema.component.scss']
})
export class EromEditorSchemaComponent implements OnDestroy {

  @Input() public set entity(payload: EromBlockInterface|EromBlockGroupInterface|EromGroupRowInterface|EromGroupInterface) {
    this._entity = payload;
    this._speakableValue = !!this._entity && !!this._entity.schema ? this._entity.schema.speakable : '';
    this._howToValue = !!this._entity && !!this._entity.schema ? this._entity.schema.howTo : '';
    this.resetSelectors();
    this.changeDetectorRef.detectChanges();
  }

  @Output() eromEditorSchemaChanged = new EventEmitter<any>();

  @ViewChildren(EromSelectComponent) selectors: QueryList<any>;

  private _entity: EromBlockInterface|EromBlockGroupInterface|EromGroupRowInterface|EromGroupInterface;
  private _speakableValue: string;
  private _howToValue: string;

  readonly _speakableSchemaTypes: SelectItemInterface[];
  readonly _howToSchemaTypes: SelectItemInterface[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this._speakableSchemaTypes = [
      {
        label: 'None',
        value: ''
      },
      {
        label: 'Image',
        value: 'image'
      },
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Headline',
        value: 'headline'
      },
      {
        label: 'Description',
        value: 'description'
      }
    ];
    this._howToSchemaTypes = [
      {
        label: 'None',
        value: ''
      },
      {
        label: 'Image',
        value: 'image'
      },
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Description',
        value: 'description'
      },
      {
        label: 'Step',
        value: 'step'
      }
    ];
  }

  public ngOnDestroy(): void {
  }

  private resetSelectors(): void {
    if (!this.selectors) {
      return;
    }
    this.selectors.forEach(selector => {
      selector.reset(false);
    });
  }

  public schemaTypeSelected(event: any, type: string): void {
    if (!this._entity) {
      return;
    }
    if (!this._entity.schema) {
      this._entity.schema = {};
    }
    this._entity.schema[type] = event.option.value;
    this.eromEditorSchemaChanged.emit({
      action: 'schemaChanged'
    });
  }

  public get speakableSchemaTypes(): SelectItemInterface[] {
    return this._speakableSchemaTypes || [];
  }

  public get howToSchemaTypes(): SelectItemInterface[] {
    return this._howToSchemaTypes || [];
  }

  public get speakableValue(): string {
    return this._speakableValue || '';
  }

  public get howToValue(): string {
    return this._howToValue || '';
  }

}
