import { ChangeDetectorRef, Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { getCookie, setCookie } from '../../../../../_helpers';
import { SelectItemInterface } from '../../../../erom-select/_interfaces';
import { GoogleAnalyticsService } from '../../../../../_services/google-analytics.service';
import { API } from '../../../../../_store';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'device-check-block',
  templateUrl: './device-check.component.html',
  styleUrls: ['./device-check.component.scss'],
  styles: [':host { display: block; }']
})

export class DeviceCheckComponent extends GenericBlock {

  public static type = BlockType.DeviceCheck;
  public static tagNames = undefined;
  public static blockCategory = 'Immutable';
  public static blockName = 'Device check';
  public static structure = {
    id: null,
    type: BlockType.DeviceCheck,
    data: null
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" class="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>';

  private _wrongArticle: boolean;
  private _tags: Array<any>;

  private _operatingSystem: SelectItemInterface;

  private _operatingSystems: Array<any>;

  constructor(
    private api: API,
    private http: HttpClient,
    private router: Router,
    private elementRef: ElementRef,
    private deviceService: DeviceDetectorService,
    private changeDetectorRef: ChangeDetectorRef,
    private ga: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super();
    this._operatingSystems = [];
  }

  public init(): void {
    this.getAllOperatingSystems();
    this._tags = this.pageData ? (this.pageData.tags || []) : [];
    this.checkUserAgentConfirmNeeded();
    this.changeDetectorRef.detectChanges();
  }

  public get type(): string {
    return DeviceCheckComponent.type;
  }

  public get tagNames(): Array<string> {
    return DeviceCheckComponent.tagNames;
  }

  public get blockName(): string {
    return DeviceCheckComponent.blockName;
  }

  public get blockCategory(): string {
    return DeviceCheckComponent.blockCategory;
  }

  public get icon(): string {
    return DeviceCheckComponent.icon;
  }

  public get structure(): object {
    return DeviceCheckComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

  private getAllOperatingSystems(): void {
    this.http.get(`${this.api.URLS.tag}/type/Operating system`, {}).subscribe((response: Array<any>) => {
      this._operatingSystems = response.map(r => {
        return {
          ...r,
          synonyms: Object.values(JSON.parse(r.synonyms) || []) || []
        };
      });
      this.findOperatingSystem();
    }, (error) => {
      console.log(error);
    });
  }

  private checkUserAgentConfirmNeeded(): void {
    if (isPlatformBrowser(this.platformId)) {
      const deviceConfirmed = getCookie(`${this.pageData.id}-device-confirmed`);
      if (deviceConfirmed === 'true') {
        this._wrongArticle = false;
        return;
      }
      const operatingSystemTag = this._tags.filter(t => t.category === 'Operating system');
      // const browserTag = this._tags.filter(t => t.category === 'Browser');
      let operatingSystem = null;
      let operatingSystemFound = false;
      // let browserFound = false;
      for (let i = 0; i < operatingSystemTag.length; i++) {
        if (operatingSystemFound) {
          continue;
        }
        // operatingSystemFound = this.deviceService.os.toLowerCase() === operatingSystemTag[i].name.toString().toLowerCase();

        if (!operatingSystemFound) {
          operatingSystem = operatingSystemTag[i].synonyms.some(s => s.toLowerCase() === this.deviceService.os_version.toLowerCase()) ?
            operatingSystemTag[i] : null;
          if (!operatingSystem) {
            const iosVersion = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
            if (iosVersion && iosVersion.length) {
              operatingSystem = operatingSystemTag[i].name.toLowerCase() === (`${this.deviceService.os_version} ${iosVersion[1]}`)
                .toLowerCase() || operatingSystemTag[i].synonyms.some(s => s.toLowerCase() === (`${this.deviceService.os_version} ${iosVersion[1]}`).toLowerCase()) ?
                operatingSystemTag[i] : null;
            } else {
              const os = navigator.userAgent.slice(13).split(';').map(o => o.trim());
              operatingSystem = operatingSystemTag[i].name.toLowerCase() === os[1].toLowerCase() ||
              operatingSystemTag[i].synonyms.some(s => s.toLowerCase() === os[1].toLowerCase()) ?
                operatingSystemTag[i] : null;
            }
            if (!operatingSystem) {
              operatingSystem = operatingSystemTag[i].name.toLowerCase() === this.deviceService.os.toLowerCase() ||
              operatingSystemTag[i].synonyms.some(s => s.toLowerCase() === this.deviceService.os.toLowerCase()) ?
                operatingSystemTag[i] : null;
              if (!operatingSystem) {
                operatingSystem = operatingSystemTag[i].name.toLowerCase().search(this.deviceService.os.toLowerCase()) > -1 ||
                operatingSystemTag[i].synonyms.some(s => s.toLowerCase().search(this.deviceService.os.toLowerCase()) > -1) ?
                  operatingSystemTag[i] : null;
              }
            }
          }
        }
        operatingSystemFound = !!operatingSystem;
      }
      // for (let i = 0; i < browserTag.length; i++) {
      //   if (browserFound) {
      //     continue;
      //   }
      //   browserFound = this.deviceService.browser.toLowerCase() === browserTag[i].name.toString().toLowerCase();
      // }
      // this._wrongArticle = !operatingSystemFound || !browserFound;
      this._wrongArticle = !operatingSystemFound;
    }
    this.findOperatingSystem();
  }

  private findOperatingSystem(): void {
    if (!this._wrongArticle) {
      return;
    }
    this._operatingSystem = this._operatingSystems.find(o =>
      o.synonyms.some(s => s.toLowerCase() === this.deviceService.os_version.toLowerCase()));
    if (!this._operatingSystem) {
      const iosVersion = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (iosVersion && iosVersion.length) {
        this._operatingSystem = this._operatingSystems.find(o =>
          o.label.toLowerCase() === (`${this.deviceService.os_version} ${iosVersion[1]}`).toLowerCase() ||
          o.synonyms.some(s => s.toLowerCase() === (`${this.deviceService.os_version} ${iosVersion[1]}`).toLowerCase()));
      } else {
        const os = navigator.userAgent.slice(13).split(';').map(o => o.trim());
        this._operatingSystem = this._operatingSystems.find(o => o.label.toLowerCase() === os[1].toLowerCase() ||
          o.synonyms.some(s => s.toLowerCase() === os[1].toLowerCase()));
      }
      if (!this._operatingSystem) {
        this._operatingSystem = this._operatingSystems.find(o => o.label.toLowerCase() === this.deviceService.os.toLowerCase() ||
          o.synonyms.some(s => s.toLowerCase() === this.deviceService.os.toLowerCase()));
        if (!this._operatingSystem) {
          this._operatingSystem = this._operatingSystems.find(o => o.label.toLowerCase()
              .search(this.deviceService.os.toLowerCase()) > -1 ||
            o.synonyms.some(s => s.toLowerCase().search(this.deviceService.os.toLowerCase()) > -1));
        }
      }
    }
  }

  public seeResults(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.ga.event('searchButtons', 'seeResultsForYourDevice');
    this.router.navigate(['/'], {
      queryParams: {
        'Operating system': this._operatingSystem ? this._operatingSystem.label : this.deviceService.os,
        // Browser: this.deviceService.browser
      }
    }).catch();
  }

  public close(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this._wrongArticle = false;
    if (isPlatformBrowser(this.platformId)) {
      setCookie(`${this.pageData.id}-device-confirmed`, 'true');
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
    }
  }

  public get wrongArticle(): boolean {
    if (this.editMode) {
      return true;
    }
    return this._wrongArticle;
  }

}
