import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { UuidService } from '../../../../../../src/app/_services/uuid.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-article-filters',
  templateUrl: './article-filters.component.html',
  styleUrls: ['./article-filters.component.scss']
})
export class ArticleFiltersComponent implements OnInit, OnDestroy {

  @Input() date: string;
  @Input() set tags(payload: Array<any>) {
    const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {});
    this._filters = groupByKey(payload, 'category');
  }

  private _filters: Array<any>;
  private _active: boolean;
  readonly _id: string;

  constructor(
    private uuidService: UuidService
  ) {
    this._id = uuidService.uuid;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public activateFilters(): void {
    this._active = !this._active;
  }

  public tagQueryParams(tag: any): object {
    const params = {};
    if (tag['category']) {
      params[tag['category']] = tag['id'];
    }
    return params;
  }

  public get id(): string {
    return this._id;
  }

  public get active(): boolean {
    return this._active;
  }

  public get filters(): Array<any> {
    return this._filters;
  }

}
