export enum GridType {
  ONE_FULL = '100',
  TWO_50_50 = '50-50',
  TWO_75_25 = '75-25',
  TWO_65_35 = '65-35',
  TWO_25_75 = '25-75',
  THREE_25_25_50 = '25-25-50',
  THREE_25_50_25 = '25-50-25',
  THREE_50_25_25 = '50-25-25',
  FOUR_25_25_25_25 = '25-25-25-25'
}
