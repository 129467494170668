<div class="erom-editor"
     (paste)="editorActioned($event)"
     (keydown)="editorActioned($event)">
  <form class="erom-editor__container"
        [formGroup]="formData"
        (submit)="submit($event)"
        [style.minHeight.px]="pageHeight">
    <div class="erom-editor__content" role="region" [attr.aria-label]="'editor.EDITOR_CONTENT' | translate">
      <ng-content select="[top]"></ng-content>
      <perfect-scrollbar #editorContent
                         [autoPropagation]="true"
                         [style.maxHeight.px]="editorHeight">
        <div class="erom-editor__group erom-editor__group--title"
             [ngClass]="groupClasses(['title', 'alias'])">
          <div class="erom-editor__group--title__inner">
            <label for="title" class="erom-editor-visually-hidden">{{ 'editor.TITLE' | translate }}</label>
            <input id="title"
                   type="text"
                   class="erom-editor__title"
                   autocomplete="off"
                   [class.active]="activeArea === 'title'"
                   (focus)="activateNonEditorArea('title')"
                   [placeholder]="'editor.ADD_TITLE' | translate"
                   formControlName="title" />
            <div class="erom-editor__title--alias"
                 *ngIf="!sectionTypePage"
                 (click)="activateNonEditorArea('title')">
              <label for="alias" class="erom-editor__title--alias__label">{{ 'editor.PERMALINK' | translate }}</label>
              <div class="erom-editor__title--alias__input"
                   (click)="$event.stopPropagation(); aliasInput.focus()"
                   [class.active]="activeArea === 'alias' || aliasChanged">
                <span>{{ origin }}</span>
                <input id="alias"
                       type="text"
                       autocomplete="off"
                       #aliasInput
                       (focus)="$event.stopPropagation(); activateNonEditorArea('alias')"
                       (input)="changeAlias($event)"
                       [value]="pageData.alias"
                       (keydown.enter)="$event.preventDefault(); saveAlias($event)" />
              </div>
              <button type="button" class="erom-editor__title--alias__submit alias-submit"
                      *ngIf="activeArea === 'alias' || aliasChanged"
                      (click)="saveAlias($event)"
                      [attr.aria-label]="'editor.PERMALINK_SUBMIT' | translate">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>
              </button>
              <button type="button" class="erom-editor__title--alias__submit alias-edit"
                      *ngIf="activeArea !== 'alias' && !aliasChanged"
                      (click)="$event.stopPropagation(); aliasInput.focus()"
                      [attr.aria-label]="'editor.PERMALINK_EDIT' | translate">
                <fa-icon [icon]="['fas', 'pen']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="erom-editor__content--inner">
          <ng-content select="[contentTop]"></ng-content>
          <div class="erom-editor__blocks-list"
               cdkDropList
               #editorBlocks
               [cdkDropListDisabled]="!groupCanDrag"
               [cdkDropListData]="contentGroups"
               (cdkDropListDropped)="dropGroup($event)">
            <div class="erom-editor__group erom-editor__group--block"
                 cdkDrag
                 role="region"
                 [attr.aria-label]="'editor.EDITOR_SECTION' | translate"
                 (cdkDragStarted)="dragStarted(false)"
                 (cdkDragEnded)="dragEnded()"
                 [ngClass]="groupClasses(groupIdPrefix + group.id)"
                 (click)="activateGroup($event, groupIdPrefix + group.id)"
                 *ngFor="let group of contentGroups; let groupIndex = index">
              <div class="erom-editor__group-overlay"
                   [class.active]="groupActionsCanBeDisplayed(group.id)">
                <div class="erom-editor-actions"
                     (click)="$event.stopPropagation()"
                     [class.active]="groupActionsCanBeDisplayed(group.id)">
                  <erom-editor-toolbar [hidden]="!groupActionsCanBeDisplayed(group.id)"
                                       [label]="'editor.GROUP_TOOLBAR' | translate"
                                       [id]="groupIdPrefix + group.id">
                    <div class="erom-editor-toolbar-group">
                      <span class="erom-editor-drag__handle"
                            [class.disabled]="!groupCanDrag"
                            cdkDragHandle>
                        <span class="erom-editor-handle"></span>
                      </span>
                      <button class="erom-editor-toolbar__item erom-editor__section-move move-up"
                              type="button"
                              [attr.aria-label]="'editor.MOVE_UP' | translate"
                              [attr.aria-disabled]="!canMove(contentGroups, group, 'up')"
                              [class.disabled]="!canMove(contentGroups, group, 'up')"
                              (click)="moveGroup(group, 'up')"></button>
                      <button class="erom-editor-toolbar__item erom-editor__section-move move-down"
                              type="button"
                              [attr.aria-label]="'editor.MOVE_DOWN' | translate"
                              [attr.aria-disabled]="!canMove(contentGroups, group, 'down')"
                              [class.disabled]="!canMove(contentGroups, group, 'down')"
                              (click)="moveGroup(group, 'down')"></button>
                    </div>
                  </erom-editor-toolbar>
                </div>
                <erom-editor-selector [empty]="!contentGroups.length"
                                      [inside]="true"
                                      [sections]="sections"
                                      *ngIf="groupActionsCanBeDisplayed(group.id)"
                                      [blocks]="blocks"
                                      (blockAdded)="gridBlockAdded($event, group.grids, group.id)"
                                      (sectionAdded)="gridSectionAdded($event)"></erom-editor-selector>
                <div class="erom-editor-settings"
                     [class.active]="groupActionsCanBeDisplayed(group.id)">
                  <erom-editor-toolbar [hidden]="!groupActionsCanBeDisplayed(group.id)"
                                       [label]="'editor.GROUP_SETTINGS' | translate"
                                       [id]="groupIdPrefix + group.id">
                    <div class="erom-editor-settings__group">
                      <div class="erom-editor-settings__switch" [class.active]="!group.hidden">
                        <label [for]="'eromBlockVisibilitySwitch-' + groupIndex">
                          <input type="checkbox"
                                 class="erom-editor-toolbar__item"
                                 [attr.aria-label]="'editor.VISIBILITY' | translate"
                                 [attr.aria-pressed]="!group.hidden"
                                 (keydown.enter)="$event.preventDefault(); changeGroupVisibility(group)"
                                 (change)="changeGroupVisibility(group)"
                                 [checked]="!group.hidden ? 'checked' : ''"
                                 [id]="'eromBlockVisibilitySwitch-' + groupIndex" />
                          <span class="unchecked"><fa-icon [icon]="['fas', 'eye-slash']"></fa-icon></span>
                          <span class="checked"><fa-icon [icon]="['fas', 'eye']"></fa-icon></span>
                        </label>
                      </div>
                    </div>
                    <div class="erom-editor-settings__group">
                      <button type="button"
                              class="erom-editor-toolbar__item erom-editor-btn__sync"
                              [attr.aria-label]="'editor.CLEAR_CONTENT' | translate"
                              (click)="resetGroup(group)">
                        <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
                      </button>
                      <button type="button"
                              class="erom-editor-toolbar__item erom-editor-btn__clone"
                              [attr.aria-label]="'editor.CLONE' | translate"
                              (click)="cloneGroup(groupIndex)">
                        <fa-icon [icon]="['fas', 'clone']"></fa-icon>
                      </button>
                      <button type="button"
                              class="erom-editor-toolbar__item erom-editor-btn__save"
                              [attr.aria-label]="'editor.SAVE_AS_SECTION' | translate"
                              (click)="saveAsTemplateInit($event)">
                        <fa-icon [icon]="['fas', 'save']"></fa-icon>
                      </button>
                    </div>
                    <div class="erom-editor-settings__group">
                      <button type="button"
                              class="erom-editor-toolbar__item erom-editor-btn__remove"
                              [attr.aria-label]="'editor.REMOVE' | translate"
                              (click)="removeGroup(groupIndex)">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                      </button>
                    </div>
                  </erom-editor-toolbar>
                </div>
              </div>
              <div class="erom-editor__rows"
                   cdkDropList
                   [id]="groupIdPrefix + group.id"
                   [cdkDropListConnectedTo]="connectedGroupList"
                   [cdkDropListData]="group.grids"
                   (cdkDropListEntered)="dragEntered('group', group.id)"
                   (cdkDropListDropped)="dropGrid($event)">
                <div class="erom-editor__rows-item bg-cover-full"
                     cdkDrag
                     role="region"
                     [attr.aria-label]="'editor.EDITOR_GRID' | translate"
                     [style.max-width]="group.width ? group.width : ''"
                     (cdkDragStarted)="dragStarted()"
                     (cdkDragEnded)="dragEnded()"
                     [id]="gridIdPrefix + group.id + '-' + gridIndex"
                     [ngClass]="gridClasses(gridIdPrefix + group.id + '-' + gridIndex, grid)"
                     [ngStyle]="grid.style || []"
                     (click)="activateGrid($event, groupIdPrefix + group.id, gridIdPrefix + group.id + '-' + gridIndex)"
                     *ngFor="let grid of group.grids; let gridIndex = index">
                  <div class="erom-editor__rows-overlay"
                       [class.active]="gridActionsCanBeDisplayed(group.id, gridIndex)">
                    <div class="erom-editor-actions"
                         (click)="$event.stopPropagation()"
                         [class.active]="gridActionsCanBeDisplayed(group.id, gridIndex)">
                      <erom-editor-toolbar [hidden]="!gridActionsCanBeDisplayed(group.id, gridIndex)"
                                           [label]="'editor.GRID_TOOLBAR' | translate"
                                           [id]="gridIdPrefix + group.id + '-' + gridIndex">
                        <div class="erom-editor-toolbar-group">
                          <span class="erom-editor-drag__handle grid-handle" cdkDragHandle>
                            <span class="erom-editor__rows-item-icon"></span>
                            <span class="erom-editor-handle"></span>
                          </span>
                          <button class="erom-editor-toolbar__item erom-editor__section-move move-up"
                                  type="button"
                                  [attr.aria-label]="'editor.MOVE_UP' | translate"
                                  [class.disabled]="!canMove(group.grids, grid, 'up')"
                                  (click)="moveGrid(group, grid, gridIndex, 'up')"></button>
                          <button class="erom-editor-toolbar__item erom-editor__section-move move-down"
                                  type="button"
                                  [attr.aria-label]="'editor.MOVE_DOWN' | translate"
                                  [class.disabled]="!canMove(group.grids, grid, 'down')"
                                  (click)="moveGrid(group, grid, gridIndex, 'down')"></button>
                        </div>
                        <div class="erom-editor-toolbar-group">
                          <div class="erom-editor-grid-selector" [ngClass]="'grid_' + (grid.grid || 100)">
                            <erom-select class="icon-select"
                                         [aria-label]="'editor.CHANGE_GRID' | translate"
                                         [options]="gridOptions"
                                         [value]="grid.grid || 100"
                                         (optionSelected)="changeGrid($event, grid)"></erom-select>
                          </div>
                        </div>
                        <div class="erom-editor-toolbar-group">
                          <div class="erom-editor-grid-image">
                            <label [for]="gridIdPrefix + gridIndex + '-image'">
                              <button type="button"
                                      class="erom-editor-toolbar__item"
                                      (click)="gridImageUploader.click()"
                                      [attr.aria-label]="'editor.GRID_UPLOAD_IMAGE' | translate"
                                      [attr.aria-controls]="gridIdPrefix + gridIndex + '-image'"></button>
                            </label>
                            <input type="file"
                                   accept="image/*"
                                   tabindex="-1"
                                   #gridImageUploader
                                   [id]="gridIdPrefix + gridIndex + '-image'"
                                   (change)="uploadGridImage($event, grid)">
                          </div>
                          <div class="erom-editor-grid-background-color">
                            <label [for]="gridIdPrefix + gridIndex + '-color'">
                              <button type="button"
                                      class="erom-editor-toolbar__item"
                                      (click)="activateToolbarItem($event)"
                                      [attr.aria-expanded]="false"
                                      [attr.aria-label]="'editor.GRID_BACKGROUND_COLOR' | translate"
                                      [attr.aria-controls]="gridIdPrefix + gridIndex + '-color'"></button>
                              <compact-picker [(color)]="grid.backgroundColor" [id]="gridIdPrefix + gridIndex + '-color'"></compact-picker>
                            </label>
                          </div>
                        </div>
                        <div class="erom-editor-toolbar-group">
                          <div class="erom-editor-grid-align-h-left">
                            <button class="erom-editor-toolbar__item"
                                    type="button"
                                    [attr.aria-pressed]="grid.align && grid.align.v && grid.align.v === 'left'"
                                    [class.active]="grid.align && grid.align.v && grid.align.v === 'left'"
                                    [attr.aria-label]="'editor.GRID_ALIGN_VERTICALLY_LEFT' | translate"
                                    (click)="changeGridAlignment(grid, 'y', 'LEFT')"></button>
                          </div>
                          <div class="erom-editor-grid-align-h-center">
                            <button class="erom-editor-toolbar__item"
                                    type="button"
                                    [attr.aria-pressed]="grid.align && grid.align.v && grid.align.v === 'center'"
                                    [class.active]="grid.align && grid.align.v && grid.align.v === 'center'"
                                    [attr.aria-label]="'editor.GRID_ALIGN_VERTICALLY_CENTER' | translate"
                                    (click)="changeGridAlignment(grid, 'y', 'CENTER')"></button>
                          </div>
                          <div class="erom-editor-grid-align-h-right">
                            <button class="erom-editor-toolbar__item"
                                    type="button"
                                    [attr.aria-pressed]="grid.align && grid.align.v && grid.align.v === 'right'"
                                    [class.active]="grid.align && grid.align.v && grid.align.v === 'right'"
                                    [attr.aria-label]="'editor.GRID_ALIGN_VERTICALLY_RIGHT' | translate"
                                    (click)="changeGridAlignment(grid, 'y', 'RIGHT')"></button>
                          </div>
                          <div class="erom-editor-grid-align-v-top">
                            <button class="erom-editor-toolbar__item"
                                    type="button"
                                    [attr.aria-pressed]="grid.align && grid.align.h && grid.align.h === 'top'"
                                    [class.active]="grid.align && grid.align.h && grid.align.h === 'top'"
                                    [attr.aria-label]="'editor.GRID_ALIGN_HORIZONTALLY_TOP' | translate"
                                    (click)="changeGridAlignment(grid, 'x', 'TOP')"></button>
                          </div>
                          <div class="erom-editor-grid-align-v-middle">
                            <button class="erom-editor-toolbar__item"
                                    type="button"
                                    [attr.aria-pressed]="grid.align && grid.align.h && grid.align.h === 'middle'"
                                    [class.active]="grid.align && grid.align.h && grid.align.h === 'middle'"
                                    [attr.aria-label]="'editor.GRID_ALIGN_HORIZONTALLY_MIDDLE' | translate"
                                    (click)="changeGridAlignment(grid, 'x', 'MIDDLE')"></button>
                          </div>
                          <div class="erom-editor-grid-align-v-bottom">
                            <button class="erom-editor-toolbar__item"
                                    type="button"
                                    [attr.aria-pressed]="grid.align && grid.align.h && grid.align.h === 'bottom'"
                                    [class.active]="grid.align && grid.align.h && grid.align.h === 'bottom'"
                                    [attr.aria-label]="'editor.GRID_ALIGN_HORIZONTALLY_BOTTOM' | translate"
                                    (click)="changeGridAlignment(grid, 'x', 'BOTTOM')"></button>
                          </div>
                        </div>
                      </erom-editor-toolbar>
                    </div>
                    <div class="erom-editor-settings"
                               [class.active]="gridActionsCanBeDisplayed(group.id, gridIndex)">
                      <erom-editor-toolbar [hidden]="!gridActionsCanBeDisplayed(group.id, gridIndex)"
                                           [label]="'editor.GRID_SETTINGS' | translate"
                                           [id]="gridIdPrefix + group.id + '-' + gridIndex">
                        <div class="erom-editor-settings__group">
                          <div class="erom-editor-settings__switch" [class.active]="!grid.hidden">
                            <label [for]="'eromBlockVisibilitySwitch-' + gridIndex">
                              <input type="checkbox"
                                     class="erom-editor-toolbar__item"
                                     [attr.aria-label]="'editor.VISIBILITY' | translate"
                                     [attr.aria-pressed]="!group.hidden"
                                     (keydown.enter)="$event.preventDefault(); changeGridVisibility(grid)"
                                     (change)="changeGridVisibility(grid)"
                                     [checked]="!group.hidden ? 'checked' : ''"
                                     [id]="'eromGridVisibilitySwitch-' + gridIndex" />
                              <span class="unchecked"><fa-icon [icon]="['fas', 'eye-slash']"></fa-icon></span>
                              <span class="checked"><fa-icon [icon]="['fas', 'eye']"></fa-icon></span>
                            </label>
                          </div>
                        </div>
                        <div class="erom-editor-settings__group">
                          <button type="button"
                                  class="erom-editor-toolbar__item erom-editor-btn__sync"
                                  [attr.aria-label]="'editor.CLEAR_CONTENT' | translate"
                                  (click)="resetGrid(grid)">
                            <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
                          </button>
                          <button type="button"
                                  class="erom-editor-toolbar__item erom-editor-btn__clone"
                                  [attr.aria-label]="'editor.CLONE' | translate"
                                  (click)="cloneGrid(group, gridIndex)">
                            <fa-icon [icon]="['fas', 'clone']"></fa-icon>
                          </button>
                          <button type="button"
                                  class="erom-editor-toolbar__item erom-editor-btn__save"
                                  [attr.aria-label]="'editor.SAVE_AS_SECTION' | translate"
                                  (click)="saveAsTemplateInit($event)">
                            <fa-icon [icon]="['fas', 'save']"></fa-icon>
                          </button>
                        </div>
                        <div class="erom-editor-settings__group">
                          <button type="button"
                                  class="erom-editor-toolbar__item erom-editor-btn__remove"
                                  [attr.aria-label]="'editor.REMOVE' | translate"
                                  (click)="removeGrid(group.grids, gridIndex)">
                            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                          </button>
                        </div>
                      </erom-editor-toolbar>
                    </div>
                  </div>
                  <div class="erom-editor__rows-columns erom-editor__content-row"
                       [style.backgroundImage]="grid.backgroundImage && grid.backgroundImage.url ? 'url(\'' + grid.backgroundImage.url + '\')' : ''"
                       [style.backgroundColor]="grid.backgroundColor ? grid.backgroundColor : ''"
                       [class.grid-align-left]="grid.align && grid.align.v === 'left'"
                       [class.grid-align-center]="grid.align && grid.align.v === 'center'"
                       [class.grid-align-right]="grid.align && grid.align.v === 'right'"
                       [class.grid-align-top]="grid.align && grid.align.h === 'top'"
                       [class.grid-align-middle]="grid.align && grid.align.h === 'middle'"
                       [class.grid-align-bottom]="grid.align && grid.align.h === 'bottom'"
                       [ngClass]="'grid_' + (grid.grid || 100)">
                    <div class="erom-editor__rows-column"
                         cdkDropList
                         role="region"
                         [attr.aria-label]="'editor.EDITOR_GRID_COLUMN' | translate"
                         [id]="gridIdPrefix + group.id + '-' + gridIndex + '-' + columnIndex"
                         [cdkDropListConnectedTo]="connectedGridList"
                         [cdkDropListData]="column"
                         (cdkDropListEntered)="dragEntered('grid', group.id, gridIndex)"
                         (cdkDropListDropped)="dropItem($event)"
                         *ngFor="let column of grid.columns; let columnIndex = index">
                      <div class="erom-editor__rows-overlay--column"
                           *ngIf="activeArea === groupIdPrefix + group.id"
                           [class.active]="gridActionsCanBeDisplayed(group.id, gridIndex)">
                        <erom-editor-selector [empty]="!contentGroups.length"
                                              [inside]="true"
                                              *ngIf="gridActionsCanBeDisplayed(group.id, gridIndex)"
                                              [blocks]="blocks"
                                              (blockAdded)="columnBlockAdded($event, column, group.id, gridIndex)"></erom-editor-selector>
                      </div>
                      <div class="erom-editor__block"
                           cdkDrag
                           role="region"
                           [attr.aria-label]="(block.type ? 'editor.EDITOR_BLOCK' : 'editor.EDITOR_BLOCK_GROUP') | translate"
                           (cdkDragStarted)="dragStarted()"
                           (cdkDragEnded)="dragEnded()"
                           [cdkDragData]="block.id"
                           [id]="blockIdPrefix + block.id"
                           [class.active]="activeBlock === blockIdPrefix + block.id"
                           [ngClass]="block.settings && block.settings.class ? block.settings.class : []"
                           *ngFor="let block of column; let blockIndex = index">
                        <div class="erom-editor__block-content"
                             [ngStyle]="block.style || {}">
                          <ng-container *ngIf="block.type">
                            <div class="erom-editor-toolbar">
                              <div class="erom-editor-toolbar--actions"
                                   (click)="$event.stopPropagation(); $event.preventDefault()">
                                <erom-editor-toolbar [hidden]="!(activeBlock === blockIdPrefix + block.id)"
                                                     [label]="'editor.BLOCK_TOOLBAR' | translate"
                                                     [id]="blockIdPrefix + block.id">
                                  <div class="erom-editor-toolbar-group">
                                    <span class="erom-editor-drag__handle" cdkDragHandle>
                                      <span class="erom-editor-handle"></span>
                                    </span>
                                    <button class="erom-editor-toolbar__item erom-editor__section-move move-up"
                                            type="button"
                                            [attr.aria-label]="'editor.MOVE_UP' | translate"
                                            [class.disabled]="!canMove(column, block, 'up')"
                                            (click)="move($event, column, block, 'up')"></button>
                                    <button class="erom-editor-toolbar__item erom-editor__section-move move-down"
                                            type="button"
                                            [attr.aria-label]="'editor.MOVE_DOWN' | translate"
                                            [class.disabled]="!canMove(column, block, 'down')"
                                            (click)="move($event, column, block, 'down')"></button>
                                  </div>
                                </erom-editor-toolbar>
                              </div>
                              <div class="erom-editor-toolbar--container" [id]="'toolbar-' + block.id"></div>
                            </div>
                            <erom-block [block]="block"
                                        [pageData]="pageData"
                                        [editMode]="true"
                                        (keydown.enter)="fakeClick($event)"
                                        (click)="activateBlock($event, groupIdPrefix + group.id, gridIdPrefix + group.id + '-' + gridIndex, block)"
                                        (eromEditorBlockChanged)="blockChanged($event)"
                                        (eromEditorBlockReady)="blockReady($event, column, group.id)"
                                        [ngClass]="blockClasses(blockIdPrefix + block.id)"></erom-block>
                          </ng-container>
                          <ng-container *ngIf="block.items">
                            <div class="erom-editor__block-group"
                                 cdkDropList
                                 [id]="blockIdPrefix + block.id"
                                 [cdkDropListConnectedTo]="connectedBlockGroupList"
                                 [cdkDropListData]="block.items"
                                 (cdkDropListEntered)="dragEntered('blockGroup', group.id, gridIndex, block.id)"
                                 (cdkDropListDropped)="dropItem($event)"
                                 (click)="activateBlockGroup($event, block, gridIdPrefix + group.id + '-' + gridIndex, groupIdPrefix + group.id)">
                              <div class="erom-editor__group-overlay"
                                   [class.active]="blockGroupActionsCanBeDisplayed(block.id)">
                                <div class="erom-editor-actions"
                                     (click)="$event.stopPropagation()"
                                     [class.active]="blockGroupActionsCanBeDisplayed(block.id)">
                                  <erom-editor-toolbar [hidden]="!blockGroupActionsCanBeDisplayed(block.id)"
                                                       [label]="'editor.BLOCK_GROUP_TOOLBAR' | translate"
                                                       [id]="blockIdPrefix + block.id">
                                    <div class="erom-editor-toolbar-group">
                                      <span class="erom-editor-drag__handle" cdkDragHandle>
                                        <span class="erom-editor-handle"></span>
                                      </span>
                                      <button class="erom-editor-toolbar__item erom-editor__section-move move-up"
                                              type="button"
                                              [attr.aria-label]="'editor.MOVE_UP' | translate"
                                              [class.disabled]="!canMove(column, block, 'up')"
                                              (click)="move($event, column, block, 'up')"></button>
                                      <button class="erom-editor-toolbar__item erom-editor__section-move move-down"
                                              type="button"
                                              [attr.aria-label]="'editor.MOVE_DOWN' | translate"
                                              [class.disabled]="!canMove(column, block, 'down')"
                                              (click)="move($event, column, block, 'down')"></button>
                                    </div>
                                    <div class="erom-editor-toolbar-group">
                                      <div class="erom-editor-grid-image">
                                        <label [for]="blockIdPrefix + block.id + '-image'">
                                          <button type="button"
                                                  class="erom-editor-toolbar__item"
                                                  (click)="blockGroupImage.click()"
                                                  [attr.aria-label]="'editor.GROUP_UPLOAD_IMAGE' | translate"
                                                  [attr.aria-controls]="blockIdPrefix + block.id + '-image'"></button>
                                        </label>
                                        <input type="file"
                                               accept="image/*"
                                               tabindex="-1"
                                               #blockGroupImage
                                               [id]="blockIdPrefix + block.id + '-image'"
                                               (change)="uploadGroupImage($event, block)">
                                      </div>
                                      <div class="erom-editor-grid-background-color">
                                        <label [for]="blockIdPrefix + block.id + '-color'">
                                          <button type="button"
                                                  class="erom-editor-toolbar__item"
                                                  (click)="activateToolbarItem($event)"
                                                  [attr.aria-expanded]="false"
                                                  [attr.aria-label]="'editor.GROUP_BACKGROUND_COLOR' | translate"
                                                  [attr.aria-controls]="blockIdPrefix + block.id + '-color'"></button>
                                          <compact-picker [id]="blockIdPrefix + block.id + '-color'" [(color)]="block.style.backgroundColor"></compact-picker>

                                        </label>
                                      </div>
                                    </div>

                                    <div class="erom-editor-toolbar-group">
                                      <div class="erom-editor-grid-align-h-left">
                                        <button type="button"
                                                class="erom-editor-toolbar__item"
                                                [attr.aria-pressed]="block.style && block.style.textAlign && block.style.textAlign === 'left'"
                                                [class.active]="block.style && block.style.textAlign && block.style.textAlign === 'left'"
                                                [attr.aria-label]="'editor.GROUP_ALIGN_VERTICALLY_LEFT' | translate"
                                                (click)="changeGroupAlignment(block, 'y', 'left')"></button>
                                      </div>
                                      <div class="erom-editor-grid-align-h-center">
                                        <button type="button"
                                                class="erom-editor-toolbar__item"
                                                [attr.aria-pressed]="block.style && block.style.textAlign && block.style.textAlign === 'center'"
                                                [class.active]="block.style && block.style.textAlign && block.style.textAlign === 'center'"
                                                [attr.aria-label]="'editor.GROUP_ALIGN_VERTICALLY_CENTER' | translate"
                                                (click)="changeGroupAlignment(block, 'y', 'center')"></button>
                                      </div>
                                      <div class="erom-editor-grid-align-h-right">
                                        <button type="button"
                                                class="erom-editor-toolbar__item"
                                                [attr.aria-pressed]="block.style && block.style.textAlign && block.style.textAlign === 'right'"
                                                [class.active]="block.style && block.style.textAlign && block.style.textAlign === 'right'"
                                                [attr.aria-label]="'editor.GROUP_ALIGN_VERTICALLY_RIGHT' | translate"
                                                (click)="changeGroupAlignment(block, 'y', 'right')"></button>
                                      </div>
                                    </div>
                                  </erom-editor-toolbar>
                                </div>
                                <erom-editor-selector [empty]="!contentGroups.length"
                                                      [inside]="true"
                                                      *ngIf="blockGroupActionsCanBeDisplayed(block.id)"
                                                      [blocks]="blocks"
                                                      (blockAdded)="columnBlockAdded($event, block.items, group.id, gridIndex)"></erom-editor-selector>
                              </div>
                              <div class="erom-editor__block-group--item"
                                   cdkDrag
                                   role="region"
                                   [attr.aria-label]="'editor.EDITOR_BLOCK' | translate"
                                   (cdkDragStarted)="dragStarted()"
                                   (cdkDragEnded)="dragEnded()"
                                   [cdkDragData]="groupBlock.id"
                                   [id]="blockIdPrefix + groupBlock.id"
                                   [class.active]="activeBlock === blockIdPrefix + groupBlock.id"
                                   *ngFor="let groupBlock of block.items; let groupBlockIndex = index">
                                <div class="erom-editor-toolbar">
                                  <div class="erom-editor-toolbar--actions"
                                       (click)="$event.stopPropagation(); $event.preventDefault()">
                                    <erom-editor-toolbar [hidden]="!(activeBlock === blockIdPrefix + groupBlock.id)"
                                                         [label]="'editor.BLOCK_GROUP_TOOLBAR' | translate"
                                                         [id]="blockIdPrefix + groupBlock.id">
                                      <div class="erom-editor-toolbar-group">
                                        <span class="erom-editor-drag__handle"
                                              cdkDragHandle>
                                          <span class="erom-editor-handle"></span>
                                        </span>
                                        <button class="erom-editor-toolbar__item erom-editor__section-move move-up"
                                                type="button"
                                                [attr.aria-label]="'editor.MOVE_UP' | translate"
                                                [class.disabled]="!canMove(block.items, groupBlock, 'up')"
                                                (click)="move($event, block.items, groupBlock, 'up')"></button>
                                        <button class="erom-editor-toolbar__item erom-editor__section-move move-down"
                                                type="button"
                                                [attr.aria-label]="'editor.MOVE_DOWN' | translate"
                                                [class.disabled]="!canMove(block.items, groupBlock, 'down')"
                                                (click)="move($event, block.items, groupBlock, 'down')"></button>
                                      </div>
                                    </erom-editor-toolbar>
                                  </div>
                                  <div class="erom-editor-toolbar--container" [id]="'toolbar-' + groupBlock.id"></div>
                                </div>
                                <erom-block [block]="groupBlock"
                                            [pageData]="pageData"
                                            [editMode]="true"
                                            (keydown.enter)="fakeClick($event)"
                                            (click)="activateBlock($event, groupIdPrefix + group.id, gridIdPrefix + group.id + '-' + gridIndex, groupBlock, block)"
                                            (eromEditorBlockChanged)="blockChanged($event)"
                                            (eromEditorBlockReady)="blockReady($event, block.items, group.id)"
                                            [ngClass]="blockClasses(blockIdPrefix + groupBlock.id)"></erom-block>
                                <div class="erom-editor-settings"
                                     [class.active]="activeBlock === blockIdPrefix + groupBlock.id">
                                  <erom-editor-toolbar [hidden]="!(activeBlock === blockIdPrefix + groupBlock.id)"
                                                       [label]="'editor.BLOCK_GROUP_SETTINGS' | translate"
                                                       [id]="blockIdPrefix + groupBlock.id">
                                    <div class="erom-editor-settings__group">
                                      <div class="erom-editor-settings__switch" [class.active]="!groupBlock.hidden">
                                        <label [for]="'eromGroupBlockVisibilitySwitch-' + groupBlockIndex">
                                          <input type="checkbox"
                                                 class="erom-editor-toolbar__item"
                                                 [attr.aria-pressed]="!groupBlock.hidden"
                                                 [attr.aria-label]="'editor.VISIBILITY' | translate"
                                                 (keydown.enter)="$event.preventDefault(); changeBlockVisibility(groupBlock)"
                                                 (change)="changeBlockVisibility(groupBlock)"
                                                 [checked]="!groupBlock.hidden ? 'checked' : ''"
                                                 [id]="'eromGroupBlockVisibilitySwitch-' + groupBlockIndex" />
                                          <span class="unchecked"><fa-icon [icon]="['fas', 'eye-slash']"></fa-icon></span>
                                          <span class="checked"><fa-icon [icon]="['fas', 'eye']"></fa-icon></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div class="erom-editor-settings__group">
                                      <button type="button"
                                              class="erom-editor-toolbar__item erom-editor-btn__sync"
                                              [attr.aria-label]="'editor.CLEAR_CONTENT' | translate"
                                              (click)="resetBlockOrGroupOfBlocks(groupBlock)">
                                        <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
                                      </button>
                                      <button type="button"
                                              class="erom-editor-toolbar__item erom-editor-btn__clone"
                                              [attr.aria-label]="'editor.CLONE' | translate"
                                              (click)="cloneBlock(block.items, groupBlockIndex)">
                                        <fa-icon [icon]="['fas', 'clone']"></fa-icon>
                                      </button>
                                    </div>
                                    <div class="erom-editor-settings__group">
                                      <button type="button"
                                              class="erom-editor-toolbar__item erom-editor-btn__remove"
                                              [attr.aria-label]="'editor.REMOVE' | translate"
                                              (click)="removeBlock(block.items, groupBlockIndex)">
                                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                                      </button>
                                    </div>
                                  </erom-editor-toolbar>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="erom-editor-settings"
                             [class.active]="activeBlock === blockIdPrefix + block.id || (!activeBlock && activeBlockGroup === blockIdPrefix + block.id)">
                          <erom-editor-toolbar [hidden]="!(activeBlock === blockIdPrefix + block.id || (!activeBlock && activeBlockGroup === blockIdPrefix + block.id))"
                                               [label]="'editor.BLOCK_SETTINGS' | translate"
                                               [id]="blockIdPrefix + block.id">
                            <div class="erom-editor-settings__group">
                              <div class="erom-editor-settings__switch" [class.active]="!block.hidden">
                                <label [for]="'eromBlockVisibilitySwitch-' + blockIndex">
                                  <input type="checkbox"
                                         class="erom-editor-toolbar__item"
                                         [attr.aria-pressed]="!block.hidden"
                                         [attr.aria-label]="'editor.VISIBILITY' | translate"
                                         (keydown.enter)="$event.preventDefault(); changeBlockVisibility(block)"
                                         (change)="changeBlockVisibility(block)"
                                         [checked]="!block.hidden ? 'checked' : ''"
                                         [id]="'eromBlockVisibilitySwitch-' + blockIndex" />
                                  <span class="unchecked"><fa-icon [icon]="['fas', 'eye-slash']"></fa-icon></span>
                                  <span class="checked"><fa-icon [icon]="['fas', 'eye']"></fa-icon></span>
                                </label>
                              </div>
                            </div>
                            <div class="erom-editor-settings__group">
                              <button type="button"
                                      class="erom-editor-toolbar__item erom-editor-btn__sync"
                                      [attr.aria-label]="'editor.CLEAR_CONTENT' | translate"
                                      (click)="resetBlockOrGroupOfBlocks(block)">
                                <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
                              </button>
                              <button type="button"
                                      class="erom-editor-toolbar__item erom-editor-btn__clone"
                                      [attr.aria-label]="'editor.CLONE' | translate"
                                      (click)="cloneBlock(column, blockIndex)">
                                <fa-icon [icon]="['fas', 'clone']"></fa-icon>
                              </button>
                            </div>
                            <div class="erom-editor-settings__group">
                              <button type="button"
                                      class="erom-editor-toolbar__item erom-editor-btn__remove"
                                      [attr.aria-label]="'editor.REMOVE' | translate"
                                      (click)="removeBlock(column, blockIndex)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                              </button>
                            </div>
                          </erom-editor-toolbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <erom-editor-selector [empty]="!contentGroups.length"
                                [blocks]="blocks"
                                [sections]="sections"
                                (blockAdded)="groupBlockAdded($event)"
                                (sectionAdded)="groupSectionAdded($event)"></erom-editor-selector>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="erom-editor__settings" role="region" [attr.aria-label]="'editor.EDITOR_SETTINGS' | translate">
      <div class="erom-editor-tabs">
        <div role="tablist"
             [attr.aria-label]="'editor.EDITOR_TABS' | translate"
             (keyup)="tabHandleKeyUp($event)"
             (keydown)="tabHandleKeyDown($event)">
          <app-tab-trigger triggerId="settings-trigger"
                           panelId="settings-panel"
                           [triggerName]="'editor.SETTINGS_TAB' | translate"
                           [isActive]="activatedTab === 0"
                           (click)="tabActivatePanel(0)">
            <fa-icon [icon]="['fas', 'sliders-h']"></fa-icon>
            <span>{{ 'editor.SETTINGS_TAB' | translate }}</span>
          </app-tab-trigger>
          <app-tab-trigger triggerId="document-trigger"
                           panelId="document-panel"
                           [triggerName]="'editor.DOCUMENT_TAB' | translate"
                           [isActive]="activatedTab === 1"
                           (click)="tabActivatePanel(1)">
            <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
            <span>{{ 'editor.DOCUMENT_TAB' | translate }}</span>
          </app-tab-trigger>
        </div>
        <app-tab-panel *ngIf="activatedTab === 0"
                       [height]="editorHeight"
                       panelId="settings-panel"
                       triggerId="settings-trigger">
          <perfect-scrollbar [autoPropagation]="true" [style.maxHeight.px]="editorHeight">
            <ng-container *ngIf="!!activeBlock && !!activeBlockObject">
              <div class="erom-editor-tabs__block-info">
                <div class="erom-editor-tabs__block-info--title">
                  <span class="erom-editor-tabs__block-info--icon inner-html" [innerHTML]="activeBlockObject.icon | safeHtml"></span>
                  {{ activeBlockObject.name }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!!activeBlockGroup && !activeBlock">
              <div class="erom-editor-tabs__block-info">
                <div class="erom-editor-tabs__block-info--title">
                  {{ 'editor.BLOCK_GROUP_SELECTED' | translate }}
                </div>
              </div>
              <div class="erom-editor-accordion" [ngClass]="{ focus: accordionFocus }">
                <accordion-header [headerTitle]="'editor.STYLES' | translate"
                                  [index]="0"
                                  [isOpen]="0 === accordionCurrentlyOpen"
                                  (focused)="accordionSetFocus(0)"
                                  (blurred)="accordionSetBlur()"
                                  (opened)="accordionOpenPanel(0)"
                                  (closed)="accordionClosePanel()">
                  <div class="erom-editor-accordion__panel">
                    <div class="erom-editor-tabs__block-options">
                      <div>
                        <div class="erom-editor-tabs__block-options--items">
                          <div class="erom-editor-tabs__block-options--item">
                            <div class="erom-editor__group">
                              <label for="blockGroupPadding" class="erom-editor__label">{{ 'editor.PADDING' | translate }}</label>
                              <input class="erom-editor__input"
                                     id="blockGroupPadding"
                                     (change)="changeActiveBlockGroupStyle($event, 'padding')"
                                     [value]="activeBlockGroupObject && activeBlockGroupObject.style ? (activeBlockGroupObject.style.padding || '') : ''"  />
                            </div>
                          </div>
                          <div class="erom-editor-tabs__block-options--item">
                            <div class="erom-editor__group">
                              <label  for="blockGroupMargin" class="erom-editor__label">{{ 'editor.MARGIN' | translate }}</label>
                              <input class="erom-editor__input"
                                     id="blockGroupMargin"
                                     (change)="changeActiveBlockGroupStyle($event, 'margin')"
                                     [value]="activeBlockGroupObject && activeBlockGroupObject.style ? (activeBlockGroupObject.style.margin || '') : ''"  />
                            </div>
                          </div>
                          <div class="erom-editor-tabs__block-options--item">
                            <div class="erom-editor__group">
                              <label  for="blockGroupClasses" class="erom-editor__label">{{ 'editor.CLASSES' | translate }}</label>
                              <input class="erom-editor__input"
                                     id="blockGroupClasses"
                                     (change)="changeActiveBlockGroupClass($event)"
                                     [value]="activeBlockGroupObject && activeBlockGroupObject.settings ? settingsClassValue(activeBlockGroupObject.settings.class) : ''"  />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-header>
              </div>
              <div class="erom-editor-accordion" [ngClass]="{ focus: accordionFocus }">
                <accordion-header [headerTitle]="'editor.ACCESSIBILITY' | translate"
                                  [index]="1"
                                  [isOpen]="1 === accordionCurrentlyOpen"
                                  (focused)="accordionSetFocus(1)"
                                  (blurred)="accordionSetBlur()"
                                  (opened)="accordionOpenPanel(1)"
                                  (closed)="accordionClosePanel()">
                  <div class="erom-editor-accordion__panel">
                    <div class="erom-editor-tabs__block-options">
                      <div>
                        <div class="erom-editor-tabs__block-options--items">
                          <div class="erom-editor-tabs__block-options--item">
                            <div class="erom-editor__group">
                              <label  for="blockGroupAriaRole" class="erom-editor__label">{{ 'editor.ARIA_ROLE' | translate }}</label>
                              <input class="erom-editor__input"
                                     id="blockGroupAriaRole"
                                     (change)="changeActiveBlockGroupAriaRole($event)"
                                     [value]="activeBlockGroupObject && activeBlockGroupObject.settings ? (activeBlockGroupObject.settings.role || '') : ''"  />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-header>
              </div>
            </ng-container>
            <ng-container *ngIf="!!activeGrid && !activeBlock && !activeBlockGroup">
              <div class="erom-editor-tabs__block-info">
                <div class="erom-editor-tabs__block-info--title">
                  <span class="erom-editor-tabs__block-info--icon">
                    <fa-icon [icon]="['fas', 'cube']"></fa-icon>
                  </span>
                  {{ activeSectionName || ('editor.SECTION_SELECTED' | translate) }}
                </div>
                <div class="erom-editor-tabs__block-info--text">
                  {{ 'editor.EDIT_SECTION_PROPERTIES' | translate }}
                </div>
              </div>
              <div class="erom-editor-tabs__settings--visibility">
                <div class="erom-editor-tabs__settings--visibility__title">{{ 'editor.OVERLAY_CONTENT' | translate }}</div>
                <div class="erom-editor-tabs__settings--visibility__toggle" [class.active]="!activeGridHidden">
                  <label for="eromSectionVisibilitySwitch">
                    <input type="checkbox"
                           (keydown.enter)="$event.preventDefault()"
                           (change)="activeGridChangeHidden()"
                           [checked]="!activeGridHidden"
                           id="eromSectionVisibilitySwitch" />
                    <span class="unchecked">Hide</span>
                    <span class="checked">Show</span>
                  </label>
                </div>
              </div>
              <div class="erom-editor-tabs__settings--actions">
                <button type="button" class="action-button reset-group" (click)="resetActiveGrid()">{{ 'editor.RESET_SECTION' | translate }}</button>
                <button type="button" class="action-button clone-page" (click)="duplicateActiveGrid()">{{ 'editor.DUPLICATE_SECTION' | translate }}</button>
                <div class="erom-editor__save-as">
                  <button type="button"
                          class="action-button erom-editor__save-as--button save-group-as"
                          id="saveAsSectionButtonTrigger"
                          [attr.aria-controls]="activeGrid + '-save-container'"
                          (click)="sectionSaveAsExpanded = !sectionSaveAsExpanded"
                          [attr.aria-expanded]="sectionSaveAsExpanded">
                    {{ 'editor.SAVE_SECTION_AS' | translate }}
                    <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!sectionImageData"></fa-icon>
                  </button>
                  <div [id]="activeGrid + '-save-container'"
                       [attr.aria-expanded]="sectionSaveAsExpanded"
                       role="region"
                       tabindex="-1"
                       class="erom-editor__save-as--container">
                    <div class="erom-editor__save-as--group">
                      <input [id]="activeGrid + '-save-template'"
                             type="radio"
                             value="0"
                             [checked]="sectionLocked === 0"
                             (change)="setupSectionData($event, 'type')"
                             [name]="activeGrid + '-save-as'"
                             class="erom-editor__save-as--radio"
                             [attr.aria-describedby]="activeGrid + '-save-template-info'" />
                      <label [for]="activeGrid + '-save-template'" class="erom-editor__save-as--label">
                        <fa-icon [icon]="['fas', 'cube']"></fa-icon>{{ 'editor.SECTION_TEMPLATE' | translate }}</label>
                      <span [id]="activeGrid + '-save-template-info'" class="erom-editor__save-as--info">
                        {{ 'editor.SECTION_TEMPLATE_INFO' | translate }}
                      </span>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <input [id]="activeGrid + '-save-reusable'"
                             type="radio"
                             value="1"
                             [checked]="sectionLocked === 1"
                             (change)="setupSectionData($event, 'type')"
                             [name]="activeGrid + '-save-as'"
                             class="erom-editor__save-as--radio"
                             [attr.aria-describedby]="activeGrid + '-save-reusable-info'" />
                      <label [for]="activeGrid + '-save-reusable'" class="erom-editor__save-as--label">
                        <fa-icon [icon]="['fas', 'shapes']"></fa-icon>{{ 'editor.REUSABLE_SECTION' | translate }}</label>
                      <span [id]="activeGrid + '-save-reusable-info'" class="erom-editor__save-as--info">
                        {{ 'editor.REUSABLE_SECTION_INFO' | translate }}
                      </span>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <label class="erom-editor__label" [for]="activeGrid + '-save-name'">{{ 'editor.NAME' | translate }}</label>
                      <input class="erom-editor__input"
                             (input)="setupSectionData($event, 'name')"
                             [value]="sectionName || ''"
                             [id]="activeGrid + '-save-name'"
                             [placeholder]="'editor.TYPE_IN' | translate" />
                    </div>
                    <div class="erom-editor__save-as--group">
                      <label class="erom-editor__label" [for]="activeGrid + '-save-image'">{{ 'editor.ADD_ICON_IMAGE' | translate }}</label>
                      <div class="erom-editor__save-as--image__container">
                        <div class="erom-editor__save-as--image" [style.backgroundImage]="sectionImageData ? 'url(\'' + sectionImageData + '\')' : ''">
                          <input type="file"
                                 #gridSectionImage
                                 class="erom-editor-visually-hidden"
                                 accept="image/*"
                                 [attr.aria-describedby]="activeGrid + '-save-image-info'"
                                 (change)="setupSectionData($event, 'image')"
                                 [id]="activeGrid + '-save-image'" />
                          <fa-icon [icon]="['fas', 'file-image']" *ngIf="!sectionImageData"></fa-icon>
                        </div>
                        <div class="erom-editor__save-as--image__actions">
                          <span class="erom-editor__save-as--image__name"
                                *ngIf="sectionImage">{{ sectionImage.name }}</span>
                          <span role="button"
                                class="erom-editor__save-as--image__change"
                                (click)="gridSectionImage.click()"
                                *ngIf="!sectionImageData">
                            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                            <span>{{ 'editor.UPLOAD_A_FILE' | translate }}</span>
                          </span>
                          <span role="button"
                                class="erom-editor__save-as--image__change"
                                (click)="removeSectionImage()"
                                *ngIf="sectionImageData">
                            <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
                            <span>{{ 'editor.REMOVE' | translate }}</span>
                          </span>
                        </div>
                      </div>
                      <div class="erom-editor__save-as--image__info" [id]="activeGrid + '-save-image-info'">{{ 'editor.UPLOAD_A_FILE_INFO' | translate }}</div>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <erom-bulk-paste [label]="'editor.TAGS' | translate"
                                       [splitRegex]="tagsSplitRegex"
                                       (tagsUpdated)="setupSectionData($event, 'tags')"></erom-bulk-paste>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <button type="button"
                              class="action-button erom-editor__save-as--submit save-group-as"
                              (click)="saveActiveGridAsTemplate()">
                        {{ 'editor.SAVE_SECTION' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <button type="button" class="action-button delete-page" (click)="deleteGrid()">{{ 'editor.DELETE_SECTION' | translate }}</button>
              </div>
              <div class="erom-editor-accordion" [ngClass]="{ focus: accordionFocus }">
                <accordion-header [headerTitle]="'Dimensions'"
                                  [index]="0"
                                  [isOpen]="0 === accordionCurrentlyOpen"
                                  (focused)="accordionSetFocus(0)"
                                  (blurred)="accordionSetBlur()"
                                  (opened)="accordionOpenPanel(0)"
                                  (closed)="accordionClosePanel()">
                  <div class="erom-editor-accordion__panel">
                    <div class="erom-editor-tabs__block-options">
                      <div>
                        <div class="erom-editor-tabs__block-options--title">Maximum width</div>
                        <div class="erom-editor-tabs__block-options--items">
                          <div class="erom-editor-tabs__block-options--buttons radio-buttons">
                            <div role="radiogroup" [attr.aria-label]="'Maximum width'">
                              <button type="button"
                                      role="radio"
                                      class="erom-editor-tabs__block-options--button"
                                      [value]="'100%'"
                                      [attr.aria-checked]="activeAreaWidth === '100%' || activeAreaWidth === '' || !activeAreaWidth"
                                      (click)="changeActiveAreaWidth('100%')">
                                Full screen
                              </button>
                              <div (click)="changeActiveAreaWidth('720px'); customRadioValue.focus()">
                                <button type="button"
                                        role="radio"
                                        class="erom-editor-tabs__block-options--button"
                                        [value]="customRadioValue.value && customRadioValue.value !== '' ? customRadioValue.value : '720px'"
                                        [attr.aria-checked]="(activeAreaWidth && activeAreaWidth !== '100%') || activeAreaWidth === ''">
                                  {{ 'editor.CUSTOM' | translate }}
                                  <input class="erom-editor__input"
                                         [value]="activeAreaWidth !== '100%' && activeAreaWidth !== '' ? (activeAreaWidth || '') : ''"
                                         (input)="changeActiveAreaWidth(customRadioValue.value)"
                                         #customRadioValue />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-header>
              </div>
              <div class="erom-editor-accordion" [ngClass]="{ focus: accordionFocus }">
                <accordion-header [headerTitle]="'editor.STYLES' | translate"
                                [index]="1"
                                [isOpen]="1 === accordionCurrentlyOpen"
                                (focused)="accordionSetFocus(1)"
                                (blurred)="accordionSetBlur()"
                                (opened)="accordionOpenPanel(1)"
                                (closed)="accordionClosePanel()">
                <div class="erom-editor-accordion__panel">
                  <div class="erom-editor-tabs__block-options">
                    <div>
                      <div class="erom-editor-tabs__block-options--items">
                        <div class="erom-editor-tabs__block-options--item">
                          <div class="erom-editor__group">
                            <label for="gridPadding" class="erom-editor__label">{{ 'editor.PADDING' | translate }}</label>
                            <input class="erom-editor__input"
                                   id="gridPadding"
                                   (change)="changeActiveGridStyle($event, 'padding')"
                                   [value]="activeGridStyles.padding || ''"  />
                          </div>
                        </div>
                        <div class="erom-editor-tabs__block-options--item">
                          <div class="erom-editor__group">
                            <label  for="gridMargin" class="erom-editor__label">{{ 'editor.MARGIN' | translate }}</label>
                            <input class="erom-editor__input"
                                   id="gridMargin"
                                   (change)="changeActiveGridStyle($event, 'margin')"
                                   [value]="activeGridStyles.margin || ''"  />
                          </div>
                        </div>
                        <div class="erom-editor-tabs__block-options--item">
                          <div class="erom-editor__group">
                            <label  for="gridClasses" class="erom-editor__label">{{ 'editor.CLASSES' | translate }}</label>
                            <input class="erom-editor__input"
                                   id="gridClasses"
                                   (change)="changeActiveGridClass($event)"
                                   [value]="activeGridSettingsClassValue"  />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </accordion-header>
              </div>
              <!--<accordion-header [headerTitle]="'editor.ACCESSIBILITY' | translate"
                                [index]="2"
                                [isOpen]="2 === accordionCurrentlyOpen"
                                (focused)="accordionSetFocus(2)"
                                (blurred)="accordionSetBlur()"
                                (opened)="accordionOpenPanel(2)"
                                (closed)="accordionClosePanel()">
                <div class="erom-editor-accordion__panel">
                  <div class="erom-editor-tabs__block-options">
                    <div>
                      <div class="erom-editor-tabs__block-options&#45;&#45;items">
                        <div class="erom-editor-tabs__block-options&#45;&#45;item">
                          <div class="erom-editor__group">
                            <label  for="gridAriaRole" class="erom-editor__label">{{ 'editor.ARIA_ROLE' | translate }}</label>
                            <input class="erom-editor__input"
                                   id="gridAriaRole"
                                   (change)="changeActiveGridAriaRole($event)"
                                   [value]="activeGridSettingsAriaRoleValue"  />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </accordion-header>-->
            </ng-container>
            <ng-container *ngIf="!!activeArea && !activeGrid && !activeBlock">
              <div class="erom-editor-tabs__block-info">
                <div class="erom-editor-tabs__block-info--title">
                  {{ activeAreaName || ('editor.GROUP_SELECTED' | translate) }}
                </div>
                <div class="erom-editor-tabs__block-info--text">
                  {{ 'editor.EDIT_GROUP_PROPERTIES' | translate }}
                </div>
              </div>
              <div class="erom-editor-tabs__settings--visibility">
                <div class="erom-editor-tabs__settings--visibility__title">{{ 'editor.DISPLAY_GROUP' | translate }}</div>
                <div class="erom-editor-tabs__settings--visibility__toggle" [class.active]="!activeGroupHidden">
                  <label for="eromGroupVisibilitySwitch">
                    <input type="checkbox"
                           (keydown.enter)="$event.preventDefault()"
                           (change)="activeGroupChangeHidden()"
                           [checked]="!activeGroupHidden"
                           id="eromGroupVisibilitySwitch" />
                    <span class="unchecked">Hide</span>
                    <span class="checked">Show</span>
                  </label>
                </div>
              </div>
              <div class="erom-editor-tabs__settings--actions">
                <button type="button" class="action-button reset-group" (click)="resetActiveGroup()">{{ 'editor.RESET_GROUP' | translate }}</button>
                <button type="button" class="action-button clone-page" (click)="duplicateActiveGroup()">{{ 'editor.DUPLICATE_GROUP' | translate }}</button>
                <div class="erom-editor__save-as">
                  <button type="button"
                          class="action-button erom-editor__save-as--button save-group-as"
                          id="saveAsSectionButtonTrigger"
                          [attr.aria-controls]="activeArea + '-save-container'"
                          (click)="sectionSaveAsExpanded = !sectionSaveAsExpanded"
                          [attr.aria-expanded]="sectionSaveAsExpanded">
                    {{ 'editor.SAVE_GROUP_AS' | translate }}
                    <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!sectionImageData"></fa-icon>
                  </button>
                  <div [id]="activeArea + '-save-container'"
                       [attr.aria-expanded]="sectionSaveAsExpanded"
                       role="region"
                       tabindex="-1"
                       class="erom-editor__save-as--container">
                    <div class="erom-editor__save-as--group">
                      <input [id]="activeArea + '-save-template'"
                             type="radio"
                             value="0"
                             [checked]="sectionLocked === 0"
                             (change)="setupSectionData($event, 'type')"
                             [name]="activeArea + '-save-as'"
                             class="erom-editor__save-as--radio"
                             [attr.aria-describedby]="activeArea + '-save-template-info'" />
                      <label [for]="activeArea + '-save-template'" class="erom-editor__save-as--label">
                        <fa-icon [icon]="['fas', 'cube']"></fa-icon>{{ 'editor.SECTION_TEMPLATE' | translate }}</label>
                      <span [id]="activeArea + '-save-template-info'" class="erom-editor__save-as--info">
                        {{ 'editor.SECTION_TEMPLATE_INFO' | translate }}
                      </span>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <input [id]="activeArea + '-save-reusable'"
                             type="radio"
                             value="1"
                             [checked]="sectionLocked === 1"
                             (change)="setupSectionData($event, 'type')"
                             [name]="activeArea + '-save-as'"
                             class="erom-editor__save-as--radio"
                             [attr.aria-describedby]="activeArea + '-save-reusable-info'" />
                      <label [for]="activeArea + '-save-reusable'" class="erom-editor__save-as--label">
                        <fa-icon [icon]="['fas', 'shapes']"></fa-icon>{{ 'editor.REUSABLE_SECTION' | translate }}</label>
                      <span [id]="activeArea + '-save-reusable-info'" class="erom-editor__save-as--info">
                        {{ 'editor.REUSABLE_SECTION_INFO' | translate }}
                      </span>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <label class="erom-editor__label" [for]="activeArea + '-save-name'">{{ 'editor.NAME' | translate }}</label>
                      <input class="erom-editor__input"
                             (input)="setupSectionData($event, 'name')"
                             [value]="sectionName || ''"
                             [id]="activeArea + '-save-name'"
                             [placeholder]="'editor.TYPE_IN' | translate" />
                    </div>
                    <div class="erom-editor__save-as--group">
                      <label class="erom-editor__label" [for]="activeArea + '-save-image'">{{ 'editor.ADD_ICON_IMAGE' | translate }}</label>
                      <div class="erom-editor__save-as--image__container">
                        <div class="erom-editor__save-as--image" [style.backgroundImage]="sectionImageData ? 'url(\'' + sectionImageData + '\')' : ''">
                          <input type="file"
                                 #groupSectionImage
                                 class="erom-editor-visually-hidden"
                                 accept="image/*"
                                 [attr.aria-describedby]="activeArea + '-save-image-info'"
                                 (change)="setupSectionData($event, 'image')"
                                 [id]="activeArea + '-save-image'" />
                          <fa-icon [icon]="['fas', 'file-image']" *ngIf="!sectionImageData"></fa-icon>
                        </div>
                        <div class="erom-editor__save-as--image__actions">
                          <span class="erom-editor__save-as--image__name"
                                *ngIf="sectionImage">{{ sectionImage.name }}</span>
                          <span role="button"
                                class="erom-editor__save-as--image__change"
                                (click)="groupSectionImage.click()"
                                *ngIf="!sectionImageData">
                            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                            <span>{{ 'editor.UPLOAD_A_FILE' | translate }}</span>
                          </span>
                          <span role="button"
                                class="erom-editor__save-as--image__change"
                                (click)="removeSectionImage()"
                                *ngIf="sectionImageData">
                            <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
                            <span>{{ 'editor.REMOVE' | translate }}</span>
                          </span>
                        </div>
                      </div>
                      <div class="erom-editor__save-as--image__info" [id]="activeArea + '-save-image-info'">{{ 'editor.UPLOAD_A_FILE_INFO' | translate }}</div>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <erom-bulk-paste [label]="'editor.TAGS' | translate"
                                       [splitRegex]="tagsSplitRegex"
                                       (tagsUpdated)="setupSectionData($event, 'tags')"></erom-bulk-paste>
                    </div>
                    <div class="erom-editor__save-as--group">
                      <button type="button"
                              class="action-button erom-editor__save-as--submit save-group-as"
                              (click)="saveActiveGroupAsTemplate()">
                        {{ 'editor.SAVE_GROUP' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <button type="button" class="action-button delete-page" (click)="deleteGroup()">{{ 'editor.DELETE_GROUP' | translate }}</button>
              </div>
            </ng-container>
            <ng-container *ngIf="activeBlockObject && activeBlockObject.options">
              <div class="erom-editor-accordion"
                   *ngFor="let group of activeBlockObject.options; let groupIndex = index"
                   [ngClass]="{ focus: accordionFocus }">
                <accordion-header [headerTitle]="group.name"
                                  [index]="groupIndex"
                                  [isOpen]="groupIndex === accordionCurrentlyOpen"
                                  (focused)="accordionSetFocus(groupIndex)"
                                  (blurred)="accordionSetBlur()"
                                  (opened)="accordionOpenPanel(groupIndex)"
                                  (closed)="accordionClosePanel()">
                  <div class="erom-editor-accordion__panel">
                    <div class="erom-editor-tabs__block-options">
                      <div *ngFor="let groupOption of group.items">
                        <div class="erom-editor-tabs__block-options--title">{{ groupOption.name }}</div>
                        <div class="erom-editor-tabs__block-options--items">
                          <ng-container *ngIf="groupOption.type && !groupOption.subtype">
                            <div class="erom-editor-tabs__block-options--item"
                                 [style.width.%]="option.width"
                                 *ngFor="let option of groupOption.options">
                              <div class="erom-editor__group">
                                <label class="erom-editor__label" [innerHTML]="option.name | safeHtml"></label>
                                <ng-container [ngSwitch]="option.type">
                                  <ng-container *ngSwitchCase="'select'">
                                    <erom-select [searchable]="true"
                                                 [value]="isValue(option.command)"
                                                 [options]="option.options"
                                                 (optionSelected)="runSelectCommand($event, option.command)"></erom-select>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'input'">
                                    <input class="erom-editor__input"
                                           (input)="runCommand($event, option.command, true)"
                                           [value]="isValue(option.command)"
                                           [name]="option.name" />
                                  </ng-container>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="groupOption.type && groupOption.subtype">
                            <div class="erom-editor-tabs__block-options--buttons"
                                 [ngClass]="groupOption.subtype + '-buttons'"
                                 [ngSwitch]="groupOption.subtype">
                              <ng-container *ngSwitchCase="'checkbox'">
                                <button type="button"
                                        class="erom-editor-tabs__block-options--button"
                                        (mouseenter)="setupTooltipPosition($event, checkboxTooltip)"
                                        (mouseleave)="removeTooltipPosition($event, checkboxTooltip)"
                                        [attr.aria-pressed]="isState(button.command)"
                                        *ngFor="let button of groupOption.options"
                                        (click)="runCommand($event, button.command)">
                                <span class="erom-editor-tabs__block-options--button__icon"
                                      aria-hidden="true"
                                      [innerHTML]="button.icon | safeHtml"></span>
                                  <span class="erom-editor-tabs__block-options--button__tooltip"
                                        #checkboxTooltip>
                                  {{ button.name }}
                                </span>
                                </button>
                              </ng-container>
                              <ng-container *ngSwitchCase="'radiobutton'">
                                <div role="radiogroup" [attr.aria-label]="groupOption.name">
                                  <button type="button"
                                          role="radio"
                                          class="erom-editor-tabs__block-options--button"
                                          (mouseenter)="setupTooltipPosition($event, radioTooltip)"
                                          (mouseleave)="removeTooltipPosition($event, radioTooltip)"
                                          [attr.aria-checked]="isState(button.command)"
                                          *ngFor="let button of groupOption.options"
                                          (click)="runCommand($event, button.command)">
                                  <span class="erom-editor-tabs__block-options--button__icon"
                                        aria-hidden="true"
                                        [innerHTML]="button.icon | safeHtml"></span>
                                    <span class="erom-editor-tabs__block-options--button__tooltip"
                                          #radioTooltip>
                                    {{ button.name }}
                                  </span>
                                  </button>
                                </div>
                              </ng-container>
                              <ng-container *ngSwitchCase="'radio'">
                                <div role="radiogroup" [attr.aria-label]="groupOption.name">
                                  <button type="button"
                                          role="radio"
                                          class="erom-editor-tabs__block-options--button"
                                          [value]="button.value"
                                          [attr.aria-checked]="isValue(groupOption.command) === button.value"
                                          *ngFor="let button of groupOption.options"
                                          (click)="runCommand($event, groupOption.command, true)">
                                    {{ button.name }}
                                  </button>
                                  <div *ngIf="groupOption.hasCustom" (click)="runCommand($event, groupOption.command, true); customRadioValue.focus()">
                                    <button type="button"
                                            role="radio"
                                            class="erom-editor-tabs__block-options--button"
                                            [value]="customRadioValue.value && customRadioValue.value !== '' ? customRadioValue.value : ''"
                                            [attr.aria-checked]="!!customChecked(groupOption.command, groupOption.options)">
                                      {{ 'editor.CUSTOM' | translate }}
                                      <input class="erom-editor__input"
                                             [value]="customChecked(groupOption.command, groupOption.options) || groupOption.default"
                                             (input)="runCommand($event, groupOption.command, true)"
                                             #customRadioValue />
                                    </button>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-header>
              </div>
            </ng-container>
            <div class="erom-editor-accordion" [ngClass]="{ focus: accordionFocus }">
              <accordion-header [headerTitle]="'editor.SCHEMA' | translate"
                                [index]="accordionPanelQuantity - 1"
                                [isOpen]="accordionPanelQuantity - 1 === accordionCurrentlyOpen"
                                (focused)="accordionSetFocus(accordionPanelQuantity - 1)"
                                (blurred)="accordionSetBlur()"
                                (opened)="accordionOpenPanel(accordionPanelQuantity - 1)"
                                (closed)="accordionClosePanel()">
                <div class="erom-editor-accordion__panel">
                  <div class="erom-editor-tabs__block-options">
                    <erom-editor-schema [entity]="activeEntity" (eromEditorSchemaChanged)="schemaUpdated($event)"></erom-editor-schema>
                  </div>
                </div>
              </accordion-header>
            </div>
          </perfect-scrollbar>
        </app-tab-panel>
        <app-tab-panel *ngIf="activatedTab === 1"
                       [height]="editorHeight"
                       panelId="document-panel"
                       triggerId="document-trigger">
          <perfect-scrollbar [autoPropagation]="true" [style.maxHeight.px]="editorHeight">
            <div class="erom-editor-tabs__settings">
              <ng-content select="[documentTabTop]"></ng-content>
              <div class="erom-editor-tabs__settings--info" *ngIf="mode === 1">
                <div>
                  <span>{{ 'editor.PAGE_STATUS' | translate }}:</span>
                  <span>{{ pageData.status }}</span>
                </div>
                <div>
                  <span>{{ 'editor.LAST_EDIT' | translate }}:</span>
                  <span>{{ pageData.modifiedOn }}</span>
                </div>
                <div>
                  <span>{{ 'editor.AUTHOR' | translate }}:</span>
                  <span>{{ pageData.createdBy }}</span>
                </div>
              </div>
              <div class="erom-editor-tabs__settings--actions">
                <ng-container *ngFor="let action of actions">
                  <ng-container *ngIf="action.action === 'savePageAsTemplate'; else nonSaveAsTemplateAction">
                    <div class="erom-editor__save-as">
                      <button type="button"
                              class="action-button erom-editor__save-as--button save-group-as"
                              id="saveAsTemplateButtonTrigger"
                              aria-controls="saveAsTemplateContainer"
                              (click)="sectionSaveAsExpanded = !sectionSaveAsExpanded"
                              [attr.aria-expanded]="sectionSaveAsExpanded">
                        {{ 'editor.SAVE_AS_TEMPLATE' | translate }}
                        <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!sectionImageData"></fa-icon>
                      </button>
                      <div id="saveAsTemplateContainer"
                           [attr.aria-expanded]="sectionSaveAsExpanded"
                           role="region"
                           tabindex="-1"
                           class="erom-editor__save-as--container">
                        <div class="erom-editor__save-as--group">
                          <label class="erom-editor__label" for="saveAsTemplateName">{{ 'editor.NAME' | translate }}</label>
                          <input class="erom-editor__input"
                                 (input)="setupSectionData($event, 'name')"
                                 [value]="sectionName || ''"
                                 id="saveAsTemplateName"
                                 [placeholder]="'editor.TYPE_IN' | translate" />
                        </div>
                        <div class="erom-editor__save-as--group">
                          <label class="erom-editor__label" for="saveAsTemplateImage">{{ 'editor.ADD_ICON_IMAGE' | translate }}</label>
                          <div class="erom-editor__save-as--image__container">
                            <div class="erom-editor__save-as--image" [style.backgroundImage]="sectionImageData ? 'url(\'' + sectionImageData + '\')' : ''">
                              <input type="file"
                                     #groupSectionImage
                                     class="erom-editor-visually-hidden"
                                     accept="image/*"
                                     aria-describedby="saveAsTemplateImageInfo"
                                     (change)="setupSectionData($event, 'image')"
                                     id="saveAsTemplateImage" />
                              <fa-icon [icon]="['fas', 'file-image']" *ngIf="!sectionImageData"></fa-icon>
                            </div>
                            <div class="erom-editor__save-as--image__actions">
                            <span class="erom-editor__save-as--image__name"
                                  *ngIf="sectionImage">{{ sectionImage.name }}</span>
                              <span role="button"
                                    class="erom-editor__save-as--image__change"
                                    (click)="groupSectionImage.click()"
                                    *ngIf="!sectionImageData">
                              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                                <span>{{ 'editor.UPLOAD_A_FILE' | translate }}</span>
                              </span>
                              <span role="button"
                                    class="erom-editor__save-as--image__change"
                                    (click)="removeSectionImage()"
                                    *ngIf="sectionImageData">
                                <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
                                <span>{{ 'editor.REMOVE' | translate }}</span>
                              </span>
                            </div>
                          </div>
                          <div class="erom-editor__save-as--image__info" id="saveAsTemplateImageInfo">{{ 'editor.UPLOAD_A_FILE_INFO' | translate }}</div>
                        </div>
                        <div class="erom-editor__save-as--group">
                          <erom-bulk-paste [label]="'editor.TAGS' | translate"
                                           [splitRegex]="tagsSplitRegex"
                                           (tagsUpdated)="setupSectionData($event, 'tags')"></erom-bulk-paste>
                        </div>
                        <div class="erom-editor__save-as--group">
                          <button type="button"
                                  class="action-button erom-editor__save-as--submit save-group-as"
                                  (click)="savePageAsTemplate()">
                            {{ 'editor.SAVE_AS_TEMPLATE' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #nonSaveAsTemplateAction>
                    <button type="button"
                            class="action-button"
                            (click)="editorButtonActioned(action)">
                      <fa-icon [icon]="['fas', action.icon]"
                               [style.font-size]="action.iconSize ? (action.iconSize + 'px') : ''"
                               *ngIf="action.icon"></fa-icon>{{ action.label | translate }}
                    </button>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </perfect-scrollbar>
        </app-tab-panel>
      </div>
    </div>
  </form>
</div>
