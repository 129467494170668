<div class="erom-editor__selector"
     [id]="id"
     [class.inside]="inside">
  <div class="erom-editor__selector-info"
       *ngIf="empty">{{ 'editor.PAGE_SELECTOR_EMPTY' | translate }}</div>
  <div class="erom-editor__selector-container"
       [class.inside]="inside"
       *ngIf="inside || !activeComponentsSelector">
    <button role="button"
            class="erom-editor__selector-component-btn"
            [class.inside]="inside"
            [class.active]="activeComponentsSelector"
            [attr.aria-label]="'editor.ADD_COMPONENT' | translate"
            (click)="activateComponentsSelector($event)">
      <span *ngIf="!inside">{{ 'editor.ADD_COMPONENT' | translate }}</span>
    </button>
    <ng-container *ngIf="templates">
      <span class="erom-editor__selector-separator">{{ 'editor.OR' | translate }}</span>
      <erom-select [placeholder]="'editor.CHOOSE_TEMPLATE' | translate"
                   [options]="templates"
                   searchable="true"></erom-select>
    </ng-container>
  </div>
  <div class="erom-editor__selector-component-list"
       [class.inside]="inside"
       *ngIf="activeComponentsSelector">
    <div class="erom-editor__selector-component-list--head">
      <div class="erom-editor__selector-component-list--head-content"
           [class.active]="searchFocused || !!searchEmpty">
        <input type="search"
               (focus)="searchFocused = true"
               (blur)="searchFocused = false"
               (input)="initSearch($event)"
               (change)="initSearch($event)"
               [attr.aria-label]="'editor.SEARCH_COMPONENT' | translate"
               [placeholder]="'editor.SEARCH_COMPONENT' | translate" />
        <button type="button" class="erom-editor__selector-component-list--head-search-clear"
                (click)="clearSearch()"
                *ngIf="!!searchEmpty">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="erom-editor__selector-component-list--tabs">
      <div role="tablist"
           (keyup)="handleKeyUp($event)"
           (keydown)="handleKeyDown($event)">
        <div>
          <app-tab-trigger triggerId="components-trigger"
                           panelId="components-panel"
                           [triggerName]="'editor.COMPONENTS' | translate"
                           [isActive]="activated === 0"
                           (click)="activatePanel(0)">
            <span>{{ 'editor.COMPONENTS' | translate }}</span>
          </app-tab-trigger>
          <app-tab-trigger triggerId="templates-trigger"
                           panelId="templates-panel"
                           *ngIf="includeSections"
                           [triggerName]="'editor.SECTION_TEMPLATES' | translate"
                           [isActive]="activated === 1"
                           (click)="activatePanel(1)">
            <span>{{ 'editor.SECTION_TEMPLATES' | translate }}</span>
          </app-tab-trigger>
          <app-tab-trigger triggerId="reusable-trigger"
                           panelId="reusable-panel"
                           *ngIf="includeSections"
                           [triggerName]="'editor.REUSABLE_SECTIONS' | translate"
                           [isActive]="activated === 2"
                           (click)="activatePanel(2)">
            <span>{{ 'editor.REUSABLE_SECTIONS' | translate }}</span>
          </app-tab-trigger>
        </div>
      </div>
      <app-tab-panel *ngIf="activated === 0"
                     panelId="components-panel"
                     triggerId="components-trigger">
        <perfect-scrollbar [autoPropagation]="true"
                           [style.height.px]="580">
          <div class="component-list">
            <div class="component-category active"
                 *ngFor="let blockCategory of blockGroups">
              <button class="component-category-trigger"
                      type="button"
                      (click)="toggleCategory($event)">{{ blockCategory.name }}</button>
              <div class="component-category-list">
                <button class="component-category-item"
                        type="button"
                        (click)="addBlock($event, block)"
                        *ngFor="let block of blockCategory.children">
                  <span class="component-category-item--icon" [innerHTML]="componentIcon(block.icon)"></span>
                  <span class="component-category-item--name">{{ block.blockName }}</span>
                </button>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </app-tab-panel>
      <app-tab-panel *ngIf="includeSections && activated === 1"
                     panelId="templates-panel"
                     triggerId="templates-trigger">
        <perfect-scrollbar [autoPropagation]="true"
                           [style.height.px]="580">
          <div class="sections-container">
            <div class="sections-list" [class.inside]="inside">
              <div class="sections-item"
                   (click)="addSection($event, section)"
                   *ngFor="let section of sectionTemplates">
                <div>
                  <div class="sections-item__image" [style.backgroundColor]="section.image ? 'url(\'' + section.image + '\')' : ''"></div>
                  <div class="sections-item__name">
                    <button type="button" (click)="addSection($event, section)">{{ section.name }}</button>
                    <ul>
                      <li *ngFor="let component of sectionComponents(section.components)">{{ component }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </app-tab-panel>
      <app-tab-panel *ngIf="includeSections && activated === 2"
                     panelId="reusable-panel"
                     triggerId="reusable-trigger">
        <perfect-scrollbar [autoPropagation]="true">
          <div class="sections-container">
            <div class="sections-list" [class.inside]="inside">
              <div class="sections-item"
                   (click)="addSection($event, section)"
                   *ngFor="let section of reusableSections">
                <div>
                  <div class="sections-item__image" [style.backgroundColor]="section.image ? 'url(\'' + section.image + '\')' : ''"></div>
                  <div class="sections-item__name">
                    <button type="button" (click)="addSection($event, section)">{{ section.name }}</button>
                    <ul>
                      <li *ngFor="let component of sectionComponents(section.components)">{{ component }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </app-tab-panel>
    </div>
  </div>
</div>
