import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { PageComponent } from './page/page.component';
import { PageNotFoundComponent } from '../../../../src/app/common/page-not-found/page-not-found.component';
import { InvalidTokenComponent } from '../../../../src/app/common/invalid-token/invalid-token.component';
import { CanAccessRouteGuard } from '../../../../src/app/_guards';
import { ForbiddenComponent } from '../../../../src/app/common/forbidden/forbidden.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: '403',
    data: {
      breadcrumb: 'navigation.FORBIDDEN'
    },
    component: ForbiddenComponent
  },
  {
    path: '404',
    data: {
      breadcrumb: 'navigation.NOT_FOUND_PAGE'
    },
    component: PageNotFoundComponent
  },
  {
    path: '498',
    data: {
      breadcrumb: 'navigation.NOT_FOUND_PAGE'
    },
    component: InvalidTokenComponent
  },
  {
    path: ':alias',
    component: PageComponent,
    canActivate: [
      CanAccessRouteGuard
    ]
  },
  // {
  //   path: 'page/:id',
  //   component: PageComponent
  // },
  // {
  //   path: 'article/:id',
  //   component: PageComponent
  // },
  // {
  //   path: ':category/:alias',
  //   component: ArticleComponent
  // },
  {
    path: '**',
    data: {
      breadcrumb: 'navigation.NOT_FOUND_PAGE'
    },
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
