import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { RoutePermissionInterface } from '../_interfaces';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable()
export class CanAccessRouteGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store<any>,
    private location: Location
  ) { }

  static canActivateRoute(route: ActivatedRouteSnapshot, routeName: string, routePermissions: RoutePermissionInterface): boolean {
    if (!routeName) {
      return true;
    }
    if (route.params.id) {
      routeName = routeName.replace(route.params.id, ':id');
    }
    const permission = `Route::${ routeName }`;
    if (!routePermissions.all.find(r => r === permission)) {
      return true;
    }
    return !!routePermissions.user.find(r => r === permission);
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const routeName = this.location.prepareExternalUrl(state.url).substring(1).replace(/(.+?)\/+$/, '') || null;

    if (this.authService.isRoot) {
      return true;
    }

    return new Promise<boolean>((resolve, reject) => {

      let routePermissions = null;

      this.store.select('routePermissionsData').subscribe((routePermissionsData: RoutePermissionInterface) => {
        routePermissions = routePermissionsData;
      });

      if (!routePermissions) {
        this.authService.getRoutePermissions().pipe(first())
          .subscribe( data => {
            routePermissions = data;
            this.authService.setRoutePermissions(routePermissions);
            if (CanAccessRouteGuard.canActivateRoute(next, routeName, routePermissions)) {
              resolve(true);
            } else {
              this.router.navigate(['/403']).catch();
              resolve(false);
              return;
            }
          }, error => {
            reject(error);
            this.router.navigate(['/403']).catch();
          });
      } else {
        if (CanAccessRouteGuard.canActivateRoute(next, routeName, routePermissions)) {
          resolve(true);
        } else {
          this.router.navigate(['/403']).catch();
          resolve(false);
          return;
        }
      }
    });
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}
