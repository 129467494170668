import { RoutePermissionInterface } from '../../_interfaces';

export function addRoutePermissionsReducer(state: RoutePermissionInterface = null, action): RoutePermissionInterface {
  switch (action.type) {
    case 'SET_ROUTE_PERMISSIONS':
      return action.payload;
    default:
      return state;
  }
}
