import { Component } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';

@Component({
  selector: 'group-block',
  template: '<template><ng-content></ng-content></template>',
  styleUrls: ['./group.component.scss'],
  styles: [':host { display: block; }']
})

export class GroupComponent extends GenericBlock {

  public static type = BlockType.Group;
  public static tagNames = undefined;
  public static blockCategory = 'Logical';
  public static blockName = 'Group';
  public static structure = {
    style: {
      padding: '10px'
    },
    items: []
  };

  public static icon = '<svg x="0px" y="0px" viewBox="0 0 13 14" style="enable-background:new 0 0 13 14;" xml:space="preserve"><path id="component_x5F_paragraph" fill="currentColor" d="M13,0.5v1C13,1.834,12.833,2,12.5,2H11v11.5c0,0.333-0.167,0.5-0.5,0.5h-1C9.166,14,9,13.833,9,13.5V2H8v11.5C8,13.833,7.833,14,7.5,14h-1C6.166,14,6,13.833,6,13.5V10H5c-1.375,0-2.553-0.489-3.531-1.469C0.489,7.553,0,6.375,0,5s0.489-2.552,1.469-3.531C2.447,0.49,3.625,0,5,0h7.5C12.833,0,13,0.167,13,0.5z"/></svg>';

  constructor() {
    super();
  }

  public init(): void {}

  public get tagNames(): Array<string> {
    return GroupComponent.tagNames;
  }

  public get type(): string {
    return GroupComponent.type;
  }

  public get blockName(): string {
    return GroupComponent.blockName;
  }

  public get blockCategory(): string {
    return GroupComponent.blockCategory;
  }

  public get icon(): string {
    return GroupComponent.icon;
  }

  public get structure(): object {
    return GroupComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
