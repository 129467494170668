<div class="mce-content-body erom-block__video immutable-block" [class.mce-edit-focus]="block.autoFocus">
  <ng-container *ngIf="block.autoFocus; else videoPreview">
    <div class="erom-block__video--editable">
      <div class="erom-editor__group">
        <label [for]="'video-provider-' + block.id"
               class="erom-editor__label">{{ 'editor.PROVIDER' | translate }}</label>
        <erom-select class="provider-select"
                     [options]="providers"
                     [value]="block.data ? block.data.provider : null"
                     (optionSelected)="providerChanged($event)"></erom-select>
      </div>
      <div class="erom-editor__group">
        <label [for]="'video-id-' + block.id"
               class="erom-editor__label">{{ 'editor.ID' | translate }}</label>
        <input [id]="'video-id-' + block.id"
               class="erom-editor__input"
               (input)="updateVideo($event, 'id')"
               [value]="block.data ? block.data.id : ''" />
      </div>
      <div class="erom-editor__group">
        <label [for]="'video-title-' + block.id"
               class="erom-editor__label">{{ 'editor.TITLE' | translate }}</label>
        <input [id]="'video-title-' + block.id"
               class="erom-editor__input"
               (input)="updateVideo($event, 'title')"
               [value]="block.data ? block.data.title : ''" />
      </div>
    </div>
  </ng-container>
  <ng-template #videoPreview>
    <div class="erom-block__video--container erom-editor-tabbable"
         [attr.aria-label]="'editor.ACTIVATE_VIDEO' | translate"
         [tabindex]="editMode ? '0' : '-1'"
         [class.edit-mode]="editMode">
      <div class="erom-media-preview">
        <svg *ngIf="!block.data || !block.data.html || block.data.html === ''" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-play" class="svg-inline--fa fa-circle-play fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM372.5 276.5l-144 88C224.7 366.8 220.3 368 216 368c-13.69 0-24-11.2-24-24V168C192 155.3 202.2 144 216 144c4.344 0 8.678 1.176 12.51 3.516l144 88C379.6 239.9 384 247.6 384 256C384 264.4 379.6 272.1 372.5 276.5z"></path>
        </svg>
      </div>
      <div [innerHTML]="(block.data && block.data.html ? block.data.html : '') | safeHtml"></div>
    </div>
  </ng-template>
</div>
