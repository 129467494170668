import { AfterViewInit, Component, HostListener, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../../../src/app/_store';
import { UuidService } from '../../../../src/app/_services/uuid.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../src/app/_services/google-analytics.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { getCookie } from '../../../../src/app/_helpers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var $: any;

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  private nonMenuNodes: any;

  private previousUrl: string;

  title = 'front';

  constructor(
    private http: HttpClient,
    private api: API,
    private uuidService: UuidService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ga: GoogleAnalyticsService,
    private titleService: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.getSettings();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const prevQuery = new URLSearchParams(this.previousUrl ? this.previousUrl.substr(1) : '');
        const newQuery = new URLSearchParams(event.urlAfterRedirects.substr(1));
        this.previousUrl = event.url;
        if (!prevQuery.get('search') && !newQuery.get('search') ||
          (prevQuery.get('search') && newQuery.get('search') && prevQuery.get('search') === newQuery.get('search'))) {
          ga.pageView(event.urlAfterRedirects);
        }
      }
    });
  }

  private focusTopOfThePage(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  private setupSiteLogoSchema(logo: string): void {
    if (!logo) {
      console.warn('Cannot setup logo schema. Invalid logo.');
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      const json = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: location.origin,
        logo: `${location.origin}/${logo}`
      };
      let logoSchemaScript = (this.document.getElementById('logoSchemaScript') as HTMLScriptElement);
      if (!logoSchemaScript)  {
        logoSchemaScript = this.document.createElement('script');
      }
      logoSchemaScript.type = 'application/ld+json';
      logoSchemaScript.innerHTML = JSON.stringify(json);
      logoSchemaScript.id = 'logoSchemaScript';
      document.head.appendChild(logoSchemaScript);
    }
  }

  private getSettings(): void {
    let dataParams = new HttpParams();
    const keys = [
      'website-logo',
      'meta-image',
      'meta-title',
      'meta-description'
    ];
    dataParams = dataParams.append('keys', JSON.stringify(keys));
    this.http.get(`${this.api.URLS.setting}/from-keys`, { params: dataParams }).subscribe((response: any) => {
      if (isPlatformBrowser(this.platformId)) {
        if (response['website-logo']) {
          this.setupSiteLogoSchema(response['website-logo']);
          this.meta.updateTag({
            property: 'og:image',
            content: `${location.origin}${response['meta-image']}`
          });
        }
        if (response['meta-title']) {
          // this.titleService.setTitle(response['meta-title']);
          // this.meta.updateTag({
          //   property: 'og:title',
          //   content: response['meta-title']
          // });
        }
        if (response['meta-description']) {
          // this.meta.updateTag({
          //   name: 'description',
          //   content: response['meta-description']
          // });
          // this.meta.updateTag({
          //   property: 'og:description',
          //   content: response['meta-description']
          // });
        }
        this.meta.updateTag({
          property: 'og:url',
          content: location.href
        });
        this.meta.updateTag({
          property: 'og:type',
          content: 'website'
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  ngAfterViewInit(): void {
    this.focusTopOfThePage();
    this.ga.setupOSDimension();
    if (isPlatformBrowser(this.platformId)) {
      const parser = new DOMParser();
      const MCMWHeader = this.document.getElementById('abilityHeaderContainer');
      const MCMWFooter = this.document.getElementById('abilityFooterContainer');
      this.http.get(`${this.api.abilityURL}/header-and-footer`, {responseType: 'text'})
        .subscribe(
          (data) => {
            const doc = parser.parseFromString(data, 'text/html');
            const abilityHeader = doc.getElementById('abilityHeader');
            const abilityNavbar = doc.getElementById('navbar');
            const abilityFooter = doc.getElementById('abilityFooter');
            if (abilityHeader) {
              MCMWHeader.appendChild(abilityHeader);
            }
            if (abilityNavbar) {
              MCMWHeader.appendChild(abilityNavbar);
            }
            if (abilityFooter) {
              MCMWFooter.appendChild(abilityFooter);
            }
            this.headerSearch();
            this.mobileMenu();
            this.initMegamenu(false);
            this.footerLinks();
            this.latestNewsLinks();
            this.resetLinkTitles('.footer-links a');
            this.resetLinkTitles('.footer-bottom-links a');
            this.headerTopMenuFix();
            this.backToTopClick();
            this.scrollToView();
          },
          (error) => {
            console.log(error);
          }
        );
    }
    // this.externalLinksRepair();
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('keyboard-focus');
  }

  private initMegamenu(reInit): void {
    const megamenu = $('.megamenu');
    if (reInit) {
      megamenu.accessibleMegaMenu('destroy');
    }
    megamenu.accessibleMegaMenu({
      uuidPrefix: 'accessible-megamenu',
      menuClass: 'nav-menu',
      topNavItemClass: 'nav-item',
      panelClass: 'sub-nav',
      panelGroupClass: 'sub-nav-group',
      hoverClass: 'hover',
      focusClass: 'focus',
      openClass: 'open',
      closeDelay: 750,
      openOnMouseover: true
    });
    // $('#navbar a.has-submenu').keydown(function (e) {
    //   if (e.which === 13 && !e.shiftKey) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     window.location.href = e.target.href;
    //   }
    // });
    $('#navbar .sub-nav a').keydown((e) => {
      if (e.which === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (isPlatformBrowser(this.platformId)) {
          window.location.href = e.target.href;
        }
      }
    });
    $('#navbar a.has-submenu').click((e) => {
      e.preventDefault();
      // window.location.href = $(this).attr('href');
    });
  }

  private headerSearch() {
    const self = this;
    const topMenu = $('.top-menu');
    const search = $('.top-search');
    const windowWidth = $(window).width();
    if (windowWidth <= this.em(73.75)) {
      search.find('form .mobile-search-trigger').attr('aria-expanded', topMenu.hasClass('search-active'));
      search.find('form .mobile-search-trigger').on('click', function(e) {
        e.preventDefault();
        if (topMenu.hasClass('search-active')) {
          topMenu.removeClass('search-active');
        } else {
          topMenu.addClass('search-active');
          search.find('form input[type="text"]').focus();
        }
        $(this).attr('aria-expanded', topMenu.hasClass('search-active'));
        $('.header-mobile-menu').removeClass('active');
      });
      search.find('#search').on('focus', function () {
        topMenu.addClass('search-active');
        search.find('form .mobile-search-trigger').attr('aria-expanded', true);
      });
      search.find('form .mobile-search-trigger').keypress(function (e) {
        e.preventDefault();
        if (e.which === 13 || e.which === 32) {
          $('.header-mobile-menu').removeClass('active');
          if (topMenu.hasClass('search-active')) {
            $('.top-menu').removeClass('search-active');
            search.find('form .mobile-search-trigger').focus();
          } else {
            topMenu.addClass('search-active');
            search.find('form input[type="text"]').focus();
          }
          $(this).attr('aria-expanded', topMenu.hasClass('search-active'));
        }
      });

    }

    search.find('form').submit(function (e) {
      e.preventDefault();
      const action = $(this).attr('action');
      const data = $(this).serializeArray();
      const query = data[0]['value'];
        if (query !== '') {
          document.location.href = self.api.abilityURL + action + '?key=' + query;
        } else {
          document.location.href = document.location.href = self.api.abilityURL + action;
        }
    });
  }

  private mobileMenu() {
    const self = this;
    const mobileMenuTrigger = $('.main-menu-trigger');
    if (mobileMenuTrigger.length) {
      mobileMenuTrigger.click(function() {
        $(this).parent().toggleClass('active');
        $('.top-search').removeClass('search-active');
        $('.mobile-search-trigger').attr('aria-expanded', false);
        $(this).attr('aria-expanded', $(this).parent().hasClass('active'));
      });
    }
    const mobileSubmenuTrigger = $('.nav-item-collapse');
    mobileSubmenuTrigger.click(function () {
      $(this).closest('li').toggleClass('active');
      $(this).attr('aria-expanded', $(this).closest('li').hasClass('active'));
    });

    const mobileMenuContainer = $('#mobileMainMenuContainer button');

    if (mobileMenuContainer) {
      mobileMenuContainer.removeAttr('tabindex');
    }

    if (Array.from) {
      const menuNodes = Array.from( this.document.querySelectorAll('app-root, #abilityHeaderContainer, #abilityHeaderContainer header, header > div, header > div > div, #abilityHeader, #abilityHeader > .container, #abilityHeader .header-container, #abilityHeader .top-menu, #abilityHeader .header-mobile-menu *, .main-menu-trigger') );
      // nonMenuNodes = this.document.querySelectorAll('body *:not(.main-menu-trigger):not(.main-menu-container):not([tabindex="-1"]):not([aria-hidden="true"]):not(#abilityHeader)');
      self.nonMenuNodes = this.document.querySelectorAll('body *:not([tabindex="-1"]):not([aria-hidden="true"]):not(app-root):not(.header-mobile-menu)');
      if (mobileMenuTrigger.length) {
        mobileMenuTrigger.click(function(e) {
          if ($(this).parent().hasClass('active')) {
            for (let i = 0; i < self.nonMenuNodes.length; i++) {
              const node = self.nonMenuNodes[i];

              if (!menuNodes.includes(node)) {
                node._prevTabindex = node.getAttribute('tabindex');
                node._prevAriaHidden = node.getAttribute('aria-hidden');
                node.setAttribute('tabindex', -1);
                node.setAttribute('aria-hidden', 'true');
                node.style.outline = 'none';
              }
            }
          } else {
            for (let i = 0; i < self.nonMenuNodes.length; i++) {
              const node = self.nonMenuNodes[i];
              if (node._prevTabindex) {
                node.setAttribute('tabindex', node._prevTabindex);
                node._prevTabindex = null;
              } else {
                node.removeAttribute('tabindex');
              }
              if (node._prevAriaHidden) {
                node.setAttribute('aria-hidden', node._prevAriaHidden);
                node._prevAriaHidden = null;
              } else {
                node.removeAttribute('aria-hidden');
              }
              node.style.outline = null;
            }
          }
        });
      }
    }
  }

  private footerLinks() {
    const self = this;
    $('#abilityFooter a:not([href^="http"], [href^="tel:"], [href^="mailto:"])').each(function () {
      $(this).attr('href', self.api.abilityURL + $(this).attr('href'));
    });
  }

  private latestNewsLinks() {
    const self = this;
    $('.mcmw-latest-news a').each(function () {
      $(this).attr('href', self.api.abilityURL + $(this).attr('href'));
    });
  }

  private resetLinkTitles(links) {
    if ($(links).length) {
      $(links).each(function () {
        $(this).removeAttr('title');
      });
    }
  }

  private headerTopMenuFix () {
    const self = this;
    const links = $('.top-menu-items .menu a');
    if (links.length) {
      links.each(function () {
        $(this).attr('href', self.api.abilityURL + $(this).attr('href'));
      });
    }
  }

  private em(input) {
    const emSize = parseFloat($('body').css('font-size'));
    return (emSize * input);
  }

  private scrollToView() {
    $(document).on('click', '.erom-block__toc--list a', function(e) {
      e.preventDefault();
      console.log($(this).attr("href"));
      const url = new URL($(this).attr("href"));
      const fragment = url.hash;
      var position = $(fragment).offset().top;
    
      $("body, html").animate({
        scrollTop: position
      } /* speed */ );
    });   
  }

  private backToTop(): void {
    const button = this.document.querySelector('.back-to-top-container button');
    const scrollTrigger = 860;
    if (!button) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.scrollY > scrollTrigger) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    }
  }

  private backToTopClick(): void {
    const button = this.document.querySelector('.back-to-top-container button');
    if (!button) {
      return;
    }
    button.addEventListener('click', (event: Event) => {
      this.skipToContent(event);
    });
  }

  private closeCookies(accepted: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cookies', accepted.toString());
    }
    const logo: HTMLElement = this.document.querySelector('.header-container .logo');
    if (logo) {
      logo.focus();
    }
  }

  public onActivate(event: any): any {
    if (isPlatformBrowser(this.platformId) && !location.hash) {
      window.scrollTo(0, 0);
    }
  }

  public skipToContent(e): void {
    e.preventDefault();
    const elm = this.document.getElementsByTagName('h1');
    if (elm.length) {
      // const oldTabIndex = elm[0].getAttribute('tabindex');
      elm[0].setAttribute('tabindex', '-1');
      elm[0].focus();
      // setTimeout(() => {
      //   elm[0].setAttribute('tabindex', oldTabIndex);
      // }, 0);
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  public get cookieAccepted(): boolean {
    const cookieAccepted = isPlatformBrowser(this.platformId) ? localStorage.getItem('cookies') : null;
    return !!cookieAccepted;
  }

  // private externalLinksRepair(): void {
  //   const checkDomain = function(url) {
  //     if ( url.indexOf('//') === 0 ) { url = location.protocol + url; }
  //     return url.toLowerCase().replace(/([a-z])?:\/\//,'$1').split('/')[0];
  //   };
  //   const isExternal = function(url) {
  //     return ( ( url.indexOf(':') > -1 || url.indexOf('//') > -1 ) && checkDomain(location.href) !== checkDomain(url) );
  //   };
  //   setTimeout(() => {
  //     const allLinks = this.document.links;
  //     for (let i = 0; i < allLinks.length; i++) {
  //       if (isExternal(allLinks[i].href)) {
  //         const id = this.uuidService.uuid;
  //         allLinks[i].innerHTML = `${allLinks[i].innerHTML} <span class="erom-visually-hidden">(link opens in new tab/window)</span>`;
  //       }
  //     }
  //   }, 2000);
  // }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.backToTop();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.headerSearch();
  }

  @HostListener('document:keydown', ['$event'])
  onDocumentKeydown(event) {
    if (event.which === 9) {
      this.document.body.classList.add('keyboard-focus');
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onDocumentMousedown(event) {
    this.document.body.classList.remove('keyboard-focus');
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('click', ['$event'])
  public clickHandler(event): void {
    if (event.target) {
      if (event.target.nodeName === 'A') {
        if (isPlatformBrowser(this.platformId)) {
          const hostRegex = new RegExp(location.hostname);
          if (event.target.download && event.target.download !== '') {
            this.ga.event('interact', 'download', event.target.href);
          } else if (!hostRegex.test(event.target.hostname)) {
            this.ga.event('externalLink', 'click', event.target.href);
          }
        }
      }
    }
  }
}
