<div class="erom-call-to-action-block">
  <div class="grid-align-right" role="region" aria-labelledby="needHelp">
    <div>
      <span class="erom-call-to-action-block__icon">
        <svg x="0px" y="0px" viewBox="0 0 21.875 25" style="enable-background:new 0 0 21.875 25;" xml:space="preserve">
          <g>
            <path fill="currentColor" d="M15.381,17.188c1.79,0,3.32,0.635,4.59,1.904s1.904,2.799,1.904,4.59c0,0.357-0.131,0.667-0.391,0.928
              C21.224,24.869,20.914,25,20.557,25H1.318c-0.358,0-0.668-0.131-0.928-0.391C0.13,24.349,0,24.039,0,23.682
              c0-1.791,0.635-3.32,1.904-4.59s2.799-1.904,4.59-1.904H15.381z M2.344,10.938c-0.521,0-0.781-0.26-0.781-0.781V9.375
              c0-2.604,0.911-4.817,2.734-6.641C6.119,0.912,8.333,0,10.938,0c2.604,0,4.817,0.912,6.641,2.734
              c1.822,1.823,2.734,4.037,2.734,6.641v0.781c0,1.498-0.537,2.783-1.611,3.857s-2.36,1.611-3.857,1.611h-4.688
              c-0.456,0-0.83-0.146-1.123-0.439s-0.439-0.667-0.439-1.123c0-0.455,0.146-0.83,0.439-1.123S9.7,12.5,10.156,12.5h1.562
              c0.455,0,0.83,0.146,1.123,0.439s0.439,0.668,0.439,1.123h1.562c1.074,0,1.993-0.382,2.759-1.147
              c0.765-0.765,1.147-1.685,1.147-2.759V9.375c0-2.148-0.766-3.987-2.295-5.518c-1.53-1.529-3.369-2.295-5.518-2.295
              S6.949,2.328,5.42,3.857c-1.53,1.53-2.295,3.369-2.295,5.518v0.781C3.125,10.678,2.864,10.938,2.344,10.938z M1.562,23.438h18.75
              c-0.065-1.303-0.578-2.408-1.538-3.32c-0.961-0.911-2.092-1.367-3.394-1.367H6.494c-1.303,0-2.434,0.456-3.394,1.367
              C2.14,21.029,1.627,22.135,1.562,23.438z M13.501,5.444c-0.766-0.504-1.62-0.757-2.563-0.757c-0.944,0-1.799,0.253-2.563,0.757
              C7.608,5.949,7.031,6.625,6.641,7.471c-0.814,1.855-0.537,3.532,0.83,5.029c-0.293,0.488-0.439,1.01-0.439,1.562v0.146
              c-1.562-1.236-2.344-2.848-2.344-4.834c0-1.725,0.61-3.198,1.831-4.419s2.693-1.831,4.419-1.831c1.725,0,3.198,0.61,4.419,1.831
              s1.831,2.694,1.831,4.419c0,0.586-0.114,1.254-0.342,2.002c-0.488,0.749-1.156,1.123-2.002,1.123h-0.439
              c1.367-1.497,1.644-3.174,0.83-5.029C14.844,6.625,14.266,5.949,13.501,5.444z"/>
          </g>
        </svg>
      </span>
      <h3 class="erom-call-to-action-block__title" id="needHelp" style="color: #000000">Need some more help?</h3>
      <div class="erom-call-to-action-block__text" style="color: #000000">
        Call our free helpline <a href="tel:+448000487642" (click)="gaEvent('helpButtons', '08000487642')">0800 048 7642</a> or email <a href="mailto:enquiries@abilitynet.org.uk" (click)="gaEvent('helpButtons', 'enquiries@abilitynet.org.uk')">enquiries@abilitynet.org.uk</a>
      </div>
      <div class="erom-call-to-action-block__actions">
        <a href="tel:+448000487642" style="color: #000000; border-color: #7F7F7F" (click)="gaEvent('helpButtons', 'callFreeHelpline')">Call free Helpline</a>
      </div>
    </div>
  </div>
  <div class="grid-align-left" role="region" aria-labelledby="needSupport">
    <div>
      <span class="erom-call-to-action-block__icon">
        <svg x="0px" y="0px" viewBox="0 0 30.506 25.024" style="enable-background:new 0 0 30.506 25.024;" xml:space="preserve">
          <g>
            <path fill="currentColor" d="M9.412,18.774c0.521,0,0.781,0.26,0.781,0.781s-0.261,0.781-0.781,0.781H4.725
              c-0.651,0-1.205-0.229-1.66-0.684c-0.456-0.455-0.684-1.01-0.684-1.66V9.79l-1.025,0.977c-0.391,0.325-0.766,0.309-1.123-0.049
              c-0.325-0.391-0.31-0.766,0.049-1.123L10.438,0.22c0.357-0.293,0.716-0.293,1.074,0l6.494,6.006V3.149
              c0-0.521,0.26-0.781,0.781-0.781c0.521,0,0.781,0.26,0.781,0.781V7.69l2.1,1.904c0.357,0.357,0.374,0.732,0.049,1.123
              c-0.163,0.162-0.358,0.244-0.586,0.244c-0.229,0-0.407-0.065-0.537-0.195L10.975,1.88L3.943,8.374v9.619
              c0,0.521,0.26,0.781,0.781,0.781H9.412z M10.975,13.306c0.521,0,0.781,0.26,0.781,0.781c0,0.521-0.261,0.781-0.781,0.781H8.631
              c-0.521,0-0.781-0.261-0.781-0.781V9.399c0-0.521,0.26-0.781,0.781-0.781h4.688c0.521,0,0.781,0.26,0.781,0.781v0.781
              c0,0.521-0.261,0.781-0.781,0.781c-0.521,0-0.781-0.261-0.781-0.781H9.412v3.125H10.975z M29.725,23.462
              c0.521,0,0.781,0.26,0.781,0.781c0,0.52-0.261,0.781-0.781,0.781H12.537c-0.521,0-0.781-0.262-0.781-0.781
              c0-0.521,0.26-0.781,0.781-0.781H29.725z M13.318,14.868c0-0.651,0.228-1.205,0.684-1.66c0.455-0.456,1.009-0.684,1.66-0.684H26.6
              c0.65,0,1.204,0.228,1.66,0.684c0.455,0.455,0.684,1.009,0.684,1.66v6.25c0,0.521-0.261,0.781-0.781,0.781
              c-0.521,0-0.781-0.26-0.781-0.781v-6.25c0-0.521-0.261-0.781-0.781-0.781H15.662c-0.521,0-0.781,0.26-0.781,0.781v6.25
              c0,0.521-0.261,0.781-0.781,0.781c-0.521,0-0.781-0.26-0.781-0.781V14.868z"/>
          </g>
          <g>
            <path fill="currentColor" d="M9.412,57.868c0.521,0,0.781,0.26,0.781,0.781s-0.261,0.781-0.781,0.781H4.725
              c-0.651,0-1.205-0.229-1.66-0.684c-0.456-0.455-0.684-1.01-0.684-1.66v-8.203L1.356,49.86c-0.391,0.325-0.766,0.309-1.123-0.049
              c-0.325-0.391-0.31-0.766,0.049-1.123l10.156-9.375c0.357-0.293,0.716-0.293,1.074,0l6.494,6.006v-3.076
              c0-0.521,0.26-0.781,0.781-0.781c0.521,0,0.781,0.26,0.781,0.781v4.541l2.1,1.904c0.357,0.357,0.374,0.732,0.049,1.123
              c-0.163,0.162-0.358,0.244-0.586,0.244c-0.229,0-0.407-0.065-0.537-0.195l-9.619-8.887l-7.031,6.494v9.619
              c0,0.521,0.26,0.781,0.781,0.781H9.412z M10.975,52.399c0.521,0,0.781,0.26,0.781,0.781c0,0.521-0.261,0.781-0.781,0.781H8.631
              c-0.521,0-0.781-0.261-0.781-0.781v-4.688c0-0.521,0.26-0.781,0.781-0.781h4.688c0.521,0,0.781,0.26,0.781,0.781v0.781
              c0,0.521-0.261,0.781-0.781,0.781c-0.521,0-0.781-0.261-0.781-0.781H9.412v3.125H10.975z M29.725,62.556
              c0.521,0,0.781,0.26,0.781,0.781c0,0.52-0.261,0.781-0.781,0.781H12.537c-0.521,0-0.781-0.262-0.781-0.781
              c0-0.521,0.26-0.781,0.781-0.781H29.725z M13.318,53.962c0-0.651,0.228-1.205,0.684-1.66c0.455-0.456,1.009-0.684,1.66-0.684H26.6
              c0.65,0,1.204,0.228,1.66,0.684c0.455,0.455,0.684,1.009,0.684,1.66v6.25c0,0.521-0.261,0.781-0.781,0.781
              c-0.521,0-0.781-0.26-0.781-0.781v-6.25c0-0.521-0.261-0.781-0.781-0.781H15.662c-0.521,0-0.781,0.26-0.781,0.781v6.25
              c0,0.521-0.261,0.781-0.781,0.781c-0.521,0-0.781-0.26-0.781-0.781V53.962z"/>
          </g>
        </svg>
      </span>
      <h3 class="erom-call-to-action-block__title erom-editor-exclude-toc" id="needSupport" style="color: #ffffff">Need free IT Support at Home?</h3>
      <div class="erom-call-to-action-block__text" style="color: #ffffff">
        If you are older or disabled and need IT support at home, you can book a free home visit.
      </div>
      <div class="erom-call-to-action-block__actions">
        <a href="https://abilitynet.org.uk/at-home/request-free-it-support-home" style="color: #ffffff; border-color: #7FADB6" (click)="gaEvent('helpButtons', 'bookHomeVisit')">Book a Home visit</a>
      </div>
    </div>
  </div>
</div>
