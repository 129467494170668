import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { markAsTouched } from '../../_helpers/common';
import { API } from '../../_store';
import { HttpClient } from '@angular/common/http';
import { PasswordValidation, RepeatPasswordValidator } from '../../_validators';

@AutoUnsubscribe()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public resetForm: FormGroup;

  private _token: string;
  private _invalidToken: boolean;
  private _tokenCheckInProgress: boolean;
  private _submitting: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: API,
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']).catch();
    }
    this.resetForm = new FormGroup({
      password: new FormControl(null, PasswordValidation),
      retypePassword: new FormControl(null, PasswordValidation)
    }, RepeatPasswordValidator );
  }

  public ngOnInit(): void {
    this._token = this.activatedRoute.snapshot.params.token;
    this.checkResetToken();
  }

  public ngOnDestroy(): void {
  }

  private checkResetToken(): void {
    this._tokenCheckInProgress = true;
    if (!this._token) {
      this._invalidToken = true;
    }
    this.authService.checkResetToken(this._token).pipe(first()).subscribe(response => {
      this._invalidToken = !response.active;
      if (this._invalidToken) {
        location.href = `${location.origin}/498`;
      } else {
        this._tokenCheckInProgress = false;
      }
    }, () => {
      this._invalidToken = true;
      this._tokenCheckInProgress = false;
    });
  }

  public onSubmit(): void {

    markAsTouched(this.resetForm);

    if (this.resetForm.invalid) {
      return;
    }

    this._submitting = true;

    this.authService.reset(this._token, this.f.password.value).pipe(first()).subscribe(response => {
      this.authService.login(response.email, this.f.password.value).pipe(first()).subscribe(() => {
        this._submitting = false;
        this.router.navigate(['/']).catch();
      }, error => {
        this._submitting = false;
        console.log(error);
      });
    }, error => {
      this._submitting = false;
      console.log(error);
    });
  }

  public get f(): any {
    return this.resetForm.controls;
  }

  public get submitting(): boolean {
    return this._submitting;
  }

  public get invalidToken(): boolean {
    return this._invalidToken;
  }

  public get tokenCheckInProgress(): boolean {
    return this._tokenCheckInProgress;
  }

}
