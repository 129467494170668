import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionHeaderComponent {

  @Input() headerTitle: string;
  @Input() index: number;
  @Input() isOpen: boolean;
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  public setFocus(): void {
    this.focused.emit();
  }

  public setBlur(): void {
    this.blurred.emit();
  }

  public togglePanel(): void {
    if (!this.isOpen) {
      this.opened.emit();
    } else {
      this.closed.emit();
    }
  }
}
