import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab-panel',
  template: `
    <div
      [id]="panelId"
      role="tabpanel"
      [attr.aria-labelledby]="triggerId"
      [style.height.px]="height"
      tabindex="0"
    >
      <ng-content></ng-content>
    </div>
  `
})
export class TabPanelComponent {
  @Input() panelId: string;
  @Input() triggerId: string;
  @Input() height: string;
}
