<div class="erom-article-feedback-block">
  <div class="erom-article-feedback-block__content"
       aria-labelledby="feedbackLabel"
       role="region">
    <h2 id="feedbackLabel" aria-live="off" #feedbackLabel>
      <ng-container *ngIf="!ratingGiven">
        {{ 'front.WAS_CONTENT_HELPFUL' | translate }}
      </ng-container>
      <ng-container *ngIf="ratingGiven">
        <ng-container *ngIf="ratingGiven === 2">
          {{ 'front.CONTENT_HELPFUL' | translate }}
        </ng-container>
        <ng-container *ngIf="ratingGiven === 1">
          {{ 'front.CONTENT_NOT_HELPFUL' | translate }}
        </ng-container>
      </ng-container>
    </h2>
    <div class="erom-article-feedback-block__actions">
      <ng-container *ngIf="!ratingGiven">
        <button (click)="ratingSubmit(2, feedbackLabel)">
          <svg x="0px" y="0px" viewBox="0 0 18 16.875" style="enable-background:new 0 0 18 16.875;" xml:space="preserve">
            <g>
              <path fill="currentColor" d="M3.656,6.75c0.233,0,0.434,0.083,0.598,0.246C4.417,7.16,4.5,7.36,4.5,7.594v8.438
                c0,0.234-0.083,0.434-0.246,0.598c-0.164,0.164-0.364,0.246-0.598,0.246H0.844c-0.234,0-0.435-0.082-0.598-0.246
                C0.082,16.465,0,16.266,0,16.031V7.594C0,7.36,0.082,7.16,0.246,6.996C0.409,6.833,0.609,6.75,0.844,6.75H3.656z M16.312,6.188
                c0.469,0,0.867,0.164,1.195,0.492C17.836,7.009,18,7.406,18,7.875v0.562c0,0.61-0.258,1.078-0.773,1.406
                c0.141,0.234,0.211,0.516,0.211,0.844v0.562c0,0.797-0.375,1.324-1.125,1.582v0.105V13.5c0,0.773-0.375,1.301-1.125,1.582v0.105
                c0,0.469-0.164,0.867-0.492,1.195s-0.727,0.492-1.195,0.492H9.562c-1.008,0-1.875-0.34-2.602-1.02l-1.336-1.23V6.75l0.527-0.738
                c0.609-0.937,1.055-1.547,1.336-1.828C7.793,3.88,8.062,3.234,8.297,2.25C8.625,0.751,9.094,0,9.703,0
                c0.258,0,0.516,0.035,0.773,0.105s0.545,0.199,0.861,0.387c0.316,0.188,0.568,0.51,0.756,0.967s0.281,1.015,0.281,1.67
                c0,0.751-0.234,1.547-0.703,2.391c-0.117,0.234-0.211,0.457-0.281,0.668H16.312z"/>
            </g>
          </svg>
          <span>{{ 'front.YES' | translate }}</span>
        </button>
        <button (click)="ratingSubmit(1, feedbackLabel)">
          <svg x="0px" y="0px" viewBox="0 0 18 16.875" style="enable-background:new 0 0 18 16.875;" xml:space="preserve">
            <g>
              <path fill="currentColor" d="M3.656,0C3.89,0,4.09,0.083,4.254,0.246C4.417,0.41,4.5,0.61,4.5,0.844v8.438c0,0.234-0.083,0.435-0.246,0.598
                c-0.164,0.164-0.364,0.246-0.598,0.246H0.844c-0.234,0-0.435-0.082-0.598-0.246C0.082,9.716,0,9.516,0,9.281V0.844
                C0,0.61,0.082,0.41,0.246,0.246C0.409,0.083,0.609,0,0.844,0H3.656z M17.227,7.031C17.742,7.36,18,7.828,18,8.438V9
                c0,0.47-0.164,0.867-0.492,1.195c-0.328,0.329-0.727,0.492-1.195,0.492h-4.922c0.07,0.211,0.164,0.434,0.281,0.668
                c0.469,0.844,0.703,1.641,0.703,2.391c0,0.867-0.182,1.553-0.545,2.057s-0.715,0.809-1.055,0.914s-0.697,0.158-1.072,0.158
                c-0.609,0-1.078-0.75-1.406-2.25c-0.234-0.984-0.504-1.629-0.809-1.934c-0.281-0.281-0.727-0.891-1.336-1.828l-0.527-0.738V2.25
                l1.336-1.23C7.688,0.34,8.555,0,9.562,0H13.5c0.469,0,0.867,0.164,1.195,0.492c0.328,0.329,0.492,0.727,0.492,1.195v0.105
                c0.75,0.281,1.125,0.809,1.125,1.582v0.562v0.105c0.75,0.259,1.125,0.786,1.125,1.582v0.562
                C17.438,6.517,17.367,6.798,17.227,7.031z"/>
            </g>
          </svg>
          <span>{{ 'front.NO' | translate }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="ratingGiven">
        <a [href]="thumbsUpDownLink" id="feedbackLink" target="_blank">
          {{ 'front.GIVE_FEEDBACK' | translate }} <span class="erom-visually-hidden">(link opens in new tab/window)</span>
        </a>
      </ng-container>
    </div>
  </div>
</div>
