<div class="mce-content-body erom-block__image immutable-block" [class.mce-edit-focus]="block.autoFocus">
  <ng-container *ngIf="block.autoFocus; else imagePreview">
    <div class="erom-block__image--editable">
      <div class="erom-editor__group">
        <label [for]="'image-file-' + block.id" class="erom-editor__label">{{ 'editor.IMAGE' | translate }}</label>
        <div class="erom-editor__image">
          <div class="erom-editor__image--preview"
               [style.background-image]="'url(\'' + block.data.file + '\')'"
               *ngIf="block.data && block.data.file"></div>
          <button type="button"
                  class="erom-editor__image--remove"
                  *ngIf="block.data && block.data.file"
                  (click)="this.removeImage()">
            <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
            <span>{{ 'editor.REMOVE' | translate }}</span>
          </button>
          <div class="erom-editor__image--input"
               [class.erom-visually-hidden]="block.data && block.data.file">
            <input type="file"
                   [id]="'image-file-' + block.id"
                   (change)="uploadImage($event)" />
            <div class="erom-editor__image--input__text"
                 *ngIf="!block.data || !block.data.file">
              <fa-icon [icon]="['fas', 'arrow-circle-up']"></fa-icon>
              <span>{{ 'editor.UPLOAD_IMAGE' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="block.data && block.data.file">
        <div class="erom-editor__group">
          <label [for]="'image-alt-' + block.id"
                 class="erom-editor__label">{{ 'editor.ALT' | translate }}</label>
          <input [id]="'image-alt-' + block.id"
                 class="erom-editor__input"
                 (input)="updateImage($event, 'alt')"
                 [value]="block.data ? block.data.alt : ''" />
        </div>
        <div class="erom-editor__group">
          <label [for]="'image-caption-' + block.id"
                 class="erom-editor__label">{{ 'editor.CAPTION' | translate }}</label>
          <input [id]="'image-caption-' + block.id"
                 class="erom-editor__input"
                 (input)="updateImage($event, 'caption')"
                 [value]="block.data ? block.data.caption : ''" />
        </div>
        <div class="erom-editor__group">
          <label [for]="'image-max-width-' + block.id"
                 class="erom-editor__label">{{ 'editor.MAX_WIDTH' | translate }}</label>
          <input [id]="'image-max-width-' + block.id"
                 class="erom-editor__input"
                 (input)="updateImage($event, 'maxWidth')"
                 [value]="block.data ? block.data.maxWidth : ''" />
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #imagePreview>
    <div class="erom-block__image--container erom-editor-tabbable"
         [attr.aria-label]="'editor.ACTIVATE_IMAGE' | translate"
         [tabindex]="editMode ? '0' : '-1'"
         [class.edit-empty]="!block.data || !block.data.html || block.data.html === ''"
         [class.edit-mode]="editMode">
      <div class="erom-media-preview" *ngIf="!block.data || !block.data.html || block.data.html === ''">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" class="svg-inline--fa fa-image fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"></path>
        </svg>
      </div>
      <div [innerHTML]="(block.data ? block.data.html : '') | safeHtml"></div>
    </div>
  </ng-template>
</div>
