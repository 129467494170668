import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EROM_BLOCK_COMPONENTS, EROM_TINYMCE_SCRIPT_SRC, EromEditorComponent } from './erom-editor.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerRefDirective } from '../_directives';
import { EromBlockComponent } from '../blocks/block.component';
import { ReplaceLineBreaksPipe, SafeHtmlPipe } from '../_pipes';
import { EromEditorGlobals } from '../_store';
import {
  TabPanelComponent,
  TabTriggerComponent
} from '../tabs';
import {
  ParagraphComponent,
  HeadingComponent,
  GroupComponent,
  ListComponent,
  BlockquoteComponent,
  // ButtonComponent,
  SearchCtaComponent,
  TableComponent,
  TableOfContentsComponent,
  ImageComponent,
  StepComponent,
  DeviceCheckComponent,
  TitleComponent,
  SurveyMonkeyComponent,
  TagsAndCategoriesComponent,
  FullEditorComponent
} from '../blocks/blocks';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EromSelectModule } from '../../../erom-select/erom-select.module';
import { EromEditorSelectorComponent } from '../selector/selector.component';
import { EromEditorToolbarComponent } from '../toolbar/toolbar.component';
import { AccordionHeaderComponent } from '../accordion-header/accordion-header.component';
import { CodeComponent } from '../blocks/code/code.component';
import { IframeComponent } from '../blocks/iframe/iframe.component';
import { VideoComponent } from '../blocks/video/video.component';
import { ShareGuideComponent } from '../blocks/share-guide/share-guide.component';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { EromEditorSchemaComponent } from '../schema/schema.component';
import { EromBulkPasteModule } from '../../../erom-bulk-paste/erom-bulk-paste.module';
import { EromEditorPreviewComponent } from '../editor-preview/editor-preview.component';
// import { NgxCaptureModule } from 'ngx-capture';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    PerfectScrollbarModule,
    DragDropModule,
    EromSelectModule,
    ColorPickerModule,
    EromBulkPasteModule,
    // NgxCaptureModule
  ],
  declarations: [
    EromEditorComponent,
    ContainerRefDirective,
    EromBlockComponent,
    ReplaceLineBreaksPipe,
    SafeHtmlPipe,
    TabTriggerComponent,
    TabPanelComponent,
    EromEditorSelectorComponent,
    ParagraphComponent,
    HeadingComponent,
    ListComponent,
    BlockquoteComponent,
    TableComponent,
    // ButtonComponent,
    GroupComponent,
    EromEditorToolbarComponent,
    AccordionHeaderComponent,
    CodeComponent,
    SearchCtaComponent,
    TableOfContentsComponent,
    IframeComponent,
    VideoComponent,
    ImageComponent,
    ShareGuideComponent,
    EromEditorSchemaComponent,
    StepComponent,
    DeviceCheckComponent,
    TitleComponent,
    EromEditorPreviewComponent,
    SurveyMonkeyComponent,
    TagsAndCategoriesComponent,
    FullEditorComponent
  ],
  providers: [
    EromEditorGlobals,
    {
      provide: EROM_TINYMCE_SCRIPT_SRC,
      useValue: 'tinymce/tinymce.min.js'
    },
    {
      provide: EROM_BLOCK_COMPONENTS,
      useValue: [
        ParagraphComponent,
        HeadingComponent,
        ListComponent,
        BlockquoteComponent,
        TableComponent,
        // ButtonComponent,
        CodeComponent,
        ImageComponent,
        VideoComponent,
        IframeComponent,
        GroupComponent,
        TitleComponent,
        SearchCtaComponent,
        TableOfContentsComponent,
        ShareGuideComponent,
        StepComponent,
        DeviceCheckComponent,
        SurveyMonkeyComponent,
        TagsAndCategoriesComponent,
        FullEditorComponent
      ],
      multi: true
    }
  ],
  exports: [EromEditorComponent, EromEditorPreviewComponent]
})
export class EromEditorModule {}
