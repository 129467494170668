<div class="erom-editor-schema">
  <div class="erom-editor__group">
    <label class="erom-editor__label">{{ 'editor.SPEAKABLE_TYPE' | translate }}</label>
    <erom-select [searchable]="true"
                 [placeholder]="'editor.SELECT_TYPE' | translate"
                 [options]="speakableSchemaTypes"
                 [value]="speakableValue"
                 (optionSelected)="schemaTypeSelected($event, 'speakable')"></erom-select>
  </div>
  <div class="erom-editor__group">
    <label class="erom-editor__label">{{ 'editor.HOW_TO_TYPE' | translate }}</label>
    <erom-select [searchable]="true"
                 [placeholder]="'editor.SELECT_TYPE' | translate"
                 [options]="howToSchemaTypes"
                 [value]="howToValue"
                 (optionSelected)="schemaTypeSelected($event, 'howTo')"></erom-select>
  </div>
</div>
