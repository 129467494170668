import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener, Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { EromSelectComponent } from '../../../../../src/app/_directives/erom-select/component/erom-select.component';
import { API } from '../../../../../src/app/_store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { convertDateTime } from '../../../../../src/app/_helpers/common';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Title } from '@angular/platform-browser';
import { getCookie, setCookie } from '../../../../../src/app/_helpers';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { GoogleAnalyticsService } from '../../../../../src/app/_services/google-analytics.service';
import { DOCUMENT, isPlatformBrowser, PlatformLocation } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('dropdownMenuList', {read: ElementRef, static: false}) dropdownMenuList: ElementRef;
  @ViewChild('searchInput', {read: ElementRef, static: false}) searchInput: ElementRef;

  @ViewChild('categoryFilter', {static: true}) categoryFilter: EromSelectComponent;
  @ViewChild('adjustmentFilter', {static: true}) adjustmentFilter: EromSelectComponent;
  @ViewChild('conditionFilter', {static: true}) conditionFilter: EromSelectComponent;
  @ViewChild('symptomFilter', {static: true}) symptomFilter: EromSelectComponent;
  @ViewChild('operatingSystemFilter', {static: true}) operatingSystemFilter: EromSelectComponent;
  @ViewChild('browserFilter', {static: true}) browserFilter: EromSelectComponent;

  @ViewChild('navigatorSettingsSaved', {static: true}) navigatorSettingsSaved: ElementRef;

  private _mobileFiltersActive: boolean;

  private _searchValue: string;
  private _oldSearchValue: string;
  private _searchResults: Array<any>;
  private _typeAheadPointer: number;
  private _descendentId: string;

  private _inputTimer: number;

  readonly _id: string;

  public searchFocused: boolean;
  public availableResultsLive: boolean;

  private _deviceFiltersApplied: boolean;
  private _navigatorSavedAlertDisplay: boolean;

  private _ppp: number;
  private _offset: number;
  private _totalCount: number;

  private _results: Array<any>;

  private _fixedSearch: boolean;
  private _windowWidth: number;

  readonly category: Array<any>;
  readonly adjustment: Array<any>;
  readonly condition: Array<any>;
  readonly symptom: Array<any>;
  readonly operatingSystem: Array<any>;
  readonly browser: Array<any>;

  private _filters: Array<any>;

  private _searchInProgress: boolean;
  private _searchSuggestionsInProgress: boolean;

  readonly _language: string;

  constructor(
    private api: API,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    private meta: Meta,
    private location: PlatformLocation,
    private ga: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._id = HomepageComponent.generateId('search');
    this._searchResults = [];
    this._typeAheadPointer = -1;
    this._searchInProgress = true;

    this._filters = [];
    this.category = [];
    this.adjustment = [];
    this.condition = [];
    this.symptom = [];

    this._ppp = 10;
    this._offset = 0;
    this._results = [];
    this._language = translateService.currentLang;
    if (isPlatformBrowser(platformId)) {
      this._windowWidth = window.innerWidth;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: HomepageComponent.filterQueryParams({
        ...this.activatedRoute.snapshot.queryParams
      }),
      replaceUrl: true
    }).catch();


    this.setupFilters();
    this._searchValue = this.activatedRoute.snapshot.queryParams.search ?
      decodeURIComponent(this.activatedRoute.snapshot.queryParams.search) : '';
    this._oldSearchValue = this._searchValue;
    this.setupFixedSearchChanges();
    this.setupMetaTags();
    this.setupMetaImage();
    this.getAllTagsThenRunSearch(true, false, true);
  }

  private static createCanonical(id: string, link: string): void {
    const head = document.querySelector('head');
    const canonical = document.createElement('link');
    canonical.id = id;
    canonical.rel = 'canonical';
    canonical.href = link;
    head.appendChild(canonical);
  }

  private static removeCanonical(id: string): void {
    const canonical = document.getElementById(id);
    if (canonical) {
      canonical.remove();
    }
  }

  private static filterQueryParams(params: object): object {
    const newParams = {};
    Object.keys(params).sort().forEach((v, i) => {
      if (params[v] && params[v].indexOf(',') > -1) {
        Object.assign(newParams, {
          [v]: params[v].split(',').sort().join(',')
        });
      } else {
        Object.assign(newParams, {
          [v]: params[v]
        });
      }
    });
    const asArray = Object.entries(newParams);
    const filtered = asArray.filter(([key, value]) => value !== null && value !== '');
    return filtered.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
  }

  private static generateId(prefix: string): string {
    const date = new Date();
    const time = date.getTime();
    const random = Math.floor(Math.random() * 1000000000);
    return prefix + '_' + random + String(time);
  }

  private createSearchSchemaScript(destroy: boolean = false): void {
    let searchSchemaScript = (this.document.getElementById('searchSchemaScript') as HTMLScriptElement);
    if (destroy) {
      if (searchSchemaScript) {
        searchSchemaScript.remove();
      }
      return;
    }
    if (!searchSchemaScript)  {
      searchSchemaScript = this.document.createElement('script');
    }
    const searchJSON = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: 'https://mcmw.abilitynet.org.uk',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://mcmw.abilitynet.org.uk?search={search_term_string}',
        'query-input': 'required name=search_term_string'
      }
    };
    searchSchemaScript.type = 'application/ld+json';
    searchSchemaScript.innerHTML = JSON.stringify(searchJSON);
    searchSchemaScript.id = 'searchSchemaScript';
    this.document.head.appendChild(searchSchemaScript);
  }

  public ngOnInit(): void {
    // this.runSearch(true);
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // this.setupFixedSearchChanges();
      this.makeCategoryFiltersSticky();
      // this._searchValue = params.search || null;
      // this.runSearch(true);
      // this.setupFixedSearchChanges();
      this.setupMetaTags();
    });
    this.createSearchSchemaScript();
  }

  private matchTag(initial: boolean = false): void {
    let tag = this.activatedRoute.snapshot.queryParams['match-tag'];
    let tagFound = false;
    if (!tag) {
      this.runSearch(true, null, initial);
      return;
    }
    tag = decodeURI(tag).toLowerCase().replace(/-/g, ' ');
    let newQuery = null;
    const maybeCategory = this.category.find(c => {
      let settings;
      let synonyms;
      try {
        settings = JSON.parse(c.settings);
      } catch (e) {
        settings = null;
      }
      try {
        synonyms = JSON.parse(c.synonyms);
      } catch (e) {
        synonyms = null;
      }
      return c.label.toLowerCase() === tag || (synonyms && synonyms.some(ss => ss.toLowerCase() === tag))
        || (settings && settings.oldId && tag === settings.oldId);
    });
    const maybeAdjustment = this.adjustment.find(a => {
      let settings;
      let synonyms;
      try {
        settings = JSON.parse(a.settings);
      } catch (e) {
        settings = null;
      }
      try {
        synonyms = Object.values(a.synonyms);
      } catch (e) {
        synonyms = null;
      }
      return a.label.toLowerCase() === tag || (synonyms && synonyms.some(ss => ss.toLowerCase() === tag))
        || (settings && settings.oldId && tag === settings.oldId);
    });
    const maybeCondition = this.condition.find(c => {
      let settings;
      let synonyms;
      try {
        settings = JSON.parse(c.settings);
      } catch (e) {
        settings = null;
      }
      try {
        synonyms = Object.values(c.synonyms);
      } catch (e) {
        synonyms = null;
      }
      return c.label.toLowerCase() === tag || (synonyms && synonyms.some(ss => ss.toLowerCase() === tag))
        || (settings && settings.oldId && tag === settings.oldId);
    });
    const maybeSymptom = this.symptom.find(s => {
      let settings;
      let synonyms;
      try {
        settings = JSON.parse(s.settings);
      } catch (e) {
        settings = null;
      }
      try {
        synonyms = Object.values(s.synonyms);
      } catch (e) {
        synonyms = null;
      }
      return s.label.toLowerCase() === tag || (synonyms && synonyms.some(ss => ss.toLowerCase() === tag))
        || (settings && settings.oldId && tag === settings.oldId);
    });
    const maybeOS = this.operatingSystem.find(o => {
      let settings;
      let synonyms;
      try {
        settings = JSON.parse(o.settings);
      } catch (e) {
        settings = null;
      }
      try {
        synonyms = Object.values(o.synonyms);
      } catch (e) {
        synonyms = null;
      }
      return o.label.toLowerCase() === tag || (synonyms && synonyms.some(ss => ss.toLowerCase() === tag))
        || (settings && settings.oldId && tag === settings.oldId);
    });
    const maybeBrowser = this.browser.find(b => {
      let settings;
      let synonyms;
      try {
        settings = JSON.parse(b.settings);
      } catch (e) {
        settings = null;
      }
      try {
        synonyms = Object.values(b.synonyms);
      } catch (e) {
        synonyms = null;
      }
      return b.label.toLowerCase() === tag || (synonyms && synonyms.some(ss => ss.toLowerCase() === tag))
        || (settings && settings.oldId && tag === settings.oldId);
    });
    if (maybeCategory) {
      newQuery = {
        Category: maybeCategory.label
      };
      this.categoryFilter.selectOption(maybeCategory, false);
      tagFound = true;
    }
    if (maybeAdjustment) {
      newQuery = {
        Adjustment: maybeAdjustment.label
      };
      this.adjustmentFilter.selectOption(maybeAdjustment, false);
      tagFound = true;
    }
    if (maybeCondition) {
      newQuery = {
        Condition: maybeCondition.label
      };
      this.conditionFilter.selectOption(maybeCondition, false);
      tagFound = true;
    }
    if (maybeSymptom) {
      newQuery = {
        Symptom: maybeSymptom.label
      };
      this.symptomFilter.selectOption(maybeSymptom, false);
      tagFound = true;
    }
    if (maybeOS) {
      // this.updateQueryParams({
      //   'match-tag': null
      // }, false);
      HomepageComponent.createCanonical('matchTagCanonical', `${this.location.protocol}//${this.location.hostname}`);
      if (isPlatformBrowser(this.platformId)) {
        this.operatingSystemFilter.selectOption(maybeOS, true);
        window.location.href = '/';
      } else {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: null,
          replaceUrl: true
        }).then(() => {
          HomepageComponent.removeCanonical('matchTagCanonical');
          this.operatingSystemFilter.selectOption(maybeOS, true);
        });
      }
      return;
    }
    if (maybeBrowser) {
      // this.updateQueryParams({
      //   'match-tag': null
      // }, false);
      HomepageComponent.createCanonical('matchTagCanonical', `${this.location.protocol}//${this.location.hostname}`);

      if (isPlatformBrowser(this.platformId)) {
        this.browserFilter.selectOption(maybeBrowser, true);
        window.location.href = '/';
      } else {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: null,
          replaceUrl: true
        }).then(() => {
          HomepageComponent.removeCanonical('matchTagCanonical');
          this.browserFilter.selectOption(maybeBrowser, true);
        });
      }
      return;
    }
    const isTagId = !Number.isNaN(parseInt(tag, 10));
    if (!isTagId && !newQuery && !maybeBrowser && !maybeOS) {
      this._searchValue = tag;
      this.setupFixedSearchChanges();
      newQuery = {
        search: this._searchValue
      };
    }
    if (newQuery) {
      this.updateQueryParams({
        ...newQuery,
        'match-tag': null
      }, true, true);
      return;
    }
    if (!tagFound) {
      if (isPlatformBrowser(this.platformId)) {
        window.location.href = '/404';
      } else {
        this.router.navigate(['/404']);
      }
      return;
    }
    if (!newQuery && !maybeOS && !maybeBrowser) {
      this.runSearch(true, null, initial);
      return;
    }
    if (newQuery && newQuery.hasOwnProperty('search')) {
      this.runSearch(true, null, false);
      return;
    }
  }

  private setupMetaTags(): void {
    const hasFilters = this._filters && this._filters.length &&
      this._filters.some(f => f.filter !== 'search' && f.filter !== 'Operating system' && f.filter !== 'Browser' && f.options.length);
    if (this._searchValue && this._searchValue !== '') {
      this.setupMeta(
        `Need help with ${ this._searchValue } on your phone or your computer?`,
        `Find ${ this._searchValue } related guides on how to adapt your phone or your computer to make it easier to use.`
      );
    } else if (hasFilters) {
      const allNonSearchFilters = this._filters.filter(f => f.filter !== 'search');
      const categoryFilters = allNonSearchFilters.filter(f => f.filter === 'Category');
      const adjustmentFilters = allNonSearchFilters.filter(f => f.filter === 'Adjustment');
      const conditionFilters = allNonSearchFilters.filter(f => f.filter === 'Condition');
      const symptomFilters = allNonSearchFilters.filter(f => f.filter === 'Symptom');
      const nonCategoryNonSystemFilters = allNonSearchFilters
        .filter(f => f.filter !== 'Category' && f.filter !== 'Operating system' && f.filter !== 'Browser');

      let title = '';
      let description = 'Make your device easier to use: ';

      if (isPlatformBrowser(this.platformId)) {
        const localStorageOS = getCookie('operatingSystem');
        if (localStorageOS) {
          const localStorageOSObject = JSON.parse(localStorageOS);
          title += `${ localStorageOSObject.label}`;
        } else {
          title += 'Computer and mobile';
        }
      } else {
        title += 'Computer and mobile';
      }

      const categoryLabels = [].concat(...categoryFilters.map(nc => nc.options.map(o => o.label)));
      const nonCategoryNonSystemLabels = [].concat(...nonCategoryNonSystemFilters.map(nc => nc.options.map(o => o.label)));
      const adjustmentLabels = [].concat(...adjustmentFilters.map(nc => nc.options.map(o => o.label)));
      const conditionLabels = [].concat(...conditionFilters.map(nc => nc.options.map(o => o.label)));
      const symptomLabels = [].concat(...symptomFilters.map(nc => nc.options.map(o => o.label)));

      const categoryExists = categoryLabels && categoryLabels.length;
      const adjustmentExists = adjustmentLabels && adjustmentLabels.length;
      const conditionExists = conditionLabels && conditionLabels.length;
      const symptomExists = symptomLabels && symptomLabels.length;

      if (categoryLabels && categoryLabels.length) {
        title += ' ';
        for (let i = 0; i < categoryLabels.length; i++) {
          title += categoryLabels[i];
          if (i < categoryLabels.length - 2) {
            title += ', ';
          }
          if (i === categoryLabels.length - 2) {
            title += ' and ';
          }
        }
        title += ' adjustments';
      }

      if (nonCategoryNonSystemLabels && nonCategoryNonSystemLabels.length) {
        if (categoryExists) {
          title += ': ';
        } else {
          title += ' ';
        }
        for (let i = 0; i < nonCategoryNonSystemLabels.length; i++) {
          title += nonCategoryNonSystemLabels[i];
          if (i < nonCategoryNonSystemLabels.length - 2) {
            title += ', ';
          }
          if (i === nonCategoryNonSystemLabels.length - 2) {
            title += ' and ';
          }
        }
      }

      if (categoryExists) {
        for (let i = 0; i < categoryLabels.length; i++) {
          description += categoryLabels[i];
          if (i < categoryLabels.length - 2) {
            description += ', ';
          }
          if (i === categoryLabels.length - 2) {
            description += ' and ';
          }
          if (i === categoryLabels.length - 1) {
            description += ' adjustments';
          }
        }
      }

      if (adjustmentExists) {
        if (categoryExists) {
          description += ' | ';
        }
        for (let i = 0; i < adjustmentLabels.length; i++) {
          description += adjustmentLabels[i];
          if (i < adjustmentLabels.length - 2) {
            description += ', ';
          }
          if (i === adjustmentLabels.length - 2) {
            description += ' and ';
          }
        }
      }

      if (conditionExists) {
        if (categoryExists || adjustmentExists) {
          description += ' | ';
        }
        description += 'Living with ';
        for (let i = 0; i < conditionLabels.length; i++) {
          description += conditionLabels[i];
          if (i < conditionLabels.length - 2) {
            description += ', ';
          }
          if (i === conditionLabels.length - 2) {
            description += ' and ';
          }
        }
      }

      if (symptomExists) {
        if (categoryExists || adjustmentExists || conditionExists) {
          description += ' | ';
        }
        description += 'Experiencing ';
        for (let i = 0; i < symptomLabels.length; i++) {
          description += symptomLabels[i];
          if (i < symptomLabels.length - 2) {
            description += ', ';
          }
          if (i === symptomLabels.length - 2) {
            description += ' and ';
          }
        }
      }

      if (isPlatformBrowser(this.platformId)) {
        const localStorageBrowser = getCookie('browser');
        const localStorageOS = getCookie('operatingSystem');

        if (localStorageBrowser) {
          const localStorageBrowserObject = JSON.parse(localStorageBrowser);
          description += ` while using ${ localStorageBrowserObject.label}`;
        }

        if (localStorageOS) {
          const localStorageOSObject = JSON.parse(localStorageOS);
          description += ` on ${ localStorageOSObject.label}`;
        }
      }
      this.setupMeta(title, description);
    } else {
      this.setupMeta(
        'Simple \'how to\' guides to make your device easier to use | My Computer My Way',
        `Find step by step instructions on how to adapt your phone📱 or your computer🖥 to make it easier to use.`
      );
    }
  }

  private setupMeta(title: string, description: string): void {
    if (title && title !== '') {
      this.titleService.setTitle(title);
      this.meta.updateTag({
        property: 'og:title',
        content: title
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: title
      });
    }
    if (description && description !== '') {
      this.meta.updateTag({
        name: 'description',
        content: description
      });
      this.meta.updateTag({
        property: 'og:description',
        content: description
      });
      this.meta.updateTag({
        property: 'twitter:description',
        content: description
      });
    }
  }

  private setupMetaImage(): void {
    let dataParams = new HttpParams();
    const keys = [
      'meta-image'
    ];
    dataParams = dataParams.append('keys', JSON.stringify(keys));
    this.http.get(`${this.api.URLS.setting}/from-keys`, { params: dataParams }).subscribe((response: any) => {
      if (response['meta-image']) {
        this.meta.updateTag({
          property: 'og:image',
          content: `${this.location.protocol}//${this.location.hostname}${response['meta-image']}`
        });
        this.meta.updateTag({
          property: 'twitter:image',
          content: `${this.location.protocol}//${this.location.hostname}${response['meta-image']}`
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  // private setupFilterMetaTags(): void {
  //   if (this._filters && this._filters.length) {
  //     const allNonSearchFilters = this._filters.filter(f => f.filter !== 'search');
  //     const categoryFilters = allNonSearchFilters.filter(f => f.filter === 'Category');
  //     const adjustmentFilters = allNonSearchFilters.filter(f => f.filter === 'Adjustment');
  //     const conditionFilters = allNonSearchFilters.filter(f => f.filter === 'Condition');
  //     const symptomFilters = allNonSearchFilters.filter(f => f.filter === 'Symptom');
  //     const nonCategoryNonSystemFilters = allNonSearchFilters
  //       .filter(f => f.filter !== 'Category' && f.filter !== 'Operating system' && f.filter !== 'Browser');
  //
  //     let title = '';
  //     let description = 'Make your device easier to use: ';
  //
  //     if (isPlatformBrowser(this.platformId)) {
  //       const localStorageOS = getCookie('operatingSystem');
  //       if (localStorageOS) {
  //         const localStorageOSObject = JSON.parse(localStorageOS);
  //         title += `${ localStorageOSObject.label}`;
  //       } else {
  //         title += 'Computer and mobile';
  //       }
  //     } else {
  //       title += 'Computer and mobile';
  //     }
  //
  //     const categoryLabels = [].concat(...categoryFilters.map(nc => nc.options.map(o => o.label)));
  //     const nonCategoryNonSystemLabels = [].concat(...nonCategoryNonSystemFilters.map(nc => nc.options.map(o => o.label)));
  //     const adjustmentLabels = [].concat(...adjustmentFilters.map(nc => nc.options.map(o => o.label)));
  //     const conditionLabels = [].concat(...conditionFilters.map(nc => nc.options.map(o => o.label)));
  //     const symptomLabels = [].concat(...symptomFilters.map(nc => nc.options.map(o => o.label)));
  //
  //     const categoryExists = categoryLabels && categoryLabels.length;
  //     const adjustmentExists = adjustmentLabels && adjustmentLabels.length;
  //     const conditionExists = conditionLabels && conditionLabels.length;
  //     const symptomExists = symptomLabels && symptomLabels.length;
  //
  //     if (categoryLabels && categoryLabels.length) {
  //       title += ' ';
  //       for (let i = 0; i < categoryLabels.length; i++) {
  //         title += categoryLabels[i];
  //         if (i < categoryLabels.length - 2) {
  //           title += ', ';
  //         }
  //         if (i === categoryLabels.length - 2) {
  //           title += ' and ';
  //         }
  //       }
  //       title += ' adjustments';
  //     }
  //
  //     if (nonCategoryNonSystemLabels && nonCategoryNonSystemLabels.length) {
  //       if (categoryExists) {
  //         title += ': ';
  //       }
  //       for (let i = 0; i < nonCategoryNonSystemLabels.length; i++) {
  //         title += nonCategoryNonSystemLabels[i];
  //         if (i < nonCategoryNonSystemLabels.length - 2) {
  //           title += ', ';
  //         }
  //         if (i === nonCategoryNonSystemLabels.length - 2) {
  //           title += ' and ';
  //         }
  //       }
  //     }
  //
  //     if (categoryExists) {
  //       for (let i = 0; i < categoryLabels.length; i++) {
  //         description += categoryLabels[i];
  //         if (i < categoryLabels.length - 2) {
  //           description += ', ';
  //         }
  //         if (i === categoryLabels.length - 2) {
  //           description += ' and ';
  //         }
  //         if (i === categoryLabels.length - 1) {
  //           description += ' adjustments';
  //         }
  //       }
  //     }
  //
  //     if (adjustmentExists) {
  //       if (categoryExists) {
  //         description += ' | ';
  //       }
  //       for (let i = 0; i < adjustmentLabels.length; i++) {
  //         description += adjustmentLabels[i];
  //         if (i < adjustmentLabels.length - 2) {
  //           description += ', ';
  //         }
  //         if (i === adjustmentLabels.length - 2) {
  //           description += ' and ';
  //         }
  //       }
  //     }
  //
  //     if (conditionExists) {
  //       if (categoryExists || adjustmentExists) {
  //         description += ' | ';
  //       }
  //       description += 'Living with ';
  //       for (let i = 0; i < conditionLabels.length; i++) {
  //         description += conditionLabels[i];
  //         if (i < conditionLabels.length - 2) {
  //           description += ', ';
  //         }
  //         if (i === conditionLabels.length - 2) {
  //           description += ' and ';
  //         }
  //       }
  //     }
  //
  //     if (symptomExists) {
  //       if (categoryExists || adjustmentExists || conditionExists) {
  //         description += ' | ';
  //       }
  //       description += 'Experiencing ';
  //       for (let i = 0; i < symptomLabels.length; i++) {
  //         description += symptomLabels[i];
  //         if (i < symptomLabels.length - 2) {
  //           description += ', ';
  //         }
  //         if (i === symptomLabels.length - 2) {
  //           description += ' and ';
  //         }
  //       }
  //     }
  //
  //     if (isPlatformBrowser(this.platformId)) {
  //       const localStorageBrowser = getCookie('browser');
  //       const localStorageOS = getCookie('operatingSystem');
  //
  //       if (localStorageBrowser) {
  //         const localStorageBrowserObject = JSON.parse(localStorageBrowser);
  //         description += ` while using ${ localStorageBrowserObject.label}`;
  //       }
  //
  //       if (localStorageOS) {
  //         const localStorageOSObject = JSON.parse(localStorageOS);
  //         description += ` on ${ localStorageOSObject.label}`;
  //       }
  //     }
  //     this.setupMeta(title, description);
  //   } else {
  //     this.setupMetaTags();
  //   }
  // }

  private setupFilters(): void {
    const queryParams: object = this.activatedRoute.snapshot.queryParams;
    for (const queryParamsKey in queryParams) {
      if (!queryParams.hasOwnProperty(queryParamsKey)) {
        continue;
      }
      const appFilters = ['Category', 'Adjustment', 'Condition', 'Symptom'];
      if (appFilters.indexOf(queryParamsKey) === -1) {
        continue;
      }
      const options = queryParams[queryParamsKey].toString().split(',').map(o => {
        return {
          label: o,
          value: o
        };
      });
      const existingFilter = this._filters.find(f => f.filter === queryParamsKey);
      if (existingFilter) {
        existingFilter.options = options;
      } else {
        this._filters.push({
          filter: queryParamsKey,
          options
        });
      }
    }
  }

  public ngOnDestroy(): void {
    this.createSearchSchemaScript(true);
  }

  public ngAfterViewInit(): void {
    this.makeCategoryFiltersSticky();
  }

  public initSuggestionsSearch(event: any): void {
    const search = event.target.value && event.target.value !== '' ? event.target.value : null;
    const deleteMode = !search || (search.length < this._searchValue.length);
    this._fixedSearch = !!search;
    this._oldSearchValue = this._searchValue;
    this._searchValue = search || '';
    this._typeAheadPointer = -1;
    this.updateActiveDescendent();
    this.setupFixedSearchChanges();
    if (isPlatformBrowser(this.platformId) && this._inputTimer) {
      window.clearTimeout(this._inputTimer);
    }
    if (isPlatformBrowser(this.platformId)) {
      this._inputTimer = window.setTimeout(() => {
        this.filterSearchResults(search);
        if (this._searchValue.length >= 3 || deleteMode) {
          if (!this._searchResults.length && !this._searchSuggestionsInProgress) {
            this.updateQueryParams({ search: encodeURI(search || '') });
            this.runSearch(true);
          }
        }
      }, 400);
    }
  }

  private filterSearchResults(search: string): void {
    if (this._searchSuggestionsInProgress) {
      return;
    }
    if (!search) {
      this._searchResults = [];
      this.searchFocused = false;
      this.updateLiveRegions();
      return;
    }
    if (search.length < 3) {
      return;
    }
    this._searchSuggestionsInProgress = true;
    this.http.get(`${this.api.URLS.tag}/suggestions/${this._searchValue}`).subscribe((results: any) => {
      this._searchSuggestionsInProgress = false;
      this._searchResults = results;
      if (!this._searchResults.length) {
        this.updateQueryParams({ search: encodeURI(search || '') }, true);
      }
      this.searchFocused = true;
      this.updateLiveRegions();
    }, (error) => {
      this._searchSuggestionsInProgress = false;
      console.log(error);
    });
  }

  private updateLiveRegions(): void {
    this.availableResultsLive = true;
    setTimeout(() => {
      this.availableResultsLive = false;
    }, 100);
  }

  private setupFixedSearchChanges(): void {
    const cookieBar = this.document.getElementById('abilityHeaderCookieBar');
    const header = this.document.getElementById('abilityHeaderContainer');
    if (!this.searchEmpty || (this.fixedSearch && this.isMobile)) {
      if (cookieBar) {
        cookieBar.classList.add('erom-visually-hidden');
      }
      if (header) {
        header.classList.add('erom-visually-hidden');
      }
    } else {
      if (cookieBar) {
        cookieBar.classList.remove('erom-visually-hidden');
      }
      if (header) {
        header.classList.remove('erom-visually-hidden');
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
    }
  }

  public typeAheadUp(event): void {
    event.preventDefault();
    if (this._typeAheadPointer > 0) {
      this._typeAheadPointer--;
      this.updateActiveDescendent();
      this.maybeAdjustScroll();
    }
  }

  public typeAheadDown(event): void {
    event.preventDefault();
    if (this._typeAheadPointer < this._searchResults.length - 1) {
      this._typeAheadPointer++;
      this.updateActiveDescendent();
      this.maybeAdjustScroll();
    }
  }

  public typeAheadHome(event): void {
    event.preventDefault();
    this._typeAheadPointer = 0;
    this.updateActiveDescendent();
    this.maybeAdjustScroll();
  }

  public typeAheadEnd(event): void {
    event.preventDefault();
    this._typeAheadPointer = this._searchResults.length - 1;
    this.updateActiveDescendent();
    this.maybeAdjustScroll();
  }

  private updateActiveDescendent(): void {
    this._descendentId = this.id + '-item-' + this._typeAheadPointer;
  }

  public maybeAdjustScroll(): void {
    const pixelsToPointerTop = this.pixelsToPointerTop();
    const pixelsToPointerBottom = this.pixelsToPointerBottom();

    if (pixelsToPointerTop <= this.viewport().top) {
      return this.scrollTo( pixelsToPointerTop);
    } else if (pixelsToPointerBottom >= this.viewport().bottom) {
      return this.scrollTo(this.viewport().top + this.pointerHeight());
    }
  }

  private pixelsToPointerTop(): number {
    let pixelsToPointerTop = 0;
    if (this.dropdownMenuList.nativeElement) {
      for (let i = 0; i < this._typeAheadPointer; i++) {
        pixelsToPointerTop += this.dropdownMenuList.nativeElement.children[i].offsetHeight;
      }
    }
    return pixelsToPointerTop;
  }

  private pixelsToPointerBottom(): number {
    return this.pixelsToPointerTop() + this.pointerHeight();
  }

  private pointerHeight(): number {
    const element = this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.children[this._typeAheadPointer] : false;
    return element ? element.offsetHeight : 0;
  }

  private viewport(): any {
    return {
      top: this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.scrollTop : 0,
      bottom: this.dropdownMenuList.nativeElement ?
        this.dropdownMenuList.nativeElement.offsetHeight + this.dropdownMenuList.nativeElement.scrollTop : 0
    };
  }

  private scrollTo(position): void {
    return this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.scrollTop = position : null;
  }

  private generateRequestFilters(resetSearch: boolean = false): {[key: string]: string} {

    const filters = {
      locale: this.translateService.currentLang
    };

    for (let i = 0; i < this._filters.length; i++) {
      if (this._filters[i].filter === 'match-tag') {
        continue;
      }
      const labels = this._filters[i].options.filter(o => o !== null).map(o => o.label);
      if (!labels || !labels.length) {
        continue;
      }
      // filters[this._filters[i].filter] = labels.join(',');
      Object.assign(filters, {[this._filters[i].filter]: labels.join(',')});
    }

    // const localStorageOS = localStorage.getItem('operatingSystem');
    // const localStorageBrowser = localStorage.getItem('browser');
    const localStorageOS = getCookie('operatingSystem');
    const localStorageBrowser = getCookie('browser');

    if (localStorageOS) {
      const localStorageOSObject = JSON.parse(localStorageOS);
      Object.assign(filters, {'Operating system': localStorageOSObject.label});
    }

    if (localStorageBrowser) {
      const localStorageBrowserObject = JSON.parse(localStorageBrowser);
      Object.assign(filters, {Browser: localStorageBrowserObject.label});
    }

    if (!resetSearch && this._searchValue && this._searchValue !== '') {
      Object.assign(filters, {search: this._searchValue});
    }

    return filters;

  }

  private getAllTagsThenRunSearch(
    matchTag: boolean = false,
    runSearch: boolean = false,
    initial: boolean = false,
    resetSearch: boolean = false
  ): void {

    const filters = this.generateRequestFilters(resetSearch);

    let dataParams = new HttpParams();
    dataParams = dataParams.append('filters', JSON.stringify(filters));

    const categoryTags = this.http.get(`${this.api.URLS.tag}/type/Category`, { params: dataParams }).pipe(map( response => response));
    const adjustmentTags = this.http.get(`${this.api.URLS.tag}/type/Adjustment`, { params: dataParams }).pipe(map( response => response));
    const conditionTags = this.http.get(`${this.api.URLS.tag}/type/Condition`, { params: dataParams }).pipe(map( response => response));
    const symptomTags = this.http.get(`${this.api.URLS.tag}/type/Symptom`, { params: dataParams }).pipe(map( response => response));
    const operatingSystemTags = this.http.get(`${this.api.URLS.tag}/type/Operating system`, { params: dataParams }).pipe(map( response => response));
    const browserTags = this.http.get(`${this.api.URLS.tag}/type/Browser`, { params: dataParams }).pipe(map( response => response));
    forkJoin([categoryTags, adjustmentTags, conditionTags, symptomTags, operatingSystemTags, browserTags]).subscribe(results => {
      this.setupTagsByType('Category', 'category', results[0], initial);
      this.setupTagsByType('Adjustment', 'adjustment', results[1], initial);
      this.setupTagsByType('Condition', 'condition', results[2], initial);
      this.setupTagsByType('Symptom', 'symptom', results[3], initial);
      this.setupTagsByType('Operating system', 'operatingSystem', results[4], initial);
      this.setupTagsByType('Browser', 'browser', results[5], initial);
      if (matchTag) {
        this.matchTag(initial);
      } else if (runSearch) {
        this.runSearch();
      }
    });
  }

  private setupTagsByType(type: string, identifier: string, results: any, initial: boolean = false): void {
    this[identifier] = [].concat(results.map(r => {
      return {
        ...r,
        synonyms: Object.values(JSON.parse(r.synonyms))
      };
    }));
    if (identifier === 'operatingSystem' || identifier === 'browser') {
      this.setupNavigatorFromTags(identifier);
    }
    if (initial) {
      this.updateSelector(identifier, type);
      this.maybeSearchAsTag();
    }
  }

  private setupNavigatorFromTags(identifier: string): void {
    // const deviceFiltersApplied = localStorage.getItem('deviceFiltersApplied');
    const deviceFiltersApplied = getCookie('deviceFiltersApplied');
    if (identifier === 'operatingSystem' && this.operatingSystem) {
      // const localStorageOS = localStorage.getItem('operatingSystem');
      const localStorageOS = getCookie('operatingSystem');
      let operatingSystem = null;
      let operatingSystemFound = false;
      if (localStorageOS) {
        const localStorageOSObject = JSON.parse(localStorageOS);
        operatingSystem = this.operatingSystem.find(o => o.value === localStorageOSObject.value ||
          o.synonyms.some(s => s.toLowerCase() === localStorageOSObject.label.toLowerCase()));
        operatingSystemFound = !!operatingSystem;
      }
      const osQuery = this.activatedRoute.snapshot.queryParams['Operating-system'];
      if (!operatingSystemFound && (!deviceFiltersApplied || osQuery)) {
        operatingSystem = this.operatingSystem.find(o =>
          o.synonyms.some(s => s.toLowerCase() === this.deviceService.os_version.toLowerCase()));
        if (osQuery) {
          operatingSystem = this.operatingSystem.find(o =>
            o.synonyms.some(s => s.toLowerCase() === osQuery.toLowerCase()));
        }
        if (!operatingSystem) {
          const iosVersion = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
          if (iosVersion && iosVersion.length) {
            operatingSystem = this.operatingSystem.find(o =>
              o.label.toLowerCase() === (`${this.deviceService.os_version} ${iosVersion[1]}`).toLowerCase() ||
              o.synonyms.some(s => s.toLowerCase() === (`${this.deviceService.os_version} ${iosVersion[1]}`).toLowerCase()));
          } else {
            const os = navigator.userAgent.slice(13).split(';').map(o => o.trim());
            operatingSystem = this.operatingSystem.find(o => o.label.toLowerCase() === os[1].toLowerCase() ||
              o.synonyms.some(s => s.toLowerCase() === os[1].toLowerCase()));
          }
          if (!operatingSystem) {
            operatingSystem = this.operatingSystem.find(o => o.label.toLowerCase() === this.deviceService.os.toLowerCase() ||
              o.synonyms.some(s => s.toLowerCase() === this.deviceService.os.toLowerCase()));
            if (!operatingSystem) {
              operatingSystem = this.operatingSystem.find(o => o.label.toLowerCase().search(this.deviceService.os.toLowerCase()) > -1 ||
                o.synonyms.some(s => s.toLowerCase().search(this.deviceService.os.toLowerCase()) > -1));
            }
          }
        }
      }
      if (operatingSystem) {
        this.operatingSystemFilter.selectOption(operatingSystem, false);
        this._deviceFiltersApplied = true;
        const existingFilter = this._filters.find(f => f.filter === 'Operating system');
        if (existingFilter) {
          existingFilter.options = [].concat(operatingSystem);
        } else {
          this._filters.push({
            filter: 'Operating system',
            options: [operatingSystem]
          });
        }
        setTimeout(() => {
          this.updateQueryParams({ 'Operating system': null });
        }, 0);
        setCookie('operatingSystem', JSON.stringify(operatingSystem));
        // localStorage.setItem('operatingSystem', JSON.stringify(operatingSystem));
      }
    }
    if (identifier === 'browser' && this.browser) {
      const localStorageBrowser = getCookie('browser');
      // const localStorageBrowser = localStorage.getItem('browser');
      let browser = null;
      // let browserFound = false;
      if (localStorageBrowser) {
        const localStorageBrowserObject = JSON.parse(localStorageBrowser);
        browser = this.browser.find(o => o.value === localStorageBrowserObject.value);
        // browserFound = !!browser;
      }
      // if (!browserFound && !deviceFiltersApplied) {
      //   browser = this.browser.find(o => o.label.toLowerCase() === this.deviceService.browser.toLowerCase());
      //   if (!browser) {
      //     const browserWithVersion = `${this.deviceService.browser} ${this.deviceService.browser_version}`;
      //     browser = this.browser.find(o => o.label.toLowerCase().search(browserWithVersion.toLowerCase()) > -1);
      //     if (!browser) {
      //       browser = this.browser.find(o => o.label.toLowerCase().search(this.deviceService.browser.toLowerCase()) > -1);
      //     }
      //   }
      // }
      if (browser) {
        this.browserFilter.selectOption(browser, false);
        this._deviceFiltersApplied = true;
        const existingFilter = this._filters.find(f => f.filter === 'Browser');
        if (existingFilter) {
          existingFilter.options = [].concat(browser);
        } else {
          this._filters.push({
            filter: 'Browser',
            options: [browser]
          });
        }
        // localStorage.setItem('browser', JSON.stringify(browser));
        setCookie('browser', JSON.stringify(browser));
      }
    }
  }

  private updateSelector(identifier: string, type: string): void {
    const filter = this._filters.find(f => f.filter === type);
    if (!filter || !this[`${identifier}`] || !this[`${identifier}Filter`]) {
      return;
    }
    const options = [];
    if (this[`${identifier}`].length && filter.options && filter.options.length) {
      for (let i = 0; i < filter.options.length; i++) {
        const option = this[`${identifier}`].find(o => o.label.toLowerCase() === filter.options[i].label.toLowerCase());
        if (option) {
          options.push(option);
        }
      }
    }
    this[`${identifier}Filter`].selectOptions(options);
    // this.runSearch(true);
  }

  public resetDeviceFilters(): void {
    // localStorage.setItem('deviceFiltersApplied', 'true');
    setCookie('deviceFiltersApplied', 'true');
    this._deviceFiltersApplied = false;
    if (this.navigatorSettingsSaved) {
      this.navigatorSettingsSaved.nativeElement.setAttribute('aria-live', 'assertive');
      this._navigatorSavedAlertDisplay = true;
      setTimeout(() => {
        this._navigatorSavedAlertDisplay = false;
        this.navigatorSettingsSaved.nativeElement.setAttribute('aria-live', 'off');
      }, 3000);
    }
    this.resetFilters(false);
    this.ga.event('search', 'searchActions', 'viewResultsForYourDevice');
  }

  public closeResetDeviceFilters(): void {
    this._deviceFiltersApplied = false;
    // localStorage.setItem('deviceFiltersApplied', 'true');
    setCookie('deviceFiltersApplied', 'true');
  }

  public filterActivated(event, filter: string): void {
    const existingFilterIndex = this._filters.findIndex(f => f.filter === filter);
    if (event.option === null) {
      this._filters.splice(existingFilterIndex, 1);
    } else {
      if (existingFilterIndex > -1) {
        const oldOptions = [].concat(this._filters[existingFilterIndex].options);
        if (oldOptions.length < event.option.length) {
          const diff = event.option.filter(obj => !oldOptions.some(obj2 => obj.label === obj2.label));
          if (diff.length === 1) {
            this.ga.event('filter', filter, diff[0].label);
          }
        }
        this._filters[existingFilterIndex].options = [].concat(event.option);
      } else {
        this._filters.push({
          filter,
          options: [].concat(event.option)
        });
        this.ga.event('filter', filter, event.option[0].label);
      }
    }
    this.updateQueryParameters();
    this.runSearch(true);
    this.updateSitemapOnSearchResults(true);
  }

  public navigatorFilterActivated(event, filterKey: string, filterName: string): void {
    if (event.option && event.option.value) {
      // localStorage.setItem(filterKey, JSON.stringify(event.option));
      if (isPlatformBrowser(this.platformId)) {
        this.ga.event('filter', filterName, event.option.label);
        setCookie(filterKey, JSON.stringify(event.option));
      }
    } else {
      // localStorage.removeItem(filterKey);
      if (isPlatformBrowser(this.platformId)) {
        setCookie(filterKey, '', -1);
      }
    }
    // localStorage.setItem('deviceFiltersApplied', 'true');
    if (isPlatformBrowser(this.platformId)) {
      setCookie('deviceFiltersApplied', 'true');
    }
    const option = Array.isArray(event.option) && !event.option.length ? null : [event.option];
    this.filterActivated({ option }, filterName);
    this.setupMetaTags();
    if (filterKey === 'operatingSystem') {
      if (isPlatformBrowser(this.platformId)) {
        this.ga.setupOSDimension();
      }
    }
  }

  private updateQueryParameters(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: HomepageComponent.filterQueryParams({
        ...this.activatedRoute.snapshot.queryParams,
        ...this.generatedQueryParameters
      }),
      replaceUrl: true
    }).catch();
  }

  private maybeSearchAsTag(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    let categoryValues = queryParams && queryParams.Category ? queryParams.Category.split(',') : null;
    let adjustmentValues = queryParams && queryParams.Adjustment ? queryParams.Adjustment.split(',') : null;
    let conditionValues = queryParams && queryParams.Condition ? queryParams.Condition.split(',') : null;
    let symptomValues = queryParams && queryParams.Symptom ? queryParams.Symptom.split(',') : null;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.search) {
        const maybeCategory = this.category.find(a => a.label.toLowerCase() === decodeURI(params.search).toLowerCase());
        const maybeAdjustment = this.adjustment.find(a => a.label.toLowerCase() === decodeURI(params.search).toLowerCase());
        const maybeCondition = this.condition.find(c => c.label.toLowerCase() === decodeURI(params.search).toLowerCase());
        const maybeSymptom = this.symptom.find(s => s.label.toLowerCase() === decodeURI(params.search).toLowerCase());
        if (maybeCategory) {
          categoryValues = categoryValues && categoryValues.indexOf(maybeCategory.label) === -1 ?
            categoryValues.concat(maybeCategory.label) : categoryValues;
        }
        if (maybeAdjustment) {
          adjustmentValues = adjustmentValues && adjustmentValues.indexOf(maybeAdjustment.label) === -1 ?
            adjustmentValues.concat(maybeAdjustment.label) : adjustmentValues;
        }
        if (maybeCondition) {
          conditionValues = conditionValues && conditionValues.indexOf(maybeCondition.label) === -1 ?
            conditionValues.concat(maybeCondition.label) : conditionValues;
        }
        if (maybeSymptom) {
          symptomValues = symptomValues && symptomValues.indexOf(maybeSymptom.label) === -1 ?
            symptomValues.concat(maybeSymptom.label) : symptomValues;
        }
        if (maybeCategory || maybeAdjustment || maybeCondition || maybeSymptom) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: HomepageComponent.filterQueryParams({
              ...queryParams,
              search: null
            }),
            replaceUrl: true
          }).catch();
        }
      }
    });
  }

  private get generatedQueryParameters(): object {
    const parameters = {};
    for (let i = 0; i < this._filters.length; i++) {
      if (this._filters[i].filter === 'Browser' || this._filters[i].filter === 'Operating system') {
        continue;
      }
      if (parameters[this._filters[i].filter]) {
        parameters[this._filters[i].filter] =
          parameters[this._filters[i].filter].split(',').concat(this._filters[i].options.map(o => o.label)).join(',');
      } else {
        parameters[this._filters[i].filter] = this._filters[i].options.map(o => o.label).join(',');
      }
    }
    return parameters;
  }

  private clearRegularFilters(): void {
    this.categoryFilter.reset(false);
    this.adjustmentFilter.reset(false);
    this.conditionFilter.reset(false);
    this.symptomFilter.reset(false);
    this.operatingSystemFilter.reset(false);
    this.browserFilter.reset(false);
    this._mobileFiltersActive = false;
    // localStorage.removeItem('operatingSystem');
    // localStorage.removeItem('browser');
    setCookie('operatingSystem', '', -1);
    setCookie('browser', '', -1);
    this._filters = this._filters.map(f => {
      return {
        ...f,
        options: []
      };
    });
    if (isPlatformBrowser(this.platformId) && window.innerWidth <= 600) {
      window.scrollTo(0, 0);
    }
    if (this.searchEmpty) {
      this._fixedSearch = false;
    }
  }

  public resetFilters(event: boolean = true): void {
    this.clearRegularFilters();
    this.updateQueryParameters();
    this.runSearch(true);
    if (event) {
      this.ga.event('search', 'searchActions', 'resetFiltersToDefault');
    }
  }

  public resetAllFilters(): void {
    this.resetSearchFilter();
    this.clearRegularFilters();
    this.updateQueryParameters();
    this.setupFixedSearchChanges();
    this.runSearch(true);
    setTimeout(() => {
      this.updateQueryParams({ search: null });
    }, 0);
  }

  public get activeFilters(): boolean {
    const appFilters = ['Category', 'Adjustment', 'Condition', 'Symptom', 'Browser', 'Operating system'];
    return this._filters && this._filters.some(f => f.options.length && appFilters.indexOf(f.filter) > -1);
  }

  public get activeCategoryFilters(): boolean {
    const categoryFilters = ['Category', 'Adjustment', 'Condition', 'Symptom'];
    return this._filters && this._filters.some(f => f.options.length && categoryFilters.indexOf(f.filter) > -1);
  }

  private makeCategoryFiltersSticky(): void {
    const marker = this.elementRef.nativeElement.querySelector('.erom-category-filters-block__marker');
    if (!marker) {
      return;
    }
    const fixedHeader = this.elementRef.nativeElement.querySelector('.erom-search-banner-block.fixed-search');
    const sticky = marker.getBoundingClientRect().top;
    const container = this.elementRef.nativeElement.querySelector('.erom-category-filters-block');
    if (isPlatformBrowser(this.platformId) && window.innerWidth <= 1180) {
      container.classList.remove('fixed-filters');
    }
    if ((!fixedHeader && sticky <= 0) || (fixedHeader && sticky + fixedHeader.offsetHeight <= fixedHeader.offsetHeight)) {
      if (isPlatformBrowser(this.platformId)) {
        if (window.innerWidth > 1180) {
          if (!container.classList.contains('fixed-filters')) {
            const containerHeight = this.outerHeight(container);
            if (fixedHeader) {
              container.style.top = fixedHeader.offsetHeight + 'px';
              marker.style.height = containerHeight + fixedHeader.offsetHeight + 'px';
            } else {
              marker.style.height = containerHeight + 'px';
            }
          }
          container.classList.add('fixed-filters');
        } else {
          if (fixedHeader) {
            marker.style.height = fixedHeader.offsetHeight + 'px';
          }
        }
      }
    } else {
      marker.style.height = '';
      container.style.top = '';
      container.classList.remove('fixed-filters');
    }
  }

  private outerHeight(element): number {
    if (isPlatformBrowser(this.platformId)) {
      const height = element.offsetHeight;
      const style = window.getComputedStyle(element);
      return ['margin-top', 'margin-bottom', 'padding-top', 'padding-bottom', 'border-top-width', 'border-bottom-width']
        .map(property => parseInt(style[property], 10))
        .reduce((total, side) => total + side, height);
    }
    return 0;
  }

  public get deviceFiltersApplied(): boolean {
    // const deviceFiltersApplied = localStorage.getItem('deviceFiltersApplied');
    const deviceFiltersApplied = getCookie('deviceFiltersApplied');
    return this._deviceFiltersApplied && !deviceFiltersApplied;
  }

  public get navigatorSavedAlertDisplay(): boolean {
    return this._navigatorSavedAlertDisplay;
  }

  public getHighLightedResult(result: string): any {
    if (!this._searchValue) {
      return result;
    }
    const re = new RegExp(this._searchValue.toLowerCase(), 'g');
    if (this._searchValue.length > 0) {
      return this.sanitizer.bypassSecurityTrustHtml(result.toLowerCase().replace(re, `<mark role="presentation">$&</mark>`));
    } else {
      return result;
    }
  }

  public onSelect(event, option): void {
    event.preventDefault();
    event.stopPropagation();
    let filterOption = null;
    switch (option.type) {
      case 'Category':
        filterOption = this.category.find(o => o.label === option.name);
        if (filterOption) {
          this._searchValue = '';
          this.categoryFilter.selectOption(filterOption);
          setTimeout(() => {
            this.updateQueryParams({ search: null });
          }, 0);
        }
        break;
      case 'Adjustment':
        filterOption = this.adjustment.find(o => o.label === option.name);
        if (filterOption) {
          this._searchValue = '';
          this.adjustmentFilter.selectOption(filterOption);
          setTimeout(() => {
            this.updateQueryParams({ search: null });
          }, 0);
        }
        break;
      case 'Condition':
        filterOption = this.condition.find(o => o.label === option.name);
        if (filterOption) {
          this._searchValue = '';
          this.conditionFilter.selectOption(filterOption);
          setTimeout(() => {
            this.updateQueryParams({ search: null });
          }, 0);
        }
        break;
      case 'Symptom':
        filterOption = this.symptom.find(o => o.label === option.name);
        if (filterOption) {
          this._searchValue = '';
          this.symptomFilter.selectOption(filterOption);
          setTimeout(() => {
            this.updateQueryParams({ search: null });
          }, 0);
        }
        break;
      case 'Operating system':
        filterOption = this.operatingSystem.find(o => o.label === option.name);
        if (filterOption) {
          this._searchValue = '';
          this.operatingSystemFilter.selectOption(filterOption);
          setTimeout(() => {
            this.updateQueryParams({ search: null });
          }, 0);
        }
        break;
      case 'Browser':
        filterOption = this.browser.find(o => o.label === option.name);
        if (filterOption) {
          this._searchValue = '';
          this.browserFilter.selectOption(filterOption);
          setTimeout(() => {
            this.updateQueryParams({ search: null });
          }, 0);
        }
        break;
      default:
        this._searchValue = option.name;
        this.updateQueryParams({ search: option.name });
        this.runSearch(true);
        break;
    }
    if (!filterOption) {
      this._searchValue = option.name;
      this.updateQueryParams({ search: option.name });
      this.runSearch(true);
    }
    this.setupFixedSearchChanges();
    setTimeout(() => {
      this.onBlur(event);
    }, 200);
    this.ga.event('search', 'suggestedTag', option.name);
  }

  public onEnter(event, value: string): void {
    const data = this._searchResults[this._typeAheadPointer];
    // console.log(data)
    // if (!data) {
    //   this.updateQueryParams({ search: encodeURI(value) });
    //   this.runSearch(true);
    //   return;
    // }
    if (data) {
      this.onSelect(event, data);
      this.searchFocused = false;
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.onBlur(event, true);
    }
  }

  private updateQueryParams(newQueryParams: object, runSearch: boolean = false, setupCanonical: boolean = false): void {
    const queryParams = HomepageComponent.filterQueryParams({
      ...this.activatedRoute.snapshot.queryParams,
      ...newQueryParams
    });
    const filteredParams = Object.keys(queryParams).reduce((newObj, key) => {
      const value = queryParams[key];
      if (value && value !== '' && value !== null) {
        newObj[key] = value;
      }
      return newObj;
    }, {});
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: filteredParams
    });

    if (setupCanonical) {
      HomepageComponent.createCanonical('matchTagCanonical', `${this.location.protocol}//${this.location.hostname}${urlTree.toString()}`);
    }

    if (setupCanonical && isPlatformBrowser(this.platformId)) {
      window.location.href = `${this.location.protocol}//${this.location.hostname}${urlTree.toString()}`;
      return;
    }

    this.router.navigateByUrl(urlTree, {
      skipLocationChange: false,
      replaceUrl: true
    }).then(() => {
      if (setupCanonical) {
        HomepageComponent.removeCanonical('matchTagCanonical');
      }
      this.setupFilters();
      if (runSearch) {
        this.runSearch(true);
      }
    });
  }

  public onFocus(): void {
    this.searchFocused = true;
    this._oldSearchValue = this._searchValue;
    // if (this._searchResults.length) {
    //   this.runSearch(true, null, false, true);
    // }
  }

  public onBlur(event, runSearch: boolean = false): void {
    if (!runSearch && event.relatedTarget && event.relatedTarget.classList.contains('ignore-blur-event')) {
      event.preventDefault();
      return;
    }
    this.searchFocused = false;
    this.searchInput.nativeElement.blur();
    console.log(this._searchValue);
    console.log(this._oldSearchValue);
    if (this._searchValue === this._oldSearchValue) {
      return;
    }
    this.updateQueryParams({ search: encodeURI(this._searchValue || '') });
    this.runSearch(true);
    if (this._searchValue && this._searchValue !== '') {
      this.ga.event('search', 'searchTerm', this._searchValue);
    }
    // this._searchValue = '';
    // this.searchInput.nativeElement.value = '';
  }

  private resetSearchFilter(): void {
    this.searchFocused = false;
    this._searchValue = '';
    this.searchInput.nativeElement.value = '';
    this._fixedSearch = false;
  }

  public resetSearch(): void {
    this.resetSearchFilter();
    this.updateQueryParams({ search: null });
    this.searchInput.nativeElement.focus();
    this.setupFixedSearchChanges();
    this.runSearch(true);
  }

  public openMobileFilters(): void {
    this._mobileFiltersActive = !this._mobileFiltersActive;
    // const nextFocus = this.document.getElementById('mobileFiltersGuidesTitle');
    // if (nextFocus) {
    //   nextFocus.tabIndex = -1;
    //   nextFocus.focus();
    // }
  }

  private runSearch(resetLoader: boolean = false, oldCount: number = null, initial: boolean = false, resetSearch: boolean = false): void {
    this._searchInProgress = true;
    if (resetLoader) {
      this._offset = 0;
    }

    const filters = this.generateRequestFilters(resetSearch);

    let dataParams = new HttpParams();
    dataParams = dataParams.append('limit', this._ppp.toString());
    dataParams = dataParams.append('offset', this._offset.toString());
    dataParams = dataParams.append('filters', JSON.stringify(filters));

    this._oldSearchValue = this._searchValue;

    this.http.get(`${this.api.URLS.page}/article-search`, { params: dataParams }).subscribe((results: any) => {
      if (resetLoader) {
        this._results = [].concat(results.items);
      } else {
        this._results = this._results.concat(results.items);
      }
      this._totalCount = results.count;
      this._searchInProgress = false;
      if (!initial) {
        this.getAllTagsThenRunSearch(false, false, false, resetSearch);
      }
    }, (error) => {
      console.log(error);
      this._searchInProgress = false;
    });
    if (oldCount) {
      setTimeout(() => {
        const articles = this.elementRef.nativeElement.querySelectorAll('a');
        if (articles.length && articles[oldCount]) {
          articles[oldCount].focus();
        }
      }, 0);
    }
  }

  private updateSitemapOnSearchResults(excludeSearch: boolean = false): void {

    const filters = this.generateRequestFilters();

    // if (!filters.hasOwnProperty('search')) {
    //   return;
    // }

    if (excludeSearch && filters.hasOwnProperty('search')) {
      delete filters.search;
    }

    console.log(filters);

    const data = {
      limit: this._ppp,
      offset: this._offset,
      filters
    };

    this.http.post(`${this.api.URLS.page}/article-search-sitemap-update`, data).subscribe(() => {

    }, (error) => {
      console.log(error);
    });
  }

  public loadMoreResults(): void {
    const oldCount = this._results.length;
    this._offset += this._ppp;
    this.runSearch(false, oldCount);
  }

  public articleLink(alias: string): string {
    return `${this.location.protocol}//${this.location.hostname}/${alias}`;
  }

  public articleDate(date: string): string {
    return convertDateTime(date, 'F d Y', false, '-');
  }

  public articleBody(body: string): string {
    if (!body) {
      return body;
    }
    if (body.length > 230) {
      return body.substring(0, 230) + ' ...';
    }
    return body;
  }

  public goToArticle(alias: string): void {
    this.router.navigate(['/', alias]).then(() => {
      this.updateSitemapOnSearchResults();
    });
  }

  public gaEvent(category: string, action: string): void {
    this.ga.event(category, action);
  }

  public get results(): Array<any> {
    return this._results;
  }

  public get totalCount(): number {
    return this._totalCount || 0;
  }

  public get searchEmpty(): boolean {
    return !this._searchValue || this._searchValue === '';
  }

  public get fixedSearch(): boolean {
    return this._fixedSearch;
  }

  public get isMobile(): boolean {
    return this._windowWidth <= 600;
  }

  public get searchValue(): string {
    return this._searchValue || '';
  }

  public get id(): string {
    return this._id;
  }

  public get expanded(): boolean {
    return this.searchFocused && this._searchValue && this._searchValue !== '' && this.searchResults.length > 0;
  }

  public get searchResults(): Array<any> {
    return this._searchResults || [];
  }

  public get descendentId(): string {
    return this._descendentId;
  }

  public get mobileFiltersActive(): boolean {
    return this._mobileFiltersActive || false;
  }

  public get activeFiltersCounter(): number {
    const counter = 0;
    return this._filters.map(filter => filter.options.length).reduce((total, side) => total + side, counter);
  }

  public get searchInProgress(): boolean {
    return this._searchInProgress;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.makeCategoryFiltersSticky();
  }

  @HostListener('document:resize', ['$event'])
  onResize(): void {
    this.makeCategoryFiltersSticky();
    if (isPlatformBrowser(this.platformId)) {
      this._windowWidth = window.innerWidth;
    }
  }

}
