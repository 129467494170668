import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-article-share',
  templateUrl: './article-share.component.html',
  styleUrls: ['./article-share.component.scss']
})
export class ArticleShareComponent implements OnInit, OnDestroy {

  @ViewChild('dropdownMenuList', {read: ElementRef, static: false}) dropdownMenuList: ElementRef;

  private _typeAheadPointer: number;
  private _descendentId: string;
  private _shareListActive: boolean;

  readonly _id: string;
  readonly _socialMedia: Array<any>;

  constructor(
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._id = ArticleShareComponent.generateId('share');
    this._typeAheadPointer = -1;
    this._socialMedia = [
      {
        name: 'editor.TWITTER',
        icon: '<svg width="18" height="18" x="0px" y="0px" viewBox="0 0 18 14.625" style="enable-background:new 0 0 18 14.625;" xml:space="preserve"><g><path fill="currentColor" d="M16.137,3.656c0.023,0.094,0.035,0.246,0.035,0.457c0,1.242-0.234,2.479-0.703,3.709s-1.131,2.355-1.986,3.375s-1.963,1.846-3.322,2.479s-2.859,0.949-4.5,0.949c-2.062,0-3.949-0.551-5.66-1.652c0.258,0.023,0.551,0.035,0.879,0.035c1.711,0,3.246-0.527,4.605-1.582c-0.82,0-1.541-0.24-2.162-0.721S2.273,9.621,2.039,8.895C2.273,8.918,2.496,8.93,2.707,8.93c0.328,0,0.656-0.035,0.984-0.105C3.129,8.707,2.625,8.473,2.18,8.121S1.383,7.342,1.125,6.838S0.738,5.789,0.738,5.203v-0.07c0.516,0.305,1.066,0.469,1.652,0.492c-1.102-0.75-1.652-1.781-1.652-3.094c0-0.656,0.176-1.277,0.527-1.863C2.18,1.816,3.299,2.73,4.623,3.41s2.736,1.055,4.236,1.125c-0.047-0.281-0.07-0.562-0.07-0.844c0-1.008,0.357-1.875,1.072-2.602S11.438,0,12.445,0c1.078,0,1.98,0.387,2.707,1.16c0.844-0.164,1.629-0.457,2.355-0.879c-0.281,0.867-0.82,1.547-1.617,2.039C16.594,2.227,17.297,2.027,18,1.723C17.484,2.473,16.863,3.117,16.137,3.656z"/></g></svg>',
        type: 'twitter',
        enabled: true
      },
      {
        name: 'editor.FACEBOOK',
        icon: '<svg width="11" height="18" x="0px" y="0px" viewBox="0 0 9.633 18" style="enable-background:new 0 0 9.633 18;" xml:space="preserve"><g><path fill="currentColor" d="M9,10.125H6.363V18H2.848v-7.875H0v-3.27h2.848V4.395c0-0.938,0.176-1.734,0.527-2.391S4.225,0.85,4.869,0.51S6.258,0,7.102,0c0.375,0,0.773,0.018,1.195,0.053s0.75,0.076,0.984,0.123l0.352,0.035v2.777H8.227c-0.656,0-1.131,0.17-1.424,0.51S6.363,4.254,6.363,4.746v2.109h3.129L9,10.125z"/></g></svg>',
        type: 'facebook',
        enabled: true
      },
      {
        name: 'editor.LINKED_IN',
        icon: '<svg width="16" height="18" x="0px" y="0px" viewBox="0 0 15.75 15.75" style="enable-background:new 0 0 15.75 15.75;" xml:space="preserve"><g><path fill="currentColor" d="M1.898,3.797c-0.516,0-0.961-0.188-1.336-0.562S0,2.414,0,1.898s0.188-0.961,0.562-1.336S1.383,0,1.898,0s0.961,0.188,1.336,0.562s0.562,0.82,0.562,1.336c0,0.328-0.088,0.639-0.264,0.932S3.123,3.357,2.83,3.533S2.227,3.797,1.898,3.797z M3.516,15.75h-3.27V5.238h3.27V15.75z M15.75,15.75h-3.27v-5.133c0-0.398-0.012-0.721-0.035-0.967s-0.082-0.527-0.176-0.844s-0.264-0.557-0.51-0.721S11.191,7.84,10.793,7.84c-0.773,0-1.295,0.246-1.564,0.738s-0.404,1.148-0.404,1.969v5.203h-3.27V5.238h3.164V6.68h0.035c0.234-0.469,0.621-0.873,1.16-1.213s1.184-0.51,1.934-0.51c0.797,0,1.459,0.123,1.986,0.369s0.926,0.609,1.195,1.09s0.457,1.002,0.562,1.564s0.158,1.23,0.158,2.004V15.75z"/></g></svg>',
        type: 'linkedin',
        enabled: true
      },
      {
        name: 'editor.EMAIL',
        icon: '<svg width="18" height="18" x="0px" y="0px" viewBox="0 0 18 13.5" style="enable-background:new 0 0 18 13.5;" xml:space="preserve"><g><path fill="currentColor" d="M0.562,2.848C0.188,2.543,0,2.156,0,1.688S0.164,0.82,0.492,0.492S1.219,0,1.688,0h14.625c0.469,0,0.867,0.164,1.195,0.492S18,1.219,18,1.688s-0.188,0.855-0.562,1.16L9.984,8.648c-0.656,0.492-1.312,0.492-1.969,0L0.562,2.848z M9,10.125c0.633,0,1.184-0.188,1.652-0.562L18,3.832v7.98c0,0.469-0.164,0.867-0.492,1.195S16.781,13.5,16.312,13.5H1.688c-0.469,0-0.867-0.164-1.195-0.492S0,12.281,0,11.812v-7.98l7.348,5.73C7.816,9.938,8.367,10.125,9,10.125z"/></g></svg>',
        type: 'email',
        enabled: true
      },
      {
        name: 'editor.COPY_LINK',
        icon: '<svg width="23" height="18" x="0px" y="0px" viewBox="0 0 22.5 18" style="enable-background:new 0 0 22.5 18;" xml:space="preserve"><g><path fill="currentColor" d="M8.227,13.605c0.07,0.094,0.176,0.211,0.316,0.352c0.328,0.352,0.82,0.703,1.477,1.055l-1.547,1.547C7.512,17.52,6.34,18,4.957,18c-1.336,0-2.508-0.48-3.516-1.441C0.48,15.574,0,14.396,0,13.025s0.48-2.537,1.441-3.498l4.711-4.711c0.961-0.961,2.133-1.441,3.516-1.441c1.336,0,2.508,0.48,3.516,1.441c0.797,0.797,1.266,1.758,1.406,2.883s-0.07,2.156-0.633,3.094c-0.352,0.305-0.727,0.457-1.125,0.457s-0.773-0.152-1.125-0.457c-0.07-0.07-0.152-0.199-0.246-0.387l0.105-0.141c0.539-0.539,0.809-1.184,0.809-1.934c0-0.773-0.27-1.406-0.809-1.898c-0.492-0.539-1.125-0.809-1.898-0.809c-0.75,0-1.395,0.27-1.934,0.809l-4.676,4.676C2.52,11.648,2.25,12.293,2.25,13.043c0,0.773,0.27,1.406,0.809,1.898c0.492,0.539,1.125,0.809,1.898,0.809c0.75,0,1.395-0.27,1.934-0.809L8.227,13.605z M21.059,1.441C22.02,2.426,22.5,3.604,22.5,4.975s-0.48,2.537-1.441,3.498l-4.711,4.711c-0.961,0.961-2.133,1.441-3.516,1.441c-1.336,0-2.508-0.48-3.516-1.441c-0.797-0.797-1.266-1.758-1.406-2.883s0.07-2.156,0.633-3.094C8.895,6.902,9.27,6.75,9.668,6.75s0.773,0.152,1.125,0.457c0.117,0.141,0.199,0.27,0.246,0.387l-0.105,0.141c-0.539,0.539-0.809,1.184-0.809,1.934c0,0.773,0.27,1.406,0.809,1.898c0.492,0.539,1.125,0.809,1.898,0.809c0.75,0,1.395-0.27,1.934-0.809l4.676-4.676c0.539-0.539,0.809-1.184,0.809-1.934c0-0.773-0.27-1.406-0.809-1.898C18.949,2.52,18.316,2.25,17.543,2.25c-0.75,0-1.395,0.27-1.934,0.809l-1.336,1.336c-0.07-0.094-0.176-0.211-0.316-0.352c-0.328-0.352-0.82-0.703-1.477-1.055l1.547-1.547C14.988,0.48,16.16,0,17.543,0C18.879,0,20.051,0.48,21.059,1.441z"/></g></svg>',
        type: 'link',
        enabled: true
      }
    ];
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  private static generateId(prefix: string): string {
    const date = new Date();
    const time = date.getTime();
    const random = Math.floor(Math.random() * 1000000000);
    return prefix + '_' + random + String(time);
  }

  private createNavigationUrl(option: object): string {
    let navUrl = '';
    if (isPlatformBrowser(this.platformId)) {
      switch(option['type']) {
        case 'facebook':
          navUrl = 'https://www.facebook.com/sharer/sharer.php?href=' + location.href;
          break;
        case 'twitter':
          navUrl = 'https://twitter.com/intent/tweet?url=' + location.href;
          break;
        case 'linkedin':
          navUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + location.href;
          break;
        case 'email':
          navUrl = 'mailto:?&subject=&cc=&bcc=&body=' + location.href;
          break;
      }
    }
    return navUrl;
  }

  public onSelect(event, option): void {
    if (option['type'] === 'link') {
      const dummy = this.document.createElement('input');
      const text = isPlatformBrowser(this.platformId) ? window.location.href : '';
      dummy.classList.add('erom-visually-hidden');
      this.document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      this.document.execCommand('copy');
      this.document.body.removeChild(dummy);
      const oldIcon = option['icon'];
      const oldName = option['name'];
      option['icon'] = '<svg width="20" height="21" x="0px" y="0px" viewBox="0 0 18.75 18.75" style="enable-background:new 0 0 18.75 18.75;" xml:space="preserve" class="copy-link-icon"><g><path fill="currentColor" d="M2.734,2.734C4.557,0.912,6.771,0,9.375,0c2.604,0,4.817,0.912,6.641,2.734c1.822,1.823,2.734,4.037,2.734,6.641c0,2.604-0.912,4.818-2.734,6.641c-1.823,1.822-4.037,2.734-6.641,2.734c-2.604,0-4.818-0.912-6.641-2.734C0.911,14.193,0,11.979,0,9.375C0,6.771,0.911,4.558,2.734,2.734z M13.906,7.656c0.208-0.208,0.312-0.469,0.312-0.781s-0.104-0.572-0.312-0.781c-0.209-0.208-0.469-0.312-0.781-0.312s-0.573,0.104-0.781,0.312l-4.219,4.219L6.406,8.594C6.197,8.386,5.938,8.281,5.625,8.281S5.052,8.386,4.844,8.594C4.635,8.803,4.531,9.062,4.531,9.375s0.104,0.573,0.312,0.781l2.5,2.5c0.26,0.209,0.521,0.312,0.781,0.312c0.26,0,0.521-0.104,0.781-0.312L13.906,7.656z"/></g></svg>';
      option['name'] = 'editor.LINK_COPIED';
      event.target.closest('li').setAttribute('disabled', 'true');
      setTimeout(() => {
        option['icon'] = oldIcon;
        option['name'] = oldName;
        event.target.closest('li').removeAttribute('disabled');
      }, 5000);
      return;
    }
    const navUrl = this.createNavigationUrl(option);
    this.close();
    if (isPlatformBrowser(this.platformId)) {
      window.open(navUrl, '_blank');
    }
  }

  public onEnter(event): void {
    if (!this._shareListActive) {
      this._shareListActive = true;
    } else {
      const data = this.socialMedia[this._typeAheadPointer];
      if (data) {
        this.onSelect(event, data);
      } else {
        this._shareListActive = false;
      }
    }
  }

  public close(): void {
    this._descendentId = undefined;
    this._shareListActive = false;
  }

  public typeAheadUp(event) {
    event.preventDefault();
    if (!this._shareListActive) {
      return;
    }
    if (this._typeAheadPointer > 0) {
      this._typeAheadPointer--;
      this.updateActiveDescendent();
      this.maybeAdjustScroll();
    }
  }

  public typeAheadDown(event): void {
    event.preventDefault();
    if (!this._shareListActive) {
      return;
    }
    if (this._typeAheadPointer < this.socialMedia.length - 1) {
      this._typeAheadPointer++;
      this.updateActiveDescendent();
      this.maybeAdjustScroll();
    }
  }

  public typeAheadHome(event): void {
    event.preventDefault();
    if (!this._shareListActive) {
      return;
    }
    this._typeAheadPointer = 0;
    this.updateActiveDescendent();
    this.maybeAdjustScroll();
  }

  public typeAheadEnd(event): void {
    event.preventDefault();
    if (!this._shareListActive) {
      return;
    }
    this._typeAheadPointer = this.socialMedia.length - 1;
    this.updateActiveDescendent();
    this.maybeAdjustScroll();
  }

  private updateActiveDescendent(): void {
    this._descendentId = this._id + '-item-' + this._typeAheadPointer;
  }

  public maybeAdjustScroll(): void {
    const pixelsToPointerTop = this.pixelsToPointerTop();
    const pixelsToPointerBottom = this.pixelsToPointerBottom();

    if (pixelsToPointerTop <= this.viewport()['top']) {
      return this.scrollTo( pixelsToPointerTop);
    } else if (pixelsToPointerBottom >= this.viewport()['bottom']) {
      return this.scrollTo(this.viewport()['top'] + this.pointerHeight());
    }
  }

  private pixelsToPointerTop(): number {
    let pixelsToPointerTop = 0;
    if (this.dropdownMenuList.nativeElement) {
      for (let i = 0; i < this._typeAheadPointer; i++) {
        pixelsToPointerTop += this.dropdownMenuList.nativeElement.children[i].offsetHeight;
      }
    }
    return pixelsToPointerTop;
  }

  private pixelsToPointerBottom(): number {
    return this.pixelsToPointerTop() + this.pointerHeight();
  }

  private pointerHeight(): number {
    const element = this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.children[this._typeAheadPointer] : false;
    return element ? element.offsetHeight : 0;
  }

  private viewport(): object {
    return {
      top: this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.scrollTop : 0,
      bottom: this.dropdownMenuList.nativeElement ?
        this.dropdownMenuList.nativeElement.offsetHeight + this.dropdownMenuList.nativeElement.scrollTop : 0
    };
  }

  private scrollTo(position): void {
    return this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.scrollTop = position : null;
  }

  public safeIcon(iconText: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(iconText);
  }

  public get id(): string {
    return this._id;
  }

  public activateShareList(): void {
    this._shareListActive = !this._shareListActive;
  }

  public get socialMedia(): Array<any> {
    return this._socialMedia.filter(s => s.enabled);
  }

  public get descendentId(): string {
    return this._descendentId;
  }

  public get shareListActive(): boolean {
    return this._shareListActive || false;
  }

}
