export enum BlockType {
  Paragraph = 'paragraph',
  Heading = 'heading',
  List = 'list',
  Blockquote = 'blockquote',
  Table = 'table',
  Button = 'button',
  Group = 'group',
  Code = 'code',
  Title = 'title',
  DeviceCheck = 'device-check',
  SearchCta = 'search-cta',
  TableOfContents = 'toc',
  Iframe = 'iframe',
  Video = 'video',
  Image = 'image',
  ShareGuide = 'share-guide',
  Step = 'step',
  SurveyMonkey = 'survey-monkey',
  ReadNext = 'read-next',
  TagsAndCategories = 'tags-categories',
  FullEditor = 'full-editor',
}
