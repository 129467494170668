import { Component } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';

@Component({
  selector: 'title-block',
  templateUrl: './title.component.html',
  styles: [':host { display: block; }']
})

export class TitleComponent extends GenericBlock {

  public static type = BlockType.Title;
  public static tagNames = undefined;
  public static blockCategory = 'Immutable';
  public static blockName = 'Title';
  public static structure = {
    id: null,
    type: BlockType.Title,
    data: null
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="h1" class="svg-inline--fa fa-h1 fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M304 96h-98.94A13.06 13.06 0 0 0 192 109.06v37.88A13.06 13.06 0 0 0 205.06 160H224v64H96v-64h18.94A13.06 13.06 0 0 0 128 146.94V112a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v34.94A13.06 13.06 0 0 0 13.06 160H32v192H13.06A13.06 13.06 0 0 0 0 365.06V400a16 16 0 0 0 16 16h98.94A13.06 13.06 0 0 0 128 402.94v-37.88A13.06 13.06 0 0 0 114.94 352H96v-64h128v64h-18.94A13.06 13.06 0 0 0 192 365.06V400a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-34.94A13.06 13.06 0 0 0 306.94 352H288V160h18.94A13.06 13.06 0 0 0 320 146.94V112a16 16 0 0 0-16-16zm256 256h-48V120a24 24 0 0 0-24-24h-40a24 24 0 0 0-21.44 13.26l-24 48A24 24 0 0 0 424 192h24v160h-48a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path></svg>';

  constructor() {
    super();
  }

  public init(): void {
  }

  public get type(): string {
    return TitleComponent.type;
  }

  public get tagNames(): Array<string> {
    return TitleComponent.tagNames;
  }

  public get blockName(): string {
    return TitleComponent.blockName;
  }

  public get blockCategory(): string {
    return TitleComponent.blockCategory;
  }

  public get icon(): string {
    return TitleComponent.icon;
  }

  public get structure(): object {
    return TitleComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
