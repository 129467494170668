import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { getTinymce } from '../TinyMCE';
import { bindHandlers, Events, mergePlugins, ScriptLoader, uuid } from '../_common';
import {
  BlockOptionsItemInterface,
  EromBlockGroupInterface,
  EromBlockInterface,
  EromEditorActionEvent,
  EromEditorActionInterface,
  EromEditorChangeEvent,
  EromEditorDataInterface,
  EromGroupInterface,
  EromGroupRowInterface,
  GenericBlockInterface
} from '../_interfaces';
import { TabTriggerComponent } from '../tabs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { EditorSettings } from 'tinymce';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { BlockType, EditorPageMode, GridType, HorizontalPosition, VerticalPosition } from '../_types';
import { EromEditorGlobals } from '../_store';
import { SelectItemInterface } from '../../../erom-select/_interfaces';
import { GenericBlock } from '../blocks/generic-block';
import { markAsTouched } from '../../../../_helpers/common';
import { AccordionHeaderComponent } from '../accordion-header/accordion-header.component';
import { SafeHtml } from '@angular/platform-browser';
// import { NgxCaptureService } from 'ngx-capture';
// import { tap } from 'rxjs/operators';

export const EROM_TINYMCE_SCRIPT_SRC = new InjectionToken<string>('EROM_TINYMCE_SCRIPT_SRC');
export const EROM_BLOCK_COMPONENTS = new InjectionToken<Array<GenericBlock[]>>('EROM_BLOCK_COMPONENTS');

const EDITOR_COMPONENT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EromEditorComponent),
  multi: true
};

@AutoUnsubscribe()
@Component({
  selector: 'erom-editor',
  templateUrl: './erom-editor.component.html',
  styleUrls: ['./erom-editor.component.scss'],
  providers: [EDITOR_COMPONENT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EromEditorComponent extends Events implements OnInit, AfterViewInit, OnDestroy {

  @Input() public init: Record<string, any> | undefined;
  @Input() public outputFormat: 'html' | 'text' | undefined;
  @Input() public allowedEvents: string | string[] | undefined;
  @Input() public ignoreEvents: string | string[] | undefined;
  @Input() public mode: EditorPageMode;
  @Input() public actions: EromEditorActionInterface[];
  @Input() public sections: any;
  @Input() public set sectionType(payload) {
    this.sectionTypePage = payload;
    if (payload) {
      this.formData.controls.titleComponentAdded.setValue(true);
    }
  }

  @Input() public set pageData(page: EromEditorDataInterface) {
    this._pageData = page;
    this._contentGroups = this._pageData.body || [];
    this.formData.controls.title.setValue(this._pageData.title);
    if (this._contentGroups.length) {
      this.tinymceScriptLoad();
      this.setupConnectedGroupsList();
      this.setupConnectedGridsList();
      this.setupConnectedBlockGroupList();
      this.changeDetectorRef.detectChanges();
    }
  }

  @ViewChildren(TabTriggerComponent, { read: ElementRef }) tabElements: QueryList<ElementRef>;
  @ViewChildren(PerfectScrollbarComponent) contentScrollableAreas: QueryList<PerfectScrollbarComponent>;
  @ViewChild(PerfectScrollbarComponent) contentScrollableArea: PerfectScrollbarComponent;

  @ViewChildren(AccordionHeaderComponent) accordionHeaders: QueryList<ElementRef>;

  @Output() eromEditorChanged = new EventEmitter<EromEditorChangeEvent>();
  @Output() eromEditorSaved = new EventEmitter<EromEditorChangeEvent>();
  @Output() eromEditorActioned = new EventEmitter<EromEditorActionEvent>();

  private _pageTemplates: SelectItemInterface[];

  private _elementRef: ElementRef;
  private _id: string;

  private _tabs: ElementRef[];
  private _activatedTab: number;
  private _focusedTab: number;

  private _windowHeight: number;

  private _contentGroups: EromGroupInterface[];

  private _dragInProgress: boolean;

  private _connectedGridList: Array<string>;
  private _connectedBlockGroupList: Array<string>;
  private _connectedGroupList: Array<string>;

  private _blocks: GenericBlockInterface[] = [];

  private _pageData: EromEditorDataInterface;

  private _toolbarData: any;

  private _aliasChanged: boolean;
  private _newAlias: string;

  public sectionTypePage: boolean;

  public ngZone: NgZone;
  public formData: FormGroup;
  public activeArea: string;
  public activeGrid: string;
  public activeBlock: string;
  public activeEditor: any;
  public activeBlockGroup: string;
  public activeBlockObject: EromBlockInterface;
  public activeBlockGroupObject: EromBlockGroupInterface;

  public groupIdPrefix: string;
  public gridIdPrefix: string;
  public blockIdPrefix: string;

  public accordionFocus: boolean;
  public accordionCurrentlyOpen: number;

  public accordionPanelQuantity: number;

  public sectionSaveAsExpanded: boolean;
  public sectionLocked: number;
  public sectionName: string;
  public sectionImage: File;
  public sectionImageData: string;
  public sectionTags: Array<string>;

  readonly _tagsSplitRegex: RegExp;

  constructor(
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private ngZoneCore: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private eromEditorGlobals: EromEditorGlobals,
    private resolver: ComponentFactoryResolver,
    // private captureService: NgxCaptureService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(EROM_TINYMCE_SCRIPT_SRC) private tinymceScriptSrc?: string,
    @Optional() @Inject(EROM_BLOCK_COMPONENTS) private blockComponents?: Array<GenericBlock[]>
  ) {
    super();

    this._pageData = ({} as EromEditorDataInterface);

    this._id = uuid('erom-editor');

    this._elementRef = elementRef;
    this.ngZone = ngZoneCore;

    this.tinymceScriptLoad();

    this._contentGroups = [];
    this._activatedTab = 1;
    this._focusedTab = 0;
    this._windowHeight = window.innerHeight;
    this.groupIdPrefix = 'erom-group-';
    this.gridIdPrefix = 'erom-grid-';
    this.blockIdPrefix = 'erom-block-';

    this.formData = this.formBuilder.group({
      title: ['', Validators.required],
      titleComponentAdded: [false, Validators.requiredTrue]
    });

    this.formData.controls.title.valueChanges.subscribe(value => {
      this._pageData.title = value;
    });

    this.formData.valueChanges.subscribe(() => {
      this.editorChanged('change');
    });

    this.accordionFocus = false;
    this.accordionCurrentlyOpen = 0;
    this._tagsSplitRegex = new RegExp(/[^A-Za-z0-9-_: ]+/g);
  }

  private static blurActiveEditor(): void {
    if (!getTinymce()) {
      return;
    }
    const editorObject = getTinymce().focusedEditor;
    if (editorObject) {
      editorObject.focus();
      editorObject.targetElm.blur();
    }
  }

  private setupBlockIcon(icon: SafeHtml, toolbarId: string): void {
    if (!icon) {
      return;
    }
    const toolbar = this.document.getElementById(toolbarId);
    if (toolbar) {
      const handle: HTMLElement = (toolbar as HTMLElement).previousElementSibling.querySelector('.erom-editor-drag__handle');
      if (handle) {
        const span = this.document.createElement('SPAN');
        span.classList.add('erom-block-icon');
        span.innerHTML = icon.toString();
        handle.prepend(span);
      }
    }
  }

  public blockChanged(event: any): void {
    this.editorChanged('change');
  }

  public blockReady(block: EromBlockInterface, grid: (EromBlockInterface|EromBlockGroupInterface)[], groupId: string): void {
    if (block.type === BlockType.Title) {
      this.formData.controls.titleComponentAdded.setValue(true);
    }
    if (block.editableAreas) {
      if (!getTinymce()) {
        return;
      }
      for (let i = 0; i < block.editableAreas.length; i++) {
        if (block.editableAreas[i].id && getTinymce().EditorManager.get(block.editableAreas[i].id)) {
          return;
        }
        const target = block.editableAreas[i].target;
        const editorOptions = block.editableAreas[i].editorOptions;
        if (!editorOptions) {
          return;
        }
        target.id = block.editableAreas[i].id;
        const customFunctions = editorOptions.customFunctions || {};
        const autoFocusFound = block.autoFocus || false;
        const localApp = location.hostname === 'localhost';
        const finalInit = {
          button_tile_map: true,
          entity_encoding : 'raw',
          target,
          contextmenu_never_use_native: true,
          autosave_restore_when_empty: true,
          autosave_prefix: 'erom-editor-autosave-{path}{query}-{id}-',
          paste_as_text: true,
          paste_preprocess: (pl, o) => {
            o.content = o.content.replace(/^<div[^>]*>|<\/div>$/g, '');
          },
          ...editorOptions,
          inline: true,
          auto_focus: autoFocusFound,
          fixed_toolbar_container: `#toolbar-${block.id}`,
          toolbar: `${editorOptions.toolbar} | fontawesome | restoredraft`,
          extended_valid_elements : 'span[*],i[*]',
          external_plugins: {
            fontawesome: `${!localApp ? '/admin' : ''}/erom-editor/plugins/fontawesome/plugin.js`
          },
          plugins: mergePlugins(['autosave', 'paste', 'tabfocus', 'image', 'fontawesome'], editorOptions.plugins),
          setup: (editor: EditorSettings) => {

            for (const func in customFunctions) {
              if (!customFunctions.hasOwnProperty(func)) {
                continue;
              }
              if (typeof customFunctions[func] !== 'function') {
                continue;
              }
              customFunctions[func](editor);
            }

            editor.on('init', (e) => {
              this.initEditor(editor);
              this.setupBlockIcon(block.icon, `#toolbar-${block.id}`);
              if (!!autoFocusFound) {
                EromEditorComponent.blurActiveEditor();
              }
              block.autoFocus = false;
              if (e.target.bodyElement.firstChild) {
                const styles = window.getComputedStyle(e.target.bodyElement.firstChild);
                e.target.bodyElement.style.fontFamily = styles.getPropertyValue('font-family').toString();
                e.target.bodyElement.style.fontSize = styles.getPropertyValue('font-size').toString();
                e.target.bodyElement.style.lineHeight = styles.getPropertyValue('line-height').toString();
              }
            });

            editor.on('StoreDraft', () => {
              console.log('StoreDraft');
            });

            editor.on('RestoreDraft', () => {

            });

            editor.on('paste', (e) => {
              // console.log(e);
              // setTimeout(() => {
              //   this.createNewParagraphBlock(grid, block, target, editor, block.editableAreas[i].newBlock);
              // }, 0);
            });

            editor.on('NewBlock', () => {
              this.createNewParagraphBlock(grid, block, target, editor, block.editableAreas[i].newBlock);
            });

            editor.on('keydown', (e: KeyboardEvent) => {
              // const currentBlockIndex = grid.indexOf(block);
              // const range = editor.selection.getRng();
              if (e.code === 'Backspace') {
                if (editor.getContent({ format: 'text' }) === '') {
                  e.preventDefault();
                  e.stopPropagation();
                  e.stopImmediatePropagation();
                }
                // console.log(editor.selection.getNode(), editor.bodyElement.firstChild);
                // if (range.startOffset === 0) {
                //   console.log('here')
                //   e.preventDefault();
                //   e.stopPropagation();
                //   e.stopImmediatePropagation();
                // }
              }
              // if (e.code === 'Backspace') {
              //   if (range.startOffset === 0) {
              //     const previousBlockIndex = currentBlockIndex - 1;
              //     if (previousBlockIndex > -1) {
              //       if (!(grid[previousBlockIndex] as EromBlockInterface).type) {
              //         return;
              //       }
              //       if ((grid[previousBlockIndex] as EromBlockInterface).type !== BlockType.Paragraph &&
              //         (grid[previousBlockIndex] as EromBlockInterface).type !== BlockType.Heading) {
              //         return;
              //       }
              //       const previousEditorId = (grid[previousBlockIndex] as EromBlockInterface)
              //         .editableAreas[(grid[previousBlockIndex] as EromBlockInterface).editableAreas.length - 1];
              //       const previousEditorObject = getTinymce().EditorManager.get(previousEditorId.id);
              //
              //       previousEditorObject.focus();
              //
              //       previousEditorObject.selection.select(previousEditorObject.getBody(), true);
              //       previousEditorObject.selection.collapse(false);
              //
              //       previousEditorObject.execCommand('mceInsertContent', false, ` <span id="_eromEditorCursor"></span>${block.data.text}`);
              //
              //       previousEditorObject.selection.select(previousEditorObject.dom.select('#_eromEditorCursor')[0]);
              //       previousEditorObject.selection.collapse(false);
              //       previousEditorObject.dom.remove('_eromEditorCursor');
              //
              //       grid.splice(currentBlockIndex, 1);
              //
              //       return false;
              //     }
              //   }
              // }
              // if (e.code === 'Delete') {
              //   if (range.startOffset === range.startContainer.length) {
              //     const nextBlockIndex = currentBlockIndex + 1;
              //     if (nextBlockIndex < grid.length) {
              //       if (!(grid[nextBlockIndex] as EromBlockInterface).type) {
              //         return;
              //       }
              //       if ((grid[nextBlockIndex] as EromBlockInterface).type !== BlockType.Paragraph &&
              //         (grid[nextBlockIndex] as EromBlockInterface).type !== BlockType.Heading) {
              //         return;
              //       }
              //       editor.execCommand('mceInsertContent', false, `<span id="_eromEditorCursor"></span>${(grid[nextBlockIndex] as EromBlockInterface).data.text}`);
              //       editor.selection.select(editor.dom.select('#_eromEditorCursor')[0]);
              //       editor.selection.collapse(false);
              //       editor.dom.remove('_eromEditorCursor');
              //       grid.splice(nextBlockIndex, 1);
              //       return false;
              //     }
              //   }
              // }
            });
            editor.on('focus', (e) => {
              e.preventDefault();
              if (this.activeBlockObject) {
                this.activeBlockObject.autoFocus = false;
              }
              this.activeBlockObject = block;
              this.activeEditor = editor;
              this.activeBlock = `${this.blockIdPrefix + block.id}`;
              this.activeArea = `${this.groupIdPrefix + groupId}`;
              const group = this._contentGroups.find(g => g.id === groupId);
              const gridIndex = group.grids.findIndex(g => g.columns.find(c => c === grid));
              if (gridIndex > -1) {
                this.activeGrid = `${this.gridIdPrefix + groupId}-${gridIndex}`;
              }
              // const blockGroup = this.document.getElementById(`${this.blockIdPrefix + block.id}`);
              // if (blockGroup && blockGroup.classList.contains('erom-editor__block-group--item')) {
              //   this.activeBlockGroup = `${this.blockIdPrefix + block.id}`;
              // } else {
              //   this.activeBlockGroup = null;
              // }
              // const blockDiv = this.document.getElementById(this.activeBlock);
              // if (blockDiv) {
              //   blockDiv.click();
              // }
              this.activateActiveBlockColumn(true);
              this.tabActivatePanel(0);
              this.changeDetectorRef.detectChanges();
            });
            editor.on('blur', (e) => {
              const preventBlurElements = [
                '.erom-editor-settings',
                '.erom-editor-actions',
                '.erom-editor__settings',
                '.erom-editor-toolbar'
              ];
              const activeElement = e.explicitOriginalTarget || this.document.activeElement;
              let blurPrevented = false;
              for (let j = 0; j < preventBlurElements.length; j++) {
                if (blurPrevented) {
                  continue;
                }
                if (activeElement.closest(preventBlurElements[j])) {
                  e.preventDefault();
                  e.stopPropagation();
                  e.stopImmediatePropagation();
                  blurPrevented = true;
                }
              }
              if (!blurPrevented && activeElement && !activeElement.classList.contains('erom-editor__section-move')) {
                this.activateActiveBlockColumn(false);
                this.activeBlockObject = null;
                this.activeBlock = null;
              }
              this.changeDetectorRef.detectChanges();
            });

            bindHandlers(this, editor);

            if (this.init && typeof this.init.setup === 'function') {
              this.init.setup(editor);
            }
          }
        };

        this.ngZone.runOutsideAngular(() => {
          getTinymce().init(finalInit);
        });
      }
    } else {
      if (block.autoFocus) {
        if (block.type === BlockType.Group) {
          this.activeBlockGroup = `${this.blockIdPrefix + block.id}`;
        } else {
          this.activeBlock = `${this.blockIdPrefix + block.id}`;
        }
      }
    }
  }

  private tinymceScriptLoad(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (getTinymce() === null && this._elementRef.nativeElement.ownerDocument) {
        ScriptLoader.load(
          this._elementRef.nativeElement.ownerDocument,
          this.tinymceScriptSrc,
          () => {}
        );
      }
    }
  }

  public ngOnInit(): void {
    this.blockComponents.forEach((components: GenericBlock[]) => {
      components.forEach((component: GenericBlock) => {
        if (!this._blocks.find((block) => block.type === component.type)) {
          this._blocks.push({
            type: component.type,
            blockCategory: component.blockCategory,
            blockName: component.blockName,
            icon: component.icon,
            structure: component.structure
          });
        }
      });
    });
  }

  public ngAfterViewInit(): void {
    this._tabs = this.tabElements.toArray();
    this.tabElements.first.nativeElement.firstChild.tabIndex = '0';
    this.accordionHeaders.changes.subscribe((list: QueryList<ElementRef>) => {
      this.accordionPanelQuantity = list.length;
    });
    this.document.body.addEventListener('eromEditorBlockChanged', (e) => {
      this.editorChanged('change');
    }, false);
  }

  public ngOnDestroy(): void {
    if (getTinymce() !== null) {
      getTinymce().remove();
    }
  }

  private createNewParagraphBlock(
    grid: (EromBlockInterface|EromBlockGroupInterface)[],
    block: EromBlockInterface,
    target: HTMLElement,
    editor: any,
    newBlockTag: BlockType = null
  ): void {
    if (!newBlockTag) {
      return;
    }
    if (!target.children.length || target.children.length <= 1) {
      return;
    }
    const oldTarget: HTMLElement = (target.cloneNode(true) as HTMLElement);
    const firstTextPart = oldTarget.children[0].outerHTML;
    EromEditorComponent.blurActiveEditor();
    this.activeBlock = `${this.blockIdPrefix}virtual`;
    for (let i = 1; i < oldTarget.children.length; i++) {
      const currentBlockIndex = grid.indexOf(block);
      const newBlock = JSON.parse(JSON.stringify(block));
      newBlock.id = uuid('erom-block');
      newBlock.type = newBlockTag;
      newBlock.data = {
        text: oldTarget.children[i].outerHTML
      };
      newBlock.autoFocus = true;
      grid.splice(currentBlockIndex + i, 0, newBlock);
    }
    setTimeout(() => {
      editor.execCommand('mceSetContent', false, firstTextPart);
    }, 0);
  }

  private activateActiveBlockColumn(onFocus: boolean = false): void {
    const activeBlockElement = this.document.getElementById(this.activeBlock);
    if (activeBlockElement) {
      const parentColumn: HTMLElement = (activeBlockElement as HTMLElement).closest('.erom-editor__rows-column');
      if (parentColumn) {
        parentColumn.style.zIndex = onFocus ? '3' : '';
      }
    }
  }

  private initEditor(editor: any): void {
    editor.on(this.eromEditorGlobals.modelEvents, (event) => {
      this.editorChanged(event.type);
    });
  }

  public tabActivatePanel(index: number): void {
    this._tabs.forEach(tab => (tab.nativeElement.firstChild.tabIndex = -1));
    this._tabs[index].nativeElement.firstChild.tabIndex = index.toString();
    this._focusedTab = index;
    this._activatedTab = index;
  }

  private tabFocusPanel(index: number): void {
    this._focusedTab = index;
    this._tabs[this._focusedTab].nativeElement.firstChild.focus();
  }

  public tabHandleKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowLeft':
        this.tabFocusPanel(this._focusedTab ? this._focusedTab - 1 : this._tabs.length - 1);
        break;
      case 'ArrowRight':
        this.tabFocusPanel((this._focusedTab + 1) % this._tabs.length);
        break;
    }
  }

  public tabHandleKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Home':
        event.preventDefault();
        this.tabFocusPanel(0);
        break;
      case 'End':
        event.preventDefault();
        this.tabFocusPanel(this.tabElements.length - 1);
        break;
    }
  }

  public submit(event): void {
    markAsTouched(this.formData);
    event.preventDefault();
    event.stopPropagation();
    this.disableAllActiveAreas();
    const editorSubmittedEvent = {
      eventType: 'submit',
      invalid: this.formData.invalid,
      title: this.formData.controls.title.value,
      groups: this.clearContentGroups(this._contentGroups)
    };
    this.eromEditorSaved.emit(editorSubmittedEvent);
  }

  public dropGrid(event: CdkDragDrop<EromGroupRowInterface[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.disableAllActiveAreas();
    }
    this.maybeGroupCleanNeeded();
    this.editorChanged('move');
  }

  public dropItem(event: CdkDragDrop<(EromBlockInterface|EromBlockGroupInterface)[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.maybeGroupCleanNeeded();
    this.editorChanged('move');
  }

  private maybeGroupCleanNeeded(): void {
    const groupsToBeDeleted = [];
    const gridsToBeDeleted = [];
    for (let i = 0; i < this._contentGroups.length; i++) {
      for (let j = 0; j < this._contentGroups[i].grids.length; j++) {
        const emptyGroup = this._contentGroups[i].grids.every(grid => grid.columns.every(column => !column.length));
        if (emptyGroup) {
          groupsToBeDeleted.push(i);
        } else {
          const emptyGrid = this._contentGroups[i].grids[j].columns.every(column => !column.length);
          if (emptyGrid) {
            gridsToBeDeleted.push({
              group: i,
              grid: j
            });
          }
        }
      }
    }
    for (let i = 0; i < gridsToBeDeleted.length; i++) {
      this._contentGroups[gridsToBeDeleted[i].group].grids.splice(gridsToBeDeleted[i].grid, 1);
    }
    for (let i = 0; i < groupsToBeDeleted.length; i++) {
      this._contentGroups.splice(groupsToBeDeleted[i], 1);
    }
  }

  public dropGroup(event: CdkDragDrop<EromBlockInterface[]>): void {
    moveItemInArray(this._contentGroups, event.previousIndex, event.currentIndex);
    this.editorChanged('move');
  }

  private editorChanged(eventName: string): void {
    markAsTouched(this.formData);
    const editorChangedEvent = {
      eventType: eventName,
      invalid: this.formData.invalid,
      title: this.formData.controls.title.value,
      groups: this.clearContentGroups(this._contentGroups)
    };
    this.eromEditorChanged.emit(editorChangedEvent);
    if (eventName === 'move' || eventName === 'clone' || eventName === 'drop' || eventName === 'created') {
      this.setupConnectedGroupsList();
      this.setupConnectedGridsList();
      this.setupConnectedBlockGroupList();
    }
  }

  private clearContentGroups(
    contentGroups: EromGroupInterface[],
    clear: boolean = false,
    groupIndex: number = null,
    gridIndex: number = null
  ): EromGroupInterface[] {
    const groups = ([] as EromGroupInterface[]);
    for (let i = 0; i < contentGroups.length; i++) {
      if (groupIndex !== null && groupIndex !== i) {
        continue;
      }
      const group = this.clearContentGroup(contentGroups[i], clear, gridIndex);
      groups.push(group);
    }
    return groups;
  }

  private clearContentGroup(
    contentGroup: EromGroupInterface,
    clear: boolean = false,
    gridIndex: number = null
  ): EromGroupInterface {
    const group = {
      id: contentGroup.id,
      name: contentGroup.name,
      grids: [],
      hidden: contentGroup.hidden,
      width: contentGroup.width,
      schema: contentGroup.schema,
      style: contentGroup.style,
      settings: contentGroup.settings
    };
    for (let i = 0; i < contentGroup.grids.length; i++) {
      if (gridIndex !== null && gridIndex !== i) {
        continue;
      }
      const grid = this.clearContentGrid(contentGroup.grids[i], clear);
      group.grids.push(grid);
    }
    return group;
  }

  private clearContentGrid(contentGrid: EromGroupRowInterface, clear: boolean = false): EromGroupRowInterface {
    const grid = {
      grid: contentGrid.grid,
      name: contentGrid.name,
      backgroundImage: contentGrid.backgroundImage,
      backgroundColor: contentGrid.backgroundColor,
      align: contentGrid.align,
      columns: [],
      hidden: contentGrid.hidden,
      schema: contentGrid.schema,
      style: contentGrid.style,
      settings: contentGrid.settings
    };
    for (let i = 0; i < contentGrid.columns.length; i++) {
      const columns = [];
      for (let j = 0; j < contentGrid.columns[i].length; j++) {
        const block = this.clearContentBlock(contentGrid.columns[i][j], clear);
        columns.push(block);
      }
      grid.columns.push(columns);
    }
    return grid;
  }

  private clearContentBlock(contentBlock: EromBlockInterface|EromBlockGroupInterface, clear: boolean = false): EromBlockInterface {
    let block;
    if ((contentBlock as EromBlockInterface).type) {
      block = {
        id: contentBlock.id,
        type: (contentBlock as EromBlockInterface).type,
        name: (contentBlock as EromBlockInterface).name,
        data: !clear ? (contentBlock as EromBlockInterface).data : (contentBlock as EromBlockInterface).structure.data,
        style: (contentBlock as EromBlockInterface).style,
        hidden: (contentBlock as EromBlockInterface).hidden,
        settings: (contentBlock as EromBlockInterface).settings,
        schema: (contentBlock as EromBlockInterface).schema
      };
    } else {
      block = {
        id: contentBlock.id,
        style: (contentBlock as EromBlockGroupInterface).style,
        settings: (contentBlock as EromBlockGroupInterface).settings,
        schema: (contentBlock as EromBlockGroupInterface).schema,
        hidden: (contentBlock as EromBlockGroupInterface).hidden,
        items: []
      };
      for (let i = 0; i < (contentBlock as EromBlockGroupInterface).items.length; i++) {
        const item = {
          id: (contentBlock as EromBlockGroupInterface).items[i].id,
          type: (contentBlock as EromBlockGroupInterface).items[i].type,
          name: (contentBlock as EromBlockGroupInterface).items[i].name,
          data: !clear ? (contentBlock as EromBlockGroupInterface).items[i].data :
            (contentBlock as EromBlockGroupInterface).items[i].structure.data,
          style: (contentBlock as EromBlockGroupInterface).items[i].style,
          hidden: (contentBlock as EromBlockGroupInterface).items[i].hidden,
          settings: (contentBlock as EromBlockGroupInterface).items[i].settings,
          schema: (contentBlock as EromBlockGroupInterface).items[i].schema
        };
        block.items.push(item);
      }
    }
    return block;
  }

  private setupConnectedGroupsList(): void {
    this._connectedGroupList = this._contentGroups.map(x => `${this.groupIdPrefix + x.id}`);
  }

  private setupConnectedGridsList(): void {
    this._connectedGridList = [];
    for (let i = 0; i < this._contentGroups.length; i++) {
      for (let j = 0; j < this._contentGroups[i].grids.length; j++) {
        for (let k = 0; k < this._contentGroups[i].grids[j].columns.length; k++) {
          this._connectedGridList.push(`${this.gridIdPrefix + this._contentGroups[i].id}-${j}-${k}`);
          // for (let l = 0; l < this._contentGroups[i].grids[j].columns[k].length; l++) {
          //   this._connectedGridList.push(`${this.blockIdPrefix + this._contentGroups[i].grids[j].columns[k][l].id}`);
          // }
        }
      }
    }
  }

  private setupConnectedBlockGroupList(): void {
    this._connectedBlockGroupList = [];
    for (let i = 0; i < this._contentGroups.length; i++) {
      for (let j = 0; j < this._contentGroups[i].grids.length; j++) {
        for (let k = 0; k < this._contentGroups[i].grids[j].columns.length; k++) {
          // this._connectedBlockGroupList.push(`${this.gridIdPrefix + this._contentGroups[i].id}-${j}-${k}`);
          for (let l = 0; l < this._contentGroups[i].grids[j].columns[k].length; l++) {
            if ((this._contentGroups[i].grids[j].columns[k][l] as EromBlockGroupInterface).items) {
              this._connectedBlockGroupList.push(`${this.blockIdPrefix + this._contentGroups[i].grids[j].columns[k][l].id}`);
            }
          }
        }
      }
    }
  }

  public move(event, grid: (EromBlockInterface|EromBlockGroupInterface)[], block: EromBlockInterface, direction: string): void {
    event.preventDefault();
    const index = grid.indexOf(block);
    if (index === -1) {
      return;
    }
    switch (direction) {
      case 'up':
        moveItemInArray(grid, index, index - 1);
        break;
      case 'down':
        moveItemInArray(grid, index, index + 1);
        break;
      default:
        break;
    }
    this.changeDetectorRef.detectChanges();
    if ((block as EromBlockInterface).type && (block as EromBlockInterface).editableAreas) {
      this.contentScrollableArea.directiveRef
        .scrollToElement(`#${(block as EromBlockInterface).editableAreas[0].id}`, -100, 100);
    } else {
      this.contentScrollableArea.directiveRef
        .scrollToElement(`#${this.activeBlock}`, -100, 100);
    }
    if ((block as EromBlockGroupInterface).items && (block as EromBlockGroupInterface).items.length &&
      (block as EromBlockGroupInterface).items[0].editableAreas) {
      this.contentScrollableArea.directiveRef
        .scrollToElement(`#${(block as EromBlockGroupInterface).items[0].editableAreas[0].id}`, -100, 100);
    } else {
      this.contentScrollableArea.directiveRef
        .scrollToElement(`#${this.activeBlock}`, -100, 100);
    }
    this.editorChanged('move');
  }

  public moveGroup(group: EromGroupInterface, direction: string): void {
    const index = this._contentGroups.indexOf(group);
    if (index === -1) {
      return;
    }
    switch (direction) {
      case 'up':
        moveItemInArray(this._contentGroups, index, index - 1);
        break;
      case 'down':
        moveItemInArray(this._contentGroups, index, index + 1);
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
      this.activeArea = this.groupIdPrefix + group.id;
      this.contentScrollableArea.directiveRef.scrollToElement(`#${this.groupIdPrefix + group.id}`, -100, 100);
    }, 0);
    this.editorChanged('move');
  }

  public moveGrid(group: EromGroupInterface, grid: EromGroupRowInterface, gridIndex: number, direction: string): void {
    const index = group.grids.indexOf(grid);
    if (index === -1) {
      return;
    }
    switch (direction) {
      case 'up':
        moveItemInArray(group.grids, index, index - 1);
        gridIndex--;
        break;
      case 'down':
        moveItemInArray(group.grids, index, index + 1);
        gridIndex++;
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
      this.activeArea = this.groupIdPrefix + group.id;
      this.activeGrid = `${this.gridIdPrefix + group.id}-${gridIndex}`;
      this.contentScrollableArea.directiveRef.scrollToElement(`#${this.activeGrid}`, -100, 100);
    }, 0);
    this.editorChanged('move');
  }

  public dragStarted(deactivateActiveAreas: boolean = false): void {
    this._dragInProgress = true;
    if (deactivateActiveAreas) {
      this.disableAllActiveAreas();
    }
  }

  public dragEnded(): void {
    this._dragInProgress = false;
  }

  public dragEntered(level: string, groupId: string, columnId: number = null, blockId: string = null): void {
    switch (level) {
      case 'group':
        this.activeArea = this.groupIdPrefix + groupId;
        break;
      case 'grid':
        this.activeArea = this.groupIdPrefix + groupId;
        this.activeGrid = `${this.gridIdPrefix + groupId}-${columnId}`;
        break;
      case 'blockGroup':
        this.activeArea = this.groupIdPrefix + groupId;
        this.activeGrid = `${this.gridIdPrefix + groupId}-${columnId}`;
        this.activeBlock = null;
        this.activeBlockGroup = `${this.blockIdPrefix + blockId}`;
        break;
      default:
        break;
    }
  }

  public exitEditorBlocks(event, container): void {
    if (event.relatedTarget && !container.contains(event.relatedTarget)) {
      this.disableAllActiveAreas();
    }
  }

  public groupActionsCanBeDisplayed(groupId: string): boolean {
    if (this._dragInProgress || this.activeBlockGroup || this.activeBlock || this.activeGrid) {
      return false;
    }
    return this.activeArea === this.groupIdPrefix + groupId;
  }

  public gridActionsCanBeDisplayed(groupId: string, gridIndex: number): boolean {
    if (this._dragInProgress || this.activeBlockGroup || this.activeBlock) {
      return false;
    }
    return this.activeGrid === `${this.gridIdPrefix + groupId}-${gridIndex}`;
  }

  public blockGroupActionsCanBeDisplayed(groupId: string): boolean {
    if (this._dragInProgress || this.activeBlock) {
      return false;
    }
    return this.activeBlockGroup === `${this.blockIdPrefix + groupId}`;
  }

  public activateGroup(event, groupIdentifier: string): void {
    this.disableAllActiveAreas();
    this.activeArea = groupIdentifier;
    this.tabActivatePanel(0);
    this.changeDetectorRef.detectChanges();
  }

  public activateGrid(event, groupIdentifier: string, gridIdentifier: string): void {
    if (this.activeArea) {
      event.stopPropagation();
    }
    if (event.target.closest('.erom-editor__block')) {
      return;
    }
    this.disableAllActiveAreas();
    this.activeArea = groupIdentifier;
    this.activeGrid = gridIdentifier;
    this.tabActivatePanel(0);
    this.changeDetectorRef.detectChanges();
  }

  public activateBlockGroup(event, block: EromBlockInterface, gridIdentifier: string, groupIdentifier: string): void {
    if (this.activeGrid) {
      event.stopPropagation();
    }
    if (event.target.classList.contains('erom-editor__group-overlay')) {
      this.disableAllActiveAreas();
    }
    this.activeArea = groupIdentifier;
    this.activeGrid = gridIdentifier;
    this.activeBlockGroupObject = block;
    this.activeBlockGroup = `${this.blockIdPrefix + block.id}`;
    this.tabActivatePanel(0);
    this.changeDetectorRef.detectChanges();
  }

  public activateBlock(
    event: any,
    groupIdentifier: string,
    gridIdentifier: string,
    groupBlock: EromBlockInterface,
    block: EromBlockGroupInterface = null
  ): void {
    event.stopPropagation();
    if (this.activeBlockObject) {
      this.activeBlockObject.autoFocus = false;
    }
    groupBlock.autoFocus = true;
    this.activeBlockObject = groupBlock;
    this.activeBlock = `${this.blockIdPrefix + groupBlock.id}`;
    this.activeArea = groupIdentifier;
    this.activeGrid = gridIdentifier;

    if (block) {
      this.activeBlockGroup = `${this.blockIdPrefix + block.id}`;
      this.activeBlockGroupObject = block;
    }

    this.tabActivatePanel(0);
    this.changeDetectorRef.detectChanges();
  }

  public fakeClick(event): void {
    if (event.target.classList.contains('erom-editor-tabbable')) {
      event.target.click();
    }
  }

  public changeActiveBlockGroupStyle(event: any, property: string): void {
    if (!this.activeBlockGroupObject) {
      return;
    }
    if (!this.activeBlockGroupObject.style) {
      this.activeBlockGroupObject.style = ({} as CSSStyleDeclaration);
    }
    this.activeBlockGroupObject.style[property] = event.target.value;
    this.editorChanged('change');
  }

  public changeActiveGridStyle(event: any, property: string): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    if (!this.activeGrid) {
      return;
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return;
    }
    if (!grid.style) {
      grid.style = ({} as CSSStyleDeclaration);
    }
    grid.style[property] = event.target.value;
    this.editorChanged('change');
  }

  public get activeGridStyles(): CSSStyleDeclaration {
    if (!this.activeArea) {
      return ({} as CSSStyleDeclaration);
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return ({} as CSSStyleDeclaration);
    }
    if (!this.activeGrid) {
      return ({} as CSSStyleDeclaration);
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return ({} as CSSStyleDeclaration);
    }
    return grid.style || ({} as CSSStyleDeclaration);
  }

  public changeActiveBlockGroupClass(event: any): void {
    if (!this.activeBlockGroupObject) {
      return;
    }
    if (!this.activeBlockGroupObject.settings) {
      this.activeBlockGroupObject.settings = {};
    }
    this.activeBlockGroupObject.settings.class = event.target.value.toString().replace('.', '').split(' ');
    this.editorChanged('change');
  }

  public changeActiveBlockGroupAriaRole(event: any): void {
    if (!this.activeBlockGroupObject) {
      return;
    }
    if (!this.activeBlockGroupObject.settings) {
      this.activeBlockGroupObject.settings = {};
    }
    this.activeBlockGroupObject.settings.role = event.target.value.toString().replace('.', '').split(' ');
    this.editorChanged('change');
  }

  public changeActiveGridClass(event: any): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    if (!this.activeGrid) {
      return;
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return;
    }
    if (!grid.settings) {
      grid.settings = {};
    }
    grid.settings.class = event.target.value.toString().replace('.', '').split(' ');
    this.editorChanged('change');
  }

  public changeActiveGridAriaRole(event: any): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    if (!this.activeGrid) {
      return;
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return;
    }
    if (!grid.settings) {
      grid.settings = {};
    }
    grid.settings.role = event.target.value.toString().replace('.', '').split(' ');
    this.editorChanged('change');
  }

  public settingsClassValue(classes: any): string {
    if (!classes) {
      return '';
    }
    return classes.join(' ');
  }

  public get activeGridSettingsClassValue(): string {
    if (!this.activeArea) {
      return '';
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return '';
    }
    if (!this.activeGrid) {
      return '';
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return '';
    }
    return grid.settings && grid.settings.class ? grid.settings.class.join(' ') : '';
  }

  public get activeGridSettingsAriaRoleValue(): string {
    if (!this.activeArea) {
      return '';
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return '';
    }
    if (!this.activeGrid) {
      return '';
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return '';
    }
    return grid.settings && grid.settings.role ? (grid.settings.role || '') : '';
  }

  public activateToolbarItem(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    const expanded = event.target.getAttribute('aria-expanded') === 'true';
    event.target.setAttribute('aria-expanded', !expanded);
  }

  public changeGrid(event, grid: EromGroupRowInterface): void {
    const newGrid = event.option.value;
    const oldColumnsCount = grid.columns.length;
    const newColumnsCount = this.eromEditorGlobals.gridColumnsCountByGrid(newGrid);
    if (newColumnsCount < oldColumnsCount) {
      const orphanBlocks = [];
      for (let i = 0; i < (oldColumnsCount - newColumnsCount); i++) {
        orphanBlocks.unshift(grid.columns[grid.columns.length - (i + 1)]);
      }
      grid.columns.splice(newColumnsCount);
      grid.columns[grid.columns.length - 1] = grid.columns[grid.columns.length - 1].concat(...orphanBlocks);
    } else if (newColumnsCount > oldColumnsCount) {
      do {
        grid.columns.push([]);
      } while (grid.columns.length < newColumnsCount);
    }
    grid.grid = event.option.value;
    this.setupConnectedGroupsList();
    this.setupConnectedGridsList();
    this.setupConnectedBlockGroupList();
    this.editorChanged('change');
  }

  public uploadGridImage(event, grid: EromGroupRowInterface): void {
    const reader = new FileReader();
    reader.onload = () => {
      grid.backgroundImage = {
        url: reader.result.toString()
      };
    };
    reader.readAsDataURL(event.target.files[0]);
    this.disableAllActiveAreas();
    this.editorChanged('change');
  }

  public uploadGroupImage(event, group: EromBlockGroupInterface): void {
    const reader = new FileReader();
    reader.onload = () => {
      if (!group.style) {
        group.style = ({} as CSSStyleDeclaration);
      }
      group.style.backgroundImage = 'url(\'' + reader.result.toString() + '\')';
    };
    reader.readAsDataURL(event.target.files[0]);
    this.disableAllActiveAreas();
    this.editorChanged('change');
  }

  public changeGridBackgroundColor(event, grid: EromGroupRowInterface): void {
    grid.backgroundColor = event.target.value;
    this.disableAllActiveAreas();
    this.editorChanged('change');
  }

  public changeGroupBackgroundColor(event, group: EromBlockGroupInterface): void {
    if (!group.style) {
      group.style = ({} as CSSStyleDeclaration);
    }
    group.style.backgroundColor = event;
    this.disableAllActiveAreas();
    this.editorChanged('change');
  }

  public changeGridAlignment(grid: EromGroupRowInterface, axis: string, position: string): void {
    if (!grid.align) {
      grid.align = {};
    }
    switch (axis) {
      case 'y':
        grid.align.v = VerticalPosition[position];
        break;
      case 'x':
        grid.align.h = HorizontalPosition[position];
        break;
      default:
        break;
    }
    this.editorChanged('change');
  }

  public changeGroupAlignment(group: EromBlockGroupInterface, axis: string, position: string): void {
    if (!group.style) {
      group.style = ({} as CSSStyleDeclaration);
    }
    switch (axis) {
      case 'y':
        group.style.textAlign = position;
        break;
      default:
        break;
    }
    this.editorChanged('change');
  }

  public activateNonEditorArea(name: string): void {
    this.disableAllActiveAreas();
    this.activeArea = name;
  }

  public disableAllActiveAreas(): void {
    this.activeArea = null;
    this.activeGrid = null;
    this.activeBlock = null;
    this.activeBlockGroup = null;
    this.activeEditor = null;
    if (this.activeBlockObject) {
      this.activeBlockObject.autoFocus = false;
    }
    this.activeBlockObject = null;
    this.activeBlockGroupObject = null;
    this.tabActivatePanel(1);
    this.resetSectionSave();
    EromEditorComponent.blurActiveEditor();
  }

  public resetSectionSave(): void {
    this.sectionSaveAsExpanded = false;
    this.sectionLocked = 0;
    this.sectionName = '';
    this.sectionImage = null;
    this.sectionImageData = null;
    this.sectionTags = [];
  }

  public groupBlockAdded(event): void {
    if (this.formData.value.titleComponentAdded && event.block.type === BlockType.Title) {
      alert('Title block can be added only once!');
      return;
    }
    const blocks = [];
    if (event.block.structure.virtualComponents && event.block.structure.virtualComponents.length) {
      for (let i = 0; i < event.block.structure.virtualComponents.length; i++) {
        blocks.push({
          ...JSON.parse(JSON.stringify(event.block.structure.virtualComponents[i].structure)),
          id: uuid('erom-block'),
          autoFocus: i === 0
        });
      }
    }
    blocks.push({
      ...JSON.parse(JSON.stringify(event.block.structure)),
      id: uuid('erom-block'),
      autoFocus: true
    });
    const groupId = uuid('erom-group');
    this._contentGroups.push({
      id: groupId,
      grids: [
        {
          grid: GridType.ONE_FULL,
          columns: [
            blocks
          ]
        }
      ]
    });
    this.activeGrid = `${this.gridIdPrefix + groupId}-0`;
    this.activeArea = `${this.groupIdPrefix + groupId}`;
    this.editorChanged('created');
  }

  public groupSectionAdded(event): void {
    const body = JSON.parse(event.section.body);
    for (let i = 0; i < body.length; i++) {
      const groupId = uuid('erom-group');
      body[i].id = groupId;
      body[i].name = event.section.name;
      this._contentGroups.push(body[i]);
      this.activeArea = `${this.groupIdPrefix + groupId}`;
    }
    this.tabActivatePanel(0);
    this.editorChanged('created');
    setTimeout(() => {
      this.contentScrollableArea.directiveRef.scrollToElement(`#${this.activeArea}`, -100, 100);
      const scrollableArea = this.contentScrollableAreas.get(1);
      if (scrollableArea) {
        scrollableArea.directiveRef.scrollToTop(0, 100);
      }
    }, 100);
  }

  public gridSectionAdded(event): void {
    const body = JSON.parse(event.section.body);
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    let newGridsLength = 0;
    for (let i = 0; i < body.length; i++) {
      for (let j = 0; j < body[i].grids.length; j++) {
        body[i].grids[j].name = event.section.name;
        newGridsLength = group.grids.push(body[i].grids[j]);
      }
    }
    this.tabActivatePanel(0);
    this.editorChanged('created');
    setTimeout(() => {
      this.activeGrid = `${this.gridIdPrefix + group.id}-${newGridsLength - 1}`;
      this.contentScrollableArea.directiveRef.scrollToElement(`#${this.activeGrid}`, -100, 100);
      const scrollableArea = this.contentScrollableAreas.get(1);
      if (scrollableArea) {
        scrollableArea.directiveRef.scrollToTop(0, 100);
      }
    }, 100);
  }

  public gridBlockAdded(event, grid: EromGroupRowInterface[], groupId: string): void {
    if (this.formData.value.titleComponentAdded && event.block.type === BlockType.Title) {
      alert('Title block can be added only once!');
      return;
    }
    const blocks = [];
    if (event.block.structure.virtualComponents && event.block.structure.virtualComponents.length) {
      for (let i = 0; i < event.block.structure.virtualComponents.length; i++) {
        blocks.push({
          ...JSON.parse(JSON.stringify(event.block.structure.virtualComponents[i].structure)),
          id: uuid('erom-block'),
          autoFocus: i === 0
        });
      }
    }
    blocks.push({
      ...JSON.parse(JSON.stringify(event.block.structure)),
      id: uuid('erom-block'),
      autoFocus: true
    });
    const newGridsLength = grid.push({
      grid: GridType.ONE_FULL,
      columns: [
        blocks
      ]
    });
    this.activeGrid = `${this.gridIdPrefix + groupId}-${newGridsLength - 1}`;
    this.activeArea = `${this.groupIdPrefix + groupId}`;
    this.editorChanged('created');
  }

  public columnBlockAdded(event, grid: Array<EromBlockInterface|EromBlockGroupInterface>, groupId: string, gridIndex: number): void {
    if (this.formData.value.titleComponentAdded && event.block.type === BlockType.Title) {
      alert('Title block can be added only once!');
      return;
    }
    if (event.block.structure.virtualComponents && event.block.structure.virtualComponents.length) {
      for (let i = 0; i < event.block.structure.virtualComponents.length; i++) {
        grid.push({
          ...JSON.parse(JSON.stringify(event.block.structure.virtualComponents[i].structure)),
          id: uuid('erom-block'),
          autoFocus: i === 0
        });
      }
      return;
    }
    grid.push({
      ...JSON.parse(JSON.stringify(event.block.structure)),
      id: uuid('erom-block'),
      autoFocus: true
    });
    this.activeGrid = `${this.gridIdPrefix + groupId}-${gridIndex}`;
    this.activeArea = `${this.groupIdPrefix + groupId}`;
    this.editorChanged('created');
  }

  public blockFocused(event): void {
    console.log(event)
  }

  public changeBlockVisibility(block: EromBlockInterface): void {
    block.hidden = !block.hidden;
    this.editorChanged('change');
  }

  public changeGridVisibility(grid: EromGroupRowInterface): void {
    grid.hidden = !grid.hidden;
    this.editorChanged('change');
  }

  public changeGroupVisibility(group: EromGroupInterface): void {
    group.hidden = !group.hidden;
    this.editorChanged('change');
  }

  public removeBlock(grid: EromBlockInterface[], index: number): void {
    grid.splice(index, 1);
    this.activeBlock = null;
    this.activeBlockGroupObject = null;
    if (this.activeBlockObject) {
      this.activeBlockObject.autoFocus = false;
    }
    this.activeBlockObject = null;
    this.activeEditor = null;
    this.editorChanged('drop');
  }

  public removeGrid(
    grids: EromGroupRowInterface[],
    index: number
  ): void {
    grids.splice(index, 1);
    this.activeGrid = null;
    this.editorChanged('drop');
  }

  public removeGroup(index: number): void {
    this._contentGroups.splice(index, 1);
    this.activeArea = null;
    this.editorChanged('drop');
  }

  public saveGridAsTemplate(groupIndex: number, gridIndex: number): void {
    // setTimeout(() => {
    //   this.captureService.getImage(element, true).pipe(
    //     tap(img => {
    //       console.log(img);
    //     })
    //   ).subscribe();
    // }, 0);
    const data = {
      eventType: 'saveGroupAsTemplate',
      contentGroups: this.clearContentGroups(this._contentGroups, false, groupIndex, gridIndex),
      cleanContentGroups: this.clearContentGroups(this._contentGroups, true, groupIndex, gridIndex),
      additionalData: {
        locked: this.sectionLocked,
        name: this.sectionName,
        image: this.sectionImage,
        tags: this.sectionTags
      }
    };
    this.eromEditorActioned.emit(data);
    this.disableAllActiveAreas();
  }

  private saveGroupAsTemplate(groupIndex: number): void {
    // setTimeout(() => {
    //   this.captureService.getImage(element, true).pipe(
    //     tap(img => {
    //       console.log(img);
    //     })
    //   ).subscribe();
    // }, 0);
    const data = {
      eventType: 'saveGroupAsTemplate',
      contentGroups: this.clearContentGroups(this._contentGroups, false, groupIndex),
      cleanContentGroups: this.clearContentGroups(this._contentGroups, true, groupIndex),
      additionalData: {
        locked: this.sectionLocked,
        name: this.sectionName,
        image: this.sectionImage,
        tags: this.sectionTags
      }
    };
    this.eromEditorActioned.emit(data);
    this.disableAllActiveAreas();
  }

  public saveAsTemplateInit(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.tabActivatePanel(0);
    this.sectionSaveAsExpanded = true;
    setTimeout(() => {
      const scrollableArea = this.contentScrollableAreas.get(1);
      if (scrollableArea) {
        scrollableArea.directiveRef.scrollToElement('#saveAsSectionButtonTrigger', -100, 100);
      }
    }, 100);
  }

  public resetBlockOrGroupOfBlocks(block: EromBlockInterface|EromBlockGroupInterface): void {
    if ((block as EromBlockInterface).type) {
      this.resetBlock((block as EromBlockInterface));
    } else {
      for (let k = 0; k < (block as EromBlockGroupInterface).items.length; k++) {
        this.resetBlock((block as EromBlockGroupInterface).items[k]);
      }
    }
  }

  public resetBlock(block: EromBlockInterface): void {
    block.data = block.structure.data;
    if (block.editableAreas) {
      for (let i = 0; i < block.editableAreas.length; i++) {
        const editorObject = getTinymce().EditorManager.get(block.editableAreas[i].id);
        editorObject.setContent('');
      }
    }
    this.editorChanged('change');
  }

  public resetGrid(grid: EromGroupRowInterface): void {
    for (let i = 0; i < grid.columns.length; i++) {
      for (let j = 0; j < grid.columns[i].length; j++) {
        if ((grid.columns[i][j] as EromBlockInterface).type) {
          this.resetBlock((grid.columns[i][j] as EromBlockInterface));
        } else {
          for (let k = 0; k < (grid.columns[i][j] as EromBlockGroupInterface).items.length; k++) {
            this.resetBlock((grid.columns[i][j] as EromBlockGroupInterface).items[k]);
          }
        }
      }
    }
  }

  public resetGroup(group: EromGroupInterface): void {
    for (let i = 0; i < group.grids.length; i++) {
      this.resetGrid(group.grids[i]);
    }
  }

  public cloneBlock(
    grid: EromBlockInterface[],
    index: number
  ): void {
    const block = grid[index];
    if (this.activeEditor) {
      this.activeEditor.focus();
    }
    this.activeBlockObject.autoFocus = false;
    EromEditorComponent.blurActiveEditor();
    this.activeBlock = `${this.blockIdPrefix}virtual`;
    const newBlock = JSON.parse(JSON.stringify(block));
    newBlock.id = uuid('erom-block');
    newBlock.autoFocus = true;
    grid.splice(index + 1, 0, newBlock);
    this.editorChanged('clone');
  }

  public cloneGrid(
    group: EromGroupInterface,
    index: number
  ): void {
    const grid = group.grids[index];
    const newGrid = JSON.parse(JSON.stringify(grid));
    group.grids.splice(index, 0, newGrid);
    this.activeGrid = `${this.gridIdPrefix + group.id}-${index + 1}`;
    setTimeout(() => {
      this.contentScrollableArea.directiveRef.scrollToElement(`#${this.activeGrid}`, -100, 100);
    }, 0);
    this.editorChanged('clone');
  }

  public cloneGroup(index: number): void {
    const newGroup = JSON.parse(JSON.stringify(this._contentGroups[index]));
    newGroup.id = uuid('erom-group');
    this._contentGroups.splice(index, 0, newGroup);
    setTimeout(() => {
      this.contentScrollableArea.directiveRef.scrollToElement(`#${this.activeArea}`, -100, 100);
    }, 0);
    this.editorChanged('clone');
  }

  public editorButtonActioned(action: EromEditorActionInterface): void {
    const data = {
      eventType: action.action,
      contentGroups: this.clearContentGroups(this._contentGroups),
      cleanContentGroups: this.clearContentGroups(this._contentGroups, true)
    };
    this.eromEditorActioned.emit(data);
  }

  public get groupCanDrag(): boolean {
    return this._contentGroups.length > 1;
  }

  public canMove(parent: Array<any>, child: any, direction: string): boolean {
    let canMove = false;
    switch (direction) {
      case 'up':
        canMove = parent.indexOf(child) > 0;
        break;
      case 'down':
        canMove = parent.indexOf(child) < parent.length - 1;
        break;
      default:
        break;
    }
    return canMove;
  }

  public runCommand(event: any, command: string, isInput: boolean = false): void {
    if (!command || !this.activeEditor) {
      return;
    }
    if (isInput) {
      this.activeEditor.execCommand(command, false, event.target.value);
    } else {
      this.activeEditor.execCommand(command, false);
    }
    event.target.focus();
  }

  public runSelectCommand(event: any, command: string): void {
    if (!command || !this.activeEditor) {
      return;
    }
    this.activeEditor.execCommand(command, false, event.option.value);
  }

  public isState(command: string): boolean {
    let state = false;
    if (!command || !this.activeEditor) {
      return state;
    }
    try {
      state = this.activeEditor.queryCommandState(command);
    } catch (e) {
      state = false;
    }
    return state;
  }

  public isValue(command: string): boolean {
    let state = false;
    if (!command || !this.activeEditor) {
      return state;
    }
    try {
      state = this.activeEditor.queryCommandValue(command);
    } catch (e) {
      state = false;
    }
    if (!state) {
      if (!this.activeBlockObject.style) {
        return false;
      }
      state = this.activeBlockObject.style[command] || false;
    }
    return state;
  }

  public customChecked(command: string, options: BlockOptionsItemInterface[]): boolean|string {
    if (!this.activeBlockObject.style) {
      return false;
    }
    const currentValue = this.activeBlockObject.style[command] || false;
    const currentOption = options.find(o => o.value === currentValue);
    return !currentOption ? currentValue : false;
  }

  public accordionSetFocus(pos: number): void {
    this.accordionFocus = true;
    this.document.body.classList.add('keyboard-focus');
  }

  public accordionSetBlur(): void {
    this.accordionFocus = false;
    this.document.body.classList.remove('keyboard-focus');
  }

  public accordionOpenPanel(pos: number): void {
    this.accordionCurrentlyOpen = pos;
  }

  public accordionClosePanel(): void {
    this.accordionCurrentlyOpen = -1;
  }

  public setupTooltipPosition(event: any, element: HTMLElement): void {
    let topPosition = -9999;
    let leftPosition = -9999;
    const domRect = event.target.getBoundingClientRect();
    if (domRect) {
      topPosition = domRect.top + domRect.height + 5;
      leftPosition = domRect.left + (domRect.width / 2);
    }
    element.style.top = topPosition + 'px';
    element.style.left = leftPosition + 'px';
    setTimeout(() => {
      element.style.top = (topPosition - 5) + 'px';
      element.style.opacity = '1';
    }, 200);
  }

  public removeTooltipPosition(event: any, element: HTMLElement): void {
    element.style.top = '';
    element.style.left = '';
    element.style.opacity = '0';
  }

  public activeGroupChangeHidden(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    group.hidden = !group.hidden;
  }

  public activeGridChangeHidden(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    const grid = group.grids[parseInt(this.activeGrid.slice(-1), 10)];
    if (!grid) {
      return;
    }
    grid.hidden = !grid.hidden;
  }

  public resetActiveGroup(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    this.resetGroup(group);
  }

  public resetActiveGrid(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    if (!this.activeGrid) {
      return;
    }
    const grid = group.grids[this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '')];
    if (!grid) {
      return;
    }
    this.resetGrid(grid);
  }

  public duplicateActiveGroup(): void {
    if (!this.activeArea) {
      return;
    }
    const groupIndex = this._contentGroups.findIndex(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (groupIndex === -1) {
      return;
    }
    this.cloneGroup(groupIndex);
  }

  public duplicateActiveGrid(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    if (!this.activeGrid) {
      return;
    }
    const gridIndex = this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '');
    this.cloneGrid(group, parseInt(gridIndex, 10));
  }

  public savePageAsTemplate(): void {
    const data = {
      eventType: 'savePageAsTemplate',
      cleanContentGroups: this.clearContentGroups(this._contentGroups, true),
      additionalData: {
        locked: 0,
        name: this.sectionName,
        image: this.sectionImage,
        tags: this.sectionTags
      }
    };
    this.eromEditorActioned.emit(data);
    this.disableAllActiveAreas();
  }

  public saveActiveGroupAsTemplate(): void {
    if (!this.activeArea) {
      return;
    }
    const groupIndex = this._contentGroups.findIndex(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (groupIndex === -1) {
      return;
    }
    this.saveGroupAsTemplate(groupIndex);
  }

  public saveActiveGridAsTemplate(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    if (!this.activeGrid) {
      return;
    }
    const gridIndex = this.activeGrid.replace(`${this.gridIdPrefix + group.id}-`, '');
    this.saveGridAsTemplate(this._contentGroups.indexOf(group), parseInt(gridIndex, 10));
  }

  public deleteGroup(): void {
    if (!this.activeArea) {
      return;
    }
    const groupIndex = this._contentGroups.findIndex(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (groupIndex === -1) {
      return;
    }
    this._contentGroups.splice(groupIndex, 1);
    this.editorChanged('drop');
  }

  public deleteGrid(): void {
    if (!this.activeArea) {
      return;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return;
    }
    group.grids.splice(parseInt(this.activeGrid.slice(-1), 10), 1);
    this.editorChanged('drop');
  }

  public changeActiveAreaWidth(value: string): void {
    if (!this.activeArea) {
      return null;
    }
    const activeArea = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!activeArea) {
      return null;
    }
    activeArea.width = value;
    this.editorChanged('change');
  }

  public editorActioned(event: any): void {
    this.editorChanged('change');
  }

  public schemaUpdated(event: any): void {
    this.editorChanged('change');
  }

  public setupSectionData(event: any, property: string): void {
    switch (property) {
      case 'type':
        this.sectionLocked = event.target.checked ? parseInt(event.target.value, 10) : 0;
        break;
      case 'name':
        this.sectionName = event.target.value;
        break;
      case 'tags':
        this.sectionTags = event.tags || [];
        break;
      case 'image':
        this.sectionImage = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          this.sectionImageData = reader.result.toString();
          this.changeDetectorRef.detectChanges();
        };
        reader.readAsDataURL(this.sectionImage);
        break;
    }
  }

  public removeSectionImage(): void {
    this.sectionImage = null;
    this.sectionImageData = null;
  }

  public changeAlias(event: any): void {
    this._newAlias = event.target.value;
    if (this._pageData.alias !== this._newAlias) {
      this._aliasChanged = true;
    }
  }

  public saveAlias(event: any): void {
    event.stopPropagation();
    this.eromEditorActioned.emit({
      eventType: 'aliasSave',
      additionalData: {
        alias: this._newAlias
      }
    });
    this.activeArea = 'title';
  }

  public groupClasses(groupIdentifier: string|Array<string>): object {
    let groupClasses;
    if (Array.isArray(groupIdentifier)) {
      groupClasses = {
        active: groupIdentifier.indexOf(this.activeArea) > -1,
        disabled: (this.activeArea !== null && this.activeArea !== undefined) && groupIdentifier.indexOf(this.activeArea) === -1
      };
    } else {
      groupClasses = {
        active: this.activeArea === groupIdentifier,
        disabled: (this.activeArea !== null && this.activeArea !== undefined) && this.activeArea !== groupIdentifier
      };
    }
    return groupClasses;
  }

  public gridClasses(gridIdentifier: string, grid: EromGroupRowInterface): object {
    const classes = {
      active: this.activeGrid === gridIdentifier,
      disabled: this.activeGrid && this.activeGrid !== gridIdentifier
    };
    if (grid.settings && grid.settings.class && grid.settings.class.length) {
      for (let i = 0; i < grid.settings.class.length; i++) {
        classes[grid.settings.class[i]] = true;
      }
    }
    return classes;
  }

  public blockClasses(blockIdentifier: string): object {
    return {
      active: this.activeBlock === blockIdentifier,
      disabled: this.activeBlock && this.activeBlock !== blockIdentifier
    };
  }

  public get f(): any {
    return this.formData.controls;
  }

  public get activeGroupHidden(): boolean {
    if (!this.activeArea) {
      return false;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return false;
    }
    return group.hidden;
  }

  public get activeAreaWidth(): string {
    if (!this.activeArea) {
      return null;
    }
    const activeArea = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!activeArea) {
      return null;
    }
    return activeArea.width;
  }

  public get activeGridHidden(): boolean {
    if (!this.activeArea) {
      return null;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return false;
    }
    const grid = group.grids[parseInt(this.activeGrid.slice(-1), 10)];
    if (!grid) {
      return false;
    }
    return grid.hidden;
  }

  public get activatedTab(): number {
    return this._activatedTab;
  }

  public get editorHeight(): number {
    const breadcrumbs: HTMLElement = this.document.querySelector('.breadcrumbs');
    let editorHeight = this._windowHeight;
    if (breadcrumbs) {
      editorHeight -= breadcrumbs.offsetHeight;
    }
    return editorHeight;
  }

  public get pageHeight(): number {
    return this._windowHeight;
  }

  public get contentGroups(): EromGroupInterface[] {
    return this._contentGroups;
  }

  public get connectedGroupList(): Array<string> {
    return this._connectedGroupList;
  }

  public get connectedGridList(): Array<string> {
    return this._connectedGridList;
  }

  public get connectedBlockGroupList(): Array<string> {
    return [...this._connectedBlockGroupList, ...this._connectedGridList];
  }

  public get gridOptions(): Array<any> {
    return this.eromEditorGlobals.gridOptions;
  }

  public get blocks(): GenericBlockInterface[] {
    return this._blocks;
  }

  public get pageData(): EromEditorDataInterface {
    return this._pageData;
  }

  public get toolbarData(): any {
    return this._toolbarData;
  }

  public get pageTemplates(): SelectItemInterface[] {
    return this._pageTemplates;
  }

  public get activeEntity(): EromBlockInterface|EromBlockGroupInterface|EromGroupRowInterface|EromGroupInterface {
    if (this.activeBlockObject) {
      return this.activeBlockObject;
    } else if (this.activeBlockGroupObject) {
      return this.activeBlockGroupObject;
    } else {
      if (!this.activeArea) {
        return null;
      }
      const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
      if (this.activeGrid) {
        if (!group) {
          return null;
        }
        const grid = group.grids[parseInt(this.activeGrid.slice(-1), 10)];
        if (!grid) {
          return null;
        }
        return grid;
      } else {
        return group;
      }
    }
    return null;
  }

  public get tagsSplitRegex(): RegExp {
    return this._tagsSplitRegex;
  }

  public get activeAreaName(): string {
    if (!this.activeArea) {
      return null;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    return group && group.name ? group.name : null;
  }

  public get activeSectionName(): string {
    if (!this.activeArea) {
      return null;
    }
    const group = this._contentGroups.find(g => g.id === this.activeArea.replace(this.groupIdPrefix, ''));
    if (!group) {
      return null;
    }
    const grid = group.grids[parseInt(this.activeGrid.slice(-1), 10)];
    return grid && grid.name ? grid.name : null;
  }

  public get aliasChanged(): boolean {
    return this._aliasChanged;
  }

  public get origin(): string {
    return `${location.origin}/`;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this._windowHeight = window.innerHeight;
  }

  @HostListener('click', ['$event'])
  onDocumentClick(event): void {
    if (event.target.classList.contains('savePublished') || event.target.classList.contains('saveDraft')
      || (event.target.classList.contains('action-button') && !event.target.classList.contains('erom-editor__save-as--button'))) {
      this.contentScrollableArea.directiveRef.scrollToTop();
    }
    // const activeAreaElement: HTMLElement = this.activeArea ? this.document.getElementById(this.activeArea) : null;
    // const activeBlockElement: HTMLElement = this.activeBlock ? this.document.getElementById(this.activeBlock) : null;
    // if (activeAreaElement && (!activeAreaElement.contains(event.target) && activeAreaElement.previousElementSibling !== event.target)) {
    //   this.disableAllActiveAreas();
    // }
    // if (activeBlockElement && !activeBlockElement.contains(event.target)) {
    //   this.blurActiveEditor();
    // }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent): void {
    if (this.activeBlock) {
      EromEditorComponent.blurActiveEditor();
      if (this.activeBlockObject) {
        this.activeBlockObject.autoFocus = false;
      }
      this.activeBlock = null;
      this.activeBlockObject = null;
      this.activeEditor = null;
    } else if (this.activeBlockGroup) {
      this.activeBlockGroup = null;
    } else if (this.activeGrid) {
      this.activeGrid = null;
    } else if (this.activeArea) {
      this.activeArea = null;
      this.tabActivatePanel(1);
    }
  }
}
