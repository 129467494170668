import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from '../../_store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { UserInterface } from '../../_interfaces';
import { convertDateTime, markAsTouched } from '../../_helpers/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  PasswordValidation,
  RepeatPasswordValidator
} from '../../_validators';
import { AuthenticationService } from '../../_services/authentication.service';
import { Location } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-user-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private _id: string;
  private _loading: boolean;
  private _submitting: boolean;
  private _archiving: boolean;
  private _user: UserInterface;
  private _activeSection: string;
  private _profileRoute: boolean;

  public accountFormData: FormGroup;
  public passwordFormData: FormGroup;

  public showPassword: boolean;
  public showNewPassword: boolean;
  public showRetypePassword: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: API,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {
    this._user = ({} as UserInterface);
  }

  public ngOnInit(): void {
    const data = this.route.snapshot.data;
    this._profileRoute = data && data.hasOwnProperty('profile') ? data.profile : false;
    this.route.paramMap.subscribe(params => {
      this._id = !this._profileRoute ? params.get('id') : this.authenticationService.id;
      this.getEntity();
      this.accountFormData = this.formBuilder.group({
        name: ['', Validators.required],
        surname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]]
      });
      this.passwordFormData = this.formBuilder.group({
        oldPassword: [null, !this.isRoot || this.isOwnProfile ? Validators.required : null],
        password: [null, PasswordValidation],
        retypePassword: [null, PasswordValidation]
      }, { validator: RepeatPasswordValidator });
    });
  }

  public ngOnDestroy(): void {
  }

  private getEntity(): void {
    this._loading = true;
    this.http.get(`${this.api.URLS.user}/${this._id}`).subscribe((user: UserInterface) => {
      this._loading = false;
      this._submitting = false;
      this._archiving = false;
      this._activeSection = null;
      this._user = {
        ...user,
        createdOn: convertDateTime(user.createdOn.toString(), 'd m Y', false, '.'),
        modifiedOn: user.modifiedOn ? convertDateTime(user.modifiedOn.toString(), 'd m Y', true, '.', ', ') : null
      };
      this.synchronizeFields();
      this.maybeOwnProfile();
    }, (error) => {
      console.log(error);
      this._loading = false;
    });
  }

  private maybeOwnProfile(): void {
    if (this.isOwnProfile && !this._profileRoute) {
      this.location.replaceState('/profile');
    }
  }

  private synchronizeFields(): void {
    this.accountFormData.controls.name.setValue(this._user.name);
    this.accountFormData.controls.surname.setValue(this._user.surname);
    this.accountFormData.controls.email.setValue(this._user.email);
  }

  public saveAccountData(): void {
    markAsTouched(this.accountFormData);
    if (this._submitting || this.accountFormData.invalid) {
      return;
    }
    this._submitting = true;
    const data = {
      id: this._id,
      email: this.accountFormData.value.email,
      phone: this._user.phone || '',
      locale: this._user.locale || 'en',
      role: this._user.roleId,
      meta: {
        name: this.accountFormData.value.name,
        surname: this.accountFormData.value.surname
      }
    };
    this.http.post(`${this.api.URLS.user}`, data).subscribe(() => {
      this.getEntity();
    }, (error) => {
      console.log(error);
      this._submitting = false;
    });
  }

  public discardChanges(): void {
    this._activeSection = null;
    this.accountFormData.reset();
    this.passwordFormData.reset();
    this.synchronizeFields();
  }

  public saveAccountPassword(): void {
    markAsTouched(this.passwordFormData);
    if (this._submitting || this.passwordFormData.invalid) {
      return;
    }
    this._submitting = true;
    const data = {
      id: this._id,
      newPassword: this.passwordFormData.value.password
    };
    if (!this.isRoot || this.isOwnProfile) {
      Object.assign(data, {
        oldPassword: this.passwordFormData.value.oldPassword
      });
    }
    this.http.post(`${this.api.URLS.user}/change-password`, data).subscribe(() => {
      this.getEntity();
    }, (error) => {
      console.log(error);
      this._submitting = false;
    });
  }

  public archiveEntity(): void {
    if (this._archiving) {
      return;
    }
    this._archiving = true;
    const data = {
      id: this._id
    };
    this.http.post(`${this.api.URLS.user}/archive`, data).subscribe(() => {
      this.getEntity();
    }, (error) => {
      console.log(error);
      this._archiving = false;
    });
  }

  public restoreEntity(): void {
    if (this._archiving) {
      return;
    }
    this._archiving = true;
    const data = {
      id: this._id
    };
    this.http.post(`${this.api.URLS.user}/restore`, data).subscribe(() => {
      this.getEntity();
    }, (error) => {
      console.log(error);
      this._archiving = false;
    });
  }

  public activateSection(name: string): void {
    this._activeSection = name;
  }

  public get userName(): string {
    return this._user && (this._user.name || this._user.surname) ? [this._user.name, this._user.surname].map(u => u).join(' ') : 'profile.USER_PROFILE';
  }

  public get user(): UserInterface {
    return this._user;
  }

  public get activeSection(): string {
    return this._activeSection;
  }

  public get submitting(): boolean {
    return this._submitting;
  }

  public get archiving(): boolean {
    return this._archiving;
  }

  public get isOwnProfile(): boolean {
    return this._id === this.authenticationService.id;
  }

  public get isRoot(): boolean {
    return this.authenticationService.isRoot;
  }

}
