const setCookie = (name, value, expire = null) => {
  const d = new Date();
  let expires;
  if (expire) {
    if (expire === -1) {
      expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
    } else {
      d.setTime(d.getTime() + (parseInt(expire, 10) * 24 * 60 * 60 * 1000));
      expires = 'expires=' + d.toUTCString();
    }
  } else {
    expires = 'expires=';
  }
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

const getCookie = (name) => {
  name = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};

export {
  setCookie,
  getCookie
};
