<a href="#" (click)="skipToContent($event)" class="skip-to-content">Skip to content</a>
<div id="abilityHeaderCookieBar" class="cookie-bar" *ngIf="!cookieAccepted">
  <div class="erom-editor__content-container">
    <div class="cookie-bar__icon">
      <svg x="0px" y="0px" viewBox="0 0 15.001 15" style="enable-background:new 0 0 15.001 15;" xml:space="preserve">
        <g>
          <path fill="currentColor" d="M14.966,7.5c0.104,0.834-0.021,1.615-0.375,2.344l-1.031,2c-0.375,0.771-0.948,1.344-1.719,1.719l-2,1.031
            C9.236,14.864,8.653,15,8.091,15c-0.292,0-0.5-0.021-0.625-0.062l-2.25-0.344c-0.812-0.125-1.521-0.49-2.125-1.094l-1.594-1.594
            c-0.604-0.604-0.969-1.312-1.094-2.125l-0.344-2.25C-0.087,6.74,0.038,5.948,0.434,5.156l1-2c0.375-0.771,0.947-1.344,1.719-1.719
            l2-1.031C5.757,0.136,6.341,0,6.903,0c0.271,0,0.469,0.011,0.594,0.031c0.021,1.042,0.396,1.922,1.125,2.641
            C9.35,3.391,10.225,3.75,11.247,3.75c0,1.021,0.359,1.896,1.078,2.625C13.044,7.104,13.924,7.479,14.966,7.5z M13.684,9.375
            c0.146-0.312,0.239-0.646,0.281-1c-0.917-0.208-1.709-0.65-2.375-1.328c-0.667-0.677-1.084-1.474-1.25-2.391
            C9.424,4.49,8.627,4.073,7.95,3.406C7.272,2.74,6.83,1.948,6.622,1.031c-0.354,0.042-0.688,0.136-1,0.281L3.591,2.344
            c-0.542,0.292-0.959,0.709-1.25,1.25L1.309,5.625C1.017,6.167,0.924,6.75,1.028,7.375l0.344,2.25
            c0.146,0.667,0.416,1.188,0.812,1.562l1.625,1.625c0.416,0.438,0.938,0.708,1.563,0.812l2.25,0.344C7.725,13.989,7.882,14,8.091,14
            c0.458,0,0.885-0.104,1.281-0.312l2.031-1.031c0.541-0.291,0.958-0.708,1.25-1.25L13.684,9.375z M5.028,4.969
            C5.174,5.115,5.247,5.292,5.247,5.5c0,0.209-0.073,0.386-0.219,0.531C4.882,6.178,4.705,6.25,4.497,6.25
            c-0.209,0-0.386-0.072-0.531-0.219C3.819,5.886,3.747,5.709,3.747,5.5c0-0.208,0.072-0.385,0.219-0.531
            C4.111,4.823,4.288,4.75,4.497,4.75C4.705,4.75,4.882,4.823,5.028,4.969z M4.966,9.969C5.111,9.823,5.288,9.75,5.497,9.75
            c0.208,0,0.385,0.073,0.531,0.219c0.146,0.146,0.219,0.323,0.219,0.531c0,0.209-0.073,0.386-0.219,0.531
            c-0.146,0.146-0.323,0.219-0.531,0.219c-0.209,0-0.386-0.072-0.531-0.219c-0.146-0.146-0.219-0.322-0.219-0.531
            C4.747,10.292,4.819,10.115,4.966,9.969z M7.966,6.969C8.111,6.823,8.288,6.75,8.497,6.75c0.208,0,0.385,0.073,0.531,0.219
            C9.174,7.115,9.247,7.292,9.247,7.5c0,0.209-0.073,0.386-0.219,0.531C8.882,8.178,8.705,8.25,8.497,8.25
            c-0.209,0-0.386-0.072-0.531-0.219C7.819,7.886,7.747,7.709,7.747,7.5C7.747,7.292,7.819,7.115,7.966,6.969z M9.966,9.969
            c0.146-0.146,0.322-0.219,0.531-0.219c0.208,0,0.385,0.073,0.531,0.219c0.146,0.146,0.219,0.323,0.219,0.531
            c0,0.209-0.073,0.386-0.219,0.531c-0.146,0.146-0.323,0.219-0.531,0.219c-0.209,0-0.386-0.072-0.531-0.219
            c-0.146-0.146-0.219-0.322-0.219-0.531C9.747,10.292,9.819,10.115,9.966,9.969z"/>
        </g>
      </svg>
    </div>
    <div class="cookie-bar__text--container">
      <div class="cookie-bar__text" [innerHTML]="'front.COOKIE_BAR_CONTENT' | translate"></div>
      <button class="cookie-bar__action" (click)="closeCookies(true)">{{ 'front.COOKIE_ACCEPT_BTN' | translate }}</button>
    </div>
  </div>
  <button class="cookie-bar__close" aria-label="Close cookie bar" (click)="closeCookies(false)">
    <svg x="0px" y="0px" xml:space="preserve">
      <g>
        <path fill="currentColor" d="M9.781,8.719C9.927,8.865,10,9.042,10,9.25c0,0.209-0.073,0.386-0.219,0.531C9.635,9.928,9.458,10,9.25,10
            c-0.209,0-0.386-0.072-0.531-0.219L5,6.062L1.281,9.781C1.135,9.928,0.958,10,0.75,10c-0.209,0-0.386-0.072-0.531-0.219
            C0.072,9.636,0,9.459,0,9.25c0-0.208,0.072-0.385,0.219-0.531L3.938,5L0.219,1.281C0.072,1.136,0,0.958,0,0.75
            s0.072-0.386,0.219-0.531C0.364,0.073,0.541,0,0.75,0c0.208,0,0.385,0.073,0.531,0.219L5,3.938l3.719-3.719
            C8.864,0.073,9.041,0,9.25,0c0.208,0,0.385,0.073,0.531,0.219C9.927,0.364,10,0.542,10,0.75S9.927,1.136,9.781,1.281L6.062,5
            L9.781,8.719z"/>
      </g>
    </svg>
  </button>
</div>
<noscript>
  <div style="padding: 0; max-width: 1200px; margin:0 auto;">
    <header style="height: 100px; margin: 20px; width: auto;">
      <div style="height: 100%;">
        <div style="display: flex; justify-content: space-between; vertical-align: middle; align-items: center; height: 100%; box-sizing: border-box;" role="banner" aria-label="My Computer My Way header">
          <a style="" href="https://mcmw.abilitynet.org.uk/no-js" title="My Computer My Way - No Script Homepage">
            <svg width="12rem" height="auto" x="0px" y="0px" viewBox="0 0 152.993 59.002" style="enable-background:new 0 0 152.993 59.002; width: 12rem; height: auto;"
                 xml:space="preserve">
                    <g transform="translate(-96.478 -304.501)">
                      <path fill="#393837" d="M117.599,334.996l-0.02-8.536l-4.268,7.032h-1.512l-4.246-6.852v8.356h-3.149V320.76h2.776
                        l5.427,8.848l5.345-8.848h2.755l0.037,14.236H117.599z M135.021,324.06l-5.034,11.607c-0.36,1.054-1.021,1.979-1.9,2.663
                        c-0.807,0.531-1.758,0.801-2.724,0.773c-0.583,0-1.163-0.089-1.719-0.264c-0.508-0.145-0.981-0.395-1.388-0.732l1.188-2.257
                        c0.254,0.22,0.545,0.392,0.86,0.508c0.307,0.119,0.634,0.181,0.963,0.183c0.384,0.018,0.763-0.093,1.077-0.315
                        c0.329-0.284,0.584-0.643,0.743-1.047l0.041-0.1l-4.826-11.019h3.335l3.128,7.421l3.149-7.421H135.021z M149.376,335.24
                        c-1.393,0.02-2.77-0.305-4.008-0.945c-1.162-0.6-2.136-1.507-2.817-2.623c-0.691-1.143-1.046-2.457-1.026-3.792
                        c-0.02-1.335,0.335-2.649,1.025-3.792c0.681-1.116,1.655-2.023,2.817-2.623c1.245-0.642,2.629-0.967,4.029-0.945
                        c1.168-0.017,2.328,0.204,3.408,0.651c0.988,0.414,1.864,1.055,2.558,1.871l-2.153,1.951c-0.903-1.076-2.242-1.688-3.646-1.667
                        c-0.836-0.014-1.662,0.185-2.4,0.579c-0.69,0.373-1.261,0.933-1.647,1.616c-0.401,0.72-0.604,1.534-0.59,2.358
                        c-0.014,0.824,0.189,1.638,0.59,2.358c0.386,0.683,0.957,1.243,1.647,1.616c0.738,0.394,1.564,0.593,2.4,0.579
                        c1.408,0.019,2.749-0.602,3.646-1.688l2.154,1.952c-0.691,0.829-1.573,1.479-2.569,1.894
                        C151.71,335.036,150.547,335.257,149.376,335.24z M162.303,335.16c-1.096,0.018-2.18-0.23-3.159-0.723
                        c-0.9-0.451-1.656-1.144-2.185-2c-0.531-0.876-0.803-1.883-0.787-2.907c-0.016-1.024,0.256-2.031,0.787-2.907
                        c0.529-0.856,1.285-1.549,2.185-2c0.979-0.492,2.063-0.74,3.159-0.722c1.093-0.019,2.174,0.229,3.149,0.722
                        c0.896,0.453,1.648,1.146,2.175,2c0.531,0.876,0.803,1.883,0.787,2.907c0.016,1.024-0.256,2.031-0.787,2.907
                        c-0.527,0.854-1.279,1.547-2.175,2C164.476,334.93,163.395,335.179,162.303,335.16z M162.303,332.56
                        c0.766,0.026,1.506-0.273,2.04-0.823c0.552-0.598,0.84-1.393,0.8-2.206c0.04-0.813-0.248-1.608-0.8-2.206
                        c-0.534-0.55-1.274-0.849-2.04-0.823c-0.769-0.024-1.512,0.274-2.051,0.823c-0.556,0.595-0.847,1.389-0.808,2.202
                        c-0.04,0.814,0.251,1.61,0.808,2.206C160.79,332.283,161.533,332.583,162.303,332.56z M184.844,323.895
                        c1.226-0.063,2.426,0.374,3.325,1.21c0.889,0.99,1.335,2.302,1.233,3.629v6.262h-3.236v-5.774c0.053-0.692-0.141-1.38-0.549-1.942
                        c-0.397-0.44-0.972-0.676-1.564-0.64c-0.676-0.033-1.334,0.231-1.8,0.722c-0.484,0.606-0.72,1.372-0.663,2.145v5.489h-3.233v-5.774
                        c0-1.721-0.704-2.582-2.113-2.582c-0.671-0.031-1.321,0.233-1.781,0.722c-0.484,0.606-0.72,1.372-0.663,2.145v5.489h-3.232V324.06
                        h3.086v1.261c0.419-0.466,0.941-0.829,1.523-1.061c0.629-0.249,1.301-0.374,1.978-0.366c0.737-0.011,1.466,0.145,2.134,0.457
                        c0.624,0.295,1.154,0.756,1.533,1.332c0.461-0.574,1.055-1.027,1.73-1.322C183.274,324.044,184.055,323.886,184.844,323.895z
                         M198.889,323.895c0.987-0.013,1.961,0.228,2.828,0.7c0.839,0.457,1.532,1.14,2,1.972c0.498,0.905,0.748,1.925,0.725,2.958
                        c0.023,1.033-0.227,2.053-0.725,2.958c-0.468,0.832-1.161,1.515-2,1.972c-0.867,0.472-1.841,0.713-2.828,0.7
                        c-1.258,0.068-2.483-0.413-3.359-1.318v5.1h-3.232V324.06h3.087v1.261c0.886-0.987,2.176-1.513,3.5-1.426H198.889z M198.326,332.56
                        c0.766,0.026,1.506-0.273,2.04-0.823c0.552-0.598,0.84-1.393,0.8-2.206c0.04-0.813-0.248-1.608-0.8-2.206
                        c-0.534-0.55-1.274-0.849-2.04-0.823c-0.766-0.026-1.506,0.273-2.04,0.823c-0.552,0.598-0.84,1.393-0.8,2.206
                        c-0.04,0.813,0.248,1.608,0.8,2.206C196.819,332.287,197.56,332.586,198.326,332.56z M217.819,324.06v10.936h-3.066v-1.3
                        c-0.424,0.471-0.948,0.843-1.533,1.087c-0.61,0.254-1.265,0.383-1.926,0.377c-1.279,0.067-2.532-0.379-3.48-1.24
                        c-0.92-0.996-1.385-2.328-1.284-3.68v-6.18h3.232v5.713c0,1.762,0.753,2.643,2.258,2.643c0.699,0.03,1.377-0.24,1.864-0.742
                        c0.51-0.617,0.761-1.408,0.7-2.206v-5.408H217.819z M228.011,334.468c-0.353,0.246-0.75,0.422-1.17,0.518
                        c-0.478,0.117-0.968,0.175-1.46,0.173c-1.115,0.07-2.215-0.287-3.076-1c-0.769-0.773-1.165-1.841-1.088-2.928v-4.489h-1.719v-2.44
                        h1.719v-2.663h3.227v2.663h2.776v2.44h-2.776v4.453c-0.025,0.39,0.106,0.773,0.363,1.067c0.274,0.263,0.646,0.4,1.025,0.376
                        c0.468,0.014,0.928-0.13,1.305-0.407L228.011,334.468z M240.399,329.568c0,0.041-0.021,0.325-0.062,0.854h-8.431
                        c0.137,0.655,0.523,1.231,1.077,1.606c0.626,0.411,1.365,0.617,2.113,0.59c0.525,0.009,1.048-0.077,1.543-0.254
                        c0.467-0.18,0.893-0.452,1.253-0.8l1.719,1.83c-1.197,1.239-2.88,1.885-4.598,1.766c-1.153,0.023-2.295-0.224-3.335-0.722
                        c-0.921-0.44-1.697-1.134-2.237-2c-0.531-0.876-0.803-1.883-0.787-2.907c-0.014-1.02,0.255-2.024,0.777-2.9
                        c0.512-0.855,1.251-1.552,2.134-2.013c0.936-0.489,1.979-0.737,3.035-0.722c1.029-0.015,2.046,0.223,2.962,0.691
                        c0.87,0.447,1.592,1.135,2.082,1.982C240.161,327.482,240.421,328.519,240.399,329.568L240.399,329.568z M234.619,326.295
                        c-0.668-0.022-1.321,0.194-1.844,0.61c-0.504,0.42-0.83,1.016-0.911,1.667h5.49c-0.086-0.647-0.411-1.238-0.911-1.657
                        c-0.513-0.42-1.161-0.64-1.824-0.621V326.295z M245.639,325.502c0.399-0.538,0.941-0.954,1.564-1.2
                        c0.722-0.283,1.493-0.422,2.268-0.407v2.928c-0.359-0.027-0.601-0.041-0.725-0.041c-0.798-0.046-1.58,0.229-2.175,0.762
                        c-0.567,0.621-0.851,1.449-0.787,2.287v5.165h-3.232V324.06h3.092L245.639,325.502z M117.599,359.394l-0.02-8.534l-4.268,7.03
                        h-1.512l-4.246-6.852v8.356h-3.149V345.16h2.776l5.427,8.844l5.345-8.844h2.755l0.037,14.234H117.599z M135.021,348.46
                        l-5.034,11.605c-0.36,1.054-1.021,1.979-1.9,2.663c-0.807,0.531-1.758,0.801-2.724,0.773c-0.583,0-1.163-0.089-1.719-0.264
                        c-0.508-0.145-0.981-0.395-1.388-0.732l1.188-2.257c0.254,0.22,0.545,0.392,0.86,0.508c0.307,0.119,0.634,0.181,0.963,0.183
                        c0.384,0.017,0.763-0.093,1.077-0.315c0.33-0.283,0.586-0.643,0.746-1.047l0.041-0.1l-4.829-11.017h3.335l3.128,7.421l3.149-7.421
                        H135.021z M164.353,345.16l-4.743,14.234h-3.6l-3.195-9.634l-3.294,9.637h-3.583l-4.765-14.237h3.48l3.273,10l3.418-10h3.107
                        l3.314,10.085l3.379-10.085H164.353z M169.653,348.291c1.429-0.095,2.843,0.335,3.977,1.21c0.984,0.949,1.493,2.287,1.388,3.65
                        v6.242h-3.021v-1.361c-0.765,1.097-2.072,1.683-3.4,1.525c-0.764,0.018-1.524-0.127-2.227-0.427
                        c-0.583-0.247-1.083-0.656-1.44-1.179c-0.334-0.506-0.508-1.101-0.5-1.708c-0.035-0.945,0.404-1.846,1.17-2.4
                        c1.083-0.664,2.348-0.969,3.615-0.874h2.569c0.039-0.603-0.197-1.191-0.642-1.6c-0.554-0.413-1.238-0.611-1.927-0.559
                        c-0.594,0-1.184,0.092-1.75,0.274c-0.527,0.159-1.021,0.41-1.46,0.742l-1.16-2.216c0.668-0.447,1.406-0.777,2.185-0.976
                        c0.856-0.23,1.739-0.345,2.626-0.341L169.653,348.291z M169.404,357.42c0.515,0.01,1.024-0.12,1.471-0.376
                        c0.426-0.249,0.749-0.642,0.911-1.108v-1.116h-2.212c-1.326,0-1.989,0.427-1.989,1.281c-0.012,0.384,0.172,0.747,0.487,0.966
                        c0.396,0.257,0.864,0.382,1.336,0.355L169.404,357.42z M188.797,348.46l-5.034,11.605c-0.36,1.054-1.021,1.979-1.9,2.663
                        c-0.807,0.531-1.758,0.801-2.724,0.773c-0.583,0-1.163-0.089-1.719-0.264c-0.508-0.145-0.981-0.395-1.388-0.732l1.181-2.257
                        c0.254,0.22,0.545,0.392,0.86,0.508c0.307,0.119,0.634,0.181,0.963,0.183c0.384,0.017,0.763-0.093,1.077-0.315
                        c0.33-0.283,0.586-0.643,0.746-1.047l0.041-0.1l-4.822-11.017h3.335l3.131,7.417l3.146-7.417H188.797z"/>
                      <path fill="#005C6E" d="M181.08,314.207c-9.646-5.886-24.824-9.686-41.908-9.706l0,0h-0.134
                        c-17.083,0.02-32.262,3.82-41.908,9.706c-0.65,0.407-0.847,1.264-0.439,1.915c0.255,0.407,0.702,0.653,1.181,0.651
                        c0.19,0,0.377-0.039,0.551-0.116c10.33-4.587,24.739-7.436,40.682-7.445c15.943,0.008,30.351,2.857,40.681,7.445
                        c0.174,0.077,0.362,0.116,0.552,0.116c0.767,0.002,1.391-0.617,1.393-1.385C181.733,314.909,181.486,314.462,181.08,314.207z"/>
                    </g>
                  </svg>
          </a>
        </div>
      </div>
    </header>
    <div style="font-family: Montserrat, sans-serif; color: #2C2A29; margin: 50px 20px 70px 20px; width: auto;">
      <h2 class="erom-editor-exclude-toc" style="font-size: 36px;font-weight: 600; margin: 0 0 10px;">This website works best with Javascript.</h2>
      <p style="font-size: 20px;font-weight: 400; margin: 0;">If you are unable to turn on JavaScript, please use <a href="https://mcmw.abilitynet.org.uk/no-js/redirect.php" style="text-decoration: underline;">this site</a>.</p>
    </div>
    <aside aria-label="Need some more help?" style="width: auto; font-family: Montserrat, sans-serif; color: #2C2A29; background-color: #00A490; padding: 10px 30px; margin: 0 20px;">
      <h3 class="erom-editor-exclude-toc" style="font-size: 26px;font-weight: 600; color: #ffffff; margin: 20px 0 10px;">Need some more help?</h3>
      <p style="font-size: 20px; margin: 0 0 20px; line-height: 20px;">Call our free helpline <a style="color: #2C2A29; text-decoration: underline;" href="tel:0800269545">0800 269 545</a> or send us an email at <a style="color: #2C2A29; text-decoration: underline;" href="mailto:enquiries@abilitynet.org.uk?Subject=I%20would%20like%20to%20receive%20free%20support%20at%20home">enquiries@abilitynet.org.uk</a></p>
    </aside>
  </div>
</noscript>
<div id="abilityHeaderContainer"></div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<div id="abilityFooterContainer"></div>
