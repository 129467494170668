import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-article-feedback',
  templateUrl: './article-feedback.component.html',
  styleUrls: ['./article-feedback.component.scss']
})
export class ArticleFeedbackComponent implements OnInit, OnDestroy {

  private _alias: string;
  private _surveyMonkeyFeedback: any;

  readonly _thumbsUpDownLink: string;

  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._thumbsUpDownLink = 'https://www.surveymonkey.co.uk/r/abilitynet-mycomputermyway';
    route.params.subscribe(val => {
      this._alias = this.route.snapshot.paramMap.get('alias');
      if (isPlatformBrowser(this.platformId)) {
        this._surveyMonkeyFeedback = localStorage.getItem(`${this._alias}-feedback`);
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  private ratingSubmit(rating: number, ariaLive: HTMLElement) {
    let choiceLabel;
    switch (rating) {
      case 1:
        choiceLabel = 'thumbs_down';
        break;
      case 2:
        choiceLabel = 'thumbs_up';
        break;
      default:
        break;
    }
    this._surveyMonkeyFeedback = rating;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(`${this._alias}-feedback`, rating.toString());
    }
    // ariaLive.setAttribute('aria-live', 'assertive');
    setTimeout(() => {
      const feedbackLink = this.document.getElementById('feedbackLink');
      if (feedbackLink) {
        feedbackLink.focus();
      }
    }, 0);
  }

  public get ratingGiven(): boolean|number {
    if (!this._surveyMonkeyFeedback) {
      return false;
    }
    return parseInt(this._surveyMonkeyFeedback);
  }

  public get thumbsUpDownLink(): string {
    const ratingText = this.ratingGiven && this.ratingGiven === 2 ? 'Yes' : 'No';
    return `${this._thumbsUpDownLink}?rating=${ratingText}&page=${isPlatformBrowser(this.platformId) ? window.location.href : ''}`;
  }

}
