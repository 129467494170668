<div class="erom-article-header">
  <div class="erom-article-header__container">
    <ng-template [ngTemplateOutlet]="logo"></ng-template>
    <div #ctaContainer class="erom-article-header--phone-cta" [style.top.px]="ctaTopPosition">
      <ng-template [ngTemplateOutlet]="logo"></ng-template>
      <div class="erom-article-header--phone-cta__content" role="region" aria-labelledby="callHelplineHeader">
        <h2 class="erom-editor-exclude-toc" id="callHelplineHeader">Call our free helpline</h2>
        <a href="tel:+448000487642" aria-label="Click to call" (click)="gaEvent('helpButtons', '08000487642')">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-rotary" class="svg-inline--fa fa-phone-rotary fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M370.43 192.25A64 64 0 0 0 314.86 160H197.14a64 64 0 0 0-55.57 32.25L36.22 376.62A32 32 0 0 0 32 392.5V448a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32v-55.5a32 32 0 0 0-4.22-15.88zM256 384a72 72 0 1 1 72-72 72 72 0 0 1-72 72zm250.18-261C436.76 65 347.38 32 256 32S75.24 65 5.82 123A16.45 16.45 0 0 0 0 135.64V192a16 16 0 0 0 16 16h70.11a16 16 0 0 0 14.31-8.85L128 128c39.9-17.28 83.2-24 128-24 44.77 0 88.07 6.72 128 24l27.58 71.15a16 16 0 0 0 14.31 8.85H496a16 16 0 0 0 16-16v-56.36a16.45 16.45 0 0 0-5.82-12.64z"></path>
          </svg>
          <span>0800 048 7642</span>
        </a>
      </div>
      <span class="erom-article-header--title">{{ editorData.title }}</span>
    </div>
  </div>
</div>
<erom-editor-preview *ngIf="pageReady"
                     [editorData]="editorData"
                     (eromEditorBlocksReady)="blocksReady()"></erom-editor-preview>
<div class="erom-editor__content-container">
  <div class="article-read-next">
    <ng-container *ngIf="nextArticle && nextArticle.alias && nextArticle.title">
      <h2 class="erom-editor-exclude-toc" (click)="goToArticle(nextArticle.alias)">{{ 'front.READ_NEXT' | translate }}</h2>
      <div>
        <a [routerLink]="['/', nextArticle.alias]" (click)="readNext()">
          <span>{{ nextArticle.title }}</span>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path>
          </svg>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<div class="erom-editor__content-row grid_100">
  <app-call-to-action></app-call-to-action>
</div>
<ng-template #logo>
  <div class="erom-article-header--logo">
    <a routerLink="/" aria-label="My Computer My Way Home" class="erom-article-header--logo__link">
      <svg x="0px" y="0px" viewBox="0 0 152.993 59.002" style="enable-background:new 0 0 152.993 59.002;" xml:space="preserve">
          <g transform="translate(-96.478 -304.501)">
            <path fill="#393837" d="M117.599,334.996l-0.02-8.536l-4.268,7.032h-1.512l-4.246-6.852v8.356h-3.149V320.76h2.776
              l5.427,8.848l5.345-8.848h2.755l0.037,14.236H117.599z M135.021,324.06l-5.034,11.607c-0.36,1.054-1.021,1.979-1.9,2.663
              c-0.807,0.531-1.758,0.801-2.724,0.773c-0.583,0-1.163-0.089-1.719-0.264c-0.508-0.145-0.981-0.395-1.388-0.732l1.188-2.257
              c0.254,0.22,0.545,0.392,0.86,0.508c0.307,0.119,0.634,0.181,0.963,0.183c0.384,0.018,0.763-0.093,1.077-0.315
              c0.329-0.284,0.584-0.643,0.743-1.047l0.041-0.1l-4.826-11.019h3.335l3.128,7.421l3.149-7.421H135.021z M149.376,335.24
              c-1.393,0.02-2.77-0.305-4.008-0.945c-1.162-0.6-2.136-1.507-2.817-2.623c-0.691-1.143-1.046-2.457-1.026-3.792
              c-0.02-1.335,0.335-2.649,1.025-3.792c0.681-1.116,1.655-2.023,2.817-2.623c1.245-0.642,2.629-0.967,4.029-0.945
              c1.168-0.017,2.328,0.204,3.408,0.651c0.988,0.414,1.864,1.055,2.558,1.871l-2.153,1.951c-0.903-1.076-2.242-1.688-3.646-1.667
              c-0.836-0.014-1.662,0.185-2.4,0.579c-0.69,0.373-1.261,0.933-1.647,1.616c-0.401,0.72-0.604,1.534-0.59,2.358
              c-0.014,0.824,0.189,1.638,0.59,2.358c0.386,0.683,0.957,1.243,1.647,1.616c0.738,0.394,1.564,0.593,2.4,0.579
              c1.408,0.019,2.749-0.602,3.646-1.688l2.154,1.952c-0.691,0.829-1.573,1.479-2.569,1.894
              C151.71,335.036,150.547,335.257,149.376,335.24z M162.303,335.16c-1.096,0.018-2.18-0.23-3.159-0.723
              c-0.9-0.451-1.656-1.144-2.185-2c-0.531-0.876-0.803-1.883-0.787-2.907c-0.016-1.024,0.256-2.031,0.787-2.907
              c0.529-0.856,1.285-1.549,2.185-2c0.979-0.492,2.063-0.74,3.159-0.722c1.093-0.019,2.174,0.229,3.149,0.722
              c0.896,0.453,1.648,1.146,2.175,2c0.531,0.876,0.803,1.883,0.787,2.907c0.016,1.024-0.256,2.031-0.787,2.907
              c-0.527,0.854-1.279,1.547-2.175,2C164.476,334.93,163.395,335.179,162.303,335.16z M162.303,332.56
              c0.766,0.026,1.506-0.273,2.04-0.823c0.552-0.598,0.84-1.393,0.8-2.206c0.04-0.813-0.248-1.608-0.8-2.206
              c-0.534-0.55-1.274-0.849-2.04-0.823c-0.769-0.024-1.512,0.274-2.051,0.823c-0.556,0.595-0.847,1.389-0.808,2.202
              c-0.04,0.814,0.251,1.61,0.808,2.206C160.79,332.283,161.533,332.583,162.303,332.56z M184.844,323.895
              c1.226-0.063,2.426,0.374,3.325,1.21c0.889,0.99,1.335,2.302,1.233,3.629v6.262h-3.236v-5.774c0.053-0.692-0.141-1.38-0.549-1.942
              c-0.397-0.44-0.972-0.676-1.564-0.64c-0.676-0.033-1.334,0.231-1.8,0.722c-0.484,0.606-0.72,1.372-0.663,2.145v5.489h-3.233v-5.774
              c0-1.721-0.704-2.582-2.113-2.582c-0.671-0.031-1.321,0.233-1.781,0.722c-0.484,0.606-0.72,1.372-0.663,2.145v5.489h-3.232V324.06
              h3.086v1.261c0.419-0.466,0.941-0.829,1.523-1.061c0.629-0.249,1.301-0.374,1.978-0.366c0.737-0.011,1.466,0.145,2.134,0.457
              c0.624,0.295,1.154,0.756,1.533,1.332c0.461-0.574,1.055-1.027,1.73-1.322C183.274,324.044,184.055,323.886,184.844,323.895z
               M198.889,323.895c0.987-0.013,1.961,0.228,2.828,0.7c0.839,0.457,1.532,1.14,2,1.972c0.498,0.905,0.748,1.925,0.725,2.958
              c0.023,1.033-0.227,2.053-0.725,2.958c-0.468,0.832-1.161,1.515-2,1.972c-0.867,0.472-1.841,0.713-2.828,0.7
              c-1.258,0.068-2.483-0.413-3.359-1.318v5.1h-3.232V324.06h3.087v1.261c0.886-0.987,2.176-1.513,3.5-1.426H198.889z M198.326,332.56
              c0.766,0.026,1.506-0.273,2.04-0.823c0.552-0.598,0.84-1.393,0.8-2.206c0.04-0.813-0.248-1.608-0.8-2.206
              c-0.534-0.55-1.274-0.849-2.04-0.823c-0.766-0.026-1.506,0.273-2.04,0.823c-0.552,0.598-0.84,1.393-0.8,2.206
              c-0.04,0.813,0.248,1.608,0.8,2.206C196.819,332.287,197.56,332.586,198.326,332.56z M217.819,324.06v10.936h-3.066v-1.3
              c-0.424,0.471-0.948,0.843-1.533,1.087c-0.61,0.254-1.265,0.383-1.926,0.377c-1.279,0.067-2.532-0.379-3.48-1.24
              c-0.92-0.996-1.385-2.328-1.284-3.68v-6.18h3.232v5.713c0,1.762,0.753,2.643,2.258,2.643c0.699,0.03,1.377-0.24,1.864-0.742
              c0.51-0.617,0.761-1.408,0.7-2.206v-5.408H217.819z M228.011,334.468c-0.353,0.246-0.75,0.422-1.17,0.518
              c-0.478,0.117-0.968,0.175-1.46,0.173c-1.115,0.07-2.215-0.287-3.076-1c-0.769-0.773-1.165-1.841-1.088-2.928v-4.489h-1.719v-2.44
              h1.719v-2.663h3.227v2.663h2.776v2.44h-2.776v4.453c-0.025,0.39,0.106,0.773,0.363,1.067c0.274,0.263,0.646,0.4,1.025,0.376
              c0.468,0.014,0.928-0.13,1.305-0.407L228.011,334.468z M240.399,329.568c0,0.041-0.021,0.325-0.062,0.854h-8.431
              c0.137,0.655,0.523,1.231,1.077,1.606c0.626,0.411,1.365,0.617,2.113,0.59c0.525,0.009,1.048-0.077,1.543-0.254
              c0.467-0.18,0.893-0.452,1.253-0.8l1.719,1.83c-1.197,1.239-2.88,1.885-4.598,1.766c-1.153,0.023-2.295-0.224-3.335-0.722
              c-0.921-0.44-1.697-1.134-2.237-2c-0.531-0.876-0.803-1.883-0.787-2.907c-0.014-1.02,0.255-2.024,0.777-2.9
              c0.512-0.855,1.251-1.552,2.134-2.013c0.936-0.489,1.979-0.737,3.035-0.722c1.029-0.015,2.046,0.223,2.962,0.691
              c0.87,0.447,1.592,1.135,2.082,1.982C240.161,327.482,240.421,328.519,240.399,329.568L240.399,329.568z M234.619,326.295
              c-0.668-0.022-1.321,0.194-1.844,0.61c-0.504,0.42-0.83,1.016-0.911,1.667h5.49c-0.086-0.647-0.411-1.238-0.911-1.657
              c-0.513-0.42-1.161-0.64-1.824-0.621V326.295z M245.639,325.502c0.399-0.538,0.941-0.954,1.564-1.2
              c0.722-0.283,1.493-0.422,2.268-0.407v2.928c-0.359-0.027-0.601-0.041-0.725-0.041c-0.798-0.046-1.58,0.229-2.175,0.762
              c-0.567,0.621-0.851,1.449-0.787,2.287v5.165h-3.232V324.06h3.092L245.639,325.502z M117.599,359.394l-0.02-8.534l-4.268,7.03
              h-1.512l-4.246-6.852v8.356h-3.149V345.16h2.776l5.427,8.844l5.345-8.844h2.755l0.037,14.234H117.599z M135.021,348.46
              l-5.034,11.605c-0.36,1.054-1.021,1.979-1.9,2.663c-0.807,0.531-1.758,0.801-2.724,0.773c-0.583,0-1.163-0.089-1.719-0.264
              c-0.508-0.145-0.981-0.395-1.388-0.732l1.188-2.257c0.254,0.22,0.545,0.392,0.86,0.508c0.307,0.119,0.634,0.181,0.963,0.183
              c0.384,0.017,0.763-0.093,1.077-0.315c0.33-0.283,0.586-0.643,0.746-1.047l0.041-0.1l-4.829-11.017h3.335l3.128,7.421l3.149-7.421
              H135.021z M164.353,345.16l-4.743,14.234h-3.6l-3.195-9.634l-3.294,9.637h-3.583l-4.765-14.237h3.48l3.273,10l3.418-10h3.107
              l3.314,10.085l3.379-10.085H164.353z M169.653,348.291c1.429-0.095,2.843,0.335,3.977,1.21c0.984,0.949,1.493,2.287,1.388,3.65
              v6.242h-3.021v-1.361c-0.765,1.097-2.072,1.683-3.4,1.525c-0.764,0.018-1.524-0.127-2.227-0.427
              c-0.583-0.247-1.083-0.656-1.44-1.179c-0.334-0.506-0.508-1.101-0.5-1.708c-0.035-0.945,0.404-1.846,1.17-2.4
              c1.083-0.664,2.348-0.969,3.615-0.874h2.569c0.039-0.603-0.197-1.191-0.642-1.6c-0.554-0.413-1.238-0.611-1.927-0.559
              c-0.594,0-1.184,0.092-1.75,0.274c-0.527,0.159-1.021,0.41-1.46,0.742l-1.16-2.216c0.668-0.447,1.406-0.777,2.185-0.976
              c0.856-0.23,1.739-0.345,2.626-0.341L169.653,348.291z M169.404,357.42c0.515,0.01,1.024-0.12,1.471-0.376
              c0.426-0.249,0.749-0.642,0.911-1.108v-1.116h-2.212c-1.326,0-1.989,0.427-1.989,1.281c-0.012,0.384,0.172,0.747,0.487,0.966
              c0.396,0.257,0.864,0.382,1.336,0.355L169.404,357.42z M188.797,348.46l-5.034,11.605c-0.36,1.054-1.021,1.979-1.9,2.663
              c-0.807,0.531-1.758,0.801-2.724,0.773c-0.583,0-1.163-0.089-1.719-0.264c-0.508-0.145-0.981-0.395-1.388-0.732l1.181-2.257
              c0.254,0.22,0.545,0.392,0.86,0.508c0.307,0.119,0.634,0.181,0.963,0.183c0.384,0.017,0.763-0.093,1.077-0.315
              c0.33-0.283,0.586-0.643,0.746-1.047l0.041-0.1l-4.822-11.017h3.335l3.131,7.417l3.146-7.417H188.797z"/>
            <path fill="#005C6E" d="M181.08,314.207c-9.646-5.886-24.824-9.686-41.908-9.706l0,0h-0.134
              c-17.083,0.02-32.262,3.82-41.908,9.706c-0.65,0.407-0.847,1.264-0.439,1.915c0.255,0.407,0.702,0.653,1.181,0.651
              c0.19,0,0.377-0.039,0.551-0.116c10.33-4.587,24.739-7.436,40.682-7.445c15.943,0.008,30.351,2.857,40.681,7.445
              c0.174,0.077,0.362,0.116,0.552,0.116c0.767,0.002,1.391-0.617,1.393-1.385C181.733,314.909,181.486,314.462,181.08,314.207z"/>
          </g>
        </svg>
    </a>
  </div>
</ng-template>
