import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../../../src/app/_services/google-analytics.service';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent implements OnInit {

  constructor(
    private ga: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}

  public gaEvent(category: string, action: string): void {
    this.ga.event(category, action);
  }

}
