import { Component, ElementRef } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';

@Component({
  selector: 'code-block',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  styles: [':host { display: block; }']
})

export class CodeComponent extends GenericBlock {

  public static type = BlockType.Code;
  public static tagNames = ['PRE'];
  public static blockCategory = 'Text';
  public static blockName = 'Code';
  public static structure = {
    id: null,
    type: BlockType.Code,
    data: {
      text: ''
    }
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="code" class="svg-inline--fa fa-code fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"></path></svg>';

  public data: string;

  constructor(
    private elementRef: ElementRef
  ) {
    super();
  }

  public init(): void {
    if (this.block.autoFocus) {
      this.elementRef.nativeElement.querySelector('code').click();
    }
    this.data = this.block.data.text;
  }

  public contentChanged(event): void {
    this.block.data.text = event.target.innerHTML;
  }

  public get type(): string {
    return CodeComponent.type;
  }

  public get tagNames(): Array<string> {
    return CodeComponent.tagNames;
  }

  public get blockName(): string {
    return CodeComponent.blockName;
  }

  public get blockCategory(): string {
    return CodeComponent.blockCategory;
  }

  public get icon(): string {
    return CodeComponent.icon;
  }

  public get structure(): object {
    return CodeComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
