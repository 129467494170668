import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { BLOCKS_COMPONENTS_MAP } from './';
import { GenericBlock } from './generic-block';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ContainerRefDirective } from '../_directives';
import { EromBlockInterface, EromEditorDataInterface } from '../_interfaces';

@AutoUnsubscribe()
@Component({
  selector: 'erom-block',
  templateUrl: './block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EromBlockComponent implements OnDestroy, AfterViewInit {

  @ViewChild(ContainerRefDirective, {read: ContainerRefDirective, static: true}) public blockHost: ContainerRefDirective;

  @Input() public block: EromBlockInterface;
  @Input() public editMode: boolean;
  @Input() public pageData: EromEditorDataInterface;

  @Output() eromEditorBlockReady = new EventEmitter<EromBlockInterface>();
  @Output() eromEditorBlockChanged = new EventEmitter<any>();

  constructor(
    private _elementRef: ElementRef,
    private resolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {}

  public ngAfterViewInit(): void {
    this.loadComponent().then(() => {
      this.eromEditorBlockReady.emit(this.block);
    });
  }

  public get blockTypeClass(): string {
    if (!this.block.type) {
      return '';
    }
    return this.block.type.toString().split(/(?=[A-Z])/).join('_').toLowerCase() + '_type';
  }

  private async loadComponent(): Promise<void> {

    if (!this.block) {
      return;
    }

    if (!BLOCKS_COMPONENTS_MAP[this.block.type]) {
      return;
    }

    const componentFactory = this.resolver.resolveComponentFactory(BLOCKS_COMPONENTS_MAP[this.block.type]);

    const viewContainerRef = this.blockHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const component = componentRef.instance as GenericBlock;

    component.eromEditorBlockChanged.subscribe((e) => {
      this.eromEditorBlockChanged.emit(e);
    });

    component.block = this.block;
    component.pageData = this.pageData;
    component.editMode = this.editMode;
    this.changeDetectorRef.detectChanges();
    component.init();
  }

}
