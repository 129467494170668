import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SharedModule } from '../../../../src/app/app.module';
import { ArticleFeedbackComponent } from './temporary-blocks/article-feedback/article-feedback.component';
import { ArticleShareComponent } from './temporary-blocks/article-share/article-share.component';
import { ArticleFiltersComponent } from './temporary-blocks/article-filters/article-filters.component';
import { PageComponent } from './page/page.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    ArticleFeedbackComponent,
    ArticleShareComponent,
    ArticleFiltersComponent,
    PageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule.forRoot()
  ],
  providers: [
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    // private translateService: TranslateService
  ) {
    // translateService.use('en');
  }
}
