<div class="mce-content-body erom-block__tags-categories immutable-block" [class.mce-edit-focus]="block.autoFocus">
  <div class="erom-block__tags-categories__header" [class.active]="active" *ngIf="filters">
    <span>{{ 'front.CREATED_DATE' | translate : { date: dateFormat(pageData.createdOn) } }}</span>
    <button type="button"
            [attr.aria-expanded]="active"
            [attr.aria-controls]="block.id + '-container'"
            (click)="activateFilters()">
      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
      </svg>
      <span *ngIf="active">{{ 'front.HIDE_FILTERS' | translate }}</span>
      <span *ngIf="!active">{{ 'front.SHOW_FILTERS' | translate }}</span>
    </button>
  </div>
  <div class="erom-block__tags-categories__container"
       role="region"
       [attr.aria-hidden]="!active"
       [class.erom-visually-hidden]="!active"
       [id]="block.id + '-container'">
    <div class="erom-block__tags-categories__filters" *ngIf="filters">
      <dl class="erom-block__tags-categories__filter">
        <div class="erom-block__tags-categories__filter--row" *ngFor="let filter of filters | keyvalue">
          <dt>{{ filter.key }}:</dt>
          <dd *ngFor="let tag of filter.value; let i = index">
            <a href="#" (click)="goToPage($event, tag)">{{ tag.name }}</a>
            <span *ngIf="i < filter.value.length - 1" aria-hidden="true">|</span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
