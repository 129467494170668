import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class API {

  readonly token: string;
  readonly headersAuthSettings: object;
  readonly URLS;

  readonly _abilityURL: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.token = isPlatformBrowser(platformId) ? localStorage.getItem('token') : null;
    this.headersAuthSettings = {
      headers: this.token ? { Authorization: `Bearer ${this.token}` } : ''
    };
    // this._abilityURL = location.hostname === 'mcmw.abilitynet.org.uk' ? 'https://abilitynet.org.uk' : 'https://dev.abilitynet.org.uk';
    this._abilityURL = 'https://abilitynet.org.uk';
    this.URLS = {
      userLogin: '/api/v1/auth/accessToken',
      refreshToken: '/api/v1/auth/refreshAccessToken',
      checkActivateToken: '/api/v1/user/activate-token-check',
      checkResetToken: '/api/v1/user/reset-token-check',
      activateAccount: '/api/v1/user/activate',
      resetPassword: '/api/v1/user/reset-password',
      passwordRecover: '/api/v1/user/forgot-password',
      getUser: '/api/v1/auth/accessToken',
      user: '/api/v1/user',
      page: '/api/v1/page',
      role: '/api/v1/role',
      rule: '/api/v1/rule',
      setting: '/api/v1/setting',
      tag: '/api/v1/tag',
      redirect: '/api/v1/redirect',
      section: '/api/v1/section',
      file: '/api/v1/file'
    };
  }

  public get abilityURL(): string {
    return this._abilityURL;
  }

}
