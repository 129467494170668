import { Component } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { SelectItemInterface } from '../../../../erom-select/_interfaces';

@Component({
  selector: 'video-block',
  templateUrl: './video.component.html',
  styleUrls: ['../../editor/erom-editor.component.scss', './video.component.scss'],
  styles: [':host { display: block; }']
})

export class VideoComponent extends GenericBlock {

  public static type = BlockType.Video;
  public static tagNames = undefined;
  public static blockCategory = 'Media';
  public static blockName = 'Video';
  public static structure = {
    id: null,
    type: BlockType.Video,
    data: {
      id: '',
      title: '',
      provider: '',
      html: ''
    }
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="play-circle" class="svg-inline--fa fa-play-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"></path></svg>';

  private readonly _providers: SelectItemInterface[];

  constructor() {
    super();

    this._providers = [
      {
        label: 'YouTube',
        value: 'youtube'
      },
      {
        label: 'Vimeo',
        value: 'vimeo'
      }
    ];
  }

  public init(): void {

  }

  private getYoutubeVideoInfo(id: string, callback): any {
    const url = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`;
    fetch(url).then((response) => {
      return response.json();
    }).then((data) => {
      callback(data);
    }).catch(e => {
      callback(null);
    });
  }

  private getVimeoVideoInfo(id: string, callback): any {
    const url = `https://vimeo.com/api/v2/video/${id}.json`;
    fetch(url).then((response) => {
      return response.json();
    }).then((data) => {
      callback(data[0]);
    }).catch(e => {
      callback(null);
    });
  }

  private maybeVideoTitleCanBeFound(id: string = null): void {
    id = id || this.block.data.id;
    if (!this.block || !this.block.data || !id || !this.block.data.provider) {
      return;
    }
    if (this.block.data.provider === 'youtube') {
      this.getYoutubeVideoInfo(id, (response) => {
        if (response) {
          this.block.data.title = response.title;
        }
      });
    } else if (this.block.data.provider === 'vimeo') {
      this.getVimeoVideoInfo(id, (response) => {
        if (response) {
          this.block.data.title = response.title;
        }
      });
    }
  }

  private generatedHtml(): string {
    let html = '';
    if (!this.block || !this.block.data || !this.block.data.provider || !this.block.data.id) {
      return html;
    }
    if (this.block.data.provider === 'youtube') {
      html = '<iframe src="//www.youtube.com/embed/' + this.block.data.id + '" title="' + this.block.data.title + '" frameborder="0" allowfullscreen></iframe>';
    } else if (this.block.data.provider === 'vimeo') {
      html = '<iframe src="https://player.vimeo.com/video/' + this.block.data.id + '" title="' + this.block.data.title + '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
    }
    return html;
  }

  public updateVideo(event: any, property: string): void {
    if (property === 'id' && this.block.data.id && this.block.data.id !== event.target.value) {
      this.maybeVideoTitleCanBeFound(event.target.value);
    }
    this.block.data[property] = event.target.value;
    this.block.data.html = this.generatedHtml();
  }

  public providerChanged(event: any): void {
    this.block.data.provider = event.option.value;
  }

  public get type(): string {
    return VideoComponent.type;
  }

  public get tagNames(): Array<string> {
    return VideoComponent.tagNames;
  }

  public get blockName(): string {
    return VideoComponent.blockName;
  }

  public get blockCategory(): string {
    return VideoComponent.blockCategory;
  }

  public get icon(): string {
    return VideoComponent.icon;
  }

  public get structure(): object {
    return VideoComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

  public get providers(): SelectItemInterface[] {
    return this._providers;
  }

}
