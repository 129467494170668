import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { slugify } from '../../_common';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'toc-block',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss'],
  styles: [':host { display: block; }']
})

export class TableOfContentsComponent extends GenericBlock {

  public static type = BlockType.TableOfContents;
  public static tagNames = undefined;
  public static blockCategory = 'Immutable';
  public static blockName = 'TOC';
  public static structure = {
    id: null,
    type: BlockType.TableOfContents,
    data: {
      title: 'Table of contents'
    }
  };

  public static icon = '<svg xmlns="http://www.w3.org/2000/svg" width="30.25" height="20.532" viewBox="0 0 30.25 20.532"> <g transform="translate(-1180.5 -406.469)"><path class="a" fill="currentColor" d="M3.75,21.75h-3A.751.751,0,0,1,0,21V18a.751.751,0,0,1,.75-.75h3A.751.751,0,0,1,4.5,18v3A.751.751,0,0,1,3.75,21.75ZM15,20.625H8.625a1.125,1.125,0,0,1,0-2.25H15v2.249ZM3.75,14.25h-3A.751.751,0,0,1,0,13.5v-3A.751.751,0,0,1,.75,9.75h3a.751.751,0,0,1,.75.751v3A.751.751,0,0,1,3.75,14.25ZM18,13.125H8.625a1.125,1.125,0,0,1,0-2.25H18v2.249ZM3.75,6.75h-3A.751.751,0,0,1,0,6V3a.751.751,0,0,1,.75-.75h3A.751.751,0,0,1,4.5,3V6A.751.751,0,0,1,3.75,6.75ZM22.875,5.625H8.625a1.125,1.125,0,1,1,0-2.25H22.875a1.125,1.125,0,1,1,0,2.25Z" transform="translate(1181 404.719)"/><path class="b" fill="currentColor" d="M4.333-3.924a.656.656,0,0,0-.18-.724l-7.437-6.563a.659.659,0,0,0-.7-.106.656.656,0,0,0-.386.6V-.656a.657.657,0,0,0,.43.616A.661.661,0,0,0-3.719,0a.655.655,0,0,0,.5-.231l2.051-2.413L.107.033A1.094,1.094,0,0,0,1.564.55h0A1.094,1.094,0,0,0,2.082-.908L.847-3.5H3.719A.656.656,0,0,0,4.333-3.924Z" transform="translate(1206.375 426.344)"/></g></svg>';

  public list: string;

  public hasHeadings: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.hasHeadings = true;
  }

  private generateTOC(): void {
    const list = this.document.querySelectorAll('h2,h3,h4,h5,h6');
    const cleanList = Array.from(list).filter(i => !i.classList.contains('erom-editor-exclude-toc'));
    const toc = this.document.createElement('div');
    if (!cleanList || !cleanList.length || !toc) {
      this.list = '';
      this.hasHeadings = false;
      this.changeDetectorRef.detectChanges();
      return;
    }
    const getIndent = (count) => {
      let html = '';
      for (let i = 0; i < count; i++) {
        html += '<ul>';
      }
      return html;
    };
    const getOutdent = (count) => {
      let html = '';
      for (let i = 0; i < count; i++) {
        html += '</ul></li>';
      }
      return html;
    };
    const getStartingHTML = (diff, index) => {
      if (diff > 0) {
        return getIndent(diff);
      }
      if (diff < 0) {
        return getOutdent(Math.abs(diff));
      }
      if (index && !diff) {
        return '</li>';
      }
      return '';
    };
    let level = cleanList[0].tagName.slice(1);
    const startingLevel = level;
    const len = cleanList.length - 1;
    toc.innerHTML =
      '<ul>' +
      Array.prototype.map.call(cleanList,  (heading, index) => {
        if (heading.classList.contains('erom-editor-exclude-toc')) {
          return '';
        }
        if (heading.innerText.trim() === '') {
          return '';
        }
        const currentLevel = heading.tagName.slice(1);
        const levelDifference = currentLevel - parseInt(level, 10);
        level = currentLevel;
        let html = getStartingHTML(levelDifference, index);
        const url = location.href;
        const hash = location.hash;
        const hashIndex = url.indexOf(hash) || url.length;
        const cleanUrl = url.substr(0, hashIndex);
        heading.id = slugify(heading.innerText);
        html += '<li><a href="' + cleanUrl + '#' + heading.id + '">' + heading.innerText.trim() + '</a>';
        if (index === len) {
          html += getOutdent(Math.abs(parseInt(startingLevel, 10) - currentLevel));
        }
        return html;
      }).join('') +
      '</ul>';
    this.list = toc.innerHTML;
    this.changeDetectorRef.detectChanges();
  }

  public init(): void {
    setTimeout(() => {
      this.generateTOC();
    }, 0);
  }

  public get type(): string {
    return TableOfContentsComponent.type;
  }

  public get tagNames(): Array<string> {
    return TableOfContentsComponent.tagNames;
  }

  public get blockName(): string {
    return TableOfContentsComponent.blockName;
  }

  public get blockCategory(): string {
    return TableOfContentsComponent.blockCategory;
  }

  public get icon(): string {
    return TableOfContentsComponent.icon;
  }

  public get structure(): object {
    return TableOfContentsComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
