import { EventEmitter } from '@angular/core';
import { EromEditorComponent } from '../editor/erom-editor.component';
import { Events, validEvents } from './events';
import { EromBlockInterface } from '../_interfaces';

const importToArray = <Key extends string, PropType>(importObject: Record<Key, PropType>): PropType[] => {
  const keys = Object.getOwnPropertyNames(importObject);
  return keys.filter(key => key.indexOf('__') !== 0).map(key => importObject[key]);
};

const bindHandlers = (ctx: EromEditorComponent, editor: any): void => {
  const allowedEvents = getValidEvents(ctx);
  allowedEvents.forEach((eventName) => {
    const eventEmitter: EventEmitter<any> = ctx[eventName];
    editor.on(eventName.substring(2), (event: any) => ctx.ngZone.run(() => eventEmitter.emit({ event, editor })));
  });
};

const getValidEvents = (ctx: EromEditorComponent): (keyof Events)[] => {
  const ignoredEvents = parseStringProperty(ctx.ignoreEvents, []);
  return parseStringProperty(ctx.allowedEvents, validEvents).filter(
    (event) => validEvents.includes(event as (keyof Events)) && !ignoredEvents.includes(event)) as (keyof Events)[];
};

const parseStringProperty = (property: string | string[] | undefined, defaultValue: (keyof Events)[]): string[] => {
  if ( typeof property === 'string') {
    return property.split(',').map((value) => value.trim());
  }
  if ( Array.isArray(property)) {
    return property;
  }
  return defaultValue;
};

let unique = 0;

const uuid = (prefix: string): string => {
  const date = new Date();
  const time = date.getTime();
  const random = Math.floor(Math.random() * 1000000000);

  unique++;

  return prefix + '_' + random + unique + String(time);
};

const normalizePluginArray = (plugins?: string | string[]): string[] => {
  if (typeof plugins === 'undefined' || plugins === '') {
    return [];
  }

  return Array.isArray(plugins) ? plugins : plugins.split(' ');
};

const mergePlugins = (initPlugins: string | string[], inputPlugins?: string | string[]) =>
  normalizePluginArray(initPlugins).concat(normalizePluginArray(inputPlugins));

const noop: (...args: any[]) => void = () => { };

const isNullOrUndefined = (value: any): value is null | undefined => value === null || value === undefined;

const getArrayDepth = (arr) => {
  return Array.isArray(arr) ?
    1 + Math.max(...arr.map(getArrayDepth)) :
    Array.isArray(arr.children) && arr.children.length ?
      1 + Math.max(...arr.children.map(getArrayDepth)) :
      0;
};

const getHtmlStringStyle = (htmlString: string, property: string): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const child: HTMLElement = (div.firstChild as HTMLElement);
  return child.style[property] || null;
};

const setBlockStyle = (block: EromBlockInterface, property: string, value: string): void => {
  if (!block.style) {
    block.style = ({} as CSSStyleDeclaration);
  }
  block.style[property] = value;
};

const convertDateTime = (
  payload: string,
  format: string = 'd f Y',
  includeTime: boolean = true,
  dateDelimiter: string = ' ',
  dateTimeDelimiter: string = ' at ',
  dayFirst = false,
  ampm: boolean = true
): string => {

  let response = 'n/a';

  if (!payload) {
    return response;
  }

  if (dayFirst) {
    const pieces = payload.split('/');
    payload = pieces[2] + '-' + pieces[1] + '-' + pieces[0];
  }

  let date = new Date(payload);

  if (isNaN(date.getTime())) {
    date = new Date(payload.replace(' ', 'T')); // https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios
  }

  if (isNaN(date.getTime())) {
    return response;
  }

  const fullYear = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const toDoubleDigit = (num): string => {
    return num.toString().length < 2 ? '0' + num : num.toString();
  };

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  let dateString;
  let timeString;

  switch (format) {
    case 'F Y':
      dateString = monthNames[month] + dateDelimiter + fullYear;
      break;
    case 'f Y':
      dateString = shortMonthNames[month] + dateDelimiter + fullYear;
      break;
    case 'd F Y':
      dateString = toDoubleDigit(day) + dateDelimiter + monthNames[month] + dateDelimiter + fullYear;
      break;
    case 'd f Y':
      dateString = toDoubleDigit(day) + dateDelimiter + shortMonthNames[month] + dateDelimiter + fullYear;
      break;
    case 'd m Y':
      dateString = toDoubleDigit(day) + dateDelimiter + toDoubleDigit(month + 1) + dateDelimiter + fullYear;
      break;
    case 'Y m d':
      dateString = fullYear + dateDelimiter + toDoubleDigit(month + 1) + dateDelimiter + toDoubleDigit(day);
      break;
    case 'F d Y':
      dateString = monthNames[month] + dateDelimiter + toDoubleDigit(day) + dateDelimiter + fullYear;
      break;
    default:
      dateString = toDoubleDigit(day) + dateDelimiter + toDoubleDigit(month + 1) + dateDelimiter + fullYear;
      break;
  }

  if (dateString) {
    response = dateString;
  }

  if (isNaN(date.getTime())) {
    return response;
  }

  if (includeTime) {
    if (ampm) {
      timeString = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    } else {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      timeString = hours + ':' + minutes;
    }

    if (timeString) {
      response += (dateTimeDelimiter + timeString);
    }
  }

  return response;

};

const slugify = (text: string) => {
  if (!text) {
    return '';
  }
  text = text.replace(/^\s+|\s+$/g, '');
  text = text.toLowerCase();
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  return text.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export {
  importToArray,
  bindHandlers,
  uuid,
  normalizePluginArray,
  mergePlugins,
  noop,
  isNullOrUndefined,
  getArrayDepth,
  getHtmlStringStyle,
  setBlockStyle,
  convertDateTime,
  slugify
};
