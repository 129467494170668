<div class="erom-editor">
  <ng-content select="[top]"></ng-content>
  <div class="erom-editor__content" role="region" [attr.aria-label]="'editor.EDITOR_CONTENT' | translate">
    <div class="erom-editor__content--inner">
      <div class="erom-editor__blocks-list">
        <ng-container *ngFor="let group of contentGroups">
          <div class="erom-editor__group erom-editor__group--block" *ngIf="!group.hidden">
            <div class="erom-editor__rows">
              <ng-container *ngFor="let grid of group.grids">
                <div class="erom-editor__rows-item bg-cover-full"
                     [style.max-width]="group.width ? group.width : ''"
                     *ngIf="!grid.hidden">
                  <div class="erom-editor__rows-columns erom-editor__content-row"
                       [style.backgroundImage]="grid.backgroundImage && grid.backgroundImage.url ? 'url(\'' + grid.backgroundImage.url + '\')' : ''"
                       [style.backgroundColor]="grid.backgroundColor ? grid.backgroundColor : ''"
                       [class.grid-align-left]="grid.align && grid.align.v === 'left'"
                       [class.grid-align-center]="grid.align && grid.align.v === 'center'"
                       [class.grid-align-right]="grid.align && grid.align.v === 'right'"
                       [class.grid-align-top]="grid.align && grid.align.h === 'top'"
                       [class.grid-align-middle]="grid.align && grid.align.h === 'middle'"
                       [class.grid-align-bottom]="grid.align && grid.align.h === 'bottom'"
                       [ngStyle]="pixelsToRem(grid.style)"
                       [ngClass]="gridClasses(grid)">
                    <div class="erom-editor__rows-column" *ngFor="let column of grid.columns">
                      <ng-container *ngFor="let block of column">
                        <div class="erom-editor__block"
                             [ngClass]="block.settings && block.settings.class ? block.settings.class : []"
                             *ngIf="!block.hidden">
                          <div class="erom-editor__block-content"
                               [ngStyle]="pixelsToRem(block.style)">
                            <ng-container *ngIf="block.type">
                              <erom-block [block]="block" [pageData]="editorData" [id]="block.id" [ngClass]="block.type + '_block-type'"></erom-block>
                            </ng-container>
                            <ng-container *ngIf="block.items">
                              <div class="erom-editor__block-group" [attr.role]="ariaRole(block)" [attr.aria-label]="ariaLabel(block)">
                                <ng-container *ngFor="let groupBlock of block.items">
                                  <div class="erom-editor__block-group--item" *ngIf="!groupBlock.hidden">
                                    <erom-block [block]="groupBlock" [pageData]="editorData" [id]="groupBlock.id" [ngClass]="groupBlock.type + '_block-type'"></erom-block>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
