import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { PageInterface } from '../../../../../src/app/_interfaces';
import {
  EromBlockGroupInterface,
  EromBlockInterface,
  EromEditorDataInterface
} from '../../../../../src/app/_directives/erom-editor/src/_interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { API } from '../../../../../src/app/_store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { convertDateTimeToISOString } from '../../../../../src/app/_helpers/common';
import { BlockType } from '../../../../../src/app/_directives/erom-editor/src/_types';
import { Meta, Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '../../../../../src/app/_services/google-analytics.service';
import { DOCUMENT, isPlatformBrowser, PlatformLocation } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {

  @ViewChild('ctaContainer', {read: ElementRef, static: false}) ctaContainer: ElementRef;

  private _alias: string;
  private _editorData: EromEditorDataInterface;
  private _requestInProcess: boolean;
  private _pageReady: boolean;

  private _windowWidth: number;

  readonly _language: string;

  private _ctaTopPosition: number;

  private _nextArticle: any;

  private _siteLogo: string;

  private _halfScrolled: boolean;
  private _baScrolled: boolean;
  private _ctScrolled: boolean;

  constructor(
    private route: ActivatedRoute,
    private api: API,
    private http: HttpClient,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private ga: GoogleAnalyticsService,
    private location: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    translateService.use('en');
    this._language = translateService.currentLang;
    this._editorData = ({} as EromEditorDataInterface);
    if (isPlatformBrowser(platformId)) {
      this._windowWidth = window.innerWidth;
    }
    this.getWebsiteLogo();
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this._alias = params.alias;
      if (isPlatformBrowser(this.platformId)) {
        this.resetGuideViewed();
      }
      this.guideViewed();
      this.getEntity();
      this.getNextArticle();
    });
  }

  private get cleanUrl(): string {
    console.log(this.router.url)
    return this.router.url;
  }

  private get verticalScrollPercentage(): number {
    const elm = this.document.body;
    const parent: HTMLElement = (elm.parentNode as HTMLElement);
    return (elm.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight ) * 100;
  }

  private isInViewport(elm: HTMLElement): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const rect = elm.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || this.document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || this.document.documentElement.clientWidth)
      );
    }
    return false;
  }

  private prepareSteps(): Array<any> {
    const steps = [];
    const stepSection = this.findArticleSchemaValue('howTo', 'step', false);
    if (stepSection && stepSection.length) {
      let stepItem;
      for (let i = 0; i < stepSection.length; i++) {
        if (stepSection[i].type === BlockType.Heading) {
          if (stepItem) {
            steps.push(stepItem);
          }
          const div = this.document.createElement('div');
          div.innerHTML = stepSection[i].data.text;
          stepItem = {
            '@type': 'HowToStep',
            url: `${this.cleanUrl}#${div.firstElementChild.id}`,
            name: div.firstElementChild.textContent
          };
        } else if (stepSection[i].type === BlockType.Paragraph && stepItem) {
          const paragraph = this.document.createElement('div');
          paragraph.innerHTML = stepSection[i].data.text;
          stepItem.text = paragraph.firstElementChild.textContent;
        } else if (stepSection[i].type === BlockType.Image && stepItem) {
          stepItem.image = `${this.location.protocol}//${this.location.hostname}${stepSection[i].data.file}`;
        }
      }
      if (stepItem) {
        steps.push(stepItem);
      }
    }
    return steps;
  }

  private tryArticleSingleSchemaSetup(): void {
    if (!this._editorData) {
      this.setupMetaImage();
      return;
    }
    const speakableDescription = this.findArticleSchemaValue('speakable', 'description');
    const howToDescription = this.findArticleSchemaValue('howTo', 'description');
    const firstParagraphDescription = this.findArticleDescriptionValue();
    this.setupMeta(null, (speakableDescription || howToDescription || firstParagraphDescription || ''));
    const steps = this.prepareSteps();
    if (steps.length) {
      if (isPlatformBrowser(this.platformId)) {
        const imgForHowTo = new Image();
        imgForHowTo.onload = () => {
          this.createHowToSchemaScript(steps, imgForHowTo, (howToDescription || speakableDescription || firstParagraphDescription || ''));
        };
        imgForHowTo.src = this.howToOrSpeakableImage('howTo');
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      const imgForSpeakable = new Image();
      imgForSpeakable.onload = () => {
        this.createSpeakableSchemaScript(imgForSpeakable, (speakableDescription || howToDescription || firstParagraphDescription || ''));
      };
      imgForSpeakable.src = this.howToOrSpeakableImage('logo');
    }

    const image = this.howToOrSpeakableImage('speakable');
    if (image) {
      this.metaService.updateTag({
        property: 'og:image',
        content: image
      });
      this.metaService.updateTag({
        property: 'twitter:image',
        content: image
      });
    } else {
      this.setupMetaImage();
    }
  }

  private howToOrSpeakableImage(prefer: string = 'speakable'): string {
    const speakableImage = this.findArticleSchemaValue('speakable', 'image');
    const howToImage = this.findArticleSchemaValue('howTo', 'image');
    const logo = this._siteLogo || null;
    if (prefer === 'speakable') {
      return speakableImage || howToImage || logo;
    } else if (prefer === 'howTo') {
      return howToImage || speakableImage || logo;
    } else {
      return logo || speakableImage || howToImage;
    }
  }

  private createHowToSchemaScript(steps: Array<object>, img: HTMLImageElement, description: string): void {
    const howToJSON = {
      '@context': 'http://schema.org',
      '@type': 'HowTo',
      name: this._editorData.title,
      description,
      image: {
        '@type': 'ImageObject',
        url: img.src,
        height: img.height,
        width: img.width
      },
      step: steps,
      totalTime: 'P2D'
    };
    let howToSchemaScript = (this.document.getElementById('howToSchemaScript') as HTMLScriptElement);
    if (!howToSchemaScript)  {
      howToSchemaScript = this.document.createElement('script');
    }
    howToSchemaScript.type = 'application/ld+json';
    howToSchemaScript.innerHTML = JSON.stringify(howToJSON);
    howToSchemaScript.id = 'howToSchemaScript';
    this.document.head.appendChild(howToSchemaScript);
  }

  private createSpeakableSchemaScript(img: HTMLImageElement, description: string): void {
    const speakableJSON = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        id: this.cleanUrl
      },
      publisher: {
        '@type': 'Organization',
        name: 'AbilityNet - My Computer My Way',
        logo: {
          '@type': 'ImageObject',
          url: img.src,
          height: img.height,
          width: img.width
        }
      },
      headline: this._editorData.title,
      name: this._editorData.title,
      datePublished: convertDateTimeToISOString(this._editorData.createdOn),
      dateModified: convertDateTimeToISOString(this._editorData.modifiedOn),
      author: {
        '@type': 'Person',
        name: this._editorData.author
      },
      description,
      image: this.howToOrSpeakableImage('speakable')
    };
    let speakableSchemaScript = (this.document.getElementById('speakableSchemaScript') as HTMLScriptElement);
    if (!speakableSchemaScript)  {
      speakableSchemaScript = this.document.createElement('script');
    }
    speakableSchemaScript.type = 'application/ld+json';
    speakableSchemaScript.innerHTML = JSON.stringify(speakableJSON);
    speakableSchemaScript.id = 'speakableSchemaScript';
    this.document.head.appendChild(speakableSchemaScript);
  }

  public ngOnDestroy(): void {
  }

  private getWebsiteLogo(): void {
    let dataParams = new HttpParams();
    const keys = [
      'website-logo'
    ];
    dataParams = dataParams.append('keys', JSON.stringify(keys));
    this.http.get(`${this.api.URLS.setting}/from-keys`, { params: dataParams }).subscribe((response: any) => {
      this._siteLogo = response['website-logo'];
      this.tryArticleSingleSchemaSetup();
    }, (error) => {
      console.log(error);
    });
  }

  private findArticleSchemaValue(schema: string, property: string, removeTags: boolean = true): any {
    if (!this._editorData.body) {
      return null;
    }
    let value;
    for (let i = 0; i < this._editorData.body.length; i++) {
      for (let j = 0; j < this._editorData.body[i].grids.length; j++) {
        for (let k = 0; k < this._editorData.body[i].grids[j].columns.length; k++) {
          for (let l = 0; l < this._editorData.body[i].grids[j].columns[k].length; l++) {
            if (value) {
              continue;
            }
            value = this.blockOrGroupValue(this._editorData.body[i].grids[j].columns[k][l], schema, property);
          }
        }
      }
    }
    if (removeTags) {
      value = value ? value.replace(/<[^>]*>?/gm, '') : '';
    }
    return value;
  }

  private findArticleDescriptionValue(): any {
    if (!this._editorData.body) {
      return null;
    }
    let value;
    for (let i = 0; i < this._editorData.body.length; i++) {
      for (let j = 0; j < this._editorData.body[i].grids.length; j++) {
        for (let k = 0; k < this._editorData.body[i].grids[j].columns.length; k++) {
          for (let l = 0; l < this._editorData.body[i].grids[j].columns[k].length; l++) {
            if (value) {
              continue;
            }
            value = this.blockOrGroupDescriptionValue(this._editorData.body[i].grids[j].columns[k][l]);
          }
        }
      }
    }
    value = value ? value.replace(/<[^>]*>?/gm, '') : '';
    return value;
  }

  private blockOrGroupValue(block: EromBlockInterface|EromBlockGroupInterface, schema: string, property: string): any {
    let value;
    if ((block as EromBlockInterface).type) {
      if ((block as EromBlockInterface).schema &&
        (block as EromBlockInterface).schema[schema] &&
        (block as EromBlockInterface).schema[schema] === property) {
        if (property === 'description') {
          value = (block as EromBlockInterface).data.text;
        } else if (property === 'image') {
          value = `${this.location.protocol}//${this.location.hostname}${(block as EromBlockInterface).data.file}`;
        }
      }
    } else if ((block as EromBlockGroupInterface).items) {
      if ((block as EromBlockGroupInterface).schema &&
        (block as EromBlockGroupInterface).schema[schema] &&
        (block as EromBlockGroupInterface).schema[schema] === property && property === 'step') {
        return (block as EromBlockGroupInterface).items;
      }
      for (let i = 0; i < (block as EromBlockGroupInterface).items.length; i++) {
        if ((block as EromBlockGroupInterface).items[i].schema &&
          (block as EromBlockGroupInterface).items[i].schema[schema] &&
          (block as EromBlockGroupInterface).items[i].schema[schema] === property) {
          if (property === 'description') {
            value = (block as EromBlockGroupInterface).items[i].data.text;
          } else if (property === 'image') {
            value = `${this.location.protocol}//${this.location.hostname}${(block as EromBlockGroupInterface).items[i].data.file}`;
          } else if (property === 'step') {
            value = (block as EromBlockGroupInterface).items[i].data.text;
          }
        }
      }
    }
    return value;
  }

  private blockOrGroupDescriptionValue(block: EromBlockInterface|EromBlockGroupInterface): any {
    let value;
    if ((block as EromBlockInterface).type) {
      if ((block as EromBlockInterface).type === BlockType.Paragraph) {
        value = (block as EromBlockInterface).data.text;
      }
    } else if ((block as EromBlockGroupInterface).items) {
      for (let i = 0; i < (block as EromBlockGroupInterface).items.length; i++) {
        if (value) {
          continue;
        }
        if ((block as EromBlockGroupInterface).items[i].type === BlockType.Paragraph) {
          value = (block as EromBlockGroupInterface).items[i].data.text;
        }
      }
    }
    return value;
  }

  private resetGuideViewed(): void {
    const viewedGuides = localStorage.getItem('viewedGuides');
    if (viewedGuides) {
      const viewedGuidesObject = JSON.parse(viewedGuides);
      const limit = 1000 * 60 * 60 * 2;
      const limitAgo = Date.now() - limit;
      const lastTimestamp = new Date(viewedGuidesObject.date);
      if (limitAgo >= lastTimestamp.getTime()) {
        localStorage.removeItem('viewedGuides');
      }
    }
  }

  private guideViewed(): void {
    if (isPlatformBrowser(this.platformId)) {
      const viewedGuides = localStorage.getItem('viewedGuides');
      if (viewedGuides) {
        const viewedGuidesObject = JSON.parse(viewedGuides);
        viewedGuidesObject.guides = [this._alias].concat(viewedGuidesObject.guides.filter((item) => this._alias.indexOf(item) < 0));
        localStorage.setItem('viewedGuides', JSON.stringify(viewedGuidesObject));
      } else {
        const newViewedGuidesObject = {
          date: new Date().getTime(),
          guides: [this._alias]
        };
        localStorage.setItem('viewedGuides', JSON.stringify(newViewedGuidesObject));
      }
    }
  }

  private getEntity(): void {
    if (this._requestInProcess) {
      return;
    }
    this._requestInProcess = true;
    let params = new HttpParams();
    params = params.append('locale', this._language);
    this.http.get(`${this.api.URLS.page}/preview/${this._alias}`, { params }).subscribe((page: PageInterface) => {
      this._editorData = {
        id: page.id,
        title: page.title,
        alias: page.alias,
        body: JSON.parse(page.body),
        tags: JSON.parse(page.tags),
        template: page.template,
        status: page.status,
        author: page.author,
        createdOn: page.createdOn,
        modifiedOn: page.modifiedOn,
        createdBy: page.createdBy
      };
      this.setupMeta(`${ page.title } | My Computer My Way`, null);
      this.tryArticleSingleSchemaSetup();
      this._pageReady = true;
      this._requestInProcess = false;
      setTimeout(() => {
        this.fixContentLists();
      }, 0);
    }, (error) => {
      this._requestInProcess = false;
      console.log(error);
    });
  }

  private getNextArticle(): void {
    let dataParams = new HttpParams();
    const viewedGuides = isPlatformBrowser(this.platformId) ? JSON.parse(localStorage.getItem('viewedGuides'))?.guides : [];
    dataParams = dataParams.append('locale', this._language.toString());
    dataParams = dataParams.append('ids', JSON.stringify(viewedGuides));
    this.http.get(`${this.api.URLS.page}/next-article/${this._alias}`, { params: dataParams }).subscribe((response: any) => {
      if (response) {
        this._nextArticle = response;
      }
    }, (error) => {
      console.log(error);
    });
  }

  private changeSidebarElements(): void {
    const sidebar = this.document.querySelector('.erom-sidebar');
    if (!sidebar) {
      return;
    }
    const blocks = sidebar.querySelectorAll('erom-block');
    const shortGuide = this.document.querySelector('.short-guide-section');
    const elementsPositionChanged = this.document.querySelectorAll('.erom-moved-block');
    if (this.isMobile || this.isTablet) {
      if (blocks.length && !elementsPositionChanged.length) {
        for (let i = 0; i < blocks.length; i++) {
          if (shortGuide && (blocks[i].classList.contains('toc_block-type') || blocks[i].classList.contains('search-cta_block-type'))) {
            const destination = this.document.createElement('DIV');
            destination.classList.add('erom-moved-block');
            destination.setAttribute('data-block-source', blocks[i].id);
            blocks[i].parentElement.setAttribute('data-block-id', blocks[i].id);
            destination.appendChild(blocks[i]);
            shortGuide.parentElement.insertBefore(destination, shortGuide);
          }
        }
      }
    } else {
      if (elementsPositionChanged.length) {
        for (let i = 0; i < elementsPositionChanged.length; i++) {
          const sourceId = elementsPositionChanged[i].getAttribute('data-block-source');
          const sourceElm = elementsPositionChanged[i].firstElementChild;
          const destination = this.document.querySelector('[data-block-id=\'' + sourceId +  '\']');
          if (destination && sourceElm) {
            destination.appendChild(sourceElm);
            elementsPositionChanged[i].remove();
          }
        }
      }
    }
  }

  private setupMetaImage(): void {
    let dataParams = new HttpParams();
    const keys = [
      'meta-image'
    ];
    dataParams = dataParams.append('keys', JSON.stringify(keys));
    this.http.get(`${this.api.URLS.setting}/from-keys`, { params: dataParams }).subscribe((response: any) => {
      if (response['meta-image']) {
        this.metaService.updateTag({
          property: 'og:image',
          content: `${this.location.protocol}//${this.location.hostname}${response['meta-image']}`
        });
        this.metaService.updateTag({
          property: 'twitter:image',
          content: `${this.location.protocol}//${this.location.hostname}${response['meta-image']}`
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  private setupMeta(title: string = null, description: string = null): void {
    if (title && title !== '') {
      this.titleService.setTitle(title);
      this.metaService.updateTag({
        property: 'og:title',
        content: title
      });
    }
    if (description && description !== '') {
      this.metaService.updateTag({
        name: 'description',
        content: description
      });
      this.metaService.updateTag({
        property: 'og:description',
        content: description
      });
    }
    this.metaService.updateTag({
      property: 'og:url',
      content: `${this.location.protocol}//${this.location.hostname}${this.location.pathname}`
    });
    this.metaService.updateTag({
      property: 'twitter:url',
      content: `${this.location.protocol}//${this.location.hostname}${this.location.pathname}`
    });
  }

  private setupFixedElements(): void {
    if (isPlatformBrowser(this.platformId)) {
      const ctaTopPosition = window.innerHeight - this.ctaContainer.nativeElement.offsetHeight - document.documentElement.scrollTop;
      const stickyElements = document.querySelectorAll<HTMLElement>('.erom-sticky');
      const deviceCheckElm = document.querySelector('.erom-block.device-check_type .erom-block__device-check__content');
      const logo = document.querySelector<HTMLElement>('.erom-article-header--logo__link');
      if (!this.ctaContainer) {
        return;
      }
      if (deviceCheckElm) {
        const deviceCheckElmStyles = window.getComputedStyle(deviceCheckElm);
        logo.style.top = parseInt(deviceCheckElmStyles.height, 10) + parseInt(deviceCheckElmStyles.marginBottom, 10) + 'px';
      } else {
        logo.style.top = '';
      }
      if (ctaTopPosition <= 0) {
        this._ctaTopPosition = 0;
        this.ctaContainer.nativeElement.classList.add('fixed-top');
        if (window.innerWidth <= 1720) {
          if (stickyElements && stickyElements.length) {
            for (let i = 0; i < stickyElements.length; i++) {
              stickyElements[i].style.top = '';
            }
          }
        } else {
          if (stickyElements && stickyElements.length) {
            const header = document.querySelector<HTMLElement>('.erom-article-header--phone-cta');
            for (let i = 0; i < stickyElements.length; i++) {
              stickyElements[i].style.top = header.offsetHeight + 30 + 'px';
            }
          }
        }
      } else {
        this._ctaTopPosition = ctaTopPosition;
        this.ctaContainer.nativeElement.classList.remove('fixed-top');
        if (stickyElements && stickyElements.length) {
          for (let i = 0; i < stickyElements.length; i++) {
            stickyElements[i].style.top = '';
          }
        }
      }
    }
  }

  private fixTOCMaxHeight(): void {
    if (isPlatformBrowser(this.platformId)) {
      const windowHeight = window.innerHeight;
      const fixedHeader = this.document.querySelector<HTMLElement>('.erom-article-header--phone-cta.fixed-top');
      const toc = this.document.querySelector<HTMLElement>('.erom-block.toc_type');
      const sidebar = Array.from(this.document.querySelectorAll<HTMLElement>('.erom-sidebar .erom-block:not(.toc_type)'));
      if (toc) {
        let maxTOCMaxHeight = windowHeight - 30;
        if (fixedHeader) {
          maxTOCMaxHeight -= fixedHeader.offsetHeight;
        }
        sidebar.forEach((elm) => {
          maxTOCMaxHeight -= (elm.offsetHeight + 30);
        });
        const tocContainer = toc.querySelector<HTMLElement>('.erom-block__toc');
        if (!tocContainer) {
          return;
        }
        if (window.innerWidth > 1180) {
          tocContainer.style.maxHeight = maxTOCMaxHeight + 'px';
        } else {
          tocContainer.style.maxHeight = '';
        }
      }
    }
  }

  public blocksReady(): void {
    setTimeout(() => {
      this.changeSidebarElements();
      this.setupFixedElements();
    }, 0);
  }

  public get editorData(): EromEditorDataInterface {
    return this._editorData;
  }

  private get isTablet(): boolean {
    return this._windowWidth <= 1180 && this._windowWidth > 570;
  }

  private get isMobile(): boolean {
    return this._windowWidth <= 570;
  }

  public get ctaTopPosition(): number {
    return this._ctaTopPosition;
  }

  public get pageReady(): boolean {
    return this._pageReady;
  }

  public get nextArticle(): any {
    return this._nextArticle;
  }

  private fixContentLists(): void {
    const listItems =
      this.document.querySelectorAll( '.full-editor_block-type li, .full-editor_block-type li, .list_block-type li, .list_block-type li' );
    if (!listItems.length) {
      return;
    }
    const wrapAll = (target) => {
      let wrapper = this.document.createElement('span');
      //wrapper.setAttribute('role', 'text');
      const filteredChildren = Array.from(target.childNodes as HTMLElement[]);
      [ ...filteredChildren ].forEach(child => {
        if (child.nodeName === 'UL' || child.nodeName === 'OL' || child.nodeName === 'IMG') {
          target.appendChild(wrapper);
          target.appendChild(child);
          wrapper = this.document.createElement('span');
          //wrapper.setAttribute('role', 'text');
        } else {
          wrapper.appendChild(child);
        }
      });
      if (wrapper.childNodes.length > 0 && wrapper.childNodes[0].textContent !== '\n') {
        target.appendChild(wrapper);
      }
    };
    Array.from(listItems).forEach((listItem) => {
      wrapAll(listItem);
    });
  }

  private halfScrolledCheck(): void {
    if (this._halfScrolled) {
      return;
    }
    if (this.verticalScrollPercentage >= 50) {
      this._halfScrolled = true;
      this.ga.event('articleJourney', 'halfThrough');
    }
  }

  private beforeAndAfterInViewportCheck(): void {
    if (this._baScrolled) {
      return;
    }
    const elm = this.document.getElementById('before-and-after');
    if (elm && this.isInViewport(elm)) {
      this._baScrolled = true;
      this.ga.event('articleJourney', 'pastShortGuide');
    }
  }

  private categoriesAndTagsInViewportCheck(): void {
    if (this._ctScrolled) {
      return;
    }
    const elm: HTMLElement = this.document.querySelector('.tags-categories_block-type');
    if (elm && this.isInViewport(elm)) {
      this._ctScrolled = true;
      this.ga.event('articleJourney', 'readInFull');
    }
  }

  public gaEvent(category: string, action: string): void {
    this.ga.event(category, action);
  }

  public readNext(): void {
    this.ga.event('Related click', 'readNext');
  }

  public goToArticle(alias: string): void {
    this.router.navigate(['/', alias]).catch();
  }

  @HostListener('window:resize', ['$event'])
  private onDocumentResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._windowWidth = window.innerWidth;
    }
    this.changeSidebarElements();
    this.fixTOCMaxHeight();
  }

  @HostListener('document:scroll', ['$event'])
  onDocumentScroll(event): void {
    this.setupFixedElements();
    this.fixTOCMaxHeight();
    this.halfScrolledCheck();
    this.beforeAndAfterInViewportCheck();
    this.categoriesAndTagsInViewportCheck();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event): void {
    const toc = this.document.querySelector('.erom-block__toc');
    if (toc && (toc === event.target || toc.contains(event.target))) {
      this.ga.event('articleJourney', 'tableOfContents');
    }
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event): void {
  //   this.setupFixedElements();
  // }

}
