import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  HostListener, Output, EventEmitter, AfterViewInit, ChangeDetectorRef
} from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  EromBlockGroupInterface,
  EromBlockInterface,
  EromEditorDataInterface,
  EromGroupInterface,
  EromGroupRowInterface
} from '../_interfaces';
import { BlockType } from '../_types';

@AutoUnsubscribe()
@Component({
  selector: 'erom-editor-preview',
  templateUrl: './editor-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EromEditorPreviewComponent implements OnDestroy, AfterViewInit {

  @Output() eromEditorBlocksReady = new EventEmitter<any>();

  @Input() public set editorData(payload: EromEditorDataInterface) {
    this._editorData = payload;
    this._contentGroups = payload.body;
    this.changeDetectorRef.detectChanges();
  }

  private _editorData: EromEditorDataInterface;
  private _contentGroups: EromGroupInterface[];

  private _windowWidth: number;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    if (typeof window !== 'undefined') {
      this._windowWidth = window.innerWidth;
    }
  }

  public ngOnDestroy(): void {}

  public ngAfterViewInit(): void {
    this.eromEditorBlocksReady.emit();
  }

  public pixelsToRem(value: object): object {
    if (!value) {
      return value;
    }
    const result = {};
    for (const key in value) {
      if (!value.hasOwnProperty(key)) {
        continue;
      }
      if (key === 'padding' || key === 'margin') {
        const values = value[key].split(' ');
        const newValues = [];
        for (let i = 0; i < values.length; i++) {
          newValues.push(this.valueToRem(values[i]));
        }
        result[key] = newValues.join(' ');
      } else {
        result[key] = value[key];
      }
    }
    return result;
  }

  private valueToRem(value: string, relative: boolean = true): string {
    if (value === '' || value === '0') {
      return '0';
    }
    const remFromPixels = parseInt(value.toString(), 10) / 16;
    if (remFromPixels === 0) {
      return '0';
    }
    let rem;
    if (relative === false) {
      return remFromPixels + 'rem';
    }
    if (this.isMobile) {
      rem = remFromPixels * 0.5;
    } else if (this.isTablet) {
      rem = remFromPixels * 0.75;
    } else {
      rem = remFromPixels;
    }
    return rem + 'rem';
  }

  public gridClasses(grid: EromGroupRowInterface): object {
    const classes = {};
    classes['grid_' + (grid.grid || 100)] = true;
    if (grid.settings && grid.settings.class && grid.settings.class.length) {
      for (let i = 0; i < grid.settings.class.length; i++) {
        classes[grid.settings.class[i]] = true;
      }
    }
    return classes;
  }

  public ariaRole(entity: EromBlockGroupInterface): string {
    return entity.settings && entity.settings.role ? entity.settings.role : null;
  }

  public ariaLabel(entity: EromBlockGroupInterface): string {
    const heading = entity.items.find(b => b.type === BlockType.Heading && b.data.level === 2);
    if (heading) {
      return heading.data && heading.data.text ? heading.data.text.replace( /(<([^>]+)>)/ig, '') : null;
    }
    return null;
  }

  public get contentGroups(): EromGroupInterface[] {
    return this._contentGroups || [];
  }

  public get editorData(): EromEditorDataInterface {
    return this._editorData;
  }

  private get isTablet(): boolean {
    return this._windowWidth <= 1200 && this._windowWidth > 600;
  }

  private get isMobile(): boolean {
    return this._windowWidth <= 600;
  }

  @HostListener('window:resize', ['$event'])
  private onDocumentResize(): void {
    if (typeof window !== 'undefined') {
      this._windowWidth = window.innerWidth;
    }
  }

}
