import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItemInterface } from '../_interfaces';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'erom-select',
  templateUrl: './erom-select.component.html',
  styleUrls: ['./erom-select.component.scss']
})

export class EromSelectComponent implements OnChanges, OnInit {

  @ViewChild('searchInput', {read: ElementRef, static: false}) searchInput: ElementRef;
  @ViewChild('dropdownMenuList', {read: ElementRef, static: false}) dropdownMenuList: ElementRef;

  @Output() optionSelected = new EventEmitter<{option: Object}>();
  @Output() valuesFound = new EventEmitter<{option: Object}>();
  @Output() searchEvent = new EventEmitter<{search: string}>();
  @Output() dropDownToggle = new EventEmitter<{openStatus: boolean, selected: Array<any>, multiple: boolean}>();

  /**
   * Custom div ID. Helpful when we have multiple selects on the same page.
   */
  @Input() id: string;

  @Input() name: string;

  @Input() customActive: boolean;

  @Input() isActive: boolean;

  @Input() checkbox: boolean;

  @Input() showLabelCounter: boolean;

  @Input() type: string;

  @Input() filtersCleared: boolean;

  /**
   * Has clear select option (All option on the top);
   */
  @Input() clearOption: boolean;
  @Input() clearText: string;

  /**
   * Enable dynamic custom title for select.
   */
  @Input() customTitle: string;

  /**
   * An array of strings or objects to be used as dropdown choices.
   * If you are using an array of objects, vue-select will look for
   * a `label` key (ex. [{label: 'This is Foo', value: 'foo'}]). A
   * custom label key can be set with the `label` prop.
   */
  @Input() options: Array<any>;

  @Input() label: string;

  @Input('aria-label') ariaLabel: string;

  @Input('aria-describedby') ariaDescribedBy: string;

  /**
   * Contains the currently selected value. Very similar to a
   * `value` attribute on an <input>. You can listen for changes
   * using 'change' event using v-on
   */
  @Input() value: any;

  /**
   * Equivalent to the `placeholder` attribute on an `<input>`.
   */
  @Input() placeholder: string;
  @Input() searchPlaceholder: string;

  @Input() allowNewTags: boolean;

  /**
   * Enable/disable filtering the options.
   */
  @Input() searchable: boolean;

  @Input() searchLoading: boolean;

  @Input() noItemsFoundText: string;

  /**
   * Equivalent to the `multiple` attribute on a `<select>` input.
   */
  @Input() multiple: boolean;

  @Input() tagsFormat: boolean;

  /**
   * Enables/disables clearing the search text when an option is selected.
   */
  @Input() clearSearchOnSelect: boolean;

  /**
   * Default selected value
   */
  @Input() defaultSelected: object;

  /**
   * Close a dropdown when an option is chosen. Set to false to keep the dropdown
   * open (useful when combined with multi-select, for example)
   */
  @Input() closeOnSelect: boolean;

  @Input() faIcon: any;

  private _openStatus: boolean;
  private _selected: any;
  private _search: string;
  private _preventCloseDropDown: boolean;

  private _typeAheadPointer: number;
  private _descendentId: string;

  private _canDisplayPlaceholder: boolean;

  public availableResultsLive: boolean;

  readonly _isMobile: boolean;

  private static generateIdString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  constructor(
    private _eref: ElementRef,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.id = EromSelectComponent.generateIdString(20);
    this.customTitle = '';
    this.value = null;
    this._search = '';
    this.searchable = false;
    this.multiple = false;
    this.clearSearchOnSelect = true;
    this.closeOnSelect = true;
    this.clearOption = false;
    this._selected = [];
    this._openStatus = false;
    this.allowNewTags = false;

    this._typeAheadPointer = -1;

    this._isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  }

  ngOnInit() {
    // this._selected = this.defaultSelected || this._selected;
    let value = null;
    if (this.value !== null) {
      if (this.options) {
        if (this.multiple && Array.isArray(this.value)) {
          value = [];
          for (let i = 0; i < this.value.length; i++) {
            const option = this.options.find(option => option.value === this.value[i]);
            if (option) {
              value.push(option);
            }
          }
        } else {
          value = this.options.find(option => option.value === this.value);
        }
        this.valuesFound.emit({option: this._selected});
      }
    }
    this._selected = this.defaultSelected || value || this._selected;
  }

  ngOnChanges(changes) {

    this._selected = this.defaultSelected || this._selected;

    this._preventCloseDropDown = false;

    if (changes.options && changes.options.previousValue !== changes.options.currentValue) {
      const selector: HTMLSelectElement = (document.getElementById(this.selectId + '-native-select') as HTMLSelectElement);
      if (selector) {
        for (let i = 0; i < selector.children.length; i++) {
          selector.options.item(i).selected = false;
          const option = changes.options.previousValue.find(o => o.value === selector.children.item(i).nodeValue);
          if (!option) {
            continue;
          }
          if (this.isOptionSelected(option)) {
            selector.options.item(i).selected = true;
          }
        }
      }
    }

    if ( changes.isActive && changes.isActive.previousValue !== changes.isActive.currentValue && changes.isActive.currentValue === true ) {
      this._openStatus = changes.isActive.currentValue;
      this._preventCloseDropDown = true;
    }

    if ( changes.filtersCleared  && changes.filtersCleared.previousValue !== changes.filtersCleared.currentValue &&
      changes.filtersCleared.currentValue === true ) {
      this.clearSelect();
    }

    if ( changes.value  && changes.value.previousValue !== changes.value.currentValue && this.options ) {
      let currentValue = null;
      if (this.multiple && changes.value.currentValue && Array.isArray(changes.value.currentValue)) {
        currentValue = [];
        for (let i = 0; i < changes.value.currentValue.length; i++) {
          const option = this.options.find(o => o.value === changes.value.currentValue[i]);
          if (option) {
            currentValue.push(option);
          }
        }
      } else if (!this.multiple && changes.value.currentValue !== null && changes.value.currentValue !== undefined) {
        currentValue = this.options.find(option => option.value.toString() === changes.value.currentValue.toString());
        if (this.allowNewTags) {
          this._search = changes.value.currentValue.toString();
        }
        if (!currentValue && this.allowNewTags) {
          currentValue = {
            label: changes.value.currentValue,
            value: changes.value.currentValue,
            new: true
          };
          this.options = [
            currentValue,
            ...this.options
          ];
        }
      }
      this._selected = currentValue || this._selected;
      if (this.allowNewTags && this._selected && this.searchInput) {
        this.searchInput.nativeElement.value = this._selected.label || '';
      }
      this.valuesFound.emit({option: this._selected});
    }

    this.generateDescendentIds();
  }

  /**
   * If search text should clear on blur
   */
  private get clearSearchOnBlur(): boolean {
    return this.clearSearchOnSelect && !this.multiple;
  }

  public toggleSelect(event: any, forced: boolean = false): void {
    if (!forced && this.isOpened && (this.allowNewTags || this._search !== '')) {
      event.preventDefault();
      return;
    }

    if (!forced && this.isOpened && this.searchInput.nativeElement === this.document.activeElement) {
      this.onSearchBlur(event);
      event.preventDefault();
      return;
    }

    if (forced && !this.isOpened) {
      this.searchInput.nativeElement.focus();
      event.preventDefault();
      return;
    }

    event.preventDefault();

    this.toggleStatus();

    // this._openStatus = !this._openStatus;
    // this.dropDownToggle.emit({openStatus: this._openStatus, selected: this._selected, multiple: this.multiple});

    if (this._openStatus && this.filteredOptions.length > 0 && this._selected) {
      let activeOption;
      if (this.multiple && this._selected.length) {
        activeOption = this.filteredOptions.findIndex((item) => item.value === this._selected[0].value);
      } else if (!this.multiple) {
        activeOption = this.filteredOptions.findIndex((item) => item.value === this._selected.value);
      }
      this._typeAheadPointer = activeOption ? activeOption : -1;
      this.updateActiveDescendent();
    }

    if (this._openStatus === true) {
      this.focusSearchInput();
    } else {
      this._typeAheadPointer = -1;
      this.searchInput.nativeElement.blur();
    }

  }

  public closeSelect(): void {
    this._openStatus = false;
    this._typeAheadPointer = -1;
  }

  onSelect(e, data, event: boolean = true) {

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // if (!data.value) {
    //
    //   this._selected = [];
    //   this.optionSelected.emit({option: []});
    //
    // }

    if (typeof this._selected === 'object' && this.areEqual(this._selected, data)) {
      if ( this.clearOption ) {
        this._selected = [];
        if (event) {
          this.optionSelected.emit({option: this._selected});
        }
      } else {
        if (event) {
          this.optionSelected.emit({option: data});
        }
      }
      // this._selected = [];
      // this.optionSelected.emit({option: this._selected});
    } else {
      this.maybeMultiple(data);
      const selected = this._selected;
      if (event) {
        this.optionSelected.emit({option: selected});
      }
    }

    if ( !this.multiple ) {
      this.toggleStatus();

      if (this.searchInput) {
        this.searchInput.nativeElement.blur();
      }

      if ( !this._openStatus ) {
        this.dropDownToggle.emit({openStatus: this._openStatus, selected: this._selected, multiple: this.multiple});
      }
    }

    if (this.closeOnSelect === true) {

      if ( this._openStatus || this.isActive ) {
        this.isActive = false;
        this.closeSelect();
        this.dropDownToggle.emit({openStatus: this._openStatus, selected: this._selected, multiple: this.multiple});
      }

      if (this.searchInput) {
        this.searchInput.nativeElement.blur();
      }

    }

    this.updateActiveDescendent();

  }

  public onNativeSelect(event: any): void {

    if (this.multiple) {
      this._selected = [];
      const nativeOptions = event.target.options;
      for (let i = 0; i < nativeOptions.length; i++) {
        if (!nativeOptions[i].selected) {
          continue;
        }
        const componentOption = this.options.find(o => o.value.toString() === nativeOptions[i].value.toString());
        if (componentOption) {
          this._selected.push(componentOption);
        }
      }
      this.optionSelected.emit({ option: this._selected });
    } else {
      const value = event.target.value;
      if (value && value !== 'null') {
        const option = this.options.find(o => o.value.toString() === event.target.value.toString());
        if (option) {
          this._selected = option;
          this.optionSelected.emit({ option: this._selected });
        }
      } else {
        this._selected = [];
        this.optionSelected.emit({ option: this._selected });
      }
    }
  }

  areEqual(a, b) {
    if (a === undefined || a === null || b === undefined || b === null || typeof a !== 'object' || typeof b !== 'object') {
      return false;
    }

    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;

  }

  private maybeMultiple(data): void {
    if (this.multiple) {
      if (this._selected.length) {
        const thisIndex = this._selected.findIndex((option) => option.value !== null && option.value === data.value);
        if (thisIndex !== -1) {
          this._selected.splice(thisIndex, 1);
        } else {
          this._selected.push(data);
        }
      } else {
        this._selected.push(data);
      }
    } else {
      if (data && data.value === null) {
        this._selected = null;
      } else {
        this._selected = data;
      }
    }
  }

  public isOptionSelected(option): boolean {
    if (!this._selected) {
      return false;
    }
    if (this.multiple) {
      return this._selected.some((item) => option.label === item.label && option.value === item.value);
    } else {
      return option.value === this._selected.value && option.label === this._selected.label;
    }
  }

  isOptionHighLighted (option, index) {
    return this._typeAheadPointer === index;
  }

  public isOptionsSelected(): boolean {
    if (!this._selected) {
      return false;
    }
    return !(this._selected.length === 0 || !Object.entries(this._selected).length);
  }

  clearSelect() {
    this._selected = [];
    this.optionSelected.emit({option: null});
  }

  triggerSelect(id) {
    const selectedOption = this.options.find( o => o.value === id );
    if ( selectedOption ) {
      this.onSelect(null, selectedOption);
    }
  }

  getOptionLabel (option) {
    return this.allowNewTags && option.new ? `${option.label} (New)` : option.label;
  }

  onInput () {
    this._search = this.searchInput ? this.searchInput.nativeElement.value : '';
    this.searchEvent.emit({search: this._search});
    this.availableResultsLive = true;
    setTimeout(() => {
      this.availableResultsLive = false;
    }, 1000);
  }

  onSearchEscape () {
    if (!this.searchInput) {
      return;
    }
    if (!this.searchInput.nativeElement.length) {
      this.searchInput.nativeElement.blur();
    } else {
      if (!this.allowNewTags) {
        this.clearSearch();
      }
    }
  }

  /**
   * Close the dropdown on blur.
   * @emits  {search:blur}
   */
  public onSearchBlur(event): void {

    event.preventDefault();

    if (this.allowNewTags && this._search && this._search !== '') {
      this._typeAheadPointer = 0;
      this.updateActiveDescendent();
      this.onEnter(event, true);
    } else {
      if (this.clearSearchOnBlur) {
        this.clearSearch();
      }
    }

    if (!this._eref.nativeElement.contains(event.relatedTarget) && this._openStatus) {
      this.closeSelect();
      this.clearSearch();
      this.searchInput.nativeElement.blur();
      this.dropDownToggle.emit({ openStatus: this._openStatus, selected: this._selected, multiple: this.multiple });
    }

    this._canDisplayPlaceholder = false;

  }

  /**
   * Open the dropdown on focus.
   * @emits  {search:focus}
   */
  public onSearchFocus(event: any): void {
    event.preventDefault();
    if (!this._openStatus) {
      this._openStatus = this.customActive ? this.isActive : true;
    }
    setTimeout(() => {
      this._canDisplayPlaceholder = true;
    }, 0);
  }

  private clearSearch(): void {
    if (!this.allowNewTags) {
      this._search = '';
      this.searchInput.nativeElement.value = '';
    }
  }

  /**
   * The currently displayed options, filtered
   * by the search elements value. If tagging
   * true, the search text will be prepended
   * if it doesn't already exist.
   */
  get filteredOptions(): SelectItemInterface[] {
    if (!this.options) {
      return [];
    }
    let options = this.options.filter((option) => {
      if (typeof option === 'object' && option.hasOwnProperty('label')) {
        return option['label'] && option['label'].toLowerCase().indexOf(this._search.toLowerCase()) > -1;
      } else if (typeof option === 'object' && !option.hasOwnProperty('label')) {
        return console.warn(`Label key "option. label does not exist in options object.`);
      }
      return option.toLowerCase().indexOf(this._search.toLowerCase()) > -1;
    });

    if (this.allowNewTags && this._search && this._search !== '') {
      const maybeNotExists = options.findIndex(o => o.label.toLowerCase() ===  this._search.toLowerCase());
      if (maybeNotExists === -1) {
        options = [
          {
            'label': this._search,
            'value': this._search,
            'new': true
          },
          ...options
        ];
      } else {
        options.splice(0, 0, options.splice(maybeNotExists, 1)[0]);
      }
    }

    if (this.clearOption) {
      let allText = '';
      this.translateService.get('select.ALL').subscribe((res: string) => {
        allText = res;
      });
      options = [
        {
          'label': this.clearText || allText,
          'value': null
        },
        ...options
      ];
    }

    return options || [];
  }

  public typeAheadUp(event) {
    event.preventDefault();
    if (this._typeAheadPointer > 0) {
      this._typeAheadPointer--;
      this.updateActiveDescendent();
      this.maybeAdjustScroll();
    }
    this._openStatus = true;
  }

  public typeAheadDown(event): void {
    event.preventDefault();
    if (this._typeAheadPointer < this.filteredOptions.length - 1) {
      this._typeAheadPointer++;
      this.updateActiveDescendent();
      this.maybeAdjustScroll();
    }
    this._openStatus = true;
  }

  public typeAheadHome(event): void {
    event.preventDefault();
    this._typeAheadPointer = 0;
    this.updateActiveDescendent();
    this.maybeAdjustScroll();
    this._openStatus = true;
  }

  public typeAheadEnd(event): void {
    event.preventDefault();
    this._typeAheadPointer = this.filteredOptions.length - 1;
    this.updateActiveDescendent();
    this.maybeAdjustScroll();
    this._openStatus = true;
  }

  public onEscape(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this._typeAheadPointer > -1) {
      this._typeAheadPointer = -1;
      return;
    }
    this.onSearchEscape();
    this.closeSelect();
  }

  public onSpace(event): void {
    if (this._typeAheadPointer > -1 && this.checkbox) {
      event.preventDefault();
      this.onEnter(event, false);
      return;
    }
  }

  public onBlur(event: any): void {
    event.preventDefault();
    if (event.relatedTarget && event.relatedTarget.closest('.erom-select-search')) {
      return;
    }
    setTimeout(() => {
      if (this._openStatus) {
        this._openStatus = false;
      }
    }, 300);
  }

  public focusSearchInput(): void {
    this.searchInput.nativeElement.focus();
  }

  public toggleStatus(): void {
    this._openStatus = this.customActive ? this.isActive : !this._openStatus;
    if (this._openStatus === false) {
      this._typeAheadPointer = -1;
    }
  }

  public maybeAdjustScroll(): void {
    const pixelsToPointerTop = this.pixelsToPointerTop();
    const pixelsToPointerBottom = this.pixelsToPointerBottom();

    if (pixelsToPointerTop <= this.viewport()['top']) {
      return this.scrollTo( pixelsToPointerTop);
    } else if (pixelsToPointerBottom >= this.viewport()['bottom']) {
      return this.scrollTo(this.viewport()['top'] + this.pointerHeight());
    }
  }

  private pixelsToPointerTop(): number {
    let pixelsToPointerTop = 0;
    if (this.dropdownMenuList.nativeElement) {
      for (let i = 0; i < this._typeAheadPointer; i++) {
        pixelsToPointerTop += this.dropdownMenuList.nativeElement.children[i].offsetHeight;
      }
    }
    return pixelsToPointerTop;
  }

  private pixelsToPointerBottom(): number {
    return this.pixelsToPointerTop() + this.pointerHeight();
  }

  private pointerHeight(): number {
    const element = this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.children[this._typeAheadPointer] : false;
    return element ? element.offsetHeight : 0;
  }

  private viewport(): object {
    return {
      top: this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.scrollTop : 0,
      bottom: this.dropdownMenuList.nativeElement ?
        this.dropdownMenuList.nativeElement.offsetHeight + this.dropdownMenuList.nativeElement.scrollTop : 0
    };
  }

  private scrollTo(position): void {
    return this.dropdownMenuList.nativeElement ? this.dropdownMenuList.nativeElement.scrollTop = position : null;
  }

  private generateDescendentIds(): void {
    this._descendentId = null;
    if (this.multiple && Array.isArray(this._selected) && this._selected.length) {
      const realIndex = this.filteredOptions.findIndex((item) =>
        item.value === this._selected[0].value && item.label === this._selected[0].label);
      this._descendentId = this.id + '-item-' + realIndex;
    } else if (this._selected) {
      const realIndex = this.filteredOptions.findIndex((item) =>
        item.value === this._selected.value && item.label === this._selected.label);
      this._descendentId = this.id + '-item-' + realIndex;
    }
  }

  public onRight(e): void {
    if (this._typeAheadPointer === -1) {
      return;
    }
    this.onEnter(e, false);
  }

  public onEnter(e, closeAfterSelect): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.allowNewTags && this._search && this._search !== '') {
      this._typeAheadPointer = 0;
      this.updateActiveDescendent();
    }

    const data = this.filteredOptions[this._typeAheadPointer];

    if (!data) {
      return;
    }

    // if (!data.value) {
    //
    //   this._selected = [];
    //   this.optionSelected.emit({option: []});
    //
    // } else

    if (this._selected === data) {

      if ( this.clearOption ) {
        this._selected = [];
        this.optionSelected.emit({option: this._selected});
      } else {
        this.optionSelected.emit({option: data});
      }

    } else {
      this.maybeMultiple(data);
      const selected = this._selected;
      this.optionSelected.emit({option: selected});
    }

    if ( !this.multiple ) {
      this.toggleStatus();

      if ( !this._openStatus ) {
        this.dropDownToggle.emit({openStatus: this._openStatus, selected: this._selected, multiple: this.multiple});
      }
    }

    if (closeAfterSelect && this.closeOnSelect === true) {

      if ( this._openStatus || this.isActive ) {
        this.isActive = false;
        this.closeSelect();
        this.dropDownToggle.emit({openStatus: this._openStatus, selected: this._selected, multiple: this.multiple});
      }

    }

  }

  private updateActiveDescendent(): void {
    this._descendentId = this.id + '-item-' + this._typeAheadPointer;
  }

  /**
   * Return the current state of the
   * search input
   * @return {Boolean} True if non empty value
   */
  searching () {
    return !!this._search;
  }

  /**
   * Classes to be output on .dropdown
   */
  public get dropdownClasses(): object {

    const classes = {
      active: this.isOpened,
      single: !this.multiple,
      searching: this.searching(),
      searchable: this.searchable,
      unsearchable: !this.searchable,
      selected: this.isOptionsSelected(),
      native: this.isMobile
    };

    if (this.name) {
      classes['column_' + this.name] = true;
    }

    return classes;
  }

  public reset(event: boolean = true): void {
    this._openStatus = false;
    this._selected = [];
    this._search = '';
    this._typeAheadPointer = -1;
    this._descendentId = null;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    if (event) {
      this.optionSelected.emit({option: this._selected});
    }
  }

  public clear(event: boolean = true): void {
    this._selected = [];
    this._search = '';
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    if (event) {
      this.optionSelected.emit({option: this._selected});
    }
  }

  public selectOption(option: SelectItemInterface, event: boolean = true): void {
    this.onSelect(null, option, event);
  }

  public selectOptions(options: SelectItemInterface[]): void {
    for (let i = 0; i < options.length; i++) {
      this.maybeMultiple(options[i]);
    }
  }

  get isOpened (): boolean {
    return this.customActive ? this.isActive : this._openStatus;
  }

  get hasItems(): boolean {
    return (this.filteredOptions && this.filteredOptions.length > 0) || false;
  }

  public get descendentId(): string {
    // if (this._typeAheadPointer > -1) {
    //   return this._descendentId;
    // }
    // return null;
    return this._descendentId;
  }

  public get openStatus(): boolean {
    return this._openStatus;
  }

  public get selected(): any {
    if (Array.isArray(this._selected) && !this._selected.length) {
      return null;
    }
    return this._selected;
  }

  public get countSelected(): number {
    if (this.multiple) {
      return this._selected.length;
    }
    return this._selected ? 1 : null;
  }

  public get placeholderText(): string {
    let placeholder = '';
    if (this._canDisplayPlaceholder) {
      placeholder = this.searchPlaceholder || this.placeholder || (!this.multiple && this.selected ? this.selected.label : '');
    } else {
      if (!this.multiple && this.selected) {
        placeholder = '';
      } else if (this.customTitle) {
        placeholder = this.customTitle;
      } else {
        placeholder = this.label || this.placeholder || this.ariaLabel || '';
      }
    }
    if (placeholder !== '' && !this.isMobile && this.showLabelCounter && this.countSelected && !this._canDisplayPlaceholder) {
      placeholder += ` (${this.countSelected})`;
    }
    return placeholder;
  }

  public get selectedLabel(): string {
    let placeholder;
    if (this._selected && !Array.isArray(this._selected)) {
      placeholder = this._selected.label;
    }
    return placeholder || '';
  }

  public get labelText(): string {
    let label = '';
    if (this.label) {
      label += this.label;
    }
    if (!this.isMobile && this.showLabelCounter && this.countSelected) {
      label += ` (${this.countSelected})`;
    }
    return label;
  }

  public get canDisplayPlaceholder(): boolean {
    return this._canDisplayPlaceholder;
  }

  public get selectId(): string {
    return this.id || EromSelectComponent.generateIdString(8);
  }

  public setSelected(value: SelectItemInterface[]): void {
    if (this.multiple) {
      this._selected.concat(value);
    } else {
      this._selected = value;
    }
  }

  public get isMobile(): boolean {
    return this._isMobile || false;
  }

  @HostListener('click', ['$event'])
  onDocumentClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && !this._preventCloseDropDown) {
      if ( this._openStatus ) {
        this.closeSelect();
        this.dropDownToggle.emit({openStatus: this._openStatus, selected: this._selected, multiple: this.multiple});
      }
    }
    this._preventCloseDropDown = false;
  }
}
