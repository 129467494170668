import { Injectable } from '@angular/core';
import { GridType } from '../_types';

@Injectable({
  providedIn: 'root',
})
export class EromEditorGlobals {

  private readonly _defaultTinyMceOptions: object;
  private readonly _modelEvents: string;
  private readonly _gridOptions: Array<any>;

  constructor() {
    const localApp = location.hostname === 'localhost';
    this._defaultTinyMceOptions = {
      branding: false,
      menubar: false,
      statusbar: false,
      toolbar_mode: 'scrolling',
      mode: 'exact',
      indentation : '8px',
      skin_url: `${!localApp ? '/admin' : ''}/erom-editor/skins/ui/erom-editor`,
      icons_url: `${!localApp ? '/admin' : ''}/erom-editor/skins/icons/icons.js`,
      skin: 'erom-editor',
      icons: 'erom-editor',
      paste_data_images: true
    };
    this._modelEvents = 'change input undo redo modelEvents';
    this._gridOptions = [
      {
        label: 'One (100%)',
        value: GridType.ONE_FULL
      },
      {
        label: 'Two (50-50%)',
        value: GridType.TWO_50_50
      },
      {
        label: 'Two (75-25%)',
        value: GridType.TWO_75_25
      },
      {
        label: 'Two (65-35%)',
        value: GridType.TWO_65_35
      },
      {
        label: 'Two (25-75%)',
        value: GridType.TWO_25_75
      },
      {
        label: 'Three (25-25-50%)',
        value: GridType.THREE_25_25_50
      },
      {
        label: 'Three (25-50-25%)',
        value: GridType.THREE_25_50_25
      },
      {
        label: 'Three (50-25-25%)',
        value: GridType.THREE_50_25_25
      },
      {
        label: 'Four (25-25-25-25%)',
        value: GridType.FOUR_25_25_25_25
      }
    ];
  }

  public get defaultTinyMceOptions(): object {
    return this._defaultTinyMceOptions;
  }

  public get modelEvents(): string {
    return this._modelEvents;
  }

  public get gridOptions(): Array<any> {
    return this._gridOptions;
  }

  public gridColumnsCountByGrid(type: GridType): number {
    let columns = 1;
    switch (type) {
      case GridType.ONE_FULL:
        columns = 1;
        break;
      case GridType.TWO_50_50:
      case GridType.TWO_75_25:
      case GridType.TWO_65_35:
      case GridType.TWO_25_75:
        columns = 2;
        break;
      case GridType.THREE_25_25_50:
      case GridType.THREE_25_50_25:
      case GridType.THREE_50_25_25:
        columns = 3;
        break;
      case GridType.FOUR_25_25_25_25:
        columns = 4;
        break;
      default:
        break;
    }
    return columns;
  }
}
