import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { EromEditorGlobals } from '../../_store';
import { BlockType, SettingType } from '../../_types';
import { getHtmlStringStyle, setBlockStyle, uuid } from '../../_common';
import { BlockOptionsInterface } from '../../_interfaces';
import { API } from '../../../../../_store';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'full-editor-block',
  template: '<template></template>',
  styleUrls: ['./full-editor.component.scss'],
  styles: [':host { display: block; }']
})

export class FullEditorComponent extends GenericBlock {

  public static type = BlockType.FullEditor;
  public static tagNames = [];
  public static blockCategory = 'Text';
  public static blockName = 'Full editor';
  public static structure = {
    id: null,
    type: BlockType.FullEditor,
    data: {
      text: ''
    }
  };

  public static options: BlockOptionsInterface[] = [
    {
      name: 'Dimensions',
      type: SettingType.Category,
      items: [
        {
          name: 'Maximum width',
          type: SettingType.Group,
          subtype: SettingType.Radio,
          command: 'maxWidth',
          hasCustom: true,
          default: '920px',
          options: [
            {
              name: 'Full screen',
              value: '100%'
            }
          ]
        }
      ]
    }
  ];

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="newspaper" class="svg-inline--fa fa-newspaper fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M552 64H112c-20.858 0-38.643 13.377-45.248 32H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h496c13.255 0 24-10.745 24-24V88c0-13.255-10.745-24-24-24zM48 392V144h16v248c0 4.411-3.589 8-8 8s-8-3.589-8-8zm480 8H111.422c.374-2.614.578-5.283.578-8V112h416v288zM172 280h136c6.627 0 12-5.373 12-12v-96c0-6.627-5.373-12-12-12H172c-6.627 0-12 5.373-12 12v96c0 6.627 5.373 12 12 12zm28-80h80v40h-80v-40zm-40 140v-24c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H172c-6.627 0-12-5.373-12-12zm192 0v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-144v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0 72v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12z"></path></svg>';

  constructor(
    private api: API,
    private http: HttpClient,
    private eromEditorGlobals: EromEditorGlobals,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public init(): void {
    const target = this.target;
    this.editableAreas = [
      {
        id: uuid('erom-block'),
        target,
        editorOptions: {
          ...this.eromEditorGlobals.defaultTinyMceOptions,
          forced_root_block: 'p',
          plugins: 'link anchor table advtable media mediaembed codesample imagetools',
          toolbar_mode: 'floating',
          toolbar: 'bold italic formatgroup | formatselect table | aligngroup |  numlist bullist checklist | colorgroup | image media link anchor codesample',
          toolbar_groups: {
            formatgroup: {
              icon: 'more-drawer',
              tooltip: 'Formatting',
              items: 'underline strikethrough superscript subscript | link'
            },
            aligngroup: {
              icon: 'align-left',
              tooltip: 'Paragraph format',
              items: 'alignleft aligncenter alignright alignjustify | indent outdent'
            },
            colorgroup: {
              icon: 'color-picker',
              tooltip: 'Styles',
              items: 'forecolor backcolor'
            }
          },
          formats : {
            'alignleft' : {'selector' : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: {"align":  'left'}},
            'aligncenter' : {'selector' : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: {"align":  'center'}},
            'alignright' : {'selector' : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: {"align":  'right'}},
            'alignfull' : {'selector' : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: {"align":  'justify'}}
          },
          images_upload_handler: (blobInfo, success, failure) => {
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            this.http.post(`${this.api.URLS.file}/upload`, formData).subscribe((response: any) => {
              success(response.file.path);
            }, (error) => {
              failure(error);
            });
          },
          customFunctions: {
            onExecCommand: (editor) => editor.on('ExecCommand', (e) => {
              switch (e['command']) {
                case 'indent':
                case 'outdent':
                  const paddingLeft = getHtmlStringStyle(editor.getContent({format: 'html'}), 'paddingLeft');
                  setBlockStyle(this.block, 'paddingLeft', paddingLeft);
                  break;
                case 'JustifyLeft':
                case 'JustifyCenter':
                case 'JustifyRight':
                case 'JustifyFull':
                  const textAlign = getHtmlStringStyle(editor.getContent({format: 'html'}), 'textAlign');
                  setBlockStyle(this.block, 'textAlign', textAlign);
                  break;
              }
            }),
            onModelEvents: (editor) => editor.on('init', (e: Event) => {
              editor.on(this.eromEditorGlobals.modelEvents, (event) => {
                if (!this.block.data || !this.block.data.text) {
                  this.block.data = {
                    text: ''
                  };
                }
                this.block.data.text = editor.getContent({ format: 'html' }).toString();
              });
              editor.addCommand('maxWidth', (ui, v) => {
                setBlockStyle(this.block, 'maxWidth', v);
                editor.bodyElement.style.maxWidth = v;
              });
            })
          }
        }
      }
    ];
    this.elementRef.nativeElement.appendChild(target);
  }

  get target(): HTMLElement {
    const div = this.document.createElement('DIV');
    if (this.block.data && this.block.data.text && this.block.data.text !== '') {
      div.innerHTML = this.block.data.text;
    }
    div.style.maxWidth = this.block.style && this.block.style.maxWidth ? this.block.style.maxWidth : '';
    return div;
  }

  public get type(): string {
    return FullEditorComponent.type;
  }

  public get tagNames(): Array<string> {
    return FullEditorComponent.tagNames;
  }

  public get blockName(): string {
    return FullEditorComponent.blockName;
  }

  public get blockCategory(): string {
    return FullEditorComponent.blockCategory;
  }

  public get icon(): string {
    return FullEditorComponent.icon;
  }

  public get structure(): object {
    return FullEditorComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return FullEditorComponent.options;
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (event.target.nodeName === 'A' && event.target.href.indexOf('#') === location.origin.length + 1) {
      event.preventDefault();
      event.stopPropagation();
      window.location.hash = event.target.hash;
    }
  }

}
