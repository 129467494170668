<h2 class="erom-editor-accordion-header" [ngClass]="{ 'is-open': isOpen }">
  <button type="button"
          class="erom-editor-accordion-header__trigger"
          [id]="'erom-editor-accordion-' + index"
          (click)="togglePanel()"
          (focus)="setFocus()"
          (blur)="setBlur()"
          [attr.aria-expanded]="isOpen"
          [attr.aria-controls]="'erom-editor-accordion-header-panel-' + index">
    <span class="erom-editor-accordion-header__title">{{ headerTitle }}</span>
    <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
  </button>
</h2>

<div [id]="'erom-editor-accordion-header-panel-' + index"
     [attr.aria-labelledby]="'erom-editor-accordion-' + index"
     role="region">
  <ng-content *ngIf="isOpen"></ng-content>
</div>
