<div class="erom-editor-toolbar"
     role="toolbar"
     [attr.aria-label]="label"
     [attr.aria-controls]="id"
     [attr.aria-hidden]="hidden"
     (keydown)="handleKeyDown($event)">
  <ng-content></ng-content>
  <!--<div class="erom-editor-toolbar__items" [attr.aria-hidden]="hidden" [class.hidden]="hidden">
    <div class="erom-editor-toolbar__group">
      <button class="erom-editor-toolbar__item bold popup"
              #button
              (click)="buttonClicked($event)"
              [attr.aria-pressed]="isPressed(button)"
              value="bold"
              tabindex="0">
        <span class="fas fa-bold" aria-hidden="true"></span>
        <span class="popup-label">
          Bold
        </span>
      </button>
      <button class="erom-editor-toolbar__item italic popup"
              aria-pressed="false"
              value="italic"
              tabindex="-1">
        <span class="fas fa-italic" aria-hidden="true"></span>
          <span class="popup-label">
          Italic
        </span>
      </button>
      <button class="erom-editor-toolbar__item underline popup"
              aria-pressed="false"
              value="underline"
              tabindex="-1">
        <span class="fas fa-underline" aria-hidden="true"></span>
        <span class="popup-label">
        Underline
      </span>
      </button>
    </div>
    <div class="erom-editor-toolbar__group"
         role="radiogroup"
         aria-label="Text Alignment">
      <button role="radio"
              class="erom-editor-toolbar__item align-left popup"
              aria-checked="true"
              tabindex="-1">
        <span class="fas fa-align-left" aria-hidden="true"></span>
        <span class="popup-label">
        Text Align Left
      </span>
      </button>
      <button role="radio"
              class="erom-editor-toolbar__item align-center popup"
              aria-checked="false"
              tabindex="-1">
        <span class="fas fa-align-center" aria-hidden="true"></span>
        <span class="popup-label">
        Text Align Center
      </span>
      </button>
      <button role="radio"
              class="erom-editor-toolbar__item align-right popup"
              aria-checked="false"
              tabindex="-1">
        <span class="fas fa-align-right" aria-hidden="true"></span>
        <span class="popup-label">
        Text Align Right
      </span>
      </button>
    </div>
    <div class="erom-editor-toolbar__group">
      <button class="erom-editor-toolbar__item copy"
              aria-disabled="true"
              tabindex="-1">
        Copy
      </button>
      <button class="erom-editor-toolbar__item paste"
              aria-disabled="true"
              tabindex="-1">
        Paste
      </button>
      <button class="erom-editor-toolbar__item cut"
              aria-disabled="true"
              tabindex="-1">
        Cut
      </button>
    </div>
    <div class="menu-popup erom-editor-toolbar__group">
      <button aria-haspopup="true"
              aria-controls="menu1"
              class="erom-editor-toolbar__item menu-button"
              tabindex="-1"
              aria-label="Font: Sans-serif"
              style="text-align: left; width: 140px; font-family: sans-serif">
        SANS-SERIF
        <span></span>
      </button>
      <ul role="menu"
          id="menu1"
          aria-label="Font Family">
        <li role="menuitemradio"
            aria-checked="true"
            style="font-family: sans-serif">
          Sans-serif
        </li>
        <li role="menuitemradio"
            aria-checked="false"
            style="font-family: serif">
          Serif
        </li>
        <li role="menuitemradio"
            aria-checked="false"
            style="font-family: monospace">
          Monospace
        </li>
        <li role="menuitemradio"
            aria-checked="false"
            style="font-family: fantasy">
          Fantasy
        </li>
        <li role="menuitemradio"
            aria-checked="false"
            style="font-family: cursive">
          Cursive
        </li>
      </ul>
    </div>
    <div class="erom-editor-toolbar__group">
      <div class="erom-editor-toolbar__item spinbutton"
           role="spinbutton"
           tabindex="-1"
           aria-valuenow="14"
           aria-valuetext="14pt"
           aria-valuemin="8"
           aria-valuemax="40"
           aria-label="Font size in points">
      <span class="value">
        14pt
      </span>
        <span class="increase">
        <svg xmlns="http://www.w3.org/2000/svg"
             width="12"
             height="12">
          <polygon class="increase"
                   fill="#333"
                   points="0,12 11,12 6,2"></polygon>
        </svg>
      </span>
        <span class="decrease">
        <svg xmlns="http://www.w3.org/2000/svg"
             width="12"
             height="12">
          <polygon class="decrease"
                   fill="#333"
                   points="0,2 11,2 6,12"></polygon>
        </svg>
      </span>
      </div>
    </div>
    <div class="erom-editor-toolbar__group">
      <label class="input">
        <input id="checkbox"
               class="erom-editor-toolbar__item nightmode"
               type="checkbox"
               tabindex="-1">
        Night Mode
      </label>
      <a id="link"
         class="erom-editor-toolbar__item link"
         href="help.html"
         tabindex="-1">
        Help
      </a>
    </div>
  </div>-->
</div>
