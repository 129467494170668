<div class="mce-content-body erom-block__iframe immutable-block" [class.mce-edit-focus]="block.autoFocus">
  <ng-container *ngIf="block.autoFocus; else iframePreview">
    <div class="erom-block__iframe--editable" contenteditable="true" (input)="updateIframe($event)">
      {{ block.data ? block.data.source : '' }}
    </div>
  </ng-container>
  <ng-template #iframePreview>
    <div class="erom-block__iframe--preview" [innerHTML]="(block.data ? block.data.source : '') | safeHtml"></div>
  </ng-template>
</div>
