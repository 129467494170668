import * as blocks from './blocks';
import { importToArray } from '../_common';

const BLOCK_COMPONENTS = importToArray(blocks);
const BLOCKS_COMPONENTS_MAP = BLOCK_COMPONENTS.reduce<any>((result, value, index, array) => {
  result[value.type] = value;
  return result;
}, {});

export { BLOCK_COMPONENTS, BLOCKS_COMPONENTS_MAP };
