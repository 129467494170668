import { FormArray, FormControl, FormGroup } from '@angular/forms';

const convertDateTime = (
  payload: string,
  format: string = 'd f Y',
  includeTime: boolean = true,
  dateDelimiter: string = ' ',
  dateTimeDelimiter: string = ' at ',
  dayFirst = false,
  ampm: boolean = true
): string => {

  let response = 'n/a';

  if (!payload) {
    return response;
  }

  if (dayFirst) {
    const pieces = payload.split('/');
    payload = pieces[2] + '-' + pieces[1] + '-' + pieces[0];
  }

  let date = new Date(payload);

  if (isNaN(date.getTime())) {
    date = new Date(payload.replace(' ', 'T')); // https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios
  }

  if (isNaN(date.getTime())) {
    return response;
  }

  const fullYear = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const toDoubleDigit = (num): string => {
    return num.toString().length < 2 ? '0' + num : num.toString();
  };

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  let dateString;
  let timeString;

  switch (format) {
    case 'F Y':
      dateString = monthNames[month] + dateDelimiter + fullYear;
      break;
    case 'f Y':
      dateString = shortMonthNames[month] + dateDelimiter + fullYear;
      break;
    case 'd F Y':
      dateString = toDoubleDigit(day) + dateDelimiter + monthNames[month] + dateDelimiter + fullYear;
      break;
    case 'F d Y':
      dateString = monthNames[month] + dateDelimiter + toDoubleDigit(day) + dateDelimiter + fullYear;
      break;
    case 'd f Y':
      dateString = toDoubleDigit(day) + dateDelimiter + shortMonthNames[month] + dateDelimiter + fullYear;
      break;
    case 'd m Y':
      dateString = toDoubleDigit(day) + dateDelimiter + toDoubleDigit(month + 1) + dateDelimiter + fullYear;
      break;
    case 'Y m d':
      dateString = fullYear + dateDelimiter + toDoubleDigit(month + 1) + dateDelimiter + toDoubleDigit(day);
      break;
    default:
      dateString = toDoubleDigit(day) + dateDelimiter + toDoubleDigit(month + 1) + dateDelimiter + fullYear;
      break;
  }

  if (dateString) {
    response = dateString;
  }

  if (isNaN(date.getTime())) {
    return response;
  }

  if (includeTime) {
    if (ampm) {
      timeString = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    } else {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      timeString = hours + ':' + minutes;
    }

    if (timeString) {
      response += (dateTimeDelimiter + timeString);
    }
  }

  return response;

};
const convertDateTimeToISOString = (payload: string): string => {
  if (!payload) {
    return null;
  }
  const date = new Date(payload);
  return date.toISOString();
};

const markAsTouched = (group: FormGroup | FormArray, touched = true) => {
  group.markAsTouched({ onlySelf: touched });
  Object.keys(group.controls).map((field) => {
    const control = group.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: touched });
    } else if (control instanceof FormGroup) {
      markAsTouched(control);
    }
  });
};

const slugify = (text: string) => {
  if (!text) {
    return '';
  }
  text = text.replace(/^\s+|\s+$/g, '');
  text = text.toLowerCase();
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  return text.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

const removeFocusable = (excludedClassOrClassesWithChildren: string|Array<string> = null) => {
  let excludeThese = [];
  if (excludedClassOrClassesWithChildren) {
    if (Array.isArray(excludedClassOrClassesWithChildren)) {
      for (let i = 0; i < excludedClassOrClassesWithChildren.length; i++) {
        const elements = Array.from(document.querySelectorAll(excludedClassOrClassesWithChildren[i]));
        if (elements) {
          excludeThese = excludeThese.concat(...elements);
        }
      }
    } else {
      const elements = Array.from(document.querySelectorAll(excludedClassOrClassesWithChildren));
      if (elements) {
        excludeThese = excludeThese.concat(...elements);
      }
    }
  }
  const focusableElements = Array.from(
    document.querySelectorAll('a[href],area[href],button,input,textarea,select,iframe,details,[tabindex]:not([tabindex^="-"])')
  ).filter(element => !element.hasAttribute('disabled'));
  for (let i = 0; i < focusableElements.length; i++) {
    for (let j = 0; j < excludeThese.length; j++) {
      if (!excludeThese[j].contains(focusableElements[i])) {
        const oldTabIndex = focusableElements[i].getAttribute('tabindex');
        const oldAriaHidden = focusableElements[i].getAttribute('aria-hidden');
        focusableElements[i].setAttribute('data-old-tabindex', oldTabIndex);
        focusableElements[i].setAttribute('data-old-aria-hidden', oldAriaHidden);
        focusableElements[i].setAttribute('tabindex', '-1');
        focusableElements[i].setAttribute('aria-hidden', 'true');
      }
    }
  }
};

const addFocusable = () => {
  const changedAttributeElements = Array.from(document.querySelectorAll('[data-old-tabindex],[data-old-aria-hidden]'));
  for (let i = 0; i < changedAttributeElements.length; i++) {
    const oldTabIndex = changedAttributeElements[i].getAttribute('data-old-tabindex');
    const oldAriaHidden = changedAttributeElements[i].getAttribute('data-old-aria-hidden');
    if (oldTabIndex !== 'null') {
      changedAttributeElements[i].setAttribute('tabindex', oldTabIndex);
    } else {
      changedAttributeElements[i].removeAttribute('tabindex');
    }
    if (oldAriaHidden !== 'null') {
      changedAttributeElements[i].setAttribute('aria-hidden', oldAriaHidden);
    } else {
      changedAttributeElements[i].removeAttribute('aria-hidden');
    }
    changedAttributeElements[i].removeAttribute('data-old-tabindex');
    changedAttributeElements[i].removeAttribute('data-old-aria-hidden');
  }
};

export {
  convertDateTime,
  convertDateTimeToISOString,
  markAsTouched,
  slugify,
  removeFocusable,
  addFocusable
};
