import { Component } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { HeadingComponent } from '../heading/heading.component';
import { ParagraphComponent } from '../paragraph/paragraph.component';
import { ImageComponent } from '../image/image.component';

@Component({
  selector: 'step-block',
  template: '<template></template>',
  styles: [':host { display: block; }']
})

export class StepComponent extends GenericBlock {

  public static type = BlockType.Step;
  public static tagNames = undefined;
  public static blockCategory = 'Immutable';
  public static blockName = 'Step';
  public static structure = {
    id: null,
    type: BlockType.Step,
    data: null,
    virtualComponents: [
      {
        component: HeadingComponent.blockName,
        structure: {
          ...JSON.parse(JSON.stringify(HeadingComponent.structure)),
          data: {
            ...JSON.parse(JSON.stringify(HeadingComponent.structure.data)),
            level: 3
          }
        }
      },
      {
        component: ParagraphComponent.blockName,
        structure: JSON.parse(JSON.stringify(ParagraphComponent.structure))
      },
      {
        component: ImageComponent.blockName,
        structure: JSON.parse(JSON.stringify(ImageComponent.structure))
      }
    ]
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="layer-group" class="svg-inline--fa fa-layer-group fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"></path></svg>';

  constructor() {
    super();
  }

  public init(): void {
  }

  public get type(): string {
    return StepComponent.type;
  }

  public get tagNames(): Array<string> {
    return StepComponent.tagNames;
  }

  public get blockName(): string {
    return StepComponent.blockName;
  }

  public get blockCategory(): string {
    return StepComponent.blockCategory;
  }

  public get icon(): string {
    return StepComponent.icon;
  }

  public get structure(): object {
    return StepComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
