import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { getCookie } from '../_helpers';
import { isPlatformBrowser } from '@angular/common';

declare function ga(...parameters): any;

@Injectable()
export class GoogleAnalyticsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  private get gaInitialized(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return !!(window as any).ga;
    }
    return false;
  }

  public pageView(page: string): void {
    if (!this.gaInitialized) {
      return;
    }
    ga('set', 'page', page);
    ga('send', 'pageview');
  }

  public event(category: string, action: string, label: string = null, type: string = 'event'): void {
    if (!this.gaInitialized) {
      return;
    }
    if (!label) {
      label = isPlatformBrowser(this.platformId) ? location.pathname : '';
    }
    ga('send', {
      hitType: type,
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  }

  public set(type: string, value: string): void {
    if (!this.gaInitialized) {
      return;
    }
    ga('set', type, value);
  }

  public setupOSDimension(): void {
    if (isPlatformBrowser(this.platformId)) {
      const localStorageOS = getCookie('operatingSystem');
      if (localStorageOS) {
        const localStorageOSObject = JSON.parse(localStorageOS);
        this.set('dimension1', localStorageOSObject.label);
      } else {
        this.set('dimension1', 'none');
      }
    }
  }
}
