import { AfterViewInit, EventEmitter, OnDestroy, Output } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  BlockOptionsInterface,
  EromBlockInterface,
  EromEditableAreaInterface,
  EromEditorDataInterface
} from '../_interfaces';
import { uuid } from '../_common';
import { SafeHtml } from '@angular/platform-browser';

@AutoUnsubscribe()
export abstract class GenericBlock implements OnDestroy, AfterViewInit {

  private _block: EromBlockInterface;
  private _editMode: boolean;
  private _pageData: EromEditorDataInterface;

  @Output() blockFocused = new EventEmitter();
  @Output() eromEditorBlockChanged = new EventEmitter<any>();

  public set editableAreas(payload: EromEditableAreaInterface[]) {
    this._block.editableAreas = payload;
  }

  public abstract init(): void;
  public abstract get type(): string;
  public abstract get tagNames(): Array<string>;
  public abstract get icon(): string;
  public abstract get blockName(): string;
  public abstract get blockCategory(): string;
  public abstract get structure(): object;
  public abstract get options(): BlockOptionsInterface[];

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {}

  set block(block: EromBlockInterface) {
    if (!block.initialized) {
      block.id = uuid('erom-block');
      block.initialized = true;
    }
    block.tagNames = this.tagNames;
    block.icon = this.icon;
    block.name = this.blockName;
    block.category = this.blockCategory;
    block.structure = this.structure;
    block.options = this.options;
    this._block = block;
  }

  set pageData(pageData: EromEditorDataInterface) {
    this._pageData = pageData;
  }

  set editMode(editMode: boolean) {
    this._editMode = editMode;
  }

  get block(): EromBlockInterface {
    return this._block;
  }

  get pageData(): EromEditorDataInterface {
    return this._pageData;
  }

  get editMode(): boolean {
    return this._editMode;
  }
}
