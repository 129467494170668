<nav [attr.aria-label]="'front.BREADCRUMB' | translate" class="breadcrumbs">
  <div class="admin-container">
    <div class="breadcrumbs-wrapper">
      <div class="breadcrumbs-body">
        <fa-icon [icon]="['fas', 'map-marker-alt']" class="breadcrumbs-icon"></fa-icon>
        <ol class="breadcrumbs-list">
          <li *ngFor="let breadcrumb of breadcrumbsItems; let i = index"
              class="breadcrumb-item">
            <ng-container *ngIf="i < breadcrumbsItems.length - 1; else noLinkBreadcrumb">
              <a [routerLink]="[breadcrumb.url]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                {{ breadcrumb.label | translate }}
              </a>
            </ng-container>
            <ng-template #noLinkBreadcrumb>
              <span>{{ breadcrumb.label | translate }}</span>
            </ng-template>
          </li>
        </ol>
      </div>
      <div class="breadcrumbs-actions" *ngIf="actions">
        <ol>
          <li *ngFor="let action of actions">
            <button (click)="actionButton($event, action)" [ngClass]="action.action">
              <fa-icon [icon]="['fas', action.icon]"
                       aria-hidden="true"
                       [style.font-size]="action.iconSize ? (action.iconSize + 'px') : ''"
                       *ngIf="action.icon"></fa-icon>{{ action.label | translate }}
            </button>
          </li>
        </ol>
      </div>
    </div>
  </div>
</nav>
