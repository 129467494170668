import { Component, ElementRef, Inject } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { EromEditorGlobals } from '../../_store';
import { BlockType, SettingType } from '../../_types';
import { setBlockStyle, uuid } from '../../_common';
import { BlockOptionsInterface } from '../../_interfaces';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'table-block',
  template: '<template></template>',
  styleUrls: ['./table.component.scss'],
  styles: [':host { display: block; }']
})

export class TableComponent extends GenericBlock {

  public static type = BlockType.Table;
  public static tagNames = ['table'];
  public static blockCategory = 'Text';
  public static blockName = 'Table';
  public static structure = {
    id: null,
    type: BlockType.Table,
    data: {
      text: ''
    }
  };

  public static icon = '<svg x="0px" y="0px"viewBox="0 0 16 14" style="enable-background:new 0 0 16 14;" xml:space="preserve"><path fill="currentColor" d="M14,0c0.541,0,1.01,0.198,1.406,0.594C15.802,0.989,16,1.458,16,2v10c0,0.542-0.198,1.01-0.594,1.406C15.01,13.802,14.541,14,14,14H2c-0.542,0-1.011-0.198-1.406-0.594C0.197,13.01,0,12.542,0,12V2c0-0.542,0.197-1.011,0.594-1.406C0.989,0.198,1.458,0,2,0H14z M7,7V4H2v3H7z M7,12V9H2v3H7z M14,7V4H9v3H14z M14,12V9H9v3H14z"/></svg>';

  public static options: BlockOptionsInterface[] = [
    {
      name: 'Dimensions',
      type: SettingType.Category,
      items: [
        {
          name: 'Maximum width',
          type: SettingType.Group,
          subtype: SettingType.Radio,
          command: 'maxWidth',
          hasCustom: true,
          default: '920px',
          options: [
            {
              name: 'Full screen',
              value: '100%'
            }
          ]
        }
      ]
    },
    {
      name: 'Text',
      type: SettingType.Category,
      items: [
        {
          name: 'Font formatting',
          type: SettingType.Group,
          options: [
            {
              name: 'Font',
              type: SettingType.Select,
              options: [
                {
                  label: 'HK Grotesk',
                  value: 'HK Grotesk,sans-serif'
                },
                {
                  label: 'Arial',
                  value: 'Arial,Helvetica,sans-serif'
                }
              ],
              command: 'FontName',
              width: 100
            },
            {
              name: 'Font size',
              type: SettingType.Input,
              default: '16',
              command: 'FontSize',
              width: 50
            },
            {
              name: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="line-height" class="svg-inline--fa fa-line-height fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M626.29 392H269.71c-7.57 0-13.71 7.16-13.71 16v16c0 8.84 6.14 16 13.71 16h356.58c7.57 0 13.71-7.16 13.71-16v-16c0-8.84-6.14-16-13.71-16zM176 144c14.31 0 21.33-17.31 11.31-27.31l-80-80a16 16 0 0 0-22.62 0l-80 80C-4.64 126 .36 144 16 144h56v224H16c-14.29 0-21.31 17.31-11.29 27.31l80 80a16 16 0 0 0 22.62 0l80-80C196.64 386 191.64 368 176 368h-56V144zm450.31 88h-356.6c-7.57 0-13.71 7.16-13.71 16v16c0 8.84 6.14 16 13.71 16h356.58c7.57 0 13.71-7.16 13.71-16v-16c0-8.84-6.14-16-13.71-16zm0-160h-356.6C262.14 72 256 79.16 256 88v16c0 8.84 6.14 16 13.71 16h356.58c7.57 0 13.71-7.16 13.71-16V88c0-8.84-6.14-16-13.71-16z"></path></svg>Line spacing',
              type: SettingType.Input,
              default: '24',
              command: 'LineHeight',
              width: 50
            }
          ]
        },
        {
          name: 'Styling',
          type: SettingType.Group,
          subtype: SettingType.Checkbox,
          options: [
            {
              name: 'Bold',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bold" class="svg-inline--fa fa-bold fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M314.52 238.78A119.76 119.76 0 0 0 232 32H48a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h16v352H48a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h208a128 128 0 0 0 128-128c0-49.49-28.38-91.92-69.48-113.22zM128 80h88a72 72 0 0 1 0 144h-88zm112 352H128V272h112a80 80 0 0 1 0 160z"></path></svg>',
              command: 'Bold'
            },
            {
              name: 'Italic',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="italic" class="svg-inline--fa fa-italic fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M320 48v16a16 16 0 0 1-16 16h-67l-88 352h59a16 16 0 0 1 16 16v16a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16v-16a16 16 0 0 1 16-16h67l88-352h-59a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h192a16 16 0 0 1 16 16z"></path></svg>',
              command: 'Italic'
            },
            {
              name: 'Underline',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="underline" class="svg-inline--fa fa-underline fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 48h32v208c0 88.22 71.78 160 160 160s160-71.78 160-160V48h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H288a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h32v208a96 96 0 0 1-192 0V48h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H32a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16zm400 416H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16z"></path></svg>',
              command: 'Underline'
            },
            {
              name: 'Strikethrough',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="strikethrough" class="svg-inline--fa fa-strikethrough fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M150.39 208h113.17l-46.31-23.16a45.65 45.65 0 0 1 0-81.68A67.93 67.93 0 0 1 247.56 96H310a45.59 45.59 0 0 1 36.49 18.25l15.09 20.13a16 16 0 0 0 22.4 3.21l25.62-19.19a16 16 0 0 0 3.21-22.4L397.7 75.84A109.44 109.44 0 0 0 310.1 32h-62.54a132.49 132.49 0 0 0-58.94 13.91c-40.35 20.17-64.19 62.31-60.18 108 1.76 20.09 10.02 38.37 21.95 54.09zM496 240H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm-92.5 80h-91.07l14.32 7.16a45.65 45.65 0 0 1 0 81.68 67.93 67.93 0 0 1-30.31 7.16H234a45.59 45.59 0 0 1-36.49-18.25l-15.09-20.13a16 16 0 0 0-22.4-3.21L134.4 393.6a16 16 0 0 0-3.21 22.4l15.11 20.17A109.44 109.44 0 0 0 233.9 480h62.54a132.42 132.42 0 0 0 58.93-13.91c40.36-20.17 64.2-62.31 60.19-108-1.19-13.69-5.89-26.27-12.06-38.09z"></path></svg>',
              command: 'Strikethrough'
            },
            {
              name: 'Subscript',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="subscript" class="svg-inline--fa fa-subscript fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M336 64h-52.28a16 16 0 0 0-13.31 7.12L176 212.73 81.59 71.12A16 16 0 0 0 68.28 64H16A16 16 0 0 0 0 80v16a16 16 0 0 0 16 16h35.16l96 144-96 144H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h52.28a16 16 0 0 0 13.31-7.12L176 299.27l94.41 141.61a16 16 0 0 0 13.31 7.12H336a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-35.16l-96-144 96-144H336a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm160 400h-24V304a16 16 0 0 0-16-16h-32a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 408 352h16v112h-24a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16z"></path></svg>',
              command: 'Subscript'
            },
            {
              name: 'Superscript',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="superscript" class="svg-inline--fa fa-superscript fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M336 64h-52.28a16 16 0 0 0-13.31 7.12L176 212.73 81.59 71.12A16 16 0 0 0 68.28 64H16A16 16 0 0 0 0 80v16a16 16 0 0 0 16 16h35.16l96 144-96 144H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h52.28a16 16 0 0 0 13.31-7.12L176 299.27l94.41 141.61a16 16 0 0 0 13.31 7.12H336a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-35.16l-96-144 96-144H336a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm160 112h-24V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 408 64h16v112h-24a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16z"></path></svg>',
              command: 'Superscript'
            }
          ]
        },
        {
          name: 'Aligning',
          type: SettingType.Group,
          subtype: SettingType.Radiobutton,
          options: [
            {
              name: 'Justify left',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="align-left" class="svg-inline--fa fa-align-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M12.83 352h262.34A12.82 12.82 0 0 0 288 339.17v-38.34A12.82 12.82 0 0 0 275.17 288H12.83A12.82 12.82 0 0 0 0 300.83v38.34A12.82 12.82 0 0 0 12.83 352zm0-256h262.34A12.82 12.82 0 0 0 288 83.17V44.83A12.82 12.82 0 0 0 275.17 32H12.83A12.82 12.82 0 0 0 0 44.83v38.34A12.82 12.82 0 0 0 12.83 96zM432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path></svg>',
              command: 'JustifyLeft'
            },
            {
              name: 'Justify center',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="align-center" class="svg-inline--fa fa-align-center fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM108.1 96h231.81A12.09 12.09 0 0 0 352 83.9V44.09A12.09 12.09 0 0 0 339.91 32H108.1A12.09 12.09 0 0 0 96 44.09V83.9A12.1 12.1 0 0 0 108.1 96zm231.81 256A12.09 12.09 0 0 0 352 339.9v-39.81A12.09 12.09 0 0 0 339.91 288H108.1A12.09 12.09 0 0 0 96 300.09v39.81a12.1 12.1 0 0 0 12.1 12.1z"></path></svg>',
              command: 'JustifyCenter'
            },
            {
              name: 'Justify right',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="align-right" class="svg-inline--fa fa-align-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 224h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm416 192H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm3.17-384H172.83A12.82 12.82 0 0 0 160 44.83v38.34A12.82 12.82 0 0 0 172.83 96h262.34A12.82 12.82 0 0 0 448 83.17V44.83A12.82 12.82 0 0 0 435.17 32zm0 256H172.83A12.82 12.82 0 0 0 160 300.83v38.34A12.82 12.82 0 0 0 172.83 352h262.34A12.82 12.82 0 0 0 448 339.17v-38.34A12.82 12.82 0 0 0 435.17 288z"></path></svg>',
              command: 'JustifyRight'
            },
            {
              name: 'Justify full',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="align-justify" class="svg-inline--fa fa-align-justify fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>',
              command: 'JustifyFull'
            },
            {
              name: 'Justify none',
              icon: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="align-slash" class="svg-inline--fa fa-align-slash fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M633.82 458.1L496.54 352H528a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H413.73l-82.81-64H528a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H248.12l-82.81-64H528a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16H112a15.82 15.82 0 0 0-15 11.18L45.46 3.38A16 16 0 0 0 23 6.19L3.37 31.46a16 16 0 0 0 2.81 22.45l588.36 454.72a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zM112 416a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h340.83L370 416zm0-64h175.21l-82.8-64H112a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16z"></path></svg>',
              command: 'JustifyNone'
            }
          ]
        }
      ]
    }
  ];

  constructor(
    private eromEditorGlobals: EromEditorGlobals,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public init(): void {
    const target = this.target;
    this.editableAreas = [
      {
        id: uuid('erom-block'),
        target,
        editorOptions: {
          ...this.eromEditorGlobals.defaultTinyMceOptions,
          plugins: 'table link image',
          toolbar_mode: 'floating',
          toolbar: 'tableprops tablegroup | bold italic formatgroup | aligngroup | forecolor',
          toolbar_groups: {
            tablegroup: {
              icon: 'more-drawer',
              tooltip: 'Formatting',
              items: 'tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
            },
            formatgroup: {
              icon: 'more-drawer',
              tooltip: 'Formatting',
              items: 'underline strikethrough superscript subscript | link'
            },
            aligngroup: {
              icon: 'align-left',
              tooltip: 'Paragraph format',
              items: 'alignleft aligncenter alignright'
            }
          },
          table_toolbar: false,
          table_default_attributes: {
            'border': '1'
          },
          table_default_styles: {
            'border-collapse': 'collapse',
            'border-color': '#000000',
            'border-width': '1px',
            'border-style': 'solid',
            'width': '100%'
          },
          table_responsive_width: true,
          table_header_type: 'sectionCells',
          customFunctions: {
            onModelEvents: (editor) => editor.on('init', (e: Event) => {
              editor.on(this.eromEditorGlobals.modelEvents, (event) => {
                if (!this.block.data || !this.block.data.text) {
                  this.block.data = {
                    text: ''
                  };
                }
                this.block.data.text = editor.getContent({ format: 'html' }).toString();
              });
              editor.addCommand('maxWidth', (ui, v) => {
                setBlockStyle(this.block, 'maxWidth', v);
                editor.bodyElement.style.maxWidth = v;
              });
            })
          }
        }
      }
    ];
    this.elementRef.nativeElement.appendChild(target);
  }

  get target(): HTMLElement {
    const div = this.document.createElement('DIV');
    if (this.block.data && this.block.data.text) {
      div.innerHTML = this.block.data.text;
      return div;
    }
    const table = this.document.createElement('table');
    table.id = uuid('table-block');
    table.style.width  = '100%';
    for (let i = 0; i < 3; i++) {
      const tr = table.insertRow();
      for (let j = 0; j < 2; j++) {
        tr.insertCell();
      }
    }
    div.appendChild(table);
    return div;
  }

  public get type(): string {
    return TableComponent.type;
  }

  public get tagNames(): Array<string> {
    return TableComponent.tagNames;
  }

  public get blockName(): string {
    return TableComponent.blockName;
  }

  public get blockCategory(): string {
    return TableComponent.blockCategory;
  }

  public get icon(): string {
    return TableComponent.icon;
  }

  public get structure(): object {
    return TableComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return TableComponent.options;
  }

}
