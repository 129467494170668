import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Input,
  OnDestroy, Output,
  QueryList,
  ViewChildren, ViewContainerRef
} from '@angular/core';
import { SelectItemInterface } from '../../../erom-select/_interfaces';
import { TabTriggerComponent } from '../tabs';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GenericBlockCategoryInterface, GenericBlockInterface } from '../_interfaces';
import { uuid } from '../_common';
import { map } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'erom-editor-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class EromEditorSelectorComponent implements AfterViewInit, OnDestroy {

  @Input() empty: boolean;
  @Input() inside: boolean;
  @Input() templates: SelectItemInterface[];
  @Input() set blocks(payload: GenericBlockInterface[]) {
    this._blocks = payload;
    this.applyFilters();
  }
  @Input() set sections(payload: Array<any>) {
    if (!payload || !payload.length) {
      return;
    }
    this._sectionTemplates = payload.filter(s => s.locked === 0);
    this._reusableSections = payload.filter(s => s.locked === 1);
    this.applyFilters();
  }

  @Output() blockAdded = new EventEmitter();
  @Output() sectionAdded = new EventEmitter();

  @ViewChildren(TabTriggerComponent, { read: ViewContainerRef }) tabElements: QueryList<ViewContainerRef>;

  private _tabsSubscription: Subscription;

  private _tabs: ElementRef[];
  private _activated: number;
  private _focused: number;

  private _activeComponentsSelector: boolean;

  private _blocks: GenericBlockInterface[];
  private _sectionTemplates: Array<any>;
  private _reusableSections: Array<any>;

  private _filteredSectionTemplates: Array<any>;
  private _filteredReusableSections: Array<any>;
  private _filteredGroupedBlocks: GenericBlockCategoryInterface[];

  private _keyword: string;

  public searchFocused: boolean;

  readonly _groupedBlocks: GenericBlockCategoryInterface[];
  readonly _id: string;

  constructor(
    private element: ElementRef,
    private sanitizer: DomSanitizer
  ) {
    this._tabs = [];
    this._activated = 0;
    this._groupedBlocks = [];
    this._id = uuid('erom-selector');
  }

  public ngAfterViewInit(): void {
    this._tabsSubscription = this.tabElements.changes.subscribe(() => {
      this._tabs = this.tabElements.map((tab) => {
        return tab.element
      });
    });
    for (let i = 0; i < this._blocks.length; i++) {
      const group = this._groupedBlocks.find((g) => g.name === this._blocks[i].blockCategory);
      if (group) {
        group.children.push(this._blocks[i]);
        continue;
      }
      this._groupedBlocks.push({
        name: this._blocks[i].blockCategory,
        children: [this._blocks[i]]
      });
    }
  }

  public ngOnDestroy(): void {}

  private applyFilters(): void {
    if (!this.searchEmpty) {
      this._filteredGroupedBlocks = this._groupedBlocks || [];
      this._filteredReusableSections = this._reusableSections || [];
      this._filteredSectionTemplates = this._sectionTemplates || [];
    } else {
      if (this._groupedBlocks) {
        this._filteredGroupedBlocks = this._groupedBlocks.filter(g => g.children.some(c =>
          c.blockName.toLowerCase().indexOf(this._keyword.toLowerCase()) > -1)).map(g => {
          return {
            ...g,
            children: g.children.filter(c => c.blockName.toLowerCase().indexOf(this._keyword.toLowerCase()) > -1)
          };
        });
      }
      if (this._reusableSections) {
        this._filteredReusableSections = this._reusableSections.filter(r => r.name.toLowerCase().indexOf(this._keyword.toLowerCase()) > -1);
      }
      if (this._sectionTemplates) {
        this._filteredSectionTemplates = this._sectionTemplates.filter(s => s.name.toLowerCase().indexOf(this._keyword.toLowerCase()) > -1);
      }
    }
  }

  public activateComponentsSelector(event): void {
    event.preventDefault();
    event.stopPropagation();
    this._activeComponentsSelector = !this._activeComponentsSelector;
  }

  activatePanel(index: number) {
    this._tabs.forEach(tab => (tab.nativeElement.firstChild.tabIndex = -1));
    this._tabs[index].nativeElement.firstChild.tabIndex = index.toString();
    this._focused = index;
    this._activated = index;
  }

  focusPanel(index: number) {
    this._focused = index;
    this._tabs[this._focused].nativeElement.firstChild.focus();
  }

  handleKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.focusPanel(this._focused ? this._focused - 1 : this._tabs.length - 1);
        break;
      case 'ArrowRight':
        this.focusPanel((this._focused + 1) % this._tabs.length);
        break;
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Home':
        event.preventDefault();
        this.focusPanel(0);
        break;
      case 'End':
        event.preventDefault();
        this.focusPanel(this.tabElements.length - 1);
        break;
    }
  }

  toggleCategory(event): void {
    event.preventDefault();
    event.target.parentElement.classList.toggle('active');
  }

  addBlock(event, block: GenericBlockInterface): void {
    event.preventDefault();
    this.blockAdded.emit({
      block
    });
    this._activeComponentsSelector = false;
  }

  addSection(event, section: any): void {
    event.preventDefault();
    this.sectionAdded.emit({
      section
    });
    this._activeComponentsSelector = false;
  }

  componentIcon(icon: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(icon);
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  public initSearch(event: any): void {
    this._keyword = event.target.value;
    this.applyFilters();
  }

  public clearSearch(): void {
    this._keyword = '';
    this.applyFilters();
  }

  public sectionComponents(components: string): Array<string> {
    return JSON.parse(components);
  }

  public get blockGroups(): GenericBlockCategoryInterface[] {
    return this._filteredGroupedBlocks;
  }

  public get sectionTemplates(): Array<any> {
    return this._filteredSectionTemplates;
  }

  public get reusableSections(): Array<any> {
    return this._filteredReusableSections;
  }

  public get activeComponentsSelector(): boolean {
    return this._activeComponentsSelector;
  }

  public get activated(): number {
    return this._activated;
  }

  public get id(): string {
    return this._id;
  }

  public get includeSections(): boolean {
    return !!this._reusableSections || !!this._sectionTemplates;
  }

  public get searchEmpty(): boolean {
    return !!this._keyword;
  }

  @HostListener('click', ['$event'])
  onDocumentClick(event) {
    if (!this.element.nativeElement.contains(event.target)) {
      if (this._activeComponentsSelector) {
        this._activeComponentsSelector = false;
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    this._activeComponentsSelector = false;
  }

}
