import { Injectable } from '@angular/core';
import { BreakpointsInterface } from '../_interfaces';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class EromIoGlobals {

  readonly _breakpoints: BreakpointsInterface;

  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.use('en');
    this._breakpoints = {
      mobile: 576,
      tablet: 1056,
      smallDesktop: 1366
    }
  }

  get breakpoints(): BreakpointsInterface {
    return this._breakpoints;
  }
}
