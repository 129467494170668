<div class="erom-article-share-block">
  <h2 id="stepHeading">{{ 'editor.STEP_BY_STEP_GUIDE' | translate }}</h2>
  <div class="erom-article-share-block__buttons">
    <button>
      <svg x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
        <g>
          <path fill="currentColor" d="M14,6c0.541,0,1.01,0.198,1.406,0.594S16,7.458,16,8v3c0,0.292-0.094,0.531-0.281,0.719S15.291,12,15,12h-1v3
                    c0,0.291-0.094,0.531-0.281,0.719S13.291,16,13,16H3c-0.291,0-0.531-0.094-0.719-0.281S2,15.291,2,15v-3H1
                    c-0.291,0-0.531-0.094-0.719-0.281S0,11.292,0,11V8c0-0.542,0.198-1.011,0.594-1.406C0.99,6.198,1.459,6,2,6H14z M4,2v3H2V1
                    c0-0.292,0.094-0.531,0.281-0.719S2.709,0,3,0h8.594c0.271,0,0.5,0.094,0.688,0.281l1.438,1.438C13.906,1.906,14,2.136,14,2.406V5
                    h-2V2.812L11.188,2H4z M12,14v-3H4v3H12z M12.969,9.031C13.115,9.178,13.291,9.25,13.5,9.25s0.385-0.072,0.531-0.219
                    C14.178,8.886,14.25,8.708,14.25,8.5s-0.072-0.386-0.219-0.531S13.709,7.75,13.5,7.75s-0.385,0.073-0.531,0.219
                    S12.75,8.292,12.75,8.5S12.822,8.886,12.969,9.031z"/>
        </g>
      </svg>
      <span>{{ 'editor.PRINT_GUIDE' | translate }}</span>
    </button>
    <div class="erom-article-share-block__buttons--share-container">
      <button aria-haspopup="listbox"
              [attr.aria-expanded]="shareListActive"
              (mousedown)="activateShareList()"
              (keydown.arrowUp)="typeAheadUp($event)"
              (keydown.arrowDown)="typeAheadDown($event)"
              (keydown.arrowRight)="onEnter($event)"
              (keydown.home)="typeAheadHome($event)"
              (keydown.end)="typeAheadEnd($event)"
              (keydown.enter)="onEnter($event)"
              (keydown.esc)="close()"
              [attr.aria-activedescendant]="descendentId"
              [attr.aria-controls]="id + '-listbox'"
              [id]="id + '-button'">
        <svg x="0px" y="0px" viewBox="0 0 14 14" style="enable-background:new 0 0 14 14;" xml:space="preserve">
          <g>
            <path fill="currentColor" d="M13.125,8.875C13.708,9.459,14,10.167,14,11c0,0.834-0.292,1.542-0.875,2.125C12.541,13.708,11.833,14,11,14
                      c-0.834,0-1.542-0.292-2.125-0.875C8.291,12.542,8,11.834,8,11c0-0.041,0.005-0.104,0.016-0.188
                      c0.01-0.083,0.016-0.146,0.016-0.188L5.062,9.156C4.5,9.719,3.812,10,3,10c-0.833,0-1.542-0.291-2.125-0.875
                      C0.292,8.542,0,7.833,0,7s0.292-1.542,0.875-2.125S2.167,4,3,4c0.812,0,1.5,0.281,2.062,0.844l2.969-1.469
                      c0-0.042-0.006-0.104-0.016-0.188C8.005,3.104,8,3.042,8,3c0-0.833,0.291-1.542,0.875-2.125C9.458,0.292,10.166,0,11,0
                      c0.833,0,1.541,0.292,2.125,0.875C13.708,1.458,14,2.167,14,3s-0.292,1.542-0.875,2.125C12.541,5.708,11.833,6,11,6
                      c-0.812,0-1.5-0.281-2.062-0.844L5.969,6.625c0,0.042,0.005,0.104,0.016,0.188C5.994,6.896,6,6.958,6,7S5.994,7.104,5.984,7.188
                      C5.974,7.271,5.969,7.333,5.969,7.375l2.969,1.469C9.5,8.281,10.188,8,11,8C11.833,8,12.541,8.292,13.125,8.875z"/>
          </g>
        </svg>
        <span>{{ 'editor.SHARE_GUIDE' | translate }}</span>
      </button>
      <ul [id]="id + '-listbox'"
          #dropdownMenuList
          class="erom-article-share-block__buttons--share-listbox"
          tabindex="-1"
          role="listbox"
          [class.erom-visually-hidden]="!shareListActive"
          [attr.aria-labelledby]="id + '-button'">
        <li role="option"
            [id]="id + '-item-' + i"
            [attr.aria-selected]="id + '-item-' + i === descendentId"
            (mousedown)="onSelect($event, social)"
            [ngClass]="social.type"
            *ngFor="let social of socialMedia; let i = index">
          <div class="erom-article-share-block__buttons--share-icon" [innerHTML]="safeIcon(social.icon)"></div>
          <span>{{ social.name | translate }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
