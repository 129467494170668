import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { uuid } from '../_common';
import { getTinymce } from '../TinyMCE';

@AutoUnsubscribe()
@Component({
  selector: 'erom-editor-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class EromEditorToolbarComponent implements AfterViewInit, OnDestroy {

  @Input() set data(payload: any) {
    if (!payload) {
      return;
    }
    this._blockData = payload;
  }

  @Input() id: string;
  @Input() label: string;
  @Input() hidden: boolean;

  private _blockData: any;

  private toolbarItems: Array<any>;
  private currentItem: HTMLElement;
  private firstItem: any;
  private lastItem: any;

  readonly keyCode: Readonly<any>;

  constructor(
    private elementRef: ElementRef
  ) {
    this.id = uuid('erom-toolbar');
    this.toolbarItems = [];
    this.keyCode = Object.freeze({
      TAB: 9,
      ENTER: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40
    });
  }

  private static setPressed(item: HTMLElement): void {
    item.setAttribute('aria-pressed', 'true');
  }

  private static resetPressed(item: HTMLElement): void {
    item.setAttribute('aria-pressed', 'false');
  }

  public ngAfterViewInit(): void {
    this.init();
  }

  public ngOnDestroy(): void {
  }

  private init(): void {
    this.toolbarItems = Array.from(this.elementRef.nativeElement.querySelectorAll('.erom-editor-toolbar__item, input[role="combobox"]'));
    this.firstItem = this.toolbarItems[0];
    this.lastItem = this.toolbarItems[this.toolbarItems.length - 1];
    for (let i = 0; i < this.toolbarItems.length; i++) {
      this.toolbarItems[i].tabIndex = -1;
      if (this.pressAble(this.toolbarItems[i])) {
        this.toolbarItems[i].addEventListener('click', () => {
          if (this.isPressed(this.toolbarItems[i])) {
            EromEditorToolbarComponent.resetPressed(this.toolbarItems[i]);
          } else {
            EromEditorToolbarComponent.setPressed(this.toolbarItems[i]);
          }
        });
      }
    }
    this.firstItem.tabIndex = 0;
    this.currentItem = this.firstItem;
  }

  private setFocusItem(item: HTMLElement): void {
    for (let i = 0; i < this.toolbarItems.length; i++) {
      this.toolbarItems[i].setAttribute('tabindex', '-1');
    }
    this.currentItem = item;
    item.setAttribute('tabindex', '0');
    item.focus();
  }

  private setFocusToNext(currentItem: HTMLElement): void {
    let index;
    let newItem;
    if (currentItem === this.lastItem) {
      newItem = this.firstItem;
    } else {
      index = this.toolbarItems.indexOf(currentItem);
      newItem = this.toolbarItems[index + 1];
    }
    this.setFocusItem(newItem);
  }

  private setFocusToPrevious(currentItem: HTMLElement): void {
    let index;
    let newItem;
    if (currentItem === this.firstItem) {
      newItem = this.lastItem;
    } else {
      index = this.toolbarItems.indexOf(currentItem);
      newItem = this.toolbarItems[index - 1];
    }
    this.setFocusItem(newItem);
  }

  private setFocusToFirst(): void {
    this.setFocusItem(this.firstItem);
  }

  private setFocusToLast(): void {
    this.setFocusItem(this.lastItem);
  }

  public setCurrentItem(event): void {
    this.currentItem = event.target;
  }

  public handleKeyDown(event): void {
    let flag = false;
    switch (event.keyCode) {
      case this.keyCode.ENTER:
      case this.keyCode.SPACE:
        // run editor command.
        break;
      case this.keyCode.RIGHT:
        this.setFocusToNext(this.currentItem);
        flag = true;
        break;
      case this.keyCode.LEFT:
        this.setFocusToPrevious(this.currentItem);
        flag = true;
        break;
      case this.keyCode.HOME:
        this.setFocusToFirst();
        flag = true;
        break;

      case this.keyCode.END:
        this.setFocusToLast();
        flag = true;
        break;
      case this.keyCode.UP:
        // if (this.buttonAction === 'align') {
        //   if (this.domNode.classList.contains('align-left')) {
        //     this.toolbar.setFocusToLastAlignItem();
        //   }
        //   else {
        //     this.toolbar.setFocusToPrevious(this);
        //   }
        //   flag = true;
        // }
        break;
      case this.keyCode.DOWN:
        // if (this.buttonAction === 'align') {
        //   if (this.domNode.classList.contains('align-right')) {
        //     this.toolbar.setFocusToFirstAlignItem();
        //   }
        //   else {
        //     this.toolbar.setFocusToNext(this);
        //   }
        //   flag = true;
        // }
        break;
      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public isPressed(item: HTMLElement): boolean {
    return item.getAttribute('aria-pressed')  === 'true';
  }

  public pressAble(item: HTMLElement): boolean {
    return item.hasAttribute('aria-pressed');
  }

  public buttonClicked(event): void {
    event.preventDefault();
    event.stopPropagation();
    getTinymce().activeEditor.execCommand('Bold', false, this._blockData.blockId);
  }

  @HostListener('click', ['$event'])
  onDocumentClick(event): void {

  }

  @HostListener('keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent): void {

  }

}
