<div class="erom-article-filters-block">
  <div class="erom-article-filters-block__header" [class.active]="active">
    <span>{{ 'front.CREATED_DATE' | translate : { date: date } }}</span>
    <button [attr.aria-expanded]="active"
            [attr.aria-controls]="id + '-container'"
            (click)="activateFilters()">
      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
      </svg>
      <span *ngIf="active">{{ 'front.HIDE_FILTERS' | translate }}</span>
      <span *ngIf="!active">{{ 'front.SHOW_FILTERS' | translate }}</span>
    </button>
  </div>
  <div class="erom-article-filters-block__container"
       role="region"
       [attr.aria-expanded]="active"
       [attr.aria-hidden]="!active"
       [class.erom-visually-hidden]="!active"
       [id]="id + '-container'">
    <div class="erom-article-filters-block__filters">
      <dl class="erom-article-filters-block__filter">
        <div class="erom-article-filters-block__filter--row" *ngFor="let filter of filters | keyvalue">
          <dt>{{ filter.key }}:</dt>
          <div>
            <dd *ngFor="let tag of filter.value; let i = index">
              <a [routerLink]="['/']" [queryParams]="tagQueryParams(tag)">{{ tag.name }}</a>
              <span *ngIf="i < filter.value.length - 1">|</span>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</div>
