import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';
import { API } from '../../../../../_store';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'image-block',
  templateUrl: './image.component.html',
  styleUrls: ['../../editor/erom-editor.component.scss', './image.component.scss'],
  styles: [':host { display: block; }']
})

export class ImageComponent extends GenericBlock {

  public static type = BlockType.Image;
  public static tagNames = undefined;
  public static blockCategory = 'Media';
  public static blockName = 'Image';
  public static structure = {
    id: null,
    type: BlockType.Image,
    data: {
      file: null,
      alt: null,
      caption: null,
      maxWidth: null,
      html: ''
    }
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" class="svg-inline--fa fa-image fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"></path></svg>';

  @ViewChild('imagePreview', {read: ElementRef, static: false}) imagePreview: ElementRef;

  constructor(
    private api: API,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public init(): void {
    this.setupImageHtml();
  }

  public updateImage(event: any, property: string): void {
    this.block.data[property] = event.target.value;
    this.setupImageHtml();
  }

  public uploadImage(event: any): void {
    const formData = new FormData();
    formData.append('file', event.target.files[0], event.target.files[0].name);
    this.http.post(`${this.api.URLS.file}/upload`, formData).subscribe((response: any) => {
      this.block.data.file = response.file.path;
      // if (!this.block.data.alt && response.file.name) {
      //   this.block.data.alt = response.file.name;
      // }
      // if (!this.block.data.caption && response.file.name) {
      //   this.block.data.caption = response.file.name;
      // }
      this.setupImageHtml();
      this.changeDetectorRef.detectChanges();
    }, (error) => {
      console.log(error);
    });
  }

  public removeImage(): void {
    this.block.data = JSON.parse(JSON.stringify(ImageComponent.structure.data));
  }

  private setupImageHtml(): void {
    const div = this.document.createElement('div');
    const figure = this.document.createElement('figure');
    const img = this.document.createElement('img');
    let figcaption;
    if (this.block.data.file) {
      img.src = this.block.data.file;
      if (this.block.data.alt) {
        img.alt = this.block.data.alt;
      }
      figure.appendChild(img);
      if (this.block.data.maxWidth) {
        figure.style.maxWidth = this.block.data.maxWidth;
      }
      if (this.block.data.caption) {
        figcaption = this.document.createElement('figcaption');
        figcaption.innerText = this.block.data.caption;
      }
      if (figcaption) {
        figure.appendChild(figcaption);
      }
      div.appendChild(figure);
      this.block.data.html = div.innerHTML;
    }
    this.eromEditorBlockChanged.emit();
  }

  public get type(): string {
    return ImageComponent.type;
  }

  public get tagNames(): Array<string> {
    return ImageComponent.tagNames;
  }

  public get blockName(): string {
    return ImageComponent.blockName;
  }

  public get blockCategory(): string {
    return ImageComponent.blockCategory;
  }

  public get icon(): string {
    return ImageComponent.icon;
  }

  public get structure(): object {
    return ImageComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
