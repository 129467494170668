import { Component, ElementRef } from '@angular/core';
import { GenericBlock } from '../generic-block';
import { BlockType } from '../../_types';
import { BlockOptionsInterface } from '../../_interfaces';

@Component({
  selector: 'iframe-block',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  styles: [':host { display: block; }']
})

export class IframeComponent extends GenericBlock {

  public static type = BlockType.Iframe;
  public static tagNames = ['iframe'];
  public static blockCategory = 'Media';
  public static blockName = 'Iframe';
  public static structure = {
    id: null,
    type: BlockType.Iframe,
    data: {
      source: ''
    }
  };

  public static icon = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="laptop-code" class="svg-inline--fa fa-laptop-code fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z"></path></svg>';

  constructor() {
    super();
  }

  public init(): void {

  }

  public updateIframe(event: any): void {
    this.block.data.source = event.target.innerText;
  }

  public get type(): string {
    return IframeComponent.type;
  }

  public get tagNames(): Array<string> {
    return IframeComponent.tagNames;
  }

  public get blockName(): string {
    return IframeComponent.blockName;
  }

  public get blockCategory(): string {
    return IframeComponent.blockCategory;
  }

  public get icon(): string {
    return IframeComponent.icon;
  }

  public get structure(): object {
    return IframeComponent.structure;
  }

  public get options(): BlockOptionsInterface[] {
    return [];
  }

}
