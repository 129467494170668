export enum VerticalPosition {
  'LEFT' = 'left',
  'CENTER' = 'center',
  'RIGHT' = 'right'
}
export enum HorizontalPosition {
  'TOP' = 'top',
  'MIDDLE' = 'middle',
  'BOTTOM' = 'bottom'
}
